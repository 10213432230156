import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";


export const GovConSmartSearch = inject("CaptureExecMobx", "GCSSMobx", "DatabaseMobx", "UserMobx")(observer(
class GovConSmartSearch extends Component {
  componentDidMount() {
    const c_companyGcssDataSourceSamShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceSamShownTF;
    const c_companyGcssDataSourceGovWinShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceGovWinShownTF;
    const c_companyGcssDataSourceFedCompShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceFedCompShownTF;
    const c_userGcssSam0GovWin1 = this.props.UserMobx.c_userGcssSam0GovWin1;

    //if this user has a datasource selected that is not one of the shown options, select the first available data source
    var availableDataSourceIndicesArray = [];
    if(c_companyGcssDataSourceSamShownTF) { availableDataSourceIndicesArray.push(3); availableDataSourceIndicesArray.push(0); }
    if(c_companyGcssDataSourceGovWinShownTF) { availableDataSourceIndicesArray.push(1); }
    if(c_companyGcssDataSourceFedCompShownTF) { availableDataSourceIndicesArray.push(2); }

    if(!JSFUNC.in_array(c_userGcssSam0GovWin1, availableDataSourceIndicesArray)) {
      var firstDataSourceIndex = 0; //select 0 SAM.gov under the hood if no data sources are shown to the user
      if(availableDataSourceIndicesArray.length > 0) {
        firstDataSourceIndex = availableDataSourceIndicesArray[0]; //first data source in order of available data sources
      }
      this.props.UserMobx.a_update_user_per_email_field("gcss_sam0_govwin1", firstDataSourceIndex, "i");
    }

    //most users do not ever use GCSS during their time in CaptureExec, so data tables (tbl_g_gcss_not_interested) are not loaded at login, they are loaded now when the GCSS tab is opened for the first time
    this.props.GCSSMobx.a_load_gcss_tab_tbls_to_local_memory_if_not_yet_loaded();
  }

  render() {
    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_manualSearchIsOpenTF = this.props.GCSSMobx.o_manualSearchIsOpenTF;
    const o_openSearchResultDetailGcssIDOrUndefined = this.props.GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined;
    const c_gcssHasAtLeast1DataSourceShownTF = this.props.DatabaseMobx.c_gcssHasAtLeast1DataSourceShownTF;

    const anyDetailSearchResultIsOpenTF = (o_openSearchResultDetailGcssIDOrUndefined !== undefined);

    var openDetailSearchResultsPanelLeft = "35%";
    if(o_mediaQueryFlag === 1) { openDetailSearchResultsPanelLeft = "2em"; }
    else if(o_mediaQueryFlag === 2) { openDetailSearchResultsPanelLeft = "15%"; }
    else if(o_mediaQueryFlag === 3) { openDetailSearchResultsPanelLeft = "10%"; }

    return(
      <>
        <div className="flex11a displayFlexRow positionRelative" style={{flexBasis:"100em"}}>
          <AutoSearchPanel />
          <div className="flex11a displayFlexColumn">
            {(c_gcssHasAtLeast1DataSourceShownTF) ? (
              <SearchResultsContent />
            ) : (
              <CEGeneralReact.EmptyScreenWhite p_fontClass="font12 fontTextLighter">
                <div className="textCenter" style={{maxWidth:"40em"}}>
                  {"Let your " + c_productStylingObj.productName + " Admin know to switch on at least 1 Data Source to get started searching with GCSS"}
                </div>
              </CEGeneralReact.EmptyScreenWhite>
            )}
          </div>
          {(o_manualSearchIsOpenTF && c_isMobileOrTabletTF) &&
            <div className="positionAbsolute displayFlexColumn boxShadowDarkGray borderL1bbb borderB1bbb bgWhite" style={{top:"0.5em", right:0, bottom:"1em", left:"15%"}}>
              <GCSSSearchEditorContents />
            </div>
          }
          {(anyDetailSearchResultIsOpenTF) &&
            <div className="positionAbsolute displayFlexColumn boxShadowDarkGray borderL1bbb borderB1bbb bgWhite" style={{top:"0.5em", right:0, bottom:"1em", left:openDetailSearchResultsPanelLeft}}>
              <GCSSOpenDetailSearchResultContents />
            </div>
          }
        </div>
        {(o_manualSearchIsOpenTF && !c_isMobileOrTabletTF) &&
          <div className="flex11a displayFlexColumn bgLighterGray" style={{flexBasis:"150em"}}>
            <div className="flex11a displayFlexColumn boxShadowDarkGray" style={{marginLeft:"1.5em", marginBottom:"0.5em"}}>
              <GCSSSearchEditorContents />
            </div>
          </div>
        }
        <GCSSImportProgressFloatingBox />
      </>
    );
  }
}));


//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&


const AutoSearchPanel = inject("CaptureExecMobx", "GCSSMobx", "DatabaseMobx", "UserMobx")(observer(
class AutoSearchPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s_numFetch: 100,
      s_posNoticeIDsComma: "",
      s_negNoticeIDsComma: ""
    };
  }

  componentDidMount() {
    const o_cexaiSelectedDivisionSamUei = this.props.GCSSMobx.o_cexaiSelectedDivisionSamUei;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    //if not filled out, select the SAM UEI for the user's division when this first mounts
    if(!JSFUNC.string_is_filled_out_tf(o_cexaiSelectedDivisionSamUei)) {
      this.props.GCSSMobx.a_set_cexai_selected_division_sam_uei(c_combinedUserObj.divisionCalcSamUeiWithUphillRoll);
    }
  }

  onclick_expand_auto_search_panel = () => {
    this.props.GCSSMobx.a_set_auto_search_panel_is_expanded_tf(true);
  }

  onclick_collapse_auto_search_panel = () => {
    this.props.GCSSMobx.a_set_auto_search_panel_is_expanded_tf(false);
  }

  onselect_gcss_data_source_tab = (i_gcssTabSam0GovWin1FedComp2) => {
    //close search editor and any open detail result panel
    this.props.GCSSMobx.a_close_any_open_gcss_panel();

    //clear previous search from previously selected data source
    this.props.GCSSMobx.a_clear_previous_search_results_to_init_state();

    //set that any open auto search is not selected
    this.props.GCSSMobx.a_set_selected_auto_search_id_or_undefined(undefined, false);
    this.props.GCSSMobx.a_set_selected_auto_search_has_unsaved_changes_tf(false);

    //clear all search editor filter settings
    this.props.GCSSMobx.a_manual_search_clear_all_filter_values();

    //update user data source tab selected
    this.props.UserMobx.a_update_user_per_email_field("gcss_sam0_govwin1", i_gcssTabSam0GovWin1FedComp2, "i");
  }

  onclick_create_new_search = () => {
    //set that any open auto search is not selected
    this.props.GCSSMobx.a_set_selected_auto_search_id_or_undefined(undefined, false);
    this.props.GCSSMobx.a_set_selected_auto_search_has_unsaved_changes_tf(false);

    //clear all search editor filter settings
    this.props.GCSSMobx.a_manual_search_clear_all_filter_values();

    //open the search panel (if it's not already open from an auto search)
    this.props.GCSSMobx.a_open_gcss_panel();
  }

  onclick_close_search_editor = () => {
    this.props.GCSSMobx.a_close_gcss_search_panel(); //close search editor without clearing filter settings (whether new search or editing autosearch)
  }

  onclick_edit_current_search = () => {
    const o_selectedAutoSearchIDOrUndefined = this.props.GCSSMobx.o_selectedAutoSearchIDOrUndefined;

    if(o_selectedAutoSearchIDOrUndefined === undefined) { //no autosearch currently run with search results displaying, simply open search panel (either new search or unsaved edited new search)
      this.props.GCSSMobx.a_open_gcss_panel();
    }
    else {
      this.props.GCSSMobx.a_edit_auto_search_from_id(o_selectedAutoSearchIDOrUndefined);
    }
  }

  onclick_run_cexai = () => {
    const s_numFetch = this.state.s_numFetch;
    //const s_posNoticeIDsComma = this.state.s_posNoticeIDsComma;
    //const s_negNoticeIDsComma = this.state.s_negNoticeIDsComma;
    
    const o_cexaiSelectedDivisionSamUei = this.props.GCSSMobx.o_cexaiSelectedDivisionSamUei;
    //const c_gcssAllSamSearchResultsMarkedForImportSolicitationNumbersArray = this.props.DatabaseMobx.c_gcssAllSamSearchResultsMarkedForImportSolicitationNumbersArray;
    const c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray = this.props.DatabaseMobx.c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray;

    //const posSolNumsComma = JSFUNC.convert_array_to_comma_list(c_gcssAllSamSearchResultsMarkedForImportSolicitationNumbersArray);
    const negGcssRowIDsComma = JSFUNC.convert_array_to_comma_list(c_gcssAllSamSearchResultsMarkedAsNotInterestedGcssIDsArray);

    this.props.GCSSMobx.a_fetch_cexai_search_results(o_cexaiSelectedDivisionSamUei, s_numFetch, "", negGcssRowIDsComma);
  }

  onchange_num_fetch = (i_newValue) => {
    this.setState({s_numFetch:i_newValue});
  }

  onselect_division_with_sam_uei_filled_out = (i_selectedDivisionSamUei) => {
    this.props.GCSSMobx.a_set_cexai_selected_division_sam_uei(i_selectedDivisionSamUei);
  }

  onchange_pos_notices = (i_newValue) => {
    this.setState({s_posNoticeIDsComma:i_newValue});
  }

  onchange_neg_notices = (i_newValue) => {
    this.setState({s_negNoticeIDsComma:i_newValue});
  }

  render() {
    const s_numFetch = this.state.s_numFetch;
    //const s_posNoticeIDsComma = this.state.s_posNoticeIDsComma;
    //const s_negNoticeIDsComma = this.state.s_negNoticeIDsComma;
    //!!!!!!!!!TODO: make a more permanent solution for these pos/neg things!!!!!
    const posSolNumsComma = JSFUNC.convert_array_to_comma_list(this.props.DatabaseMobx.c_gcssAllSamSearchResultsMarkedForImportSolicitationNumbersArray);
    const negSolNumsComma = JSFUNC.convert_array_to_comma_list(this.props.DatabaseMobx.c_gcssAllSamSearchResultsMarkedAsNotInterestedSolicitationNumbersArray);
    //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_autoSearchPanelIsExpandedTF = this.props.GCSSMobx.o_autoSearchPanelIsExpandedTF;
    const o_manualSearchIsOpenTF = this.props.GCSSMobx.o_manualSearchIsOpenTF;
    const o_selectedAutoSearchIDOrUndefined = this.props.GCSSMobx.o_selectedAutoSearchIDOrUndefined;
    const o_searchResultsLoadingFlag = this.props.GCSSMobx.o_searchResultsLoadingFlag;
    const o_cexaiSelectedDivisionSamUei = this.props.GCSSMobx.o_cexaiSelectedDivisionSamUei;
    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;
    const c_createNewSearchFilledOutFiltersWithValuesHoverTextBlock = this.props.GCSSMobx.c_createNewSearchFilledOutFiltersWithValuesHoverTextBlock;
    const c_cexaiSelectDivisionWithSamUeiFilledOutFieldTypeObj = this.props.GCSSMobx.c_cexaiSelectDivisionWithSamUeiFilledOutFieldTypeObj;
    const c_cexaiSelectedSamUeiCompanyName = this.props.GCSSMobx.c_cexaiSelectedSamUeiCompanyName;
    const c_companyGcssDataSourceSamShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceSamShownTF;
    const c_companyGcssDataSourceGovWinShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceGovWinShownTF;
    const c_companyGcssDataSourceFedCompShownTF = this.props.DatabaseMobx.c_companyGcssDataSourceFedCompShownTF;
    const c_gcssHasAtLeast1DataSourceShownTF = this.props.DatabaseMobx.c_gcssHasAtLeast1DataSourceShownTF;
    const c_userGcssSam0GovWin1 = this.props.UserMobx.c_userGcssSam0GovWin1;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;

    const allowAutoSearchPanelCollapseTF = c_isMobileTF;
    const autoSearchPanelIsExpandedTF = (!allowAutoSearchPanelCollapseTF || o_autoSearchPanelIsExpandedTF); //panel being expanded/collapsed is only an option for mobile size, otherwise permanently expanded

    //auto search panel is collapsed
    if(!autoSearchPanelIsExpandedTF) {
      return(
        <div className="flex00a displayFlexColumn bgDarkGray borderR1bbb" style={{flexBasis:"1.5em"}}>
          <div
            className="flex00a displayFlexColumnHcVc border bevelBorderDarkColors bgDarkGray hoverGrayGradient textCenter cursorPointer"
            style={{flexBasis:"2em"}}
            title="Expand Auto Search Panel"
            onClick={this.onclick_expand_auto_search_panel}>
            <font className="fontWhite">
              {">>"}
            </font>
          </div>
          <div className="flex11a" />
        </div>
      );
    }

    var dataSourceTabDbNamesArray = [];
    var dataSourceTabDisplayNamesArray = [];
    if(c_companyGcssDataSourceSamShownTF) {
      dataSourceTabDbNamesArray.push(3);
      dataSourceTabDisplayNamesArray.push("CE.A.I.");
      dataSourceTabDbNamesArray.push(0);
      dataSourceTabDisplayNamesArray.push("SAM.gov");
    }
    if(c_companyGcssDataSourceGovWinShownTF) {
      dataSourceTabDbNamesArray.push(1);
      dataSourceTabDisplayNamesArray.push("GovWin");
    }
    if(c_companyGcssDataSourceFedCompShownTF) {
      dataSourceTabDbNamesArray.push(2);
      dataSourceTabDisplayNamesArray.push("Federal Compass");
    }

    const newOrAutoSearchHasBeenConductedTF = (o_searchResultsLoadingFlag !== "init");
    const searchIsLoadingTF = (o_searchResultsLoadingFlag === "loading");

    var createNewSearchButtonTitle = "Click here to create and conduct a new search of the GCSS opportunities"; //says this for "init" and when an auto search is selected
    if(newOrAutoSearchHasBeenConductedTF && (o_selectedAutoSearchIDOrUndefined === undefined)) {
      createNewSearchButtonTitle = "Click here to continue editing the most recent New Search"; //says this when an unsaved search has been conducted
      createNewSearchButtonTitle += "\n" + c_createNewSearchFilledOutFiltersWithValuesHoverTextBlock;
    }

    return(
      <div className="flex00a displayFlexColumn bgLighterGray borderR1bbb" style={{flexBasis:"16em"}}>
        {(allowAutoSearchPanelCollapseTF) &&
          <div
            className="flex00a displayFlexColumnVc border bevelBorderDarkColors bgDarkGray hoverGrayGradient lrPad textRight cursorPointer"
            style={{flexBasis:"1.5em"}}
            title="Collapse Auto Search Panel"
            onClick={this.onclick_collapse_auto_search_panel}>
            <font className="fontWhite">
              {"<<"}
            </font>
          </div>
        }
        <div className="flex00a borderB1bbb bgDarkGray tbPad">
          <div className="microBottomMargin textCenter">
            <font className="fontAlmostWhite">
              {"Data Source"}
            </font>
          </div>
          <div style={{margin:"0 1.5em"}}>
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={dataSourceTabDbNamesArray}
              p_tabDisplayNamesArray={dataSourceTabDisplayNamesArray}
              p_selectedTabDbName={c_userGcssSam0GovWin1}
              p_tabHeight="1.5em"
              p_textMaxHeight="1.5em"
              p_tabWidth={undefined}
              p_selectedBgClass={c_gcssDataSourceColorsObj.gcssBgDarkGradientClass}
              p_selectedFontClass="fontAlmostWhite"
              p_unselectedBgClass="bgLightGrayGradient hoverLighterGrayGradient"
              p_unselectedFontClass=""
              p_canEditTF={true}
              p_rowFlexWrapTF={false}
              p_verticalTabsTF={true}
              p_tabSpacing={undefined}
              p_borderRadiusClass="borderRadius10"
              p_unselectValue={undefined}
              f_onSelect={this.onselect_gcss_data_source_tab}
            />
          </div>
        </div>
        {(c_gcssHasAtLeast1DataSourceShownTF) &&
          (!c_userGcssDataSource3CexaiTF) ? (
            <>
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"6em"}}>
                {(!o_manualSearchIsOpenTF) &&
                  <div
                    className={"displayFlexColumnHcVc medTopMargin border bevelBorderDarkColors bgLightesterGrayGradient hoverLightestBlueGradient textCenter cursorPointer"}
                    style={{width:"13em", height:"3em"}}
                    title={"Click here to create and conduct a new search of the GCSS opportunities"}
                    onClick={this.onclick_create_new_search}>
                    <font className="font11 fontBold">
                      {"Create New Search"}
                    </font>
                  </div>
                }
                {(!o_manualSearchIsOpenTF && newOrAutoSearchHasBeenConductedTF) &&
                  <div className="displayFlexColumnHcVc medTopMargin">
                    <CEGeneralReact.CEButton
                      p_type="add"
                      p_text="Edit Current Search"
                      f_onClick={this.onclick_edit_current_search}
                    />
                  </div>
                }
                {(o_manualSearchIsOpenTF) &&
                  <div
                    className="displayFlexColumnHcVc medTopMargin border bevelBorderDarkColors bgDarkGrayGradient hoverGrayGradient textCenter cursorPointer"
                    style={{width:"11em", height:"3.3em"}}
                    title={"Click to close the GCSS Search Editor panel"}
                    onClick={this.onclick_close_search_editor}>
                    <div style={{height:"1.6em"}}><font className="font14 fontWhite">{"\u2715"}</font></div>
                    <div><font className="fontItalic fontWhite">{"Close Search Editor"}</font></div>
                  </div>
                }
              </div>
              <div className="flex00a">
                <div className="borderT1bbb medFullMargin" />
                <div className=" microBottomMargin textCenter">
                  <font className="font11 fontBold fontItalic fontTextLight">
                    {"My Auto Searches"}
                  </font>
                </div>
              </div>
              <div className="flex11a yScroll bgLighterGray">
                <AutoSearchButtonsList />
              </div>
            </>
          ) : (
            <>
              <div className="flex00a displayFlexColumnHcVc">
                <div
                  className={"displayFlexColumnHcVc medTopMargin border bevelBorderDarkColors borderRadius10 bgDarkGreenGradient textCenter " + ((searchIsLoadingTF) ? ("") : ("hoverGreenGradient cursorPointer"))}
                  style={{width:"13em", height:"3em"}}
                  title={"Run the CE.A.I. Large Language Model (LLM) comparing the text of your company attributes to public opportunities"}
                  onClick={((searchIsLoadingTF) ? (undefined) : (this.onclick_run_cexai))}>
                  <font className={"font11 fontWhite " + ((searchIsLoadingTF) ? ("fontItalic") : ("fontBold"))}>
                    {"Run CE.A.I."}
                  </font>
                </div>
              </div>
              <div className="lrPad">
                <div className="bigTopMargin">
                  <div>
                    <font className="fontItalic fontTextLight">
                      {"Company SAM UEI:"}
                    </font>
                  </div>
                  <div className="">
                    <CEGeneralReact.GenericInputOrSelectFromInputType
                      p_fieldTypeObj={c_cexaiSelectDivisionWithSamUeiFilledOutFieldTypeObj}
                      p_valueRaw={o_cexaiSelectedDivisionSamUei}
                      f_onChangeOrOnSelect={this.onselect_division_with_sam_uei_filled_out}
                    />
                  </div>
                  <div className="smallTopMargin">
                    {(JSFUNC.string_is_filled_out_tf(o_cexaiSelectedDivisionSamUei)) ? (
                      <font className="">
                        <div>{o_cexaiSelectedDivisionSamUei}</div>
                        <div>{c_cexaiSelectedSamUeiCompanyName}</div>
                      </font>
                    ) : (
                      <font className="fontBold fontDarkRed">
                        {"--No SAM UEI Selected by your Admin for your Company/Division--"}
                      </font>
                    )}
                  </div>
                </div>
                <div className="hugeTopMargin">
                  <div><font className="fontItalic fontTextLight">{"Max # Records to Fetch:"}</font></div>
                  <LibraryReact.Integer p_value={s_numFetch} f_onChange={this.onchange_num_fetch} />
                </div>
                {(false) &&
                  <div className="hugeTopMargin">
                    <div><font className="fontItalic fontTextLight">{"Positive Solicitation Number Examples"}</font></div>
                    <LibraryReact.Textarea p_value={posSolNumsComma} p_styleObj={{height:"6em"}} f_onChange={this.onchange_pos_notices} />
                  </div>
                }
                <div className="bigTopMargin">
                  <div>
                    <font className="fontItalic fontTextLight">
                      <div>{"Model Avoidance"}</div>
                      <div>{"Solicitation Numbers:"}</div>
                    </font>
                  </div>
                  <LibraryReact.Textarea p_value={negSolNumsComma} p_styleObj={{height:"6em"}} f_onChange={this.onchange_neg_notices} />
                </div>
              </div>
            </>
          )
        }
      </div>
    );
  }
}));


const AutoSearchButtonsList = inject("GCSSMobx")(observer(
class AutoSearchButtonsList extends Component {
  render() {
    const c_autoSearchesArrayOfObjs = this.props.GCSSMobx.c_autoSearchesArrayOfObjs;

    const numAutoSearches = c_autoSearchesArrayOfObjs.length;
    const numAutoSearchesIs1TF = (numAutoSearches === 1);
    const lastAutoSearchIndex = (numAutoSearches - 1);

    return(
      c_autoSearchesArrayOfObjs.map((m_autoSearchObj, m_index) =>
        <AutoSearchButton
          p_autoSearchObj={m_autoSearchObj}
          p_isOnlyItemTF={numAutoSearchesIs1TF}
          p_isLastItemTF={(m_index === lastAutoSearchIndex)}
        />
      )
    );
  }
}));


const AutoSearchButton = inject("GCSSMobx", "UserMobx")(observer(
class AutoSearchButton extends Component { //props: p_autoSearchObj, p_isOnlyItemTF, p_isLastItemTF
  onclick_auto_search_button = () => {
    const p_autoSearchObj = this.props.p_autoSearchObj;

    this.props.GCSSMobx.a_close_any_open_gcss_panel();

    const fetchGcssTblSearchResultsTF = true;
    this.props.GCSSMobx.a_set_selected_auto_search_id_or_undefined(p_autoSearchObj.id, fetchGcssTblSearchResultsTF);
  }

  onclick_edit_auto_search = () => {
    const p_autoSearchObj = this.props.p_autoSearchObj;
    this.props.GCSSMobx.a_edit_auto_search_from_id(p_autoSearchObj.id);
  }

  onclick_rename_auto_search = (i_updatedName) => {
    const p_autoSearchObj = this.props.p_autoSearchObj;
    this.props.GCSSMobx.a_rename_auto_search_from_id_old_name_and_updated_name(p_autoSearchObj.id, p_autoSearchObj.name, i_updatedName);
  }

  onclick_save_as_auto_search_button = (i_newAutoSearchName) => {
    this.props.GCSSMobx.a_create_new_auto_search_or_save_existing_auto_search(true, i_newAutoSearchName);
  }

  onclick_delete_auto_search = () => {
    const p_autoSearchObj = this.props.p_autoSearchObj;
    this.props.GCSSMobx.a_delete_auto_search_from_id(p_autoSearchObj.id);
  }

  render() {
    const p_autoSearchObj = this.props.p_autoSearchObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const o_searchResultsLoadingFlag = this.props.GCSSMobx.o_searchResultsLoadingFlag;
    const o_selectedAutoSearchIDOrUndefined = this.props.GCSSMobx.o_selectedAutoSearchIDOrUndefined;
    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;
    const o_userPerEmailID = this.props.UserMobx.o_userPerEmailID;
    const c_userGcssSam0GovWin1 = this.props.UserMobx.c_userGcssSam0GovWin1;

    const searchIsLoadingTF = (o_searchResultsLoadingFlag === "loading");
    const autoSearchIsSelectedTF = (p_autoSearchObj.id === o_selectedAutoSearchIDOrUndefined);

    var autoSearchButtonTitle = "";
    if(searchIsLoadingTF) {
      autoSearchButtonTitle = "--A GCSS Search is currently running--";
    }
    else {
      autoSearchButtonTitle = "Click this button to conduct this search and view search results on the right.";
      autoSearchButtonTitle += "\nYou can edit the parameters of the Auto Search by clicking the vertical dots and selecting 'Edit Auto Search'.";
      autoSearchButtonTitle += "\n" + p_autoSearchObj.filledOutFiltersWithValuesHoverTextBlock;
    }

    const autoSearchButtonMenuItemsArrayOfObjs = [
      {
        displayName: "Edit Auto Search",
        functionOnClickMenuItem: this.onclick_edit_auto_search
      },
      {
        displayName: "Rename",
        confirmType: "inputText",
        confirmTitle: "Rename Auto Search",
        confirmTextInitialValue: p_autoSearchObj.name,
        confirmButton1Name: "Rename",
        confirmMessage: "Enter a new name for Auto Search '" + p_autoSearchObj.name + "'",
        functionOnClickConfirmButton: this.onclick_rename_auto_search
      },
      {
        displayName: "Copy to new Auto Search",
        confirmType: "inputText",
        confirmTitle: "Copy to new Auto Search",
        confirmTextInitialValue: p_autoSearchObj.name + " - COPY",
        confirmButton1Name: "Create New Auto Search",
        confirmTextMustBeChangedForSaveTFU: false,
        functionOnClickConfirmButton: this.onclick_save_as_auto_search_button
      },
      {
        displayName: "Delete",
        confirmType: "confirmDelete",
        confirmTitle: "Delete Auto Search",
        confirmMessage: "Are you sure you want to delete Auto Search '" + p_autoSearchObj.name + "'?",
        functionOnClickConfirmButton: this.onclick_delete_auto_search
      }
    ];

    var autoSearchButtonBgClass = c_gcssDataSourceColorsObj.gcssBgLightGradientWithHoverClass;
    var autoSearchButtonItemSortNumberFontClass = undefined;
    var autoSearchButtonFontColorClass = "fontTextLight";
    if(autoSearchIsSelectedTF) {
      autoSearchButtonFontColorClass = "fontWhite";
      autoSearchButtonItemSortNumberFontClass = "fontAlmostWhite";
      autoSearchButtonBgClass = c_gcssDataSourceColorsObj.gcssBgDarkGradientClass;
    }

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={true}
        p_uniqueString="gcssAutoSearches"
        p_itemID={p_autoSearchObj.id}
        p_itemSort={p_autoSearchObj.sort}
        p_itemSortColumnWidth="2em"
        p_itemSortNumberFontClass={autoSearchButtonItemSortNumberFontClass}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="lrMedPad tbMicroPad"
        p_itemClass={"border bevelBorderColors " + autoSearchButtonBgClass}
        p_itemStyleObj={{height:"3.8em"}}
        p_lastItemExtraDropZoneHeight="3em"
        p_tblName="tbl_u_gcss_auto_searches"
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={["user_per_email_id", "auto_search_sam0_govwin1"]}
        p_filterValuesArray={[o_userPerEmailID, c_userGcssSam0GovWin1]}
        f_onDropForeignItem={undefined}>
        <div
          className="flex11a displayFlexRowVc lrMedPad cursorPointer"
          title={autoSearchButtonTitle}
          onClick={((searchIsLoadingTF) ? (undefined) : (this.onclick_auto_search_button))}>
          <LibraryReact.MaxHeightWrap p_maxHeight="3.2em" p_fontClass={"fontBold " + autoSearchButtonFontColorClass}>
            {p_autoSearchObj.name}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em"}}>
          <CEGeneralReact.VerticalDotsMenu
            p_menuItemsArrayOfObjs={autoSearchButtonMenuItemsArrayOfObjs}
            p_dotsFontClass={autoSearchButtonFontColorClass}
          />
        </div>
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));



//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&


const CloseGCSSOpenPanelButton = inject("GCSSMobx")(observer(
class CloseGCSSOpenPanelButton extends Component { //props: p_fontClass, f_onClick
  render() {
    const p_fontClass = this.props.p_fontClass;

    return(
      <div
        className="flex00a displayFlexColumnHcVc hoverLightGrayGradient cursorPointer textCenter"
        style={{flexBasis:"4em"}}
        onClick={this.props.f_onClick}>
        <font className={"font15 fontBold " + p_fontClass}>
          {"\u2794"}
        </font>
      </div>
    );
  }
}));


const GCSSSearchEditorContents = inject("CaptureExecMobx", "GCSSMobx", "DatabaseMobx", "UserMobx")(observer(
class GCSSSearchEditorContents extends Component {
  onkeydownesc_manual_search = () => {
    this.props.GCSSMobx.a_close_gcss_search_panel();
  }

  onclick_search_button = () => {
    const numResultsToFetch = false; //use default value for SAM/GovWin
    const fetchOffset = false; //default 0 offset
    const appendResultsTrueOverwriteResultsFalse = false; //overwrite previous results with newly fetched results of this autosearch
    this.props.GCSSMobx.a_fetch_gcss_tbl_search_results_from_current_filter_settings(numResultsToFetch, fetchOffset, appendResultsTrueOverwriteResultsFalse);
  }

  onclick_rename_auto_search = (i_updatedName) => {
    const c_selectedAutoSearchObjOrUndefined = this.props.GCSSMobx.c_selectedAutoSearchObjOrUndefined;
    this.props.GCSSMobx.a_rename_auto_search_from_id_old_name_and_updated_name(c_selectedAutoSearchObjOrUndefined.id, c_selectedAutoSearchObjOrUndefined.name, i_updatedName);
  }

  onclick_save_as_auto_search_button = (i_newAutoSearchName) => {
    this.props.GCSSMobx.a_create_new_auto_search_or_save_existing_auto_search(true, i_newAutoSearchName);
  }

  onclick_save_changes_to_auto_search_button = () => {
    const c_selectedAutoSearchObjOrUndefined = this.props.GCSSMobx.c_selectedAutoSearchObjOrUndefined;
    if(c_selectedAutoSearchObjOrUndefined !== undefined) {
      this.props.GCSSMobx.a_create_new_auto_search_or_save_existing_auto_search(false, c_selectedAutoSearchObjOrUndefined.id);
    }
  }

  onclick_clear_search_button = () => {
    this.props.GCSSMobx.a_manual_search_clear_all_filter_values();
    this.props.GCSSMobx.a_set_selected_auto_search_has_unsaved_changes_tf(true);
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_selectedAutoSearchHasUnsavedChangesTF = this.props.GCSSMobx.o_selectedAutoSearchHasUnsavedChangesTF;
    const o_searchResultsLoadingFlag = this.props.GCSSMobx.o_searchResultsLoadingFlag;
    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;
    const c_manualSearchNumFiltersBoxesPerRow = this.props.GCSSMobx.c_manualSearchNumFiltersBoxesPerRow;
    const c_searchFieldsWithValuesObj = this.props.GCSSMobx.c_searchFieldsWithValuesObj;
    const c_searchImportDefaultSelectedCaptureTypeIsFilledOutTF = this.props.GCSSMobx.c_searchImportDefaultSelectedCaptureTypeIsFilledOutTF;
    const c_searchImportDefaultStagesInSelectedCaptureTypeFieldTypeObj = this.props.GCSSMobx.c_searchImportDefaultStagesInSelectedCaptureTypeFieldTypeObj;
    const c_selectedAutoSearchObjOrUndefined = this.props.GCSSMobx.c_selectedAutoSearchObjOrUndefined;
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;

    const includeAlreadyInCEResultsCheckedTF = c_searchFieldsWithValuesObj.includeAlreadyInCEResultsCheckedTF;
    const includeNotInterestedResultsCheckedTF = c_searchFieldsWithValuesObj.includeNotInterestedResultsCheckedTF;
    const boxesOfBubblesOfFiltersWithValuesArrayOfObjs = c_searchFieldsWithValuesObj.boxesOfBubblesOfFiltersWithValuesArrayOfObjs;

    const editingAutoSearchTF = (c_selectedAutoSearchObjOrUndefined !== undefined);

    var currentSearchFontClass = "font13 fontBold fontItalic fontAlmostWhite";
    var currentSearchName = "Unsaved New Search";
    if(editingAutoSearchTF) {
      currentSearchFontClass = "font13 fontBold " + c_gcssDataSourceColorsObj.fontLightClass;
      currentSearchName = c_selectedAutoSearchObjOrUndefined.name;
    }

    const gcssEditSearchMenuItemsArrayOfObjs = [
      {
        displayName: "Rename",
        confirmType: "inputText",
        confirmTitle: "Rename Auto Search",
        confirmTextInitialValue: ((editingAutoSearchTF) ? (c_selectedAutoSearchObjOrUndefined.name) : ("")),
        confirmButton1Name: "Rename",
        confirmMessage: "Enter a new name for Auto Search" + ((editingAutoSearchTF) ? (" '" + c_selectedAutoSearchObjOrUndefined.name + "'") : ("")),
        functionOnClickConfirmButton: this.onclick_rename_auto_search
      },
      {
        displayName: "Copy to new Auto Search",
        confirmType: "inputText",
        confirmTitle: "Copy to new Auto Search",
        confirmTextInitialValue: ((editingAutoSearchTF) ? (currentSearchName + " - COPY") : ("")),
        confirmButton1Name: "Create New Auto Search",
        confirmTextMustBeChangedForSaveTFU: ((editingAutoSearchTF) ? (false) : (true)),
        functionOnClickConfirmButton: this.onclick_save_as_auto_search_button
      }
    ];

    const manualSearchFiltersBoxIndicesToDrawArray = JSFUNC.array_fill_incrementing_0_to_nm1(boxesOfBubblesOfFiltersWithValuesArrayOfObjs.length); //array 1-N number of filter boxes
    const manualSearchFiltersBoxIndicesRCMatrix = JSFUNC.get_rc_matrix_from_id_array_and_num_columns(manualSearchFiltersBoxIndicesToDrawArray, c_manualSearchNumFiltersBoxesPerRow);

    return(
      <LibraryReact.InteractiveDiv
        p_class="flex11a displayFlexColumn bgLightGray"
        f_onKeyDownEsc={this.onkeydownesc_manual_search}>
        <div className="flex00a displayFlexRow borderB1bbb bgDarkGrayGradient" style={{flexBasis:"3.6em"}}>
          <CloseGCSSOpenPanelButton p_fontClass="fontWhite" f_onClick={this.onkeydownesc_manual_search} />
          <div className="flex00a displayFlexRow borderL1bbb borderR1bbb lrMedPad" style={{flexBasis:"20.5em"}}>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"12em"}}>
              {(o_searchResultsLoadingFlag === "loading") ? (
                <>
                  {(c_userGcssDataSource0SamTF) &&
                    <>
                      <div className="textCenter">
                        <font className="font09 fontItalic fontAlmostWhite">
                          {"Searching GCSS"}
                        </font>
                      </div>
                      <GCSSLoadingAnimation />
                    </>
                  }
                  {(c_userGcssDataSource1GovWinTF || c_userGcssDataSource2FedCompTF) &&
                    <div className="bgLighterGray">
                      <GCSSLoadingAnimation />
                    </div>
                  }
                </>
              ) : (
                <div
                  className={"displayFlexColumnHcVc border bevelBorderDarkColors " + c_gcssDataSourceColorsObj.gcssBgLightGradientWithHoverClass + " textCenter cursorPointer"}
                  style={{width:"11em", height:"2.5em"}}
                  title="Click to search the GCSS database of opportunities using the search parameters below"
                  onClick={this.onclick_search_button}>
                  <font className="font11 fontBold">
                    {"Search GCSS"}
                  </font>
                </div>
              )}
            </div>
            <div
              className="flex00a displayFlexColumnHcVc hoverFontUnderline textCenter cursorPointer"
              style={{flexBasis:"7em"}}
              title="Reset all filters in this Search Editor to their initial not filled out state"
              onClick={this.onclick_clear_search_button}>
              <font className="fontAlmostWhite">
                {"Reset Search"}
              </font>
            </div>
          </div>
          <div className="flex11a displayFlexColumnVc borderR1bbb lrMedPad textCenter" title={((editingAutoSearchTF) ? ("Editing: " + currentSearchName) : (undefined))}>
            <LibraryReact.Nowrap p_fontClass={currentSearchFontClass}>
              {currentSearchName}
            </LibraryReact.Nowrap>
            {(editingAutoSearchTF && o_selectedAutoSearchHasUnsavedChangesTF) &&
              <LibraryReact.Nowrap p_fontClass="fontItalic fontAlmostWhite">
                {"changes not yet saved"}
              </LibraryReact.Nowrap>
            }
          </div>
          {(!editingAutoSearchTF) &&
            <div className="flex00a displayFlexColumnHcVc lrMedPad">
              <CEGeneralReact.ButtonWithConfirmBox
                p_buttonType="add"
                p_buttonText="Save New Search as Auto Search"
                p_title={undefined}
                p_confirmType="inputText"
                p_confirmMessage="Enter a name for this new Auto Search"
                p_button1Name="Create New Auto Search"
                p_textMustBeFilledOutTF={true}
                f_onClickConfirm={this.onclick_save_as_auto_search_button}
              />
            </div>
          }
          {(editingAutoSearchTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"18em"}}>
              {(o_selectedAutoSearchHasUnsavedChangesTF) &&
                <CEGeneralReact.CEButton
                  p_type="add"
                  p_text="Save Changes to Auto Search"
                  f_onClick={this.onclick_save_changes_to_auto_search_button}
                />
              }
            </div>
          }
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            {(editingAutoSearchTF) &&
              <CEGeneralReact.VerticalDotsMenu
                p_menuItemsArrayOfObjs={gcssEditSearchMenuItemsArrayOfObjs}
                p_dotsFontClass="fontAlmostWhite"
              />
            }
          </div>
        </div>
        <div className="flex11a yScroll smallFullPad">
          {manualSearchFiltersBoxIndicesRCMatrix.map((rowFiltersBoxIndicesArray, rowIndex) =>
            <div
              key={JSFUNC.rc_unique_row_key(c_manualSearchNumFiltersBoxesPerRow, rowIndex)}
              className="flex00a displayFlexRow">
              {rowFiltersBoxIndicesArray.map((filterBoxIndex) =>
                <ManualSearchBoxOfBubblesOfFilters
                  key={filterBoxIndex}
                  p_filterBoxIndex={filterBoxIndex}
                />
              )}
            </div>
          )}
          {(c_userGcssDataSource0SamTF) &&
            <div className="displayFlexRowHcVc flexWrap smallFullMargin border bevelBorderColors bgWhite tbMedPad">
              <div className="flex00a lrBigMargin">
                <SearchFilterWithCheckBox
                  p_autoSearchDbName="include_already_in_ce_results_01"
                  p_checkedTF={includeAlreadyInCEResultsCheckedTF}
                  p_filterDescription="Include Already In CE"
                  p_filterDescriptionWidthEm={14}
                />
              </div>
              <div className="flex00a lrBigMargin">
                <SearchFilterWithCheckBox
                  p_autoSearchDbName="include_not_interested_results_01"
                  p_checkedTF={includeNotInterestedResultsCheckedTF}
                  p_filterDescription="Include Not Interested"
                  p_filterDescriptionWidthEm={14}
                />
              </div>
            </div>
          }
          <div className="displayFlexColumnHcVc hugeTopMargin">
            <div className="flex00a border bevelBorderColors bgDarkGray medFullPad" style={{width:"75%"}}>
              <div className="smallBottomMargin textCenter">
                <font className="font11 fontTextLightestest">
                  {"Default Values for Import into " + c_productStylingObj.productName}
                </font>
              </div>
              <div className="displayFlexRowHcWrap smallBottomMargin">
                <SearchImportDefaultCaptureExecField
                  p_autoSearchDbName="import_default_capture_managers_ids_colon_percent_comma"
                  p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfCaptureManagers.get("display_name")}
                  p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfCaptureManagers.get("fieldTypeObj")}
                  p_tabIndex={150}
                />
                <SearchImportDefaultCaptureExecField
                  p_autoSearchDbName="import_default_division_owners_ids_colon_percent_comma"
                  p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("display_name")}
                  p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("fieldTypeObj")}
                  p_tabIndex={160}
                />
              </div>
              <div className="displayFlexRowHcWrap">
                <SearchImportDefaultCaptureExecField
                  p_autoSearchDbName="import_default_capture_type_id"
                  p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfCaptureType.get("display_name")}
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureTypeFieldTypeObj}
                  p_tabIndex={170}
                />
                <SearchImportDefaultCaptureExecField
                  p_autoSearchDbName="import_default_stage_id"
                  p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfStage.get("display_name")}
                  p_fieldTypeObj={c_searchImportDefaultStagesInSelectedCaptureTypeFieldTypeObj}
                  p_noEditMessageOrUndefined={((c_searchImportDefaultSelectedCaptureTypeIsFilledOutTF) ? (undefined) : ("--Select a Capture Type before choosing a " + this.props.DatabaseMobx.c_fieldMapOfStage.get("display_name") + "--"))}
                  p_tabIndex={180}
                />
              </div>
            </div>
          </div>
          <div className="bigTopMargin" />
        </div>
      </LibraryReact.InteractiveDiv>
    );
  }
}));


const SearchFilterWithCheckBox = inject("GCSSMobx")(observer(
class SearchFilterWithCheckBox extends Component { //props: p_autoSearchDbName, p_checkedTF, p_filterDescription, p_filterDescriptionWidthEm
  onclick_search_filter_checkbox = () => {
    const p_autoSearchDbName = this.props.p_autoSearchDbName;
    const p_checkedTF = this.props.p_checkedTF;

    const updatedValue01 = ((p_checkedTF) ? (0) : (1));
    this.props.GCSSMobx.a_set_manual_search_local_selected_filter_value(p_autoSearchDbName, updatedValue01);
  }

  render() {
    const p_autoSearchDbName = this.props.p_autoSearchDbName;
    const p_checkedTF = this.props.p_checkedTF;
    const p_filterDescription = this.props.p_filterDescription;
    const p_filterDescriptionWidthEm = this.props.p_filterDescriptionWidthEm;

    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;

    return(
      <div
        className={"flex00a displayFlexRowVc tbMicroMargin border bevelBorderColors borderRadius05 " + ((p_checkedTF) ? (c_gcssDataSourceColorsObj.gcssBgLightGradientClass) : ("bgLighterGray")) + " tbPad lrPad cursorPointer"}
        style={{width:p_filterDescriptionWidthEm + "em"}}
        onClick={this.onclick_search_filter_checkbox}>
        <div className="flex00a lrMargin">
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={((p_checkedTF) ? (1) : (0))}
            p_sizeEm={1.1}
            p_tabIndex={undefined}
            p_title={undefined}
            p_errorTF={undefined}
          />
        </div>
        <div className="flex11a lrMargin">
          <font className="">
            {p_filterDescription}
          </font>
        </div>

      </div>
    );
  }
}));


const SearchImportDefaultCaptureExecField = inject("GCSSMobx")(observer(
class SearchImportDefaultCaptureExecField extends Component { //props: p_autoSearchDbName, p_fieldDisplayName, p_fieldTypeObj, p_noEditMessageOrUndefined, p_tabIndex
  onchange_search_import_default_ce_field = (i_newValue) => {
    const p_autoSearchDbName = this.props.p_autoSearchDbName;
    this.props.GCSSMobx.a_set_manual_search_local_selected_filter_value(p_autoSearchDbName, i_newValue);
  }

  render() {
    const p_autoSearchDbName = this.props.p_autoSearchDbName;
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_noEditMessageOrUndefined = this.props.p_noEditMessageOrUndefined;
    const p_tabIndex = this.props.p_tabIndex;

    const o_manualSearchLocalSelectedFilterValuesMap = this.props.GCSSMobx.o_manualSearchLocalSelectedFilterValuesMap;

    const valueRaw = o_manualSearchLocalSelectedFilterValuesMap.get(p_autoSearchDbName);

    return(
      <div className="flex11a tbMicroMargin lrMargin border1bbb borderRadius10 bgLightestGray tbMedPad lrBigPad" style={{flexBasis:"100em", maxWidth:"30em"}}>
        <div className="smallBottomMargin">
          <font className="fontRedCapture">
            {p_fieldDisplayName}
          </font>
        </div>
        <div className="">
          {(p_noEditMessageOrUndefined === undefined) ? (
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={p_fieldTypeObj}
              p_valueRaw={valueRaw}
              p_tabIndex={p_tabIndex}
              f_onChangeOrOnSelect={this.onchange_search_import_default_ce_field}
            />
          ) : (
            <font className="fontItalic fontTextLighter">
              {p_noEditMessageOrUndefined}
            </font>
          )}
        </div>
      </div>
    );
  }
}));


const ManualSearchBoxOfBubblesOfFilters = inject("GCSSMobx", "DatabaseMobx")(observer(
class ManualSearchBoxOfBubblesOfFilters extends Component { //props: p_filterBoxIndex
  render() {
    const p_filterBoxIndex = this.props.p_filterBoxIndex;

    const c_searchFieldsWithValuesObj = this.props.GCSSMobx.c_searchFieldsWithValuesObj;

    const boxesOfBubblesOfFiltersWithValuesArrayOfObjs = c_searchFieldsWithValuesObj.boxesOfBubblesOfFiltersWithValuesArrayOfObjs;
    const boxContainerClass = "flex11a tbMargin lrMargin";

    var boxOfBubblesOfFiltersWithValuesObj = undefined;
    if(p_filterBoxIndex >= 0) {
      boxOfBubblesOfFiltersWithValuesObj = boxesOfBubblesOfFiltersWithValuesArrayOfObjs[p_filterBoxIndex];
    }

    if(boxOfBubblesOfFiltersWithValuesObj === undefined) {
      return(
        <div className={boxContainerClass} style={{flexBasis:"100em"}} />
      );
    }

    const boxTitle = boxOfBubblesOfFiltersWithValuesObj.boxTitle;
    const bubblesOfFiltersWithValuesArrayofObjs = boxOfBubblesOfFiltersWithValuesObj.bubblesOfFiltersWithValuesArrayofObjs;

    return(
      <div className={boxContainerClass + " border bevelBorderColors bgWhite medFullPad"} style={{flexBasis:"100em"}}>
        <div className="textCenter">
          <font className="font12 fontBold fontTextLighter">
            {boxTitle}
          </font>
        </div>
        {bubblesOfFiltersWithValuesArrayofObjs.map((m_bubbleOfFiltersWithValuesObj, m_index) =>
          <ManualSearchSingleBubbleOfFilters
            p_bubbleOfFiltersWithValuesObj={m_bubbleOfFiltersWithValuesObj}
            p_filterBoxIndex={p_filterBoxIndex}
            p_bubbleIndex={m_index}
          />
        )}
      </div>
    );
  }
}));


const ManualSearchSingleBubbleOfFilters = inject("GCSSMobx", "DatabaseMobx")(observer(
class ManualSearchSingleBubbleOfFilters extends Component { //props: p_bubbleOfFiltersWithValuesObj, p_filterBoxIndex, p_bubbleIndex
  render() {
    const p_bubbleOfFiltersWithValuesObj = this.props.p_bubbleOfFiltersWithValuesObj;
    const p_filterBoxIndex = this.props.p_filterBoxIndex;
    const p_bubbleIndex = this.props.p_bubbleIndex;

    const filtersWithValuesArrayOfObjs = p_bubbleOfFiltersWithValuesObj.filtersWithValuesArrayOfObjs;

    return(
      <div
        className="flex11a displayFlexRow flexWrap smallTopMargin border bevelBorderColors borderRadius05 bgLightestGray"
        style={{flexBasis:"100em"}}>
        {filtersWithValuesArrayOfObjs.map((m_filterWithValueObj, m_index) =>
          <ManualSearchSingleFilter
            p_filterWithValueObj={m_filterWithValueObj}
            p_filterBoxIndex={p_filterBoxIndex}
            p_bubbleIndex={p_bubbleIndex}
            p_filterIndex={m_index}
          />
        )}
      </div>
    );
  }
}));


const ManualSearchSingleFilter = inject("GCSSMobx", "DatabaseMobx")(observer(
class ManualSearchSingleFilter extends Component { //props: p_filterWithValueObj, p_filterBoxIndex, p_bubbleIndex, p_filterIndex
  constructor(props) {
    super(props);
    this.state = {
      s_keywordsHelpFloatingBoxIsOpenTF: false
    };
  }

  onclick_open_keywords_help_floating_box = () => {
    this.setState({s_keywordsHelpFloatingBoxIsOpenTF:true});
  }

  onclick_close_keywords_help_floating_box = () => {
    this.setState({s_keywordsHelpFloatingBoxIsOpenTF:false});
  }

  onchange_filter_value = (i_newValue) => {
    const p_filterWithValueObj = this.props.p_filterWithValueObj;
    this.props.GCSSMobx.a_set_manual_search_local_selected_filter_value(p_filterWithValueObj.autoSearchDbName, i_newValue);
  }

  onclick_clear_date = () => {
    const p_filterWithValueObj = this.props.p_filterWithValueObj;
    const updatedFilterValue = JSFUNC.blank_date();
    this.props.GCSSMobx.a_set_manual_search_local_selected_filter_value(p_filterWithValueObj.autoSearchDbName, updatedFilterValue);
  }

  render() {
    const s_keywordsHelpFloatingBoxIsOpenTF = this.state.s_keywordsHelpFloatingBoxIsOpenTF;

    const p_filterWithValueObj = this.props.p_filterWithValueObj;
    const p_filterBoxIndex = this.props.p_filterBoxIndex;
    const p_bubbleIndex = this.props.p_bubbleIndex;
    const p_filterIndex = this.props.p_filterIndex;

    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;

    const filterTitle = p_filterWithValueObj.filterTitle;
    const filterSubTitle = p_filterWithValueObj.filterSubTitle;
    const filterInstructions = p_filterWithValueObj.filterInstructions;
    const filterKeywordsHelpLinkText = p_filterWithValueObj.filterKeywordsHelpLinkText;
    const autoSearchDbName = p_filterWithValueObj.autoSearchDbName;
    const searchPostVarName = p_filterWithValueObj.searchPostVarName;
    const fieldTypeObj = p_filterWithValueObj.fieldTypeObj;
    const selectedFilterValue = p_filterWithValueObj.selectedFilterValue;
    const filterValueMaskSortIfoObj = p_filterWithValueObj.filterValueMaskSortIfoObj;

    var filterValueMask = filterValueMaskSortIfoObj.valueMask;
    const filterIsFilledOutTF = filterValueMaskSortIfoObj.isFilledOutTF;

    var filterTitleFontClass = "fontBlue";
    if(filterIsFilledOutTF) {
      filterTitleFontClass = "fontBold fontBlue";
    }

    const inputTabIndex = (p_filterBoxIndex * 20) + (p_bubbleIndex * 4) + (p_filterIndex * 2) + 1;

    if(fieldTypeObj.dateWithRelativeDateTF && filterIsFilledOutTF) {
      const fixedDateObj = JSFUNC.convert_relative_ymd_date_to_fixed_date_obj(selectedFilterValue, this.props.DatabaseMobx.c_companyDateFormat);
      filterValueMask = fixedDateObj.fixedDateFormatted;
    }

    return(
      <div className={"flex11a medFullPad " + ((filterIsFilledOutTF) ? (c_gcssDataSourceColorsObj.gcssBgLightGradientClass) : (""))} style={{flexBasis:"17em"}}>
        <div className="smallBottomMargin textCenter">
          <font className={"font11 " + filterTitleFontClass}>
            {filterTitle}
          </font>
        </div>
        {(filterSubTitle !== undefined) &&
          <div className="smallBottomMargin textCenter">
            <font className="">
              {filterSubTitle}
            </font>
          </div>
        }
        {(filterInstructions !== undefined) &&
          <div className="microBottomMargin textCenter">
            <font className="font09 fontItalic">
              {filterInstructions}
            </font>
          </div>
        }
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={fieldTypeObj}
          p_valueRaw={selectedFilterValue}
          p_tabIndex={inputTabIndex}
          f_onChangeOrOnSelect={this.onchange_filter_value}
        />
        {(fieldTypeObj.dateWithRelativeDateTF) &&
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex11a">
              <font className="">
                {filterValueMask}
              </font>
            </div>
            <div
              className={"flex00a displayFlexColumnHcVc textCenter " + ((filterIsFilledOutTF) ? ("hoverFontUnderline cursorPointer") : (""))}
              style={{width:"3em", height:"1.1em"}}
              onClick={((filterIsFilledOutTF) ? (this.onclick_clear_date) : (undefined))}>
              {(filterIsFilledOutTF) &&
                <font className="font09 fontItalic">
                  {"clear"}
                </font>
              }
            </div>
          </div>
        }
        {(filterKeywordsHelpLinkText !== undefined) &&
          <div className="displayFlexRow smallTopMargin">
            <div className="flex11a" />
            <div
              className="flex00a lrPad textRight hoverFontUnderline cursorPointer"
              style={{width:"16em", height:"1.1em"}}
              onClick={this.onclick_open_keywords_help_floating_box}>
              <font className="font09">
                {filterKeywordsHelpLinkText}
              </font>
            </div>
            {(s_keywordsHelpFloatingBoxIsOpenTF) &&
              <KeywordsHelpFloatingBox f_onClickClose={this.onclick_close_keywords_help_floating_box} />
            }
          </div>
        }
      </div>
    );
  }
}));


const KeywordsHelpFloatingBox = inject("UserMobx")(observer(
class KeywordsHelpFloatingBox extends Component { //props: f_onClickClose
  render() {
    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;

    var floatingBoxTitle = "Keywords Search Help";
    var helpContentComponent = null;
    if(c_userGcssDataSource1GovWinTF) {
      floatingBoxTitle = "GovWin Keywords Search Help";
      helpContentComponent = (
        <>
          <GovWinKeywordsHelpRow p_label="and">
            <GWKHRIC><Fi>{'Type '}</Fi><Fbir>{'AND'}</Fbir><Fi>{' (upper or lower case) between terms if all terms must be included in search results.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'router and firewall'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="or">
            <GWKHRIC><Fi>{'Type '}</Fi><Fbir>{'OR'}</Fbir><Fi>{' (upper or lower case) between terms if any terms must be included in search results.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'router or firewall'}</Fbgyr></GWKHRIC>
            <GWKHRIC>{'You may omit '}<Fbr>{'OR'}</Fbr>{' and achieve the same results, since '}<Fbr>{'OR'}</Fbr>{' is the default Boolean operator.'}</GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'router firewall load-balancer'}</Fbgyr>{' behaves the same as '}<Fbgyr>{'router or firewall or load-balancer'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="not">
            <GWKHRIC><Fi>{'Type '}</Fi><Fbir>{'NOT'}</Fbir><Fi>{' (upper or lower case) before a term to exclude it from the search results.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'router not firewall'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Exact Phrases">
            <GWKHRIC><Fi>{'Enclose phrases in double quotes if the exact phrase must be included in search results. This is useful when searching for phrases that contain an '}</Fi><Fbir>{'and'}</Fbir><Fi>{' or '}</Fi><Fbir>{'or'}</Fbir><Fi>{'. Single quotes are not supported.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'"modeling and simulation"'}</Fbgyr></GWKHRIC>
            <GWKHRIC><Fi>{'Stemming is supported in exact phrases'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'"Network Router"'}</Fbgyr>{' is treated the same as '}<Fbgyr>{'"Network Routers"'}</Fbgyr></GWKHRIC>
            <GWKHRIC><Fi>{'Phrases are not case-sensitive.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'"CISCO Routers"'}</Fbgyr>{' is treated the same as '}<Fbgyr>{'"cisco routers"'}</Fbgyr>{'.'}</GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Proximity">
            <GWKHRIC><Fi>{'While a phrase query (eg '}</Fi><Fbir>{'"john smith"'}</Fbir><Fi>{') expects all of the terms in exactly the same order, a proximity query allows the specified words to be further apart or in a different order.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'"fox quick"~5'}</Fbgyr>{' - '}<Fbr>{'fox'}</Fbr>{' and '}<Fbr>{'quick'}</Fbr>{' must be within 5 words of each other.'}</GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Stemming">
            <GWKHRIC><Fi>{'Stemming is a search technique that the search engine automatically performs, taking the root word and expanding it to all its various forms.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'run'}</Fbgyr>{" will expand to include "}<Fbgyr>{'runs'}</Fbgyr>{" and "}<Fbgyr>{'running'}</Fbgyr></GWKHRIC>
            <GWKHRIC><Fi>{'To turn off stemming, un-check the "Enable Stemming" checkbox.'}</Fi></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Partial Wildcards">
            <GWKHRIC><Fi>{'Wildcards replace a single letter ('}</Fi><Fbir>{'?'}</Fbir><Fi>{') or a series of letters ('}</Fi><Fbir>{'*'}</Fbir><Fi>{'when placed in the middle or end of a term.'}</Fi></GWKHRIC>
            <GWKHRIC><Fi>{'Wildcards at the beginning of a term are not supported.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'secur*'}</Fbgyr>{' will match '}<Fbgyr>{'secure'}</Fbgyr>{', '}<Fbgyr>{'security'}</Fbgyr>{', '}<Fbgyr>{'securities'}</Fbgyr>{', etc.'}</GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'long_file-nam*.txt'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Nested Searches">
            <GWKHRIC><Fi>{'Nest searches by enclosing search terms in parentheses.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'(routers and hubs) not switches'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Synonyms">
            <GWKHRIC><Fi>{'The search engine will automatically manage synonyms.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'"DoD"'}</Fbgyr>{' will automatically be interpreted as '}<Fbgyr>{'"Department of Defense"'}</Fbgyr>{'.'}</GWKHRIC>
            <GWKHRIC><Fi>{'Capitalization does not affect search results.'}</Fi></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Word order is important">
            <GWKHRIC><Fi>{'The search engine recognizes the order of words in a search. Search with the natural word order that you would normally use.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example of poor word order choice:'}</Fu>{' '}<Fbgyr>{'Defense Department'}</Fbgyr></GWKHRIC>
            <GWKHRIC><Fu>{'Example of good word order choice:'}</Fu>{' '}<Fbgyr>{'Department of Defense'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Word Spacing">
            <GWKHRIC><Fi>{'Place a space between words so that the search engine can see exactly what you are searching for.'}</Fi></GWKHRIC>
            <GWKHRIC><Fu>{'Example:'}</Fu>{' '}<Fbgyr>{'Information Security'}</Fbgyr>{' works better than '}<Fbgyr>{'InformationSecurity'}</Fbgyr></GWKHRIC>
          </GovWinKeywordsHelpRow>
          <GovWinKeywordsHelpRow p_label="Some Additional Examples">
            <GWKHRIC><Fu>{'Example 1:'}</Fu>{' If you enter '}<Fbgyr>{'Wireless Technology OR Systems Integrators'}</Fbgyr>{', this will be interpreted as '}<Fbgyr>{'Wireless OR Technology OR Systems OR Integrators'}</Fbgyr></GWKHRIC>
            <GWKHRIC><Fu>{'Example 2:'}</Fu>{' If you enter '}<Fbgyr>{'Wireless Technology AND Systems Integrators'}</Fbgyr>{', this will be interpreted as searching for '}<Fi>{'Required: '}</Fi><Fbgyr>{'Technology AND Systems'}</Fbgyr><Fi>{' and optionally: '}</Fi><Fbgyr>{'Wireless'}</Fbgyr><Fi>{' and optionally: '}</Fi><Fbgyr>{'Integrators'}</Fbgyr></GWKHRIC>
            <GWKHRIC><Fu>{'Example 3:'}</Fu>{' If you want to search on the two phrases '}<Fbr>{'"Wireless Technology"'}</Fbr>{' and '}<Fbr>{'"Systems Integrators"'}</Fbr>{'.'}</GWKHRIC>
            <GWKHRICB>{'If you only want to search for the terms '}<Fbr>{'"Wireless"'}</Fbr>{' or '}<Fbr>{'"Technology"'}</Fbr>{' (separated possibly) and the terms '}<Fbr>{'"Systems"'}</Fbr>{' or '}<Fbr>{'"Integrators"'}</Fbr>{' (separated possibly), then enter '}<Fbgyr>{'(Wireless Technology) AND (Systems Integrators)'}</Fbgyr>{'.'}</GWKHRICB>
            <GWKHRICB>{'Or you could do it as '}<Fbgyr>{'(Wireless OR Technology) AND (Systems OR Integrators)'}</Fbgyr></GWKHRICB>
            <GWKHRIC><Fu>{'Example 4:'}</Fu>{' If you want to search for the exact (but case-insensitive) phrase '}<Fbr>{'"Wireless Technology"'}</Fbr>{' and the exact (but case-insensitive) phrase '}<Fbr>{'"Systems Integrators"'}</Fbr>{', then enter '}<Fbgyr>{'"Wireless Technology" AND "Systems Integrators"'}</Fbgyr>{'.'}</GWKHRIC>
          </GovWinKeywordsHelpRow>
        </>
      );
    }
    else if(c_userGcssDataSource2FedCompTF) {
      floatingBoxTitle = "Federal Compass Keywords Search Help";
      helpContentComponent = (
        <>
          <FCRow>{'Keywords can be combined into complex boolean phrases using keyword hints below.'}</FCRow>
          <div className="smallTopPad" />
          <FCSymb p_symb="AND">{'[default operator] for example, "red AND yellow" is the same as "red yellow"'}</FCSymb>
          <FCSymb p_symb="+">{'signifies AND operation'}</FCSymb>
          <FCSymb p_symb="|">{'signifies OR operation'}</FCSymb>
          <FCSymb p_symb="-">{'negates a single word'}</FCSymb>
          <FCSymb p_symb='"'>{'wraps a number of words to signify a phrase for searching'}</FCSymb>
          <FCSymb p_symb="*">{'at the end of a term signifies a prefix query'}</FCSymb>
          <FCSymb p_symb="( and )">{'signify precedence, group compound terms by parenthesis'}</FCSymb>
          <div className="hugeTopPad" />
          <FCRow>{'For instance, let' + "'" + 's say you' + "'" + 'd like to search on email security and cyber security, but you want the actual phrase "cyber security". This could be accomplished by using the following complex search term:'}</FCRow>
          <div className="smallTopPad" />
          <FCEx>{'Example 1: ((email security) | "cyber security")'}</FCEx>
          <div className="smallTopPad" />
          <FCExExp>{'This will return any record that has both words "email" and "security" OR the phrase "cyber security".'}</FCExExp>
          <div className="hugeTopPad" />
          <FCEx>{'Example 2: ((hardware | software) + (supplies | equipment))'}</FCEx>
          <div className="smallTopPad" />
          <FCExExp>{'This will return any record that has either "hardware" and "supplies" or "hardware" and "equipment" OR "software" and "supplies" or "software" and "equipment".'}</FCExExp>
          <div className="hugeTopPad" />
          <FCRow>{"In addition, keyword stemming will pick up different word forms for the root word (ie. STEM will search for 'stem-ming', 'stem-med', etc.)"}</FCRow>
        </>
      );
    }

    return (
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="medium"
        p_title="GovWin Keywords Search Help"
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a yScroll medFullPad">
          {helpContentComponent}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


function GovWinKeywordsHelpRow(props) { //p_label, children
  const p_label = props.p_label;

  return(
    <div className="displayFlexRow bigBottomMargin">
      <div className="flex00a" style={{flexBasis:"10em"}}>
        <font className="fontBold">
          {p_label}
        </font>
      </div>
      <div className="flex11a">
        {props.children}
      </div>
    </div>
  );
}

function GWKHRIC(props) { //props: children
  return(<div className="microBottomMargin">{props.children}</div>);
}

function GWKHRICB(props) { //props: children
  return(
    <div className="displayFlexRow microBottomMargin" style={{marginLeft:"1.5em"}}>
      <div className="flex00a" style={{flexBasis:"1em"}}>
        {"\u2022 "}
      </div>
      <div className="flex11a">
        {props.children}
      </div>
    </div>
  );
}

function Fi(props) {
  return(<font className="fontItalic">{props.children}</font>);
}

function Fu(props) {
  return(<font className="fontUnderline">{props.children}</font>);
}

function Fbr(props) {
  return(<font className="fontBold fontDarkRed">{props.children}</font>);
}

function Fbir(props) {
  return(<font className="fontBold fontItalic fontDarkRed">{props.children}</font>);
}

function Fbgyr(props) {
  return(<font className="bgContactsLightYellow fontDarkRed">{props.children}</font>);
}

function FCRow(props) { //props: children
  return(
    <div className="smallBottomMargin">
      <font className="font11 fontTextLight">
        {props.children}
      </font>
    </div>
  );
}

function FCSymb(props) { //props: p_symb, children
  return(
    <div className="displayFlexRowVc">
      <div className="flex00a" style={{flexBasis:"1em"}} />
      <div className="flex00a" style={{flexBasis:"4em"}}>
        <font className="font11 fontBold fontGcssFedCompDarkBlue">
          {props.p_symb}
        </font>
      </div>
      <div className="flex11a">
        <font>
          {props.children}
        </font>
      </div>
    </div>
  );
}

function FCEx(props) { //props: children
  return(
    <div className="displayFlexRowVc">
      <div className="flex00a" style={{flexBasis:"1em"}} />
      <div className="flex11a">
        <font className="fontItalic fontGcssFedCompDarkBlue">
          {props.children}
        </font>
      </div>
    </div>
  );
}

function FCExExp(props) { //props: children
  return(
    <div className="displayFlexRowVc">
      <div className="flex00a" style={{flexBasis:"1em"}} />
      <div className="flex11a">
        <font>
          {props.children}
        </font>
      </div>
    </div>
  );
}



//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&



const SearchResultsContent = inject("GCSSMobx", "DatabaseMobx", "UserMobx")(observer(
class SearchResultsContent extends Component { //props:
  render() {
    const o_manualSearchLocalSelectedFilterValuesMap = this.props.GCSSMobx.o_manualSearchLocalSelectedFilterValuesMap;
    const o_searchResultsLoadingFlag = this.props.GCSSMobx.o_searchResultsLoadingFlag;
    const o_searchResultsErrorMessage = this.props.GCSSMobx.o_searchResultsErrorMessage;
    const c_fieldMapOfOpportunityName = this.props.DatabaseMobx.c_fieldMapOfOpportunityName;
    const c_userCanMarkImportOrMergeCapturesFromGCSSTF = this.props.UserMobx.c_userCanMarkImportOrMergeCapturesFromGCSSTF;
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;

    if(o_searchResultsLoadingFlag === "init") {
      return(
        <CEGeneralReact.EmptyScreenWhite p_fontClass="font12 fontTextLighter">
          <div className="textCenter" style={{maxWidth:"40em"}}>
            {"Begin by either building a new search with 'Create New Search', or by clicking one of your created Auto Search buttons to conduct a search with those saved settings"}
          </div>
        </CEGeneralReact.EmptyScreenWhite>
      );
    }
    
    if(o_searchResultsLoadingFlag === "loading") {
      var fetchText = "Fetching Search Results";
      var fetchSubTextOrUndefined = undefined;
      if(c_userGcssDataSource0SamTF) {
        fetchText = "Fetching Search Results from SAM.gov";

        const samFilterOpportunityNameSearch = o_manualSearchLocalSelectedFilterValuesMap.get("filter_opportunity_name_search");
        const samFilterKeyword = o_manualSearchLocalSelectedFilterValuesMap.get("filter_keyword_search_opportunity_name_description_of_work");
        if(JSFUNC.string_is_filled_out_tf(samFilterKeyword)) {
          fetchSubTextOrUndefined = "(Search may take longer due to keyword search for '" + samFilterKeyword + "')";
        }
        else if(JSFUNC.string_is_filled_out_tf(samFilterOpportunityNameSearch)) {
          fetchSubTextOrUndefined = "(Search may take longer due to " + c_fieldMapOfOpportunityName.get("display_name") + " search for '" + samFilterOpportunityNameSearch + "')";
        }
      }
      else if(c_userGcssDataSource1GovWinTF) {
        fetchText = "Fetching Search Results through GovWin API";
      }
      else if(c_userGcssDataSource2FedCompTF) {
        fetchText = "Fetching Search Results through Federal Compass API";
      }
      else if(c_userGcssDataSource3CexaiTF) {
        fetchText = "Fetching Search Results from SAM.gov";
        fetchSubTextOrUndefined = "Using an AI Large Language Model (LLM) to correlate your company profile and past wins in FPDS to all currently active SAM opportunities"
      }

      return(
        <div className="flex11a displayFlexColumnHcVc yScroll lrMedPad bgWhite textCenter">
          <div className="smallBottomMargin">
            <font className="fontItalic fontTextLight">
              {fetchText}
            </font>
          </div>
          <GCSSLoadingAnimation />
          {(fetchSubTextOrUndefined !== undefined) &&
            <div className="hugeTopMargin">
              <font className="font11 fontBold fontTextLight">
                {fetchSubTextOrUndefined}
              </font>
            </div>
          }
        </div>
      );
    }
    
    //fetch/transmission of search results to php had an error (not an 'error message' from the php itself)
    if(o_searchResultsLoadingFlag === "error") {
      return(
        <CEGeneralReact.EmptyScreenWhite>
          <font className="font12 fontBold fontDarkRed">
            {"There was an issue fetching these search results, please try your search again"}
          </font>
        </CEGeneralReact.EmptyScreenWhite>
      );
    }
    
    if(JSFUNC.string_is_filled_out_tf(o_searchResultsErrorMessage)) {
      return(
        <div className="flex11a displayFlexColumnHcVc">
          <div className="flex00a textCenter" style={{maxWidth:"40em"}}>
            <div className="smallBottomMargin textCenter">
              <font className="font12 fontBold fontDarkRed">
                {"Issue fetching GCSS Search Results"}
              </font>
            </div>
            <div className="textCenter">
              <font className="font11 fontDarkRed">
                {this.props.GCSSMobx.translate_gcss_error_message_to_user_display_error_message(o_searchResultsErrorMessage)}
              </font>
            </div>
            {(c_userGcssDataSource3CexaiTF) &&
              <div className="smallFullMargin">
                <font className="">
                  {o_searchResultsErrorMessage}
                </font>
              </div>
            }
          </div>
        </div>
      );
    }

    return(
      <>
        <div className="flex00a displayFlexRowVc flexWrap">
          <div className="flex00a displayFlexRowVc">
            <div className="flex00a lrMedPad">
              <SearchResultsTotalNumResultsAndPageControls />
            </div>
            {(c_userGcssDataSource1GovWinTF || c_userGcssDataSource2FedCompTF) &&
              <div className="flex00a displayFlexColumnHcVc">
                <SearchResultsFetchMoreResultsButton p_numResultsToFetch={100} />
              </div>
            }
            {(c_userCanMarkImportOrMergeCapturesFromGCSSTF) &&
              <div className="flex00a lrMedPad">
                <SearchResultsImportButtonWithImportSetupFloatingBox />
              </div>
            }
          </div>
          <div className="flex11a" />
          <div className="flex00a displayFlexRowVc">
            {(c_userCanMarkImportOrMergeCapturesFromGCSSTF) &&
              <div className="flex00a lrMedPad">
                <SearchResultsFilterControls />
              </div>
            }
            <div className="flex00a lrMedPad">
              <SearchResultsSortControls />
            </div>
            <div className="flex00a lrMedPad">
              <SearchResultsResultDisplayControls />
            </div>
          </div>
        </div>
        <div className="flex11a yScroll lrMedPad">
          <AllSearchResultItemsOnCurrentPage />
        </div>
      </>
    );
  }
}));

const SearchResultsTotalNumResultsAndPageControls = inject("CaptureExecMobx", "GCSSMobx")(observer(
class SearchResultsTotalNumResultsAndPageControls extends Component { //props:
  onclick_clear_previous_search_button = () => {
    this.props.GCSSMobx.a_clear_previous_search_results_to_init_state();
  }

  onclick_increment_current_page_number = () => {
    this.props.GCSSMobx.a_increment_current_page_number();
  }

  onclick_decrement_current_page_number = () => {
    this.props.GCSSMobx.a_decrement_current_page_number();
  }

  render() {
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_totalNumResultsMatchingSearch = this.props.GCSSMobx.o_totalNumResultsMatchingSearch;
    const c_totalNumFilteredSearchResultsFromSelectedTab = this.props.GCSSMobx.c_totalNumFilteredSearchResultsFromSelectedTab;
    const c_currentPageFirstItemNumber = this.props.GCSSMobx.c_currentPageFirstItemNumber;
    const c_currentPageLastItemNumber = this.props.GCSSMobx.c_currentPageLastItemNumber;
    const c_canIncrementCurrentPageNumberTF = this.props.GCSSMobx.c_canIncrementCurrentPageNumberTF;
    const c_canDecrementCurrentPageNumberTF = this.props.GCSSMobx.c_canDecrementCurrentPageNumberTF;

    var totalResultsAndPagesContainerWidthEm = 25.5;
    var totalResultsFontClass = "font12 fontBold";
    var totalResultsText = o_totalNumResultsMatchingSearch + " Total " + JSFUNC.plural(o_totalNumResultsMatchingSearch, "Result Matches", "Results Match") + " Current Search";
    if(c_isMobileTF) {
      totalResultsAndPagesContainerWidthEm = 16;
      totalResultsFontClass = "font12 fontBold";
      totalResultsText = o_totalNumResultsMatchingSearch + " Total " + JSFUNC.plural(o_totalNumResultsMatchingSearch, "Result", "Results");
    }

    const pageButtonWidthEm = 2.5;
    const pageButtonHeightEm = 1.6;
    const pageButtonCanClickBgClass = "bgLighterGrayGradient hoverLightestGrayGradient";
    const pageButtonCanClickFontClass = "";
    const pageButtonCantClickBgClass = "bgDarkGrayGradient";
    const pageButtonCantClickFontClass = "fontTextLighter";

    return(
      <div className="" style={{width:totalResultsAndPagesContainerWidthEm + "em"}}>
        <div className="textCenter">
          <font className={totalResultsFontClass}>
            {totalResultsText}
          </font>
        </div>
        <div className="displayFlexColumnHcVc">
          <div className="flex00a displayFlexRowVc" style={{width:"15em"}}>
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={false}
                p_canClickTF={c_canDecrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_decrement_current_page_number}
              />
            </div>
            <div className="flex11a lrPad textCenter">
              <font className="">
                {c_currentPageFirstItemNumber + " - " + c_currentPageLastItemNumber + " of " + c_totalNumFilteredSearchResultsFromSelectedTab}
              </font>
              <div
                className="flex00a hoverLightestGray textCenter cursorPointer"
                title="Click to clear the search results and search settings"
                onClick={this.onclick_clear_previous_search_button}>
                <font className="font09 fontItalic fontTextLight">
                  {"Clear Results"}
                </font>
              </div>
            </div>
            <div className="flex00a">
              <CEGeneralReact.PageIncrementOrDecrementButton
                p_incTrueDecFalse={true}
                p_canClickTF={c_canIncrementCurrentPageNumberTF}
                p_widthEm={pageButtonWidthEm}
                p_heightEm={pageButtonHeightEm}
                p_canClickBgClass={pageButtonCanClickBgClass}
                p_canClickFontClass={pageButtonCanClickFontClass}
                p_cantClickBgClass={pageButtonCantClickBgClass}
                p_cantClickFontClass={pageButtonCantClickFontClass}
                f_onClick={this.onclick_increment_current_page_number}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}));



const SearchResultsFetchMoreResultsButton = inject("GCSSMobx", "UserMobx")(observer(
class SearchResultsFetchMoreResultsButton extends Component { //props: p_numResultsToFetch
  onclick_fetch_more_results_button = () => {
    const p_numResultsToFetch = this.props.p_numResultsToFetch;

    const o_searchResultsArrayOfObjs = this.props.GCSSMobx.o_searchResultsArrayOfObjs;

    const fetchOffset = o_searchResultsArrayOfObjs.length; //start fetching new records where the previous search left off using the offset
    const appendResultsTrueOverwriteResultsFalse = true; //append the newly fetched results to the already existing results
    this.props.GCSSMobx.a_fetch_gcss_tbl_search_results_from_current_filter_settings(p_numResultsToFetch, fetchOffset, appendResultsTrueOverwriteResultsFalse);
  }

  render() {
    const p_numResultsToFetch = this.props.p_numResultsToFetch;

    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;

    var buttonTitle = "Click to load " + p_numResultsToFetch + " more results from this same search into this GCSS Search Results list";
    buttonTitle += "\n[";
    if(c_userGcssDataSource1GovWinTF) {
      buttonTitle += "GovWin ";
    }
    else if(c_userGcssDataSource2FedCompTF) {
      buttonTitle += "Federal Compass ";
    }
    buttonTitle += "API fetches have a maximum of 100 results each]";

    return(
      <div
        className="displayFlexColumnHcVc border bevelBorderColors bgLighterGrayGradient hoverLightestGrayGradient cursorPointer textCenter"
        style={{width:"8em", height:"2.7em"}}
        title={buttonTitle}
        onClick={this.onclick_fetch_more_results_button}>
        <font className="">
          {"Load the Next " + p_numResultsToFetch + " Results"}
        </font>
      </div>
    );
  }
}));


const SearchResultsFilterControls = inject("CaptureExecMobx", "GCSSMobx")(observer(
class SearchResultsFilterControls extends Component { //props:
  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_undecidedExpandedSearchResultsArrayOfObjs = this.props.GCSSMobx.c_undecidedExpandedSearchResultsArrayOfObjs;
    const c_markedForImportExpandedSearchResultsArrayOfObjs = this.props.GCSSMobx.c_markedForImportExpandedSearchResultsArrayOfObjs;
    const c_markedAsNotInterestedExpandedSearchResultsArrayOfObjs = this.props.GCSSMobx.c_markedAsNotInterestedExpandedSearchResultsArrayOfObjs;
    const c_alreadyInCEExpandedSearchResultsArrayOfObjs = this.props.GCSSMobx.c_alreadyInCEExpandedSearchResultsArrayOfObjs;

    const filterUndecidedTitle = "Show only Undecided (" + c_undecidedExpandedSearchResultsArrayOfObjs.length + ")";
    const filterMarkedForImportTitle = "Show only Marked For Import (" + c_markedForImportExpandedSearchResultsArrayOfObjs.length + ")";
    const filterNotInterestedTitle = "Show only Marked As Not Interested (" + c_markedAsNotInterestedExpandedSearchResultsArrayOfObjs.length + ")";
    const filterAlreadyInCETitle = "Show only Already In " + c_productStylingObj.productName + " (" + c_alreadyInCEExpandedSearchResultsArrayOfObjs.length + ")";

    return(
      <div>
        <div className="microBottomMargin textCenter">
          <font className="fontItalic fontTextLighter">
            {"Quick Filter"}
          </font>
        </div>
        <div className="displayFlexRowVc">
          <SearchResultsSingleFilterButton p_tabDbName="undecided" p_text="Und" p_bgClass="bgLightGray" p_fontClass="fontTextLight" p_title={filterUndecidedTitle} f_onClick={this.onclick_filter} />
          <SearchResultsSingleFilterButton p_tabDbName="markedForImport" p_text="Imp" p_bgClass="bgBlue" p_fontClass="fontAlmostWhite" p_title={filterMarkedForImportTitle} f_onClick={this.onclick_filter} />
          <SearchResultsSingleFilterButton p_tabDbName="markedAsNotInterested" p_text="NI" p_bgClass="bgDarkGray" p_fontClass="fontAlmostWhite" p_title={filterNotInterestedTitle} f_onClick={this.onclick_filter} />
          <SearchResultsSingleFilterButton p_tabDbName="alreadyInCE" p_text={c_productStylingObj.productNameAcronym} p_bgClass="bgDarkGreenGradient" p_fontClass="fontAlmostWhite" p_title={filterAlreadyInCETitle} f_onClick={this.onclick_filter} />
        </div>
      </div>
    );
  }
}));

const SearchResultsSingleFilterButton = inject("GCSSMobx")(observer(
class SearchResultsSingleFilterButton extends Component { //props: p_tabDbName, p_text, p_bgClass, p_fontClass, p_title, f_onClick
  onclick_filter_button = () => {
    const p_tabDbName = this.props.p_tabDbName;

    const o_selectedSearchResultsTabDbName = this.props.GCSSMobx.o_selectedSearchResultsTabDbName;

    var newSelectedTabDbName = p_tabDbName;
    if(p_tabDbName === o_selectedSearchResultsTabDbName) { //toggle click already selected filter turns it off (back to "all" results)
      newSelectedTabDbName = "all";
    }

    this.props.GCSSMobx.a_set_selected_search_results_tab_db_name(newSelectedTabDbName);
  }

  render() {
    const p_tabDbName = this.props.p_tabDbName;
    const p_text = this.props.p_text
    const p_bgClass = this.props.p_bgClass;
    const p_fontClass = this.props.p_fontClass;
    const p_title = this.props.p_title;

    const o_selectedSearchResultsTabDbName = this.props.GCSSMobx.o_selectedSearchResultsTabDbName;

    const filterSelectedTF = (p_tabDbName === o_selectedSearchResultsTabDbName);

    return(
      <div
        className={"displayFlexColumnHcVc border bevelBorderColors " + p_bgClass + " cursorPointer"}
        style={{width:"2.5em", height:"2.3em", margin:"0 0.25em", boxShadow:"0 0.1em 0.25em 0.2em " + ((filterSelectedTF) ? ("#04f") : ("#ccc"))}}
        title={p_title + ((filterSelectedTF) ? ("\n[Click again to go back to All Results]") : (""))}
        onClick={this.onclick_filter_button}>
        <font className={p_fontClass}>
          {p_text}
        </font>
      </div>
    );
  }
}));


const SearchResultsSortControls = inject("CaptureExecMobx", "GCSSMobx", "UserMobx")(observer(
class SearchResultsSortControls extends Component { //props:
  onselect_gcss_field_for_sort = (i_newValue) => {
    this.props.UserMobx.a_update_user_per_email_field("gcss_search_results_sort_gcss_field_db_name", i_newValue, "s");
  }

  onclick_gcss_sort_asc_desc_arrow_button = () => {
    const c_userGcssSearchResultsSortIsAscTF = this.props.UserMobx.c_userGcssSearchResultsSortIsAscTF;
    const updatedSortDesc0Asc1 = ((c_userGcssSearchResultsSortIsAscTF) ? (0) : (1));
    this.props.UserMobx.a_update_user_per_email_field("gcss_search_results_sort_desc0_asc1", updatedSortDesc0Asc1, "i");
  }

  render() {
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;
    const c_searchResultSortSelectGcssFieldFieldTypeObj = this.props.GCSSMobx.c_searchResultSortSelectGcssFieldFieldTypeObj;
    const c_userGcssSearchResultsSortGcssFieldDbName = this.props.UserMobx.c_userGcssSearchResultsSortGcssFieldDbName;
    const c_userGcssSearchResultsSortIsAscTF = this.props.UserMobx.c_userGcssSearchResultsSortIsAscTF;

    var sortSelectWidthEm = 22;
    if(c_isMobileTF) {
      sortSelectWidthEm = 20;
    }

    return(
      <>
        <div className="textCenter">
          <font className="fontItalic fontTextLighter">
            {"Sort Search Results"}
          </font>
        </div>
        <div className="displayFlexRowVc" style={{width:sortSelectWidthEm + "em"}}>
          <div className="flex11a">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={c_searchResultSortSelectGcssFieldFieldTypeObj}
              p_valueRaw={c_userGcssSearchResultsSortGcssFieldDbName}
              f_onChangeOrOnSelect={this.onselect_gcss_field_for_sort}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            <div
              className={"flex00a displayFlexColumnHcVc border bevelBorderColors " + c_gcssDataSourceColorsObj.gcssBgLightGradientWithHoverClass + " cursorPointer"}
              style={{width:"1.5em", height:"2.2em"}}
              title={"Currently sorted " + ((c_userGcssSearchResultsSortIsAscTF) ? ("Ascending") : ("Descending")) + " (click to change search results sort to " + ((c_userGcssSearchResultsSortIsAscTF) ? ("Descending") : ("Ascending")) + ")"}
              onClick={this.onclick_gcss_sort_asc_desc_arrow_button}>
              <CEGeneralReact.HeaderSortArrow p_isSortedTF={true} p_sortedAscTF={c_userGcssSearchResultsSortIsAscTF} />
            </div>
          </div>
        </div>
      </>
    );
  }
}));


const SearchResultsResultDisplayControls = inject("CaptureExecMobx", "GCSSMobx", "UserMobx")(observer(
class SearchResultsResultDisplayControls extends Component { //props:
  onclick_search_result_display_compact = () => {
    this.props.GCSSMobx.a_set_current_page_number(1); //reset current page back to page 1
    this.props.UserMobx.a_update_user_per_email_field("gcss_search_results_compact0_large1", 0, "i");
  }

  onclick_search_result_display_large = () => {
    this.props.GCSSMobx.a_set_current_page_number(1); //reset current page back to page 1
    this.props.UserMobx.a_update_user_per_email_field("gcss_search_results_compact0_large1", 1, "i");
  }

  render() {
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;
    const c_userGcssSearchResultsCompact0Large1 = this.props.UserMobx.c_userGcssSearchResultsCompact0Large1;

    //results size is forced (user setting not changed in database) to "large" when screen size is mobile, so remove this choice
    if(c_isMobileTF) {
      return(null);
    }

    return(
      <>
        <div className="textCenter">
          <font className="fontItalic fontTextLighter">
            {"Results Size"}
          </font>
        </div>
        <div className="displayFlexColumnHcVc microBottomMargin">
          <div className="flex00a displayFlexRow">
            <ResultDisplayControlButton
              p_compact0Large1={0}
              p_selectedTF={(c_userGcssSearchResultsCompact0Large1 !== 1)}
              p_selectedBgClass={c_gcssDataSourceColorsObj.gcssBgLightGradientClass}
              f_onClick={this.onclick_search_result_display_compact}
            />
            <div className="flex00a" style={{flexBasis:"0.2em"}} />
            <ResultDisplayControlButton
              p_compact0Large1={1}
              p_selectedTF={(c_userGcssSearchResultsCompact0Large1 === 1)}
              p_selectedBgClass={c_gcssDataSourceColorsObj.gcssBgLightGradientClass}
              f_onClick={this.onclick_search_result_display_large}
            />
          </div>
        </div>
      </>
    );
  }
}));

function ResultDisplayControlButton(props) { //props: p_compact0Large1, p_selectedTF, p_selectedBgClass, f_onClick
  const p_compact0Large1 = props.p_compact0Large1;
  const p_selectedTF = props.p_selectedTF;
  const p_selectedBgClass = props.p_selectedBgClass

  const isLargeDisplayTF = (p_compact0Large1 === 1);
  const isCompactDisplayTF = (!isLargeDisplayTF);

  var buttonTitle = undefined;
  var buttonPictureComponent = null;
  if(isCompactDisplayTF) {
    buttonTitle = "Compact search result item display with 50 items per page";
    buttonPictureComponent = (
      <>
        {[0,1,2,3,4].map((m_boxIndex) =>
          <ResultDisplayControlButtonInsideSingleBox key={m_boxIndex} p_boxIndex={m_boxIndex} p_selectedTF={p_selectedTF} p_heightEm={0.4} />
        )}
      </>
    );
  }
  else if(isLargeDisplayTF) {
    buttonTitle = "Large search result item display with 10 items per page";
    buttonPictureComponent = (
      <>
        {[0,1].map((m_boxIndex) =>
          <ResultDisplayControlButtonInsideSingleBox key={m_boxIndex} p_boxIndex={m_boxIndex} p_selectedTF={p_selectedTF} p_heightEm={1.2} />
        )}
      </>
    );
  }

  return(
    <div
      className={"flex00a displayFlexColumnHcVc border bevelBorderDarkColors " + ((p_selectedTF) ? (p_selectedBgClass) : ("bgLightGrayGradient hoverLighterGrayGradient cursorPointer"))}
      style={{width:"2.5em", height:"3.1em"}}
      title={buttonTitle}
      onClick={((p_selectedTF) ? (undefined) : (props.f_onClick))}>
      {buttonPictureComponent}
    </div>
  );
}

function ResultDisplayControlButtonInsideSingleBox(props) { //props: p_boxIndex, p_selectedTF, p_heightEm
  const p_boxIndex = props.p_boxIndex;
  const p_selectedTF = props.p_selectedTF;
  const p_heightEm = props.p_heightEm;

  return(
    <div style={{marginTop:((p_boxIndex > 0) ? ("0.1em") : (undefined)) ,width:"80%", height:p_heightEm + "em", border:"solid 1px #" + ((p_selectedTF) ? ("111") : ("999"))}} />
  );
}




const SearchResultsImportButtonWithImportSetupFloatingBox = inject("CaptureExecMobx", "GCSSMobx", "DatabaseMobx")(observer(
class SearchResultsImportButtonWithImportSetupFloatingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_gcssSetupImportFloatingBoxIsOpenTF: false
    };
  }

  onclick_set_up_import_into_captureexec_button = () => {
    const o_manualSearchLocalSelectedFilterValuesMap = this.props.GCSSMobx.o_manualSearchLocalSelectedFilterValuesMap;

    this.setState({s_gcssSetupImportFloatingBoxIsOpenTF:true});

    this.onchange_gcss_setup_import_capture_manager(o_manualSearchLocalSelectedFilterValuesMap.get("import_default_capture_managers_ids_colon_percent_comma"));
    this.onchange_gcss_setup_import_division_owner(o_manualSearchLocalSelectedFilterValuesMap.get("import_default_division_owners_ids_colon_percent_comma"));
    this.onchange_gcss_setup_import_capture_type(o_manualSearchLocalSelectedFilterValuesMap.get("import_default_capture_type_id"));
    this.onchange_gcss_setup_import_stage(o_manualSearchLocalSelectedFilterValuesMap.get("import_default_stage_id"));
    this.onselect_overwrite_data_with_updates_01(1); //always default a new import to overwrite (1)
  }

  onclick_close_set_up_import_floating_box = () => {
    this.setState({s_gcssSetupImportFloatingBoxIsOpenTF:false});
  }

  onchange_gcss_setup_import_capture_manager = (i_newValue) => {
    this.props.GCSSMobx.a_set_gcss_setup_import_capture_manager_ids_colon_percents_comma(i_newValue);
  }

  onchange_gcss_setup_import_division_owner = (i_newValue) => {
    this.props.GCSSMobx.a_set_gcss_setup_import_division_owner_ids_colon_percents_comma(i_newValue);
  }

  onchange_gcss_setup_import_capture_type = (i_newValue) => {
    this.props.GCSSMobx.a_set_gcss_setup_import_capture_type_id(i_newValue);
  }

  onchange_gcss_setup_import_stage = (i_newValue) => {
    this.props.GCSSMobx.a_set_gcss_setup_import_stage_id(i_newValue);
  }

  onselect_overwrite_data_with_updates_01 = (i_newValue01) => {
    this.props.GCSSMobx.a_set_gcss_setup_import_overwrite_data_with_updates_01(i_newValue01);
  }

  onclick_import_marked_for_import_results_into_captureexec = () => {
    this.setState({s_gcssSetupImportFloatingBoxIsOpenTF:false});
    this.props.GCSSMobx.a_gcss_import_marked_for_import_results_into_captureexec();
  }

  render() {
    const s_gcssSetupImportFloatingBoxIsOpenTF = this.state.s_gcssSetupImportFloatingBoxIsOpenTF;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_gcssSetupImportCaptureManagerIDsColonPercentsComma = this.props.GCSSMobx.o_gcssSetupImportCaptureManagerIDsColonPercentsComma;
    const o_gcssSetupImportDivisionOwnerIDsColonPercentsComma = this.props.GCSSMobx.o_gcssSetupImportDivisionOwnerIDsColonPercentsComma;
    const o_gcssSetupImportCaptureTypeID = this.props.GCSSMobx.o_gcssSetupImportCaptureTypeID;
    const o_gcssSetupImportStageID = this.props.GCSSMobx.o_gcssSetupImportStageID;
    const o_gcssSetupImportOverwriteDataWithUpdates01 = this.props.GCSSMobx.o_gcssSetupImportOverwriteDataWithUpdates01;
    const c_markedForImportExpandedSearchResultsArrayOfObjs = this.props.GCSSMobx.c_markedForImportExpandedSearchResultsArrayOfObjs;

    const numImport = c_markedForImportExpandedSearchResultsArrayOfObjs.length;
    const importDefaultFieldsContainerClass = "tbMicroPad";
    const importDefaultFieldsFieldClass = "fontBlue";
    const importDefaultFieldsFieldWidth = "30%";

    const gcssSetupImportCaptureTypeIsFilledOutTF = this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(o_gcssSetupImportCaptureTypeID, this.props.DatabaseMobx.c_selectCaptureTypeNoClearFieldTypeObj);
    const gcssSetupImportStagesInSelectedCaptureTypeFieldTypeObj = this.props.DatabaseMobx.create_select_stages_field_type_obj_within_capture_type_id(o_gcssSetupImportCaptureTypeID);

    return(
      <>
        <CEGeneralReact.CEButton
          p_type={((numImport > 0) ? ("blue") : ("blueDisabled"))}
          p_text={"Import into " + c_productStylingObj.productName}
          p_title={((numImport > 0) ? ("Open options for importing selected GCSS Opportunities") : ("No GCSS Opportunities have been marked for import yet"))}
          f_onClick={this.onclick_set_up_import_into_captureexec_button}
        />
        {(s_gcssSetupImportFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="medium"
            p_title={"Set up GCSS Import into " + c_productStylingObj.productName}
            f_onClickCancel={this.onclick_close_set_up_import_floating_box}>
            <div className="flex11a yScroll medFullPad">
              <div className="displayFlexColumnHcVc">
                <div className="border1bbb borderRadius10 bgLightestBlue medFullPad" style={{width:"80%"}}>
                  <div className="smallBottomMargin textCenter">
                    <font className="fontBold fontBlue">
                      {"Assignments applied to every new Capture created by this Import"}
                    </font>
                  </div>
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString="gcssImportSetupCaptureManagers"
                    p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfCaptureManagers.get("display_name")}
                    p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfCaptureManagers.get("fieldTypeObj")}
                    p_valueRaw={o_gcssSetupImportCaptureManagerIDsColonPercentsComma}
                    p_valueIsEditableTFU={true}
                    p_containerClass={importDefaultFieldsContainerClass}
                    p_fieldClass={importDefaultFieldsFieldClass}
                    p_fieldWidth={importDefaultFieldsFieldWidth}
                    f_onSaveChanged={this.onchange_gcss_setup_import_capture_manager}
                  />
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString="gcssImportSetupDivisionOwners"
                    p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("display_name")}
                    p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("fieldTypeObj")}
                    p_valueRaw={o_gcssSetupImportDivisionOwnerIDsColonPercentsComma}
                    p_valueIsEditableTFU={true}
                    p_containerClass={importDefaultFieldsContainerClass}
                    p_fieldClass={importDefaultFieldsFieldClass}
                    p_fieldWidth={importDefaultFieldsFieldWidth}
                    f_onSaveChanged={this.onchange_gcss_setup_import_division_owner}
                  />
                  <CEGeneralReact.CaptureExecFieldEditSaveCancel
                    p_ceEditItemString="gcssImportSetupCaptureType"
                    p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfCaptureType.get("display_name")}
                    p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfCaptureType.get("fieldTypeObj")}
                    p_valueRaw={o_gcssSetupImportCaptureTypeID}
                    p_valueIsEditableTFU={true}
                    p_containerClass={importDefaultFieldsContainerClass}
                    p_fieldClass={importDefaultFieldsFieldClass}
                    p_fieldWidth={importDefaultFieldsFieldWidth}
                    f_onSaveChanged={this.onchange_gcss_setup_import_capture_type}
                  />
                  {(gcssSetupImportCaptureTypeIsFilledOutTF) ? (
                    <CEGeneralReact.CaptureExecFieldEditSaveCancel
                      p_ceEditItemString="gcssImportSetupStage"
                      p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfStage.get("display_name")}
                      p_fieldTypeObj={gcssSetupImportStagesInSelectedCaptureTypeFieldTypeObj}
                      p_valueRaw={o_gcssSetupImportStageID}
                      p_valueIsEditableTFU={true}
                      p_containerClass={importDefaultFieldsContainerClass}
                      p_fieldClass={importDefaultFieldsFieldClass}
                      p_fieldWidth={importDefaultFieldsFieldWidth}
                      f_onSaveChanged={this.onchange_gcss_setup_import_stage}
                    />
                  ) : (
                    <div className="tbPad">
                      <font className="fontItalic fontTextLighter">
                        {"--Select a " + this.props.DatabaseMobx.c_fieldMapOfCaptureType.get("display_name") + " above before choosing a " + this.props.DatabaseMobx.c_fieldMapOfStage.get("display_name") + "--"}
                      </font>
                    </div>
                  )}
                  <div className="displayFlexColumnHcVc hugeTopMargin">
                    <div className="smallBottomMargin">
                      <font className="fontItalic">
                        {"GCSS Automatic Updates Overwrite Option"}
                      </font>
                    </div>
                    <div className="" style={{width:((c_isMobileTF) ? (undefined) : ("75%"))}}>
                      <CEGeneralReact.GenericInputOrSelectFromInputType
                        p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("fieldTypeObj")}
                        p_valueRaw={o_gcssSetupImportOverwriteDataWithUpdates01}
                        f_onChangeOrOnSelect={this.onselect_overwrite_data_with_updates_01}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bigTopMargin border1bbb borderRadius10 bgLightestGray medFullPad">
                <div className="smallBottomMargin textCenter">
                  <font className="font11 fontBold">
                    {numImport + " selected GCSS Search " + JSFUNC.plural(numImport, "Result", "Results") + " will be imported as " + JSFUNC.plural(numImport, "a new Capture", "new Captures") + " into " + c_productStylingObj.productName}
                  </font>
                </div>
                {c_markedForImportExpandedSearchResultsArrayOfObjs.map((m_expandedSearchResultsObj) =>
                  <div className="microTopMargin">
                    <font className="fontItalic fontTextLight">
                      {" - " + m_expandedSearchResultsObj.opportunityNameMaskSortIfoObj.valueMaskPlainText}
                    </font>
                  </div>
                )}
              </div>
            </div>
            <div className="flex00a displayFlexRowHcVc borderT1bbb medFullPad">
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text={"Import " + numImport + " " + JSFUNC.plural(numImport, "Capture", "Captures") + " into " + c_productStylingObj.productName}
                  f_onClick={this.onclick_import_marked_for_import_results_into_captureexec}
                />
              </div>
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Cancel"
                  f_onClick={this.onclick_close_set_up_import_floating_box}
                />
              </div>
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));


const AllSearchResultItemsOnCurrentPage = inject("GCSSMobx")(observer(
class AllSearchResultItemsOnCurrentPage extends Component {
  render() {
    const c_currentPageOfExpandedSearchResultsArrayOfObjs = this.props.GCSSMobx.c_currentPageOfExpandedSearchResultsArrayOfObjs;
    return(
      c_currentPageOfExpandedSearchResultsArrayOfObjs.map((m_expandedSearchResultsObj, m_index) =>
        <SingleSearchResultItem
          key={m_expandedSearchResultsObj.id}
          p_expandedSearchResultObj={m_expandedSearchResultsObj}
          p_pageIndex={m_index}
        />
      )
    );
  }
}));


const SingleSearchResultItem = inject("CaptureExecMobx", "GCSSMobx", "UserMobx")(observer(
class SingleSearchResultItem extends Component { //props: p_expandedSearchResultObj, p_pageIndex
  onclick_search_result_item = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    this.props.GCSSMobx.a_set_open_search_result_detail_gcss_id_or_undefined(p_expandedSearchResultObj.id); //open detail
  }

  onclick_three_way_switch = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    this.props.GCSSMobx.a_set_focused_tws_search_result_gcss_id_or_undefined(p_expandedSearchResultObj.id); //set three way switch focus
  }

  onswitch_mark_to_left = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(1, p_expandedSearchResultObj);
  }

  onswitch_mark_to_right = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(2, p_expandedSearchResultObj);
  }

  onswitch_mark_to_center = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(0, p_expandedSearchResultObj);
  }

  onkeydownup_three_way_switch = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    const previousSearchResultIDOrUndefined = this.props.GCSSMobx.previous_search_result_id_or_undefined_on_current_page_from_search_result_id(p_expandedSearchResultObj.id);
    if(previousSearchResultIDOrUndefined !== undefined) {
      this.props.GCSSMobx.a_set_focused_tws_search_result_gcss_id_or_undefined(previousSearchResultIDOrUndefined);
    }
  }

  onkeydowndown_three_way_switch = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    const nextSearchResultIDOrUndefined = this.props.GCSSMobx.next_search_result_id_or_undefined_on_current_page_from_search_result_id(p_expandedSearchResultObj.id);
    if(nextSearchResultIDOrUndefined !== undefined) {
      this.props.GCSSMobx.a_set_focused_tws_search_result_gcss_id_or_undefined(nextSearchResultIDOrUndefined);
    }
  }

  render() {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    const p_pageIndex = this.props.p_pageIndex;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_focusedTwsSearchResultGcssIDOrUndefined = this.props.GCSSMobx.o_focusedTwsSearchResultGcssIDOrUndefined;
    const o_openSearchResultDetailGcssIDOrUndefined = this.props.GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined;
    const c_gcssDataSourceColorsObj = this.props.GCSSMobx.c_gcssDataSourceColorsObj;
    const c_currentPageFirstItemNumber = this.props.GCSSMobx.c_currentPageFirstItemNumber;
    const c_searchResultItemWidthFlag = this.props.GCSSMobx.c_searchResultItemWidthFlag;
    const c_userCanMarkImportOrMergeCapturesFromGCSSTF = this.props.UserMobx.c_userCanMarkImportOrMergeCapturesFromGCSSTF;
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;
    const c_userGcssSearchResultsCompact0Large1 = this.props.UserMobx.c_userGcssSearchResultsCompact0Large1;

    const searchResultTwsIsFocusedTF = (p_expandedSearchResultObj.id === o_focusedTwsSearchResultGcssIDOrUndefined);
    const searchResultDetailIsOpenTF = (p_expandedSearchResultObj.id === o_openSearchResultDetailGcssIDOrUndefined);

    //search results size (user setting), true - large, false - compact
    var isLargeTF = (c_userGcssSearchResultsCompact0Large1 === 1);
    if(c_isMobileTF) { //force search result size to display as large when in mobile screen size
      isLargeTF = true;
    }

    var searchResultContainerMarginClass = "tbMargin";
    var twsContainerWidthEm = 5;
    var twsWidthEm = 4;
    var alreadyInCELargeTF = false;
    var fieldValuesContainerPaddingClass = "tbMicroPad";
    if(isLargeTF) {
      searchResultContainerMarginClass = "tbMedMargin";
      twsContainerWidthEm = 7;
      twsWidthEm = 4.75;
      alreadyInCELargeTF = true;
      fieldValuesContainerPaddingClass = "tbMicroPad";
    }

    var threeWaySwitchOrAlreadyInCEComponent = null;
    if(p_expandedSearchResultObj.alreadyInCETF) { //always display if a capture is already in CE for any user
      threeWaySwitchOrAlreadyInCEComponent = (
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:twsContainerWidthEm + "em"}}>
          <AlreadyInCELabel
            p_expandedSearchResultObj={p_expandedSearchResultObj}
            p_isShortTF={true}
            p_isLargeTF={alreadyInCELargeTF}
          />
        </div>
      );
    }
    else if(c_userCanMarkImportOrMergeCapturesFromGCSSTF) { //only display three-way switch if user can mark/import/merge GCSS opps
      threeWaySwitchOrAlreadyInCEComponent = (
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:twsContainerWidthEm + "em"}}>
          <LibraryReact.ThreeWaySwitch
            key={p_expandedSearchResultObj.id}
            p_stateC0L1R2={p_expandedSearchResultObj.markedUndec0NotInt1Import2}
            p_sizeEm={twsWidthEm}
            p_leftColor="333"
            p_rightColor="00f"
            p_tabIndex={undefined}
            p_focusTF={searchResultTwsIsFocusedTF}
            p_leftTitle="Mark as Not Interested"
            p_centerTitle="Mark as Undecided"
            p_rightTitle="Mark for Import"
            p_errorTF={undefined}
            f_onSelectCenter={this.onswitch_mark_to_center}
            f_onSelectLeft={this.onswitch_mark_to_left}
            f_onSelectRight={this.onswitch_mark_to_right}
            f_onClick={this.onclick_three_way_switch}
            f_onKeyDownEnter={this.onclick_search_result_item}
            f_onKeyDownUpArrow={this.onkeydownup_three_way_switch}
            f_onKeyDownDownArrow={this.onkeydowndown_three_way_switch}
          />
        </div>
      );
    }
    else {
      threeWaySwitchOrAlreadyInCEComponent = (
        <div className="flex00a" style={{flexBasis:twsContainerWidthEm + "em"}} />
      );
    }
    
    var deptAgSubTierOrOfficeRowsComponent = null;
    if(c_userGcssDataSource0SamTF || c_userGcssDataSource3CexaiTF) {
      deptAgSubTierOrOfficeRowsComponent = (
        <>
          <SearchResultFieldDepartmentAgency p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} p_blankIfBlankTF={!isLargeTF} />
          <SearchResultFieldSubTier p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} p_blankIfBlankTF={!isLargeTF} />
        </>
      );
    }
    else if(c_userGcssDataSource1GovWinTF || c_userGcssDataSource2FedCompTF) {
      deptAgSubTierOrOfficeRowsComponent = (
        <SearchResultFieldOffice p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} p_blankIfBlankTF={!isLargeTF} />
      );
    }

    var dataSourceLinkComponent = null;
    if(c_userGcssDataSource0SamTF || c_userGcssDataSource3CexaiTF) {
      dataSourceLinkComponent = (
        <SearchResultFieldSamUrl p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
      );
    }
    else if(c_userGcssDataSource1GovWinTF) {
      dataSourceLinkComponent = (
        <SearchResultFieldGovWinLink p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
      );
    }
    else if(c_userGcssDataSource2FedCompTF) {
      dataSourceLinkComponent = (
        <SearchResultFieldFedCompLink p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
      );
    }

    var fieldValuesComponent = null;
    if(!isLargeTF) { //compact size (not large)
      const lastUpdatedDateOriginalPublishedDateSamUrlRowComponent = (
        <div className="displayFlexRowVc">
          <SearchResultFieldLastUpdatedDate p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={8.5} />
          <SearchResultFieldOriginalPublishedDate p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={8.5} />
          {dataSourceLinkComponent}
        </div>
      );

      var popStateCountryRowComponent = null;
      if(c_userGcssDataSource0SamTF || c_userGcssDataSource2FedCompTF || c_userGcssDataSource3CexaiTF) {
        popStateCountryRowComponent = (
          <div className="displayFlexRowVc">
            <SearchResultFieldPlaceOfPerformanceCountry p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={15} />
            <SearchResultFieldPlaceOfPerformanceState p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
          </div>
        );
      }
      else if(c_userGcssDataSource1GovWinTF) {
        popStateCountryRowComponent = (
          <SearchResultFieldPlaceOfPerformanceCountry p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
        );
      }

      if(c_searchResultItemWidthFlag === 1) { //screen 1/2 mobile tablet
        fieldValuesComponent = (
          <div className="flex11a">
            <SearchResultFieldProposalDueDateTimeUtc p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
            {lastUpdatedDateOriginalPublishedDateSamUrlRowComponent}
            <SearchResultFieldOpportunityName p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} f_onClick={this.onclick_search_result_item} />
            <SearchResultFieldDescriptionOfWork p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
            {(c_userGcssDataSource2FedCompTF) &&
              <SearchResultFieldFedCompContractType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            }
            <SearchResultFieldSetAside p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            <SearchResultFieldContractOpportunityType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            <SearchResultFieldNaicsCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            <SearchResultFieldProductServiceCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            {deptAgSubTierOrOfficeRowsComponent}
            {popStateCountryRowComponent}
          </div>
        );
      }
      else if(c_searchResultItemWidthFlag === 2) { //screen 3/4
        fieldValuesComponent = (
          <>
            <div className="flex00a" style={{flexBasis:"26em"}}>
              <SearchResultFieldProposalDueDateTimeUtc p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
              {lastUpdatedDateOriginalPublishedDateSamUrlRowComponent}
              <SearchResultFieldSetAside p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              <SearchResultFieldContractOpportunityType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              <SearchResultFieldNaicsCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              <SearchResultFieldProductServiceCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            </div>
            <div className="flex11a">
              <SearchResultFieldOpportunityName p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} f_onClick={this.onclick_search_result_item} />
              <SearchResultFieldDescriptionOfWork p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
              {(c_userGcssDataSource2FedCompTF) &&
                <SearchResultFieldFedCompContractType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              }
              {deptAgSubTierOrOfficeRowsComponent}
              {popStateCountryRowComponent}
            </div>
          </>
        );
      }
      else { //screen 5/6
        fieldValuesComponent = (
          <>
            <div className="flex00a" style={{flexBasis:"26em"}}>
              <SearchResultFieldProposalDueDateTimeUtc p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
              {lastUpdatedDateOriginalPublishedDateSamUrlRowComponent}
              <SearchResultFieldSetAside p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            </div>
            <div className="flex11a" style={{flexBasis:"200em"}}>
              <SearchResultFieldOpportunityName p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} f_onClick={this.onclick_search_result_item} />
              <SearchResultFieldDescriptionOfWork p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} />
              {popStateCountryRowComponent}
            </div>
            <div className="flex11a" style={{flexBasis:"100em"}}>
              {(c_userGcssDataSource2FedCompTF) &&
                <SearchResultFieldFedCompContractType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              }
              {deptAgSubTierOrOfficeRowsComponent}
            </div>
            <div className="flex11a" style={{flexBasis:"100em"}}>
              <SearchResultFieldContractOpportunityType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              <SearchResultFieldNaicsCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
              <SearchResultFieldProductServiceCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_blankIfBlankTF={true} />
            </div>
          </>
        );
      }
    }
    else { //large display (p_isLarge is true and opportunityName font is bigger)
      fieldValuesComponent = (
        <div className="flex11a">
          <SearchResultFieldOpportunityName p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} p_fontSizeClass="font13" f_onClick={this.onclick_search_result_item} />
          <SearchResultFieldDescriptionOfWork p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldProposalDueDateTimeUtc p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldLastUpdatedDate p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldOriginalPublishedDate p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          {dataSourceLinkComponent}
          {(c_userGcssDataSource2FedCompTF) &&
            <SearchResultFieldFedCompContractType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          }
          <SearchResultFieldSetAside p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldContractOpportunityType p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldNaicsCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldProductServiceCode p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          {deptAgSubTierOrOfficeRowsComponent}
          <SearchResultFieldPlaceOfPerformanceCountry p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
          <SearchResultFieldPlaceOfPerformanceState p_expandedSearchResultObj={p_expandedSearchResultObj} p_widthEm={undefined} p_isLargeTF={isLargeTF} />
        </div>
      );
    }

    var searchResultNumberContainerBgClass = "";
    if(!searchResultDetailIsOpenTF) {
      searchResultNumberContainerBgClass = ((searchResultTwsIsFocusedTF) ? (c_gcssDataSourceColorsObj.gcssBgLightGradientClass) : ("bgLightGrayGradient")) + " " + c_gcssDataSourceColorsObj.gcssHoverLighterGradientClass + " cursorPointer";
    }

    var itemBgClass = ((p_expandedSearchResultObj.alreadyInCETF) ? ("border1LightGreen") : ("border1bbb")) + " bgLightestGray";
    if(searchResultDetailIsOpenTF) {
      itemBgClass = "border1bbb bevelBorderColorBlue " + c_gcssDataSourceColorsObj.gcssBgLightGradientClass;
    }

    return(
      <div className={"displayFlexRow " + searchResultContainerMarginClass + " " + itemBgClass}>
        <div
          className={"flex00a displayFlexColumnHcVc " + searchResultNumberContainerBgClass}
          style={{flexBasis:"2em"}}
          title="Click to open Detail Panel for this Search Result"
          onClick={((searchResultDetailIsOpenTF) ? (undefined) : (this.onclick_search_result_item))}>
          <font className="">
            {(c_currentPageFirstItemNumber + p_pageIndex)}
          </font>
        </div>
        {(c_userGcssDataSource3CexaiTF) &&
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"5em"}}>
            <div
              className="flex00a displayFlexColumnHcVc border bevelBorderDarkColors borderRadius15 textCenter"
              style={{height:"2em", width:"4em", background:"#" + p_expandedSearchResultObj.cexaiModelScoreColor}}
              title={p_expandedSearchResultObj.cexaiModelScoreTitle}>
              <font className="font12 fontBold fontWhite">
                {p_expandedSearchResultObj.cexaiModelScoreDisplay}
              </font>
            </div>
          </div>
        }
        {threeWaySwitchOrAlreadyInCEComponent}
        <div className={"flex11a displayFlexRow " + fieldValuesContainerPaddingClass}>
          {fieldValuesComponent}
        </div>
      </div>
    );
  }
}));

const AlreadyInCELabel = inject("CaptureExecMobx", "GCSSMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class AlreadyInCELabel extends Component { //props: p_expandedSearchResultObj, p_isShortTF, p_isLargeTF
  onclick_already_in_ce_label = () => {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;

    const c_gcssTblCapturesGcssIDColumnDbName = this.props.GCSSMobx.c_gcssTblCapturesGcssIDColumnDbName;

    //find the matching capture based on the gcssID field matching the gcss tbl row id for this search result
    const matchingCaptureMap = JSFUNC.get_first_map_matching_field_value(this.props.DatabaseMobx.o_tbl_captures, c_gcssTblCapturesGcssIDColumnDbName, p_expandedSearchResultObj.id);
    if(matchingCaptureMap !== undefined) {
      this.props.OpenCaptureMobx.a_open_single_capture(matchingCaptureMap.get("id"));
    }
  }

  render() {
    const p_expandedSearchResultObj = this.props.p_expandedSearchResultObj;
    const p_isShortTF = this.props.p_isShortTF;
    const p_isLargeTF = JSFUNC.prop_value(this.props.p_isLargeTF, false);

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    var widthEm = 25;
    var heightEm = 1.8;
    var fontSizeClass = "";
    var textComponent = "Already Imported into " + c_productStylingObj.productName + " on " + p_expandedSearchResultObj.alreadyInCEAddedDateMaskPlainText;
    if(p_isShortTF) {
      if(p_isLargeTF) {
        widthEm = 5.5;
        heightEm = 3.2;
        fontSizeClass = "font11";
      }
      else {
        widthEm = 4.1;
        heightEm = 2.8;
      }

      textComponent = (
        <>
          <div>{"Already"}</div>
          <div>{"in " + c_productStylingObj.productNameAcronym}</div>
        </>
      );
    }

    return(
      <div
        className="displayFlexColumnHcVc border1LightGreen borderRadius05 bgDarkGreenGradient hoverGreenGradient textCenter cursorPointer"
        style={{width:widthEm + "em", height:heightEm + "em"}}
        title={"Click to open this already imported Capture in " + c_productStylingObj.productName}
        onClick={this.onclick_already_in_ce_label}>
        <font className={fontSizeClass + " fontItalic fontWhite"}>
          {textComponent}
        </font>
      </div>
    );
  }
}));

function SearchResultFieldOriginalPublishedDate(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.originalPublishedDateMaskSortIfoObj} p_fieldDisplayName="Original Published Date" p_label="OP" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldLastUpdatedDate(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.lastUpdatedDateMaskSortIfoObj} p_fieldDisplayName="Last Updated Date" p_label="LU" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldProposalDueDateTimeUtc(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.proposalDueDateTimeUtcMaskSortIfoObj} p_fieldDisplayName="Proposal Due Date" p_label="Proposal Due" p_widthEm={props.p_widthEm} p_valueFontClass={((props.p_expandedSearchResultObj.proposalDueDateHasPassedTF) ? ("fontItalic fontTextLighter") : (undefined))} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldSamUrl(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.samUrlMaskSortIfoObj} p_fieldDisplayName="SAM Link" p_label={undefined} p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldGovWinLink(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.govWinLinkMaskSortIfoObj} p_fieldDisplayName="GovWin Link" p_label={undefined} p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldFedCompLink(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.fedCompLinkMaskSortIfoObj} p_fieldDisplayName="Federal Compass Link" p_label={undefined} p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldOpportunityName(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF, p_fontSizeClass, f_onClick
  const p_fontSizeClass = JSFUNC.prop_value(props.p_fontSizeClass, "font11");
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.opportunityNameMaskSortIfoObj} p_fieldDisplayName="Opportunity Name" p_label={undefined} p_widthEm={props.p_widthEm} p_valueFontClass={p_fontSizeClass + " fontBold fontBlue"} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} f_onClick={props.f_onClick} />);
}
function SearchResultFieldDescriptionOfWork(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.descriptionOfWorkOpeningMaskSortIfoObj} p_fieldDisplayName="Description of Work" p_label="Description" p_widthEm={props.p_widthEm} p_valueFontClass="fontItalic" p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldDepartmentAgency(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.departmentAgencyMaskSortIfoObj} p_fieldDisplayName="Department/Agency" p_label="Dept" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldSubTier(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.subTierMaskSortIfoObj} p_fieldDisplayName="Sub-Tier" p_label="Sub-Tier" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldOffice(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.officeMaskSortIfoObj} p_fieldDisplayName="Office" p_label="Office" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldSetAside(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.setAsideMaskSortIfoObj} p_fieldDisplayName="Set Aside" p_label="Set Aside" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldContractOpportunityType(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.contractOpportunityTypeMaskSortIfoObj} p_fieldDisplayName="Contract Opportunity Type" p_label="Type" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldFedCompContractType(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.fedCompContractTypeMaskSortIfoObj} p_fieldDisplayName="Federal Compass Contract Type" p_label="FCCT" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldNaicsCode(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.naicsCodeMaskSortIfoObj} p_fieldDisplayName="NAICS Code" p_label="NAICS" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldProductServiceCode(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.productServiceCodeMaskSortIfoObj} p_fieldDisplayName="Product Service Code" p_label="PSC" p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldPlaceOfPerformanceState(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.placeOfPerformanceStateMaskSortIfoObj} p_fieldDisplayName="Place Of Performance State" p_label={undefined} p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}
function SearchResultFieldPlaceOfPerformanceCountry(props) { //props: p_expandedSearchResultObj, p_widthEm, p_isLargeTF, p_blankIfBlankTF
  return(<SearchResultSingleField p_valueMaskSortIfoObj={props.p_expandedSearchResultObj.placeOfPerformanceCountryMaskSortIfoObj} p_fieldDisplayName="Place Of Performance Country" p_label={undefined} p_widthEm={props.p_widthEm} p_valueFontClass={undefined} p_isLargeTF={props.p_isLargeTF} p_blankIfBlankTF={props.p_blankIfBlankTF} />);
}

function SearchResultSingleField(props) { //props: p_valueMaskSortIfoObj, p_fieldDisplayName, p_label, p_widthEm, p_valueFontClass, p_isLargeTF, p_blankIfBlankTF, f_onClick
  const p_valueMaskSortIfoObj = props.p_valueMaskSortIfoObj;
  const p_fieldDisplayName = props.p_fieldDisplayName;
  const p_label = props.p_label;
  const p_widthEm = props.p_widthEm;
  const p_valueFontClass = props.p_valueFontClass;
  const p_isLargeTF = JSFUNC.prop_value(props.p_isLargeTF, false);
  const p_blankIfBlankTF = JSFUNC.prop_value(props.p_blankIfBlankTF, false);

  var containerFlexClass = "flex11a";
  var containerFlexBasis = "100em";
  if(p_widthEm !== undefined) {
    containerFlexClass = "flex00a";
    containerFlexBasis = p_widthEm + "em";
  }

  var label = p_label;
  if(p_isLargeTF) {
    label = p_fieldDisplayName;
  }

  const labelTF = (label !== undefined);

  var valueMask = p_valueMaskSortIfoObj.valueMask;
  if(p_blankIfBlankTF && !p_valueMaskSortIfoObj.isFilledOutTF) {
    valueMask = "";
  }

  return(
    <div
      className={containerFlexClass + " displayFlexRowVc tbMicroPad lrPad " + ((props.f_onClick !== undefined) ? ("hoverLighterBlueGradient cursorPointer") : (""))}
      style={{flexBasis:containerFlexBasis}}
      title={p_fieldDisplayName + ": " + p_valueMaskSortIfoObj.valueMaskPlainText}
      onClick={props.f_onClick}>
      {(labelTF) &&
        <div className="flex00a rMargin nowrap" style={{flexBasis:((p_isLargeTF) ? ("15em") : (undefined))}}>
          <font className={"fontItalic " + ((p_isLargeTF) ? ("fontTextLighter") : ("fontTextLightester"))}>
            {label + ":"}
          </font>
        </div>
      }
      <div className="flex11a">
        <LibraryReact.Nowrap p_fontClass={p_valueFontClass}>
          {valueMask}
        </LibraryReact.Nowrap>
      </div>
    </div>
  );
}




//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&



const GCSSOpenDetailSearchResultContents = inject("CaptureExecMobx", "GCSSMobx", "DatabaseMobx", "UserMobx")(observer(
class GCSSOpenDetailSearchResultContents extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_mergeWithExistingCaptureFloatingBoxIsOpenTF: false
    };
  }

  onclick_mark_as_not_interested = () => {
    const c_openExpandedDetailSearchResultObjOrUndefined = this.props.GCSSMobx.c_openExpandedDetailSearchResultObjOrUndefined;
    this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(1, c_openExpandedDetailSearchResultObjOrUndefined);
  }

  onclick_reset_as_undecided = () => {
    const c_openExpandedDetailSearchResultObjOrUndefined = this.props.GCSSMobx.c_openExpandedDetailSearchResultObjOrUndefined;
    this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(0, c_openExpandedDetailSearchResultObjOrUndefined);
  }

  onclick_mark_for_import = () => {
    const c_openExpandedDetailSearchResultObjOrUndefined = this.props.GCSSMobx.c_openExpandedDetailSearchResultObjOrUndefined;
    this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(2, c_openExpandedDetailSearchResultObjOrUndefined);
  }

  onclick_merge_with_existing_capture = () => {
    this.setState({s_mergeWithExistingCaptureFloatingBoxIsOpenTF:true});
  }

  onclick_close_merge_with_existing_capture_floating_box = () => {
    this.setState({s_mergeWithExistingCaptureFloatingBoxIsOpenTF:false});
  }

  onkeydownesc_open_search_result_detail = () => {
    this.props.GCSSMobx.a_set_open_search_result_detail_gcss_id_or_undefined(undefined);
  }

  onkeydownup_open_search_result_detail = () => {
    const o_openSearchResultDetailGcssIDOrUndefined = this.props.GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined;
    const o_openSearchResultDetailOpTblDataRowsAreLoadingTF = this.props.GCSSMobx.o_openSearchResultDetailOpTblDataRowsAreLoadingTF;
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;

    if(!o_openSearchResultDetailOpTblDataRowsAreLoadingTF || c_userGcssDataSource0SamTF || c_userGcssDataSource3CexaiTF) { //prevent arrows when GovWin/FedComp are still loading an open search result details from the API
      const previousSearchResultIDOrUndefined = this.props.GCSSMobx.previous_search_result_id_or_undefined_on_current_page_from_search_result_id(o_openSearchResultDetailGcssIDOrUndefined);
      if(previousSearchResultIDOrUndefined !== undefined) {
        this.props.GCSSMobx.a_set_open_search_result_detail_gcss_id_or_undefined(previousSearchResultIDOrUndefined);
      }
    }
  }

  onkeydowndown_open_search_result_detail = () => {
    const o_openSearchResultDetailGcssIDOrUndefined = this.props.GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined;
    const o_openSearchResultDetailOpTblDataRowsAreLoadingTF = this.props.GCSSMobx.o_openSearchResultDetailOpTblDataRowsAreLoadingTF;
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;

    if(!o_openSearchResultDetailOpTblDataRowsAreLoadingTF || c_userGcssDataSource0SamTF || c_userGcssDataSource3CexaiTF) { //prevent arrows when GovWin is still loading an open search result details from the API
      const nextSearchResultIDOrUndefined = this.props.GCSSMobx.next_search_result_id_or_undefined_on_current_page_from_search_result_id(o_openSearchResultDetailGcssIDOrUndefined);
      if(nextSearchResultIDOrUndefined !== undefined) {
        this.props.GCSSMobx.a_set_open_search_result_detail_gcss_id_or_undefined(nextSearchResultIDOrUndefined);
      }
    }
  }

  onsave_not_interested_reasons = (i_updatedNotInterestedReasonIDsComma) => {
    const c_openExpandedDetailSearchResultObjOrUndefined = this.props.GCSSMobx.c_openExpandedDetailSearchResultObjOrUndefined;
    this.props.GCSSMobx.a_update_gcss_not_interested_field(c_openExpandedDetailSearchResultObjOrUndefined.gcssNotInterestedRowID, "reason_ids_comma", i_updatedNotInterestedReasonIDsComma, "s");
  }

  onsave_not_interested_explanation = (i_updatedNotInterestedExplanation) => {
    const c_openExpandedDetailSearchResultObjOrUndefined = this.props.GCSSMobx.c_openExpandedDetailSearchResultObjOrUndefined;
    this.props.GCSSMobx.a_update_gcss_not_interested_field(c_openExpandedDetailSearchResultObjOrUndefined.gcssNotInterestedRowID, "explanation", i_updatedNotInterestedExplanation, "s");
  }

  render() {
    const s_mergeWithExistingCaptureFloatingBoxIsOpenTF = this.state.s_mergeWithExistingCaptureFloatingBoxIsOpenTF;

    const o_mediaQueryFlag = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_openSearchResultDetailGcssIDOrUndefined = this.props.GCSSMobx.o_openSearchResultDetailGcssIDOrUndefined;
    const c_openExpandedDetailSearchResultObjOrUndefined = this.props.GCSSMobx.c_openExpandedDetailSearchResultObjOrUndefined;
    const c_userCanMarkImportOrMergeCapturesFromGCSSTF = this.props.UserMobx.c_userCanMarkImportOrMergeCapturesFromGCSSTF;

    //a search item with an invalid ID is open
    if((o_openSearchResultDetailGcssIDOrUndefined === undefined) || (c_openExpandedDetailSearchResultObjOrUndefined === undefined)) {
      var errorMessage = "--GCSS Search Result";
      if(o_openSearchResultDetailGcssIDOrUndefined !== undefined) {
        errorMessage += " (ID: " + o_openSearchResultDetailGcssIDOrUndefined + ")";
      }
      errorMessage += " is not currently loaded--";

      return(
        <LibraryReact.InteractiveDiv
          p_class="flex11a displayFlexColumn bgLighterGray"
          f_onKeyDownEsc={this.onkeydownesc_open_search_result_detail}>
          <div className="flex00a displayFlexRow borderB1bbb bgLighterBlue" style={{flexBasis:"2.5em"}}>
            <CloseGCSSOpenPanelButton p_fontClass="fontTextLighter" f_onClick={this.onkeydownesc_open_search_result_detail} />
            <div className="flex11a" />
          </div>
          <CEGeneralReact.EmptyScreenWhite>
            {errorMessage}
          </CEGeneralReact.EmptyScreenWhite>
        </LibraryReact.InteractiveDiv>
      );
    }

    const notInterestedReasonsCEEditItemString = "gcssNIReasons" + c_openExpandedDetailSearchResultObjOrUndefined.id;
    const notInterestedExplanationCEEditItemString = "gcssNIExplanation" + c_openExpandedDetailSearchResultObjOrUndefined.id;
    const isContactFieldTF = false;
    const isEditingReasonsTF = this.props.CaptureExecMobx.is_editing_item_tf(notInterestedReasonsCEEditItemString, isContactFieldTF);
    const isEditingExplanationTF = this.props.CaptureExecMobx.is_editing_item_tf(notInterestedExplanationCEEditItemString, isContactFieldTF);
    const notInterestedReasonsOrExplanationCurrentlyBeingEditedTF = (isEditingReasonsTF || isEditingExplanationTF);

    var markMergeButtonsOrAlreadyInCEComponent = null;
    if(c_openExpandedDetailSearchResultObjOrUndefined.alreadyInCETF) {
      markMergeButtonsOrAlreadyInCEComponent = (
        <div className="flex00a displayFlexColumnHcVc" style={{height:"3.5em"}}>
          <AlreadyInCELabel
            p_expandedSearchResultObj={c_openExpandedDetailSearchResultObjOrUndefined}
            p_isShortTF={false}
          />
        </div>
      );
    }
    else if(c_userCanMarkImportOrMergeCapturesFromGCSSTF) {
      var mergeButtonContainerLeftWidthEm = 15;
      var mergeButtonContainerRightWidthEm = 15;
      if(c_isMobileOrTabletTF) {
        mergeButtonContainerLeftWidthEm = 1;
        mergeButtonContainerRightWidthEm = 8;
      }
      else if(o_mediaQueryFlag === 3) {
        mergeButtonContainerLeftWidthEm = 4;
        mergeButtonContainerRightWidthEm = 15;
      }
      else if(o_mediaQueryFlag === 4) {
        mergeButtonContainerLeftWidthEm = 8;
        mergeButtonContainerRightWidthEm = 15;
      }

      var markButtonWidthsEm = 12;
      var mergeButtonWidthEm = 14;
      var markAsNotInterestedButtonText = "Mark as Not Interested";
      var markAsUndecidedButtonText = "Undecided";
      var markForImportButtonText = "Mark for Import";
      var mergeWithExistingCaptureButtonText = "Merge with Existing Capture";
      if(c_isMobileOrTabletTF) {
        markButtonWidthsEm = 7;
        mergeButtonWidthEm = 7;
        markAsNotInterestedButtonText = "Mark NI";
        markAsUndecidedButtonText = "Undecided";
        markForImportButtonText = "Mark Import";
        mergeWithExistingCaptureButtonText = "Merge";
      }
      
      const notInterestedTitle = "Mark this opportunity as Not Interested (marked at a shared company wide level)\n[Shortcut - Left Arrow key]";
      const undecidedTitle = "Return this opportunity to the original Undecided state\n[Shortcut - Space Bar key]";
      const importTitle = "Mark this opportunity for Import (marked for your personal import bucket)\n[Shortcut - Right Arrow key]";
      var mergeTitle = "Merge the data from this GCSS record with an opportunity that has already been created in " + c_productStylingObj.productName + ".";
      mergeTitle += "\nYou will select which capture to merge and link this data with on the next screen.";
      mergeTitle += "\nAny fields from GCSS (that are filled out) will overwrite your data in the " + c_productStylingObj.productName + " record.";
      
      markMergeButtonsOrAlreadyInCEComponent = (
        <div className="flex00a displayFlexRow" style={{height:"3.5em"}}>
          <div className="flex00a" style={{flexBasis:mergeButtonContainerLeftWidthEm + "em"}} />
          <div className="flex11a displayFlexRowHcVc">
            <OpenDetailMarkButtonWithLine
              p_text={markAsNotInterestedButtonText}
              p_selectedTF={c_openExpandedDetailSearchResultObjOrUndefined.buttonNotInterestedSelectedTF}
              p_widthEm={markButtonWidthsEm}
              p_selectedBgClass="bgDarkGrayGradient"
              p_lineBgClass="bgDarkGray"
              p_title={notInterestedTitle}
              f_onClick={this.onclick_mark_as_not_interested}
            />
            <OpenDetailMarkButtonWithLine
              p_text={markAsUndecidedButtonText}
              p_selectedTF={c_openExpandedDetailSearchResultObjOrUndefined.buttonUndecidedSelectedTF}
              p_widthEm={markButtonWidthsEm}
              p_selectedBgClass="bgGrayGradient"
              p_lineBgClass="bgLightGray"
              p_title={undecidedTitle}
              f_onClick={this.onclick_reset_as_undecided}
            />
            <OpenDetailMarkButtonWithLine
              p_text={markForImportButtonText}
              p_selectedTF={c_openExpandedDetailSearchResultObjOrUndefined.buttonMarkForImportSelectedTF}
              p_widthEm={markButtonWidthsEm}
              p_selectedBgClass="bgBlueGradient"
              p_lineBgClass="bgBlue"
              p_title={importTitle}
              f_onClick={this.onclick_mark_for_import}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:mergeButtonContainerRightWidthEm + "em"}}>
            <OpenDetailMarkButtonWithLine
              p_text={mergeWithExistingCaptureButtonText}
              p_selectedTF={false}
              p_widthEm={mergeButtonWidthEm}
              p_selectedBgClass="bgGreenGradient"
              p_lineBgClass="bgGreen"
              p_title={mergeTitle}
              f_onClick={this.onclick_merge_with_existing_capture}
            />
          </div>
        </div>
      );
    }

    return(
      <>
        <LibraryReact.InteractiveDiv
          p_class="flex11a displayFlexColumn bgLighterGray"
          p_drawFocusBorderTF={false}
          p_focusTF={!notInterestedReasonsOrExplanationCurrentlyBeingEditedTF}
          f_onKeyDownEsc={((notInterestedReasonsOrExplanationCurrentlyBeingEditedTF) ? (undefined) : (this.onkeydownesc_open_search_result_detail))}
          f_onKeyDownSpace={((notInterestedReasonsOrExplanationCurrentlyBeingEditedTF) ? (undefined) : (this.onclick_reset_as_undecided))}
          f_onKeyDownUpArrow={((notInterestedReasonsOrExplanationCurrentlyBeingEditedTF) ? (undefined) : (this.onkeydownup_open_search_result_detail))}
          f_onKeyDownDownArrow={((notInterestedReasonsOrExplanationCurrentlyBeingEditedTF) ? (undefined) : (this.onkeydowndown_open_search_result_detail))}
          f_onKeyDownLeftArrow={((notInterestedReasonsOrExplanationCurrentlyBeingEditedTF) ? (undefined) : (this.onclick_mark_as_not_interested))}
          f_onKeyDownRightArrow={((notInterestedReasonsOrExplanationCurrentlyBeingEditedTF) ? (undefined) : (this.onclick_mark_for_import))}>
          <div className="flex00a displayFlexRow borderB1bbb bgLighterBlue" style={{flexBasis:"2.5em"}}>
            <CloseGCSSOpenPanelButton p_fontClass="fontTextLighter" f_onClick={this.onkeydownesc_open_search_result_detail} />
            <div className="flex11a displayFlexRowVc borderL1bbb lrMedPad">
              <LibraryReact.Nowrap p_fontClass="font14 fontBold fontBlue">
                {c_openExpandedDetailSearchResultObjOrUndefined.opportunityNameMaskSortIfoObj.valueMask}
              </LibraryReact.Nowrap>
            </div>
          </div>
          {markMergeButtonsOrAlreadyInCEComponent}
          {(c_userCanMarkImportOrMergeCapturesFromGCSSTF) &&
            <div className="flex00a displayFlexRowVc borderT1ddd borderB1bbb" style={{flexBasis:"2em"}}>
              {(c_openExpandedDetailSearchResultObjOrUndefined.gcssNotInterestedRowExistsTF) &&
                <>
                  <div className="flex11a lrMedPad borderR1ddd" style={{flexBasis:"100em"}}>
                    <LibraryReact.Nowrap p_fontClass="fontTextLighter">
                      {"Marked Not Interested by " + c_openExpandedDetailSearchResultObjOrUndefined.notInterestedByUserFullNamePlainText}
                    </LibraryReact.Nowrap>
                  </div>
                  <div className="flex11a lrMedPad borderR1ddd" style={{flexBasis:"100em"}}>
                    <CEGeneralReact.CaptureExecFieldEditSaveCancelFromValueMaskSortIfoObj
                      p_ceEditItemString={notInterestedReasonsCEEditItemString}
                      p_fieldDisplayName="Reason(s)"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiAddGcssNotInterestedReasonsFieldTypeObj}
                      p_valueMaskSortIfoObj={c_openExpandedDetailSearchResultObjOrUndefined.notInterestedReasonsValueMaskSortIfoObj}
                      p_valueIsEditableTFU={true}
                      p_fieldClass="fontBold fontTextLightester"
                      p_fieldWidth="5em"
                      p_fieldNowrapTF={true}
                      p_valueClass=""
                      p_valueMaxHeight="nowrap"
                      p_valueTitleTF={true}
                      f_onSaveChanged={this.onsave_not_interested_reasons}
                    />
                  </div>
                  <div className="flex11a lrMedPad" style={{flexBasis:"100em"}}>
                    <CEGeneralReact.CaptureExecFieldEditSaveCancelFromValueMaskSortIfoObj
                      p_ceEditItemString={notInterestedExplanationCEEditItemString}
                      p_fieldDisplayName="Explanation"
                      p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextareaFieldTypeObj}
                      p_valueMaskSortIfoObj={c_openExpandedDetailSearchResultObjOrUndefined.notInterestedExplanationValueMaskSortIfoObj}
                      p_valueIsEditableTFU={true}
                      p_fieldClass="fontBold fontTextLightester"
                      p_fieldWidth="6em"
                      p_fieldNowrapTF={true}
                      p_valueClass=""
                      p_valueMaxHeight="nowrap"
                      p_valueTitleTF={true}
                      p_floatingBoxTitle={"Explanation for Not Interested in '" + c_openExpandedDetailSearchResultObjOrUndefined.opportunity_name + "'"}
                      f_onSaveChanged={this.onsave_not_interested_explanation}
                    />
                  </div>
                </>
              }
            </div>
          }
          <DetailSearchResultsFieldsWithHistories
            p_expandedDetailSearchResultObj={c_openExpandedDetailSearchResultObjOrUndefined}
          />
        </LibraryReact.InteractiveDiv>
        {(s_mergeWithExistingCaptureFloatingBoxIsOpenTF) &&
          <MergeWithExistingCaptureSelectionFloatingBox
            p_expandedDetailSearchResultObj={c_openExpandedDetailSearchResultObjOrUndefined}
            f_onClickClose={this.onclick_close_merge_with_existing_capture_floating_box}
          />
        }
      </>
    );
  }
}));


function OpenDetailMarkButtonWithLine(props) { //props: p_text, p_selectedTF, p_widthEm, p_selectedBgClass, p_lineBgClass, p_title, f_onClick
  const p_text = props.p_text;
  const p_selectedTF = props.p_selectedTF;
  const p_widthEm = props.p_widthEm;
  const p_selectedBgClass = props.p_selectedBgClass;
  const p_lineBgClass = props.p_lineBgClass;
  const p_title = props.p_title;

  return(
    <div className="smallFullMargin" style={{width:p_widthEm + "em"}}>
      <div
        className={"flex00a displayFlexColumnHcVc border bevelBorderColors borderRadius05 textCenter " + ((p_selectedTF) ? (p_selectedBgClass) : ("bgLighterGrayGradient hoverLighterBlueGradient cursorPointer"))}
        style={{height:"2em"}}
        title={p_title}
        onClick={((p_selectedTF) ? (undefined) : (props.f_onClick))}>
        <font className={"" + ((p_selectedTF) ? ("fontWhite") : (""))}>
          {p_text}
        </font>
      </div>
      <div className={"microTopMargin " + p_lineBgClass} style={{height:"0.4em"}} />
    </div>
  );
}


const DetailSearchResultsFieldsWithHistories = inject("CaptureExecMobx", "GCSSMobx", "UserMobx")(observer(
class DetailSearchResultsFieldsWithHistories extends Component { //props: p_expandedDetailSearchResultObj
  render() {
    const p_expandedDetailSearchResultObj = this.props.p_expandedDetailSearchResultObj;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_openSearchResultDetailOpTblDataRowsAreLoadingTF = this.props.GCSSMobx.o_openSearchResultDetailOpTblDataRowsAreLoadingTF;
    const o_openSearchResultDetailErrorMessage = this.props.GCSSMobx.o_openSearchResultDetailErrorMessage;
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;

    const allGcssFieldsWithHistoryObjNamesOrganizedBySingleDetailSectionsArrayOfObjs = this.props.GCSSMobx.get_all_gcss_fields_with_history_obj_names_organized_by_single_detail_sections_arrayOfObjs();

    const leftSideContentComponent = (
      <>
        {allGcssFieldsWithHistoryObjNamesOrganizedBySingleDetailSectionsArrayOfObjs.map((m_gcssDetailSectionObj, m_index) =>
          (m_gcssDetailSectionObj.leftSideTF) &&
          <>
            <DetailSearchResultSectionHeader
              p_text={m_gcssDetailSectionObj.sectionHeader}
              p_dividerAboveTF={(m_index > 0)}
            />
            {m_gcssDetailSectionObj.gcssFieldsArrayOfObjs.map((m_gcssFieldObj) =>
              ((m_gcssFieldObj.samTF && (c_userGcssDataSource0SamTF || c_userGcssDataSource3CexaiTF)) || (m_gcssFieldObj.govWinTF && c_userGcssDataSource1GovWinTF) || (m_gcssFieldObj.fecCompTF && c_userGcssDataSource2FedCompTF)) &&
              <DetailSearchResultsSingleFieldWithHistory
                p_fieldHistoryObj={p_expandedDetailSearchResultObj[m_gcssFieldObj.fieldHistoryObjName]}
                p_showFieldDisplayNameTF={((m_gcssFieldObj.showFieldDisplayNameTF === false) ? (false) : (true))}
              />
            )}
          </>
        )}
      </>
    );

    var loadingOrErrorContentComponent = null;
    var rightSideContentComponent = null;
    if(o_openSearchResultDetailOpTblDataRowsAreLoadingTF) {
      loadingOrErrorContentComponent = (
        <div className="displayFlexColumnHcVc smallTopMargin">
          <GCSSLoadingAnimation />
        </div>
      );
    }
    else if(JSFUNC.string_is_filled_out_tf(o_openSearchResultDetailErrorMessage)) {
      loadingOrErrorContentComponent = (
        <div className="displayFlexColumnHcVc smallTopMargin">
          <div className="flex00a textCenter" style={{maxWidth:"40em"}}>
            <div className="smallBottomMargin textCenter">
              <font className="font12 fontBold fontDarkRed">
                {"Issue fetching single GCSS Result Details"}
              </font>
            </div>
            <div className="textCenter">
              <font className="font11 fontDarkRed">
                {this.props.GCSSMobx.translate_gcss_error_message_to_user_display_error_message(o_openSearchResultDetailErrorMessage)}
              </font>
            </div>
          </div>
        </div>
      );
    }
    else {
      rightSideContentComponent = (
        <>
          <DetailSearchResultSectionHeader p_text={p_expandedDetailSearchResultObj.descriptionOfWorkHistoryObj.fieldDisplayName} p_dividerAboveTF={c_isMobileTF} />
          <div className="border1ddd bgLightesterGray smallFullPad">
            <font className="">
              {p_expandedDetailSearchResultObj.descriptionOfWorkHistoryObj.currentValueMaskSortIfoObj.valueMask}
            </font>
          </div>
        </>
      );
    }

    if(c_isMobileTF) {
      return(
        <div className="flex11a yScroll bgLightestGray medFullPad">
          {loadingOrErrorContentComponent}
          {leftSideContentComponent}
          {rightSideContentComponent}
        </div>
      );
    }

    return(
      <div className="flex11a displayFlexRow bgLightestGray">
        <div className="flex11a yScroll borderR1bbb medFullPad" style={{flexBasis:"100em"}}>
          {leftSideContentComponent}
        </div>
        <div className="flex11a yScroll medFullPad" style={{flexBasis:"100em"}}>
          {loadingOrErrorContentComponent}
          {rightSideContentComponent}
        </div>
      </div>
    );
  }
}));


function DetailSearchResultSectionHeader(props) { //props: p_text, p_dividerAboveTF
  const p_text = props.p_text;
  const p_dividerAboveTF = JSFUNC.prop_value(props.p_dividerAboveTF, true);

  return(
    <>
      {(p_dividerAboveTF) &&
        <div className="tbMedMargin borderT1ddd" />
      }
      <div className="smallBottomMargin">
        <font className="fontBold fontBlue">
          {p_text}
        </font>
      </div>
    </>
  );
}

function DetailSearchResultSectionDivider(props) { //props:
  return(
    <div className="tbMedMargin borderT1ddd" />
  );
}


class DetailSearchResultsSingleFieldWithHistory extends Component { //props: p_fieldHistoryObj, p_showFieldDisplayNameTF
  constructor(props) {
    super(props);
    this.state = {
      s_showHistoryTF: false
    };
  }

  componentDidMount() {
    this.setState({s_showHistoryTF:false});
  }

  onclick_show_or_hide_history = () => {
    this.setState({s_showHistoryTF:(!this.state.s_showHistoryTF)});
  }

  render() {
    const s_showHistoryTF = this.state.s_showHistoryTF;

    const p_fieldHistoryObj = this.props.p_fieldHistoryObj;
    const p_showFieldDisplayNameTF = JSFUNC.prop_value(this.props.p_showFieldDisplayNameTF, true);

    const fieldDisplayName = p_fieldHistoryObj.fieldDisplayName;
    const currentValueMaskSortIfoObj = p_fieldHistoryObj.currentValueMaskSortIfoObj;
    const historyArrayOfObjs = p_fieldHistoryObj.historyArrayOfObjs;
    const hasHistoryTF = p_fieldHistoryObj.hasHistoryTF;

    return(
      <>
        <div className="displayFlexRow microBottomMargin">
          {(p_showFieldDisplayNameTF) &&
            <div className="flex11a" style={{flexBasis:"100em"}}>
              <font className="fontItalic fontTextLight">
                {fieldDisplayName}
              </font>
            </div>
          }
          <div className="flex11a" style={{flexBasis:"150em"}}>
            <font className="">
              {currentValueMaskSortIfoObj.valueMask}
            </font>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
            {(hasHistoryTF) &&
              <div
                className="border1ddd displayFlexColumnHcVc bgLightesterGray hoverLightestestGray textCenter cursorPointer"
                style={{width:"1.9em", height:"1.4em"}}
                title={"Show history of changes for '" + fieldDisplayName + "'"}
                onClick={this.onclick_show_or_hide_history}>
                <font className="">
                  {((s_showHistoryTF) ? ("\u25B2") : ("\u25BC"))}
                </font>
              </div>
            }
          </div>
        </div>
        {(hasHistoryTF && s_showHistoryTF) &&
          <div className="bgGray tbMicroPad lrPad bigBottomMargin">
            {historyArrayOfObjs.map((m_historyObj, m_index) =>
              <DetailSearchResultsSingleFieldSingleHistoryItem
                p_historyObj={m_historyObj}
                p_isFirstItemTF={(m_index === 0)}
              />
            )}
          </div>
        }
      </>
    );
  }
}

function DetailSearchResultsSingleFieldSingleHistoryItem(props) { //props: p_historyObj, p_isFirstItemTF
  const p_historyObj = props.p_historyObj;
  const p_isFirstItemTF = props.p_isFirstItemTF;

  const rawSamOppOriginalPublishedDateTimeUtc = p_historyObj.rawSamOppOriginalPublishedDateTimeUtc;
  const valueMaskPlainText = p_historyObj.valueMaskPlainText;

  const rawSamOppOriginalPublishedDateLocalMask = JSFUNC.get_Ymd_date_local_from_natural_datetime_utc(rawSamOppOriginalPublishedDateTimeUtc);
  const rawSamOppOriginalPublishedMjYgiADateTimeLocalPlainText = JSFUNC.get_MjYgiA_datetime_local_from_natural_datetime_utc(rawSamOppOriginalPublishedDateTimeUtc);

  var valueMask = valueMaskPlainText;
  if(valueMaskPlainText === JSFUNC.blank_datetime()) {
    valueMask = "--Date/Time Not Set--";
  }
  else if(JSFUNC.datetime_is_filled_out_tf(valueMaskPlainText)) {
    valueMask = JSFUNC.get_MjYgiA_datetime_local_from_natural_datetime_utc(valueMaskPlainText);
  }
  else if(JSFUNC.string_is_filled_out_tf(valueMaskPlainText)) {
    if(valueMaskPlainText.substring(0, 6) === '[{"d":') {
      var documentLinksArrayOfObjs = JSON.parse(valueMaskPlainText);
      if(JSFUNC.is_array(documentLinksArrayOfObjs)) {
        JSFUNC.sort_arrayOfObjs(documentLinksArrayOfObjs, ["o", "d"], [true, false]);
        valueMask = (
          documentLinksArrayOfObjs.map((m_documentObj) =>
            <div className="">
              <font className="">
                {m_documentObj.o + ". " + m_documentObj.n}
              </font>
            </div>
          )
        );
      }
    }
  }

  return(
    <div className={"displayFlexRow " + ((p_isFirstItemTF) ? ("") : ("microTopMargin"))}>
      <div className="flex00a" style={{flexBasis:"1em"}} />
      <div
        className="flex00a displayFlexRowVc bgLightGray lrPad"
        style={{flexBasis:"7em"}}
        title={rawSamOppOriginalPublishedMjYgiADateTimeLocalPlainText}>
        <font className="fontItalic fontTextLight">
          {rawSamOppOriginalPublishedDateLocalMask}
        </font>
      </div>
      <div className="flex11a displayFlexRowVc bgLightGray tbMicroPad lrPad breakWord">
        <font className="">
          {valueMask}
        </font>
      </div>
    </div>
  );
}



const MergeWithExistingCaptureSelectionFloatingBox = inject("CaptureExecMobx", "GCSSMobx", "DatabaseMobx")(observer(
class MergeWithExistingCaptureSelectionFloatingBox extends Component { //props: p_expandedDetailSearchResultObj, f_onClickClose
  constructor(props) {
    super(props);
    this.state = {
      s_selectedCaptureID: -1,
      s_showSelectErrorTF: false,
      s_gcssOverwriteDataWithUpdates01: 1,
      s_mergeFinishedTF: false,
      s_mergeSuccessfulTF: false
    };
  }

  onselect_capture_id_to_merge_to = (i_selectedCaptureID) => {
    this.setState({
      s_selectedCaptureID: i_selectedCaptureID,
      s_showSelectErrorTF: false
    });
  }

  onselect_overwrite_data_with_updates_01 = (i_newValue01) => {
    this.setState({s_gcssOverwriteDataWithUpdates01:i_newValue01});
  }

  onclick_merge_gcss_search_result_to_selected_capture = () => {
    const s_selectedCaptureID = this.state.s_selectedCaptureID;
    const s_gcssOverwriteDataWithUpdates01 = this.state.s_gcssOverwriteDataWithUpdates01;

    const p_expandedDetailSearchResultObj = this.props.p_expandedDetailSearchResultObj;

    const selectedCaptureIsFilledOutTF = this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(s_selectedCaptureID, this.props.DatabaseMobx.c_fieldMapOfCaptureID.get("fieldTypeObj"));
    if(!selectedCaptureIsFilledOutTF) {
      this.setState({s_showSelectErrorTF:true});
    }
    else {
      const functionOnSuccess = () => {
        const markUndec0NotInt1Import2 = 0; //mark this GCSS opp as undecided to undo any not interested or import markings after merge
        this.props.GCSSMobx.a_mark_search_result_from_expanded_search_result_obj(markUndec0NotInt1Import2, p_expandedDetailSearchResultObj);

        this.setState({
          s_mergeFinishedTF: true,
          s_mergeSuccessfulTF: true
        });
      }

      const functionOnError = () => {
        this.setState({
          s_mergeFinishedTF: true,
          s_mergeSuccessfulTF: false
        });
      }
      this.props.GCSSMobx.a_merge_gcss_search_result_data_to_existing_capture_in_captureexec(p_expandedDetailSearchResultObj, s_selectedCaptureID, s_gcssOverwriteDataWithUpdates01, functionOnSuccess, functionOnError);
    }
  }

  onclick_cancel = () => {
    this.setState({
      s_selectedCaptureID: -1,
      s_showSelectErrorTF: false,
      s_gcssOverwriteDataWithUpdates01: 1,
      s_mergeFinishedTF: false,
      s_mergeSuccessfulTF: false
    });

    if(JSFUNC.is_function(this.props.f_onClickClose)) {
      this.props.f_onClickClose();
    }
  }

  render() {
    const s_selectedCaptureID = this.state.s_selectedCaptureID;
    const s_showSelectErrorTF = this.state.s_showSelectErrorTF;
    const s_gcssOverwriteDataWithUpdates01 = this.state.s_gcssOverwriteDataWithUpdates01;
    const s_mergeFinishedTF = this.state.s_mergeFinishedTF;
    const s_mergeSuccessfulTF = this.state.s_mergeSuccessfulTF;

    const p_expandedDetailSearchResultObj = this.props.p_expandedDetailSearchResultObj;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title="Select Existing Capture to Merge and Link GCSS Search Result To"
        f_onClickCancel={this.onclick_cancel}>
        <div className="flex11a yScroll medFullPad">
          {(s_mergeFinishedTF) ? (
            <div className="">
              <font className={((s_mergeSuccessfulTF) ? ("fontDarkGreen") : ("fontRed"))}>
                {((s_mergeSuccessfulTF) ? ("Successfully merged GCSS Search Result '" + p_expandedDetailSearchResultObj.opportunityNameMaskSortIfoObj.valueMaskPlainText + "'") : ("Merge unsuccessful. You can close this dialogue and click the Merge button to retry."))}
              </font>
            </div>
          ) : (
            <>
              <div className="textCenter">
                <font className="font11 fontBold fontBlue">
                  {p_expandedDetailSearchResultObj.opportunityNameMaskSortIfoObj.valueMask}
                </font>
              </div>
              <div className="hugeTopMargin microBottomMargin">
                <font className="font11 fontBold fontTextLight">
                  {"Selected Existing Capture in " + c_productStylingObj.productName}
                </font>
              </div>
              <div className="lrMedPad">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectAllCapturesForcedCaptureIDInNameFieldTypeObj}
                  p_valueRaw={s_selectedCaptureID}
                  p_tabIndex={1}
                  p_errorTF={s_showSelectErrorTF}
                  f_onChangeOrOnSelect={this.onselect_capture_id_to_merge_to}
                />
                {(s_showSelectErrorTF) &&
                  <CEGeneralReact.ErrorText
                    p_class="microTopMargin"
                    p_text={"Select a Capture from " + c_productStylingObj.productName + " to merge/link this GCSS Search Result with"}
                  />
                }
              </div>
              <div className="enormousTopMargin microBottomMargin textCenter">
                <font className="fontItalic">
                  {"GCSS Automatic Updates Overwrite Option"}
                </font>
              </div>
              <div className="lrMedPad">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_fieldMapOfGcssOverwriteDataWithUpdates01.get("fieldTypeObj")}
                  p_valueRaw={s_gcssOverwriteDataWithUpdates01}
                  p_tabIndex={2}
                  f_onChangeOrOnSelect={this.onselect_overwrite_data_with_updates_01}
                />
              </div>
            </>
          )}
        </div>
        <div className="flex00a displayFlexRowHcVc tbPad">
          {(!s_mergeFinishedTF) &&
            <div className="flex00a lrMedPad">
              <CEGeneralReact.CEButton
                p_type="blue"
                p_text="Merge/Link GCSS Search Result to Capture"
                p_tabIndex={3}
                f_onClick={this.onclick_merge_gcss_search_result_to_selected_capture}
              />
            </div>
          }
          <div className="flex00a lrMedPad">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text={((s_mergeFinishedTF) ? ("Close") : ("Cancel"))}
              p_tabIndex={4}
              f_onClick={this.onclick_cancel}
            />
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));



//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
//&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&





const GCSSImportProgressFloatingBox = inject("GCSSMobx")(observer(
class GCSSImportProgressFloatingBox extends Component { //props:
  onclick_close_completed_gcss_capture_import = () => {
    //reset gcss import progress flags to close the floating box
    this.props.GCSSMobx.a_set_gcss_import_current_import_capture_index(-1);
    this.props.GCSSMobx.a_set_gcss_import_current_import_capture_name("");
    this.props.GCSSMobx.a_set_gcss_import_total_num_captures_to_import(-1);
    this.props.GCSSMobx.a_reset_gcss_import_success_import_capture_ids_and_names_array();
    this.props.GCSSMobx.a_reset_gcss_import_error_import_capture_names_array();
  }

  render() {
    const o_gcssImportCurrentImportCaptureIndex = this.props.GCSSMobx.o_gcssImportCurrentImportCaptureIndex;
    const o_gcssImportCurrentImportCaptureName = this.props.GCSSMobx.o_gcssImportCurrentImportCaptureName;
    const o_gcssImportTotalNumCapturesToImport = this.props.GCSSMobx.o_gcssImportTotalNumCapturesToImport;
    const o_gcssImportSuccessImportCaptureIDsAndNamesArray = this.props.GCSSMobx.o_gcssImportSuccessImportCaptureIDsAndNamesArray;
    const o_gcssImportErrorImportCaptureNamesArray = this.props.GCSSMobx.o_gcssImportErrorImportCaptureNamesArray;

    //the import total num captures being set is the flag that the import is currently running
    if(!JSFUNC.is_number(o_gcssImportTotalNumCapturesToImport) || !JSFUNC.is_number(o_gcssImportCurrentImportCaptureIndex) || (o_gcssImportTotalNumCapturesToImport <= 0)) {
      return(null);
    }

    //if the import ran to completion, then the current import capture index should be equal to the total num captures
    if(o_gcssImportCurrentImportCaptureIndex >= o_gcssImportTotalNumCapturesToImport) {
      const numCapturesSuccessfullyImported = o_gcssImportSuccessImportCaptureIDsAndNamesArray.length;
      const numCapturesErrorNotImported = o_gcssImportErrorImportCaptureNamesArray.length;

      return(
        <CEGeneralReact.FloatingBoxWithSaveCancel
          p_trblFlag="medium"
          p_title="GCSS Capture Import Completed"
          f_onClickCancel={this.onclick_close_completed_gcss_capture_import}>
          <div className="flex11a displayFlexRow">
            <div className="flex11a yScroll medFullPad" style={{flexBasis:"100em"}}>
              <div className="smallBottomMargin textCenter">
                <font className="fontBold fontGreen">
                  {"Successfully Imported " + numCapturesSuccessfullyImported + " " + JSFUNC.plural(numCapturesSuccessfullyImported, "Capture", "Captures") + " from GCSS"}
                </font>
              </div>
              <CEGeneralReact.BulletList
                p_linesArray={o_gcssImportSuccessImportCaptureIDsAndNamesArray}
                p_fontClass="fontDarkGreen"
              />
            </div>
            {(numCapturesErrorNotImported > 0) &&
              <div className="flex11a yScroll borderL1bbb medFullPad" style={{flexBasis:"100em"}}>
                <div className="smallBottomMargin textCenter">
                  <font className="fontBold fontRed">
                    {"Error Importing " + numCapturesErrorNotImported + " " + JSFUNC.plural(numCapturesErrorNotImported, "Capture", "Captures") + " from GCSS"}
                  </font>
                </div>
                <CEGeneralReact.BulletList
                  p_linesArray={o_gcssImportErrorImportCaptureNamesArray}
                  p_fontClass="fontDarkRed"
                />
              </div>
            }
          </div>
          <div className="flex00a displayFlexColumnHcVc borderT1bbb tbMedPad">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Close"
              f_onClick={this.onclick_close_completed_gcss_capture_import}
            />
          </div>
        </CEGeneralReact.FloatingBoxWithSaveCancel>
      );
    }

    //show progress bar of importing captures
    var importPercentComplete0to100 = 100; //assume that 0/0 is 100% progress
    if(o_gcssImportTotalNumCapturesToImport > 0) {
      importPercentComplete0to100 = (100 * ((o_gcssImportCurrentImportCaptureIndex + 1) / o_gcssImportTotalNumCapturesToImport));
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="confirmBox"
        p_title="GCSS Importing Captures Progress">
        <div className="displayFlexColumnHcVc medFullPad">
          <div className="smallBottomMargin textCenter">
            <font className="fontItalic fontTextLight">
              {"Currently Importing"}
            </font>
          </div>
          <div className="smallBottomMargin textCenter">
            <font className="fontBold fontTextLight">
              {o_gcssImportCurrentImportCaptureName}
            </font>
          </div>
          <LibraryReact.TwoColorDiv
            p_color2="#fc9"
            p_color2Percent={importPercentComplete0to100}
            p_class="displayFlexColumnHcVc border1bbb borderRadius10"
            p_styleObj={{width:"80%", height:"2em"}}>
            <font className="fontBold fontTextLighter">
              {(o_gcssImportCurrentImportCaptureIndex + 1) + " / " + o_gcssImportTotalNumCapturesToImport}
            </font>
          </LibraryReact.TwoColorDiv>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));





const GCSSLoadingAnimation = inject("UserMobx")(observer(
class GCSSLoadingAnimation extends Component { //props:
  render() {
    const c_userGcssDataSource0SamTF = this.props.UserMobx.c_userGcssDataSource0SamTF;
    const c_userGcssDataSource1GovWinTF = this.props.UserMobx.c_userGcssDataSource1GovWinTF;
    const c_userGcssDataSource2FedCompTF = this.props.UserMobx.c_userGcssDataSource2FedCompTF;
    const c_userGcssDataSource3CexaiTF = this.props.UserMobx.c_userGcssDataSource3CexaiTF;

    if(c_userGcssDataSource0SamTF || c_userGcssDataSource3CexaiTF) {
      return(<CEGeneralReact.LoadingAnimation />);
    }

    if(c_userGcssDataSource1GovWinTF) {
      return(<CEGeneralReact.GovWinLoadingAnimation />);
    }

    if(c_userGcssDataSource2FedCompTF) {
      return(<CEGeneralReact.FedCompLoadingAnimation />);
    }

    return(null);
  }
}));

