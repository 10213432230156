//js code packages
import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import { observer, inject } from 'mobx-react';

//general functions
import * as JSFUNC from '../Library/JSFUNC.js';
import * as LibraryReact from '../Library/LibraryReact.js';

import * as JSPHP from '../CaptureExecLocalDatabaseMobx/JSPHP.js';

import * as ContactsReact from '../CaptureExecReactMobxPairs/Contacts/ContactsReact.js';

//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Custom Buttons
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export function CEButton(props) { //props: p_type, p_text, p_fontClass, p_tabIndex, p_title, p_errorTF, f_onClick
  var fontClass = JSFUNC.prop_value(props.p_fontClass, "font11");

  var buttonClass;
  var allowButtonClickTF = true;
  if(props.p_type === "blue") { buttonClass = "ceButton ceButtonBlue cursorPointer"; }
  else if(props.p_type === "blueDisabled") { buttonClass = "ceButton ceButtonBlueDisabled cursorNotAllowed"; allowButtonClickTF = false; }
  else if(props.p_type === "gray") { buttonClass = "ceButton ceButtonGray cursorPointer"; }
  else if(props.p_type === "red") { buttonClass = "ceButton ceButtonRed cursorPointer"; }
  else if(props.p_type === "captureRed") { buttonClass = "ceButton ceButtonCaptureRed cursorPointer"; }
  else if(props.p_type === "green") { buttonClass = "ceButton ceButtonGreen cursorPointer"; }
  else if(props.p_type === "add") { buttonClass = "ceButtonAdd cursorPointer"; }
  else if(props.p_type === "hyperlink") { buttonClass = "cursorPointer"; fontClass += " fontBlue"; }
  else if(props.p_type === "hyperlinkUnderline") { buttonClass = "cursorPointer"; fontClass += " fontUnderline fontBlue"; }
  else if(props.p_type === "hyperlinkBoldUnderline") { buttonClass = "cursorPointer"; fontClass += " fontBold fontUnderline fontBlue"; }

  return(
    <LibraryReact.ButtonNowrap
      p_value={props.p_text}
      p_class={buttonClass}
      p_fontClass={fontClass}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onClick={((allowButtonClickTF) ? (props.f_onClick) : (undefined))}
    />
  );
}



export function ButtonExpandCollapse(props) { //props: p_isExpandedTF, p_sizeEm, p_bgClass, p_tabIndex, p_title, f_onClick
  const p_isExpandedTF = JSFUNC.prop_value(props.p_isExpandedTF, false);
  const p_sizeEm = JSFUNC.prop_value(props.p_sizeEm, 1.5);
  const p_bgClass = JSFUNC.prop_value(props.p_bgClass, "");

  const arrowSize = (p_sizeEm / 1.7);
  const containerSize = p_sizeEm + "em";
  const radiusSize = (p_sizeEm / 2) + "em";

  return(
    <LibraryReact.InteractiveDiv
      p_class={"displayFlexColumnHcVc " + p_bgClass + " hoverLightestestGray cursorPointer"}
      p_styleObj={{width:containerSize, height:containerSize, border:"solid 1px #aaa", borderRadius:radiusSize}}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={((props.p_tabIndex > 0) ? (props.f_onClick) : (undefined))}>
      <SvgUpDownCarat
        p_isDownTF={!p_isExpandedTF} //currently expanded, arrow is pointing up to collapse it again
        p_sizeEm={arrowSize}
      />
    </LibraryReact.InteractiveDiv>
  );
}


export function ButtonLeftRightCircledCarat(props) { //props: p_leftFalseRightTrue, p_sizeEm, p_tabIndex, p_title, f_onClick
    const leftFalseRightTrue = JSFUNC.prop_value(props.p_leftFalseRightTrue, false);
    const sizeEm = JSFUNC.prop_value(props.p_sizeEm, 1.5);

    const arrowSize = (sizeEm / 1.7);
    const containerSize = sizeEm + "em";
    const radiusSize = (sizeEm / 2) + "em";

    return(
      <LibraryReact.InteractiveDiv
        p_class="displayFlexColumnHcVc hoverLightestestGray cursorPointer"
        p_styleObj={{width:containerSize, height:containerSize, border:"solid 1px #ddd", borderRadius:radiusSize}}
        p_tabIndex={props.p_tabIndex}
        p_title={props.p_title}
        f_onClick={props.f_onClick}
        f_onKeyDownEnter={((props.p_tabIndex > 0) ? (props.f_onClick) : (undefined))}>
        <SvgLeftRightCarat
          p_leftFalseRightTrue={leftFalseRightTrue}
          p_sizeEm={arrowSize}
        />
      </LibraryReact.InteractiveDiv>
    );
}


export const EditSaveCancelIcon = inject("UserMobx")(observer(
class EditSaveCancelIcon extends Component { //props: p_iconType, p_tabIndex, p_title, f_onClick, f_onHover
  constructor(props) {
    super(props);
    this.state = {
      s_hoverTF: false
    };
  }

  onhover_container = (i_hoverTF) => {
    this.setState({s_hoverTF:i_hoverTF});

    if(this.props.f_onHover) {
      this.props.f_onHover(i_hoverTF);
    }
  }

  render() {
    const hoverTF = this.state.s_hoverTF;

    const iconType = this.props.p_iconType; //"edit", "save", "cancel", "blank"
    const tabIndex = this.props.p_tabIndex;
    const title = this.props.p_title;

    var isBlankTF = false;
    var iconComponent = null;
    var bgColor = "";
    if(iconType === "blank") {
      isBlankTF = true;
    }
    else if(iconType === "edit") {
      iconComponent = ((hoverTF) ? (this.props.UserMobx.c_svgEditPencil11White) : (this.props.UserMobx.c_svgEditPencil11Gray));
      bgColor = ((hoverTF) ? ("68a") : ("fafafa"));
    }
    else if(iconType === "save") {
      iconComponent = (
        <font className="font09 fontBold fontWhite">
          {"\u2713"}
        </font>
      );
      bgColor = ((hoverTF) ? ("3c3") : ("6a6"));
    }
    else if(iconType === "cancel") {
      iconComponent = (
        <font className="font09 fontBold fontWhite">
          {"\u2715"}
        </font>
      );
      bgColor = ((hoverTF) ? ("766") : ("988"));
    }
    else if(iconType === "cancel") {
      iconComponent = (
        <font className="font09 fontBold fontWhite">
          {"\u2715"}
        </font>
      );
      bgColor = ((hoverTF) ? ("766") : ("988"));
    }

    const containerHeightEm = 1.6;
    const containerWdithEm = 2.2;
    const circleSizeEm = 1.6;

    //blank icon, space is same size as normal icon container, but cannot be clicked or hovered (even if those inputs are provided)
    if(isBlankTF) {
      return(
        <div className="flex00a" style={{minHeight:containerHeightEm + "em", width:containerWdithEm + "em"}} />
      );
    }

    return(
      <LibraryReact.InteractiveDiv
        p_class="flex00a displayFlexColumnHcVc cursorPointer"
        p_styleObj={{minHeight:containerHeightEm + "em", width:containerWdithEm + "em"}}
        p_tabIndex={tabIndex}
        p_title={title}
        f_onClick={this.props.f_onClick}
        f_onKeyDownEnter={this.props.f_onClick}
        f_onHover={this.onhover_container}>
        <div
          className="displayFlexColumnHcVc"
          style={{height:circleSizeEm + "em", width:circleSizeEm + "em", borderRadius:"0.8em", border:"solid 1px #eee", background:"#" + bgColor}}>
          {iconComponent}
        </div>
      </LibraryReact.InteractiveDiv>
    );
  }
}));



export function HelpHoverIcon(props) { //props: p_tabIndex, children
  const tabIndex = props.children;
  const messageOrArrayOfMessageLines = props.children;

  const [hookHelpFloatingBoxIsOpenTF, f_hookSetHelpFloatingBoxIsOpenTF] = useState(false);

  var titleMessage = undefined;
  var floatingBoxMessage = undefined;
  if(JSFUNC.is_array(messageOrArrayOfMessageLines)) {
    titleMessage = messageOrArrayOfMessageLines.join("\n");
    floatingBoxMessage = (
      messageOrArrayOfMessageLines.map((m_messageLine) =>
        <div className="displayFlexRow smallBottomMargin">
          <div className="flex00a lrMargin">
            <font className="fontTextLighter">
              {"-"}
            </font>
          </div>
          <div className="flex11a lrMargin">
            <font className="fontTextLight">
              {m_messageLine}
            </font>
          </div>
        </div>
      )
    );
  }
  else { //single string passed in, use it as both the title and the box message
    titleMessage = messageOrArrayOfMessageLines;
    floatingBoxMessage = messageOrArrayOfMessageLines;
  }

  return(
    <>
      <LibraryReact.InteractiveDiv
        p_class="displayFlexColumnHcVc cursorHelp helpIcon hoverLightBlueGradient fontBold"
        p_styleObj={{height:"1.4em", width:"1.5em", borderRadius:"0.8em"}}
        p_tabIndex={tabIndex}
        p_title={titleMessage}
        f_onClick={() => f_hookSetHelpFloatingBoxIsOpenTF(true)}
        f_onKeyDownEnter={() => f_hookSetHelpFloatingBoxIsOpenTF(true)}>
        {"?"}
      </LibraryReact.InteractiveDiv>
      {(hookHelpFloatingBoxIsOpenTF) &&
        <ConfirmBox
          p_type="help"
          f_onClickConfirm={() => f_hookSetHelpFloatingBoxIsOpenTF(false)}
          f_onClickCancel={() => f_hookSetHelpFloatingBoxIsOpenTF(false)}>
          {floatingBoxMessage}
        </ConfirmBox>
      }
    </>
  );
}

export function NotificationIcon(props) { //props: p_type, p_number
  var bgClass = "";
  var fontColor = "";
  if(props.p_type === "red") {
    bgClass = "bgRed";
    fontColor = "fontWhite";
  }
  else if(props.p_type === "gray") {
    bgClass = "bgLightGray";
    fontColor = "";
  }

  return(
    <div
      className={"positionAbsolute border bevelBorderColors borderRadius10 textCenter " + bgClass}
      style={{bottom:"0.15em", right:"0.15em", padding:"0.05em 0.45em"}}>
      <font className={"font09 fontBold " + fontColor}>
        {props.p_number}
      </font>
    </div>
  );
}

export class RemoveItemButton extends Component { //props: p_tabIndex, p_title, p_heightEm, p_fontClass, f_onClick
  //small x used for single click removal of items such as editing capture owners or reasons won/lost
  constructor(props) {
    super(props);
    this.state = {
      s_icon: "\u2715"
    }
  }

  onhover_remove_button = (i_hoverTF) => {
    this.setState({s_icon:((i_hoverTF) ? ("\u2716") : ("\u2715"))});
  }

  render() {
    const heightEm = JSFUNC.prop_value(this.props.p_heightEm, 1.5);
    const fontClass = JSFUNC.prop_value(this.props.p_fontClass, "font12 fontTextLightester");
    return(
      <div
        className="flex00a displayFlexColumn"
        style={{flexBasis:"1.5em", height:heightEm + "em"}}
        title={this.props.p_title}>
        <LibraryReact.InteractiveDiv
          p_class={"flex11a displayFlexColumnHcVc smallFullPad textCenter cursorPointer hoverDeleteButtonX " + fontClass}
          p_styleObj={{lineHeight:(heightEm / 2) + "em"}}
          p_tabIndex={this.props.p_tabIndex}
          f_onClick={this.props.f_onClick}
          f_onKeyDownEnter={this.props.f_onClick}
          f_onHover={this.onhover_remove_button}>
          {this.state.s_icon}
        </LibraryReact.InteractiveDiv>
      </div>
    );
  }
}


export class ButtonCopyToClipboard extends Component { //props: p_clipboardText, p_preCopyLabel, p_postCopyLabel, p_preCopyClass, p_postCopyClass, p_styleObj, p_preCopyFontClass, p_postCopyFontClass, p_title
  constructor(props) {
    super(props);

    this.state = {
      s_copiedTF: false
    }
  }

  onclick_copy_input_text_value_to_clipboard = () => {
    const p_clipboardText = this.props.p_clipboardText;

    if(navigator.clipboard) {
      navigator.clipboard.writeText(p_clipboardText);
      this.setState({s_copiedTF:true});
      this.timeoutID = setTimeout(() => this.reset_to_initial_state_and_clear_timeout(), 1500);
    }
  }

  reset_to_initial_state_and_clear_timeout = () => {
    this.setState({s_copiedTF:false});
    clearTimeout(this.timeoutID);
  }

  render() {
    const s_copiedTF = this.state.s_copiedTF;

    const p_clipboardText = this.props.p_clipboardText;
    const p_preCopyLabel = this.props.p_preCopyLabel;
    const p_postCopyLabel = this.props.p_postCopyLabel;
    const p_preCopyClass = this.props.p_preCopyClass;
    const p_postCopyClass = this.props.p_postCopyClass;
    const p_styleObj = this.props.p_styleObj;
    const p_preCopyFontClass = this.props.p_preCopyFontClass;
    const p_postCopyFontClass = this.props.p_postCopyFontClass;
    const p_title = this.props.p_title;

    var inputClass = ((s_copiedTF) ? (p_postCopyClass) : (p_preCopyClass));

    var buttonClass = "flex00a displayFlexColumnHcVc textCenter cursorPointer";
    if(JSFUNC.string_is_filled_out_tf(inputClass)) {
      buttonClass = buttonClass + " " + inputClass;
    }

    return(
      <div
        className={buttonClass}
        style={p_styleObj}
        title={p_title}
        onClick={this.onclick_copy_input_text_value_to_clipboard}>
        <font className={((s_copiedTF) ? (p_postCopyFontClass) : (p_preCopyFontClass))}>
          {((s_copiedTF) ? (p_postCopyLabel) : (p_preCopyLabel))}
        </font>
      </div>
    );
  }
}



export class SwitchWithTextAndConfirmBox extends Component { //props:
  //p_isOnTF, p_sizeEm, p_onColor, p_offColor, p_onText, p_offText, p_onTextFontClass, p_offTextFontClass, p_switchToOnMessage, p_switchToOnButtonName, p_switchToOffMessage, p_switchToOffButtonName, p_labelText, p_labelFontClass, p_labelWidthEm, p_tabIndex, p_title,
  //f_onSwitch
  constructor(props) {
    super(props);
    this.state = {
      s_confirmSwitchOnOrOffFloatingBoxIsOpenTF: false
    }
  }

  onclick_switch = () => {
    const isOnTF = this.props.p_isOnTF;
    const p_switchToOnMessage = this.props.p_switchToOnMessage;
    const p_switchToOffMessage = this.props.p_switchToOffMessage;

    if((isOnTF && (p_switchToOffMessage !== undefined)) || (!isOnTF && (p_switchToOnMessage !== undefined))) { //if it's on and there's an off message OR it's off and there's an on message, open the confirm floating box, otherwise just call the output f_onSwitch
      this.setState({s_confirmSwitchOnOrOffFloatingBoxIsOpenTF:true});
    }
    else {
      this.call_onswitch();
    }
  }

  call_onswitch = () => {
    this.onclick_cancel_confirm_switch();

    if(JSFUNC.is_function(this.props.f_onSwitch)) {
      this.props.f_onSwitch();
    }
  }

  onclick_cancel_confirm_switch = () => {
    this.setState({s_confirmSwitchOnOrOffFloatingBoxIsOpenTF:false});
  }

  render() {
    const s_confirmSwitchOnOrOffFloatingBoxIsOpenTF = this.state.s_confirmSwitchOnOrOffFloatingBoxIsOpenTF;

    const p_isOnTF = this.props.p_isOnTF;
    const p_sizeEm = this.props.p_sizeEm;
    const p_onColor = this.props.p_onColor;
    const p_offColor = this.props.p_offColor;
    const p_onText = this.props.p_onText;
    const p_offText = this.props.p_offText;
    const p_onTextFontClass = this.props.p_onTextFontClass;
    const p_offTextFontClass = this.props.p_offTextFontClass;
    const p_switchToOnMessage = this.props.p_switchToOnMessage;
    const p_switchToOnButtonName = this.props.p_switchToOnButtonName;
    const p_switchToOffMessage = this.props.p_switchToOffMessage;
    const p_switchToOffButtonName = this.props.p_switchToOffButtonName;
    const p_labelText = this.props.p_labelText;
    const p_labelFontClass = this.props.p_labelFontClass;
    const p_labelWidthEm = this.props.p_labelWidthEm;
    const p_tabIndex = this.props.p_tabIndex;
    const p_title = this.props.p_title;

    const canClickTF = JSFUNC.is_function(this.props.f_onSwitch);

    const switchComponent = (
      <LibraryReact.Switch
        p_isOnTF={p_isOnTF}
        p_sizeEm={p_sizeEm}
        p_onColor={p_onColor}
        p_offColor={p_offColor}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        f_onClick={((canClickTF) ? (this.onclick_switch) : (undefined))}
      />
    );

    var switchWithTextComponent = null;
    if((p_onText !== undefined) || (p_offText !== undefined)) { //if using switch with text written next to it
      var textFontClass = undefined;
      var textColor = undefined;
      var switchText = "";
      if(p_isOnTF) {
        textFontClass = JSFUNC.prop_value(p_onTextFontClass, "font11 fontBold");
        textColor = JSFUNC.prop_value(p_onColor, "005da3");
        switchText = p_onText;
      }
      else {
        textFontClass = JSFUNC.prop_value(p_offTextFontClass, "font11");
        textColor = JSFUNC.prop_value(p_offColor, "6b6b6b");
        switchText = p_offText;
      }

      switchWithTextComponent = (
        <div className="displayFlexRow" title={p_title}>
          <div className="flex00a displayFlexColumnVc rMargin">
            {switchComponent}
          </div>
          <div className="flex11a displayFlexColumnVc lrPad">
            <font className={textFontClass} style={{color:"#" + textColor}}>
              {switchText}
            </font>
          </div>
        </div>
      );
    }
    else { //just the switch with no text
      switchWithTextComponent = switchComponent;
    }

    //apply a label to the left side of the switch if the input p_labelText is filled out
    if(JSFUNC.string_is_filled_out_tf(p_labelText)) {
      switchWithTextComponent = (
        <div className="displayFlexRow" title={p_title}>
          <div className="flex00a displayFlexRowVc lrPad" style={{flexBasis:p_labelWidthEm + "em"}}>
            <font className={p_labelFontClass}>
              {p_labelText}
            </font>
          </div>
          <div className="flex11a displayFlexRowVc">
            {switchWithTextComponent}
          </div>
        </div>
      );
    }

    return(
      <>
        {switchWithTextComponent}
        {(s_confirmSwitchOnOrOffFloatingBoxIsOpenTF) &&
          <ConfirmBox
            p_type="confirm"
            p_button1Name={((p_isOnTF) ? (p_switchToOffButtonName) : (p_switchToOnButtonName))}
            f_onClickConfirm={this.call_onswitch}
            f_onClickCancel={this.onclick_cancel_confirm_switch}>
            {((p_isOnTF) ? (p_switchToOffMessage) : (p_switchToOnMessage))}
          </ConfirmBox>
        }
      </>
    );
  }
}


export class VerticalSwitch extends Component { //props:
  //p_valueArray, p_displayArray, p_selectConfirmMessagesArray, p_selectConfirmButtonNamesArray, p_selectedValue, p_rowHeightEm, p_sizeMultiplier, 
  //p_selectedColor, p_unselectedColor, p_selectedDisplayFontClass, p_unselectedDisplayFontClass, p_tabIndex, 
  //f_onSelect, f_onKeyDownEnter
  constructor(props) {
    super(props);
    this.state = {
      s_confirmSwitchFloatingBoxIsOpenTF: false
    }
  }

  onclick_switch_zone = (i_selectedValueIndex) => {
    const p_valueArray = this.props.p_valueArray;
    const p_displayArray = this.props.p_displayArray;
    const p_selectConfirmMessagesArray = this.props.p_selectConfirmMessagesArray;
    const p_selectConfirmButtonNamesArray = this.props.p_selectConfirmButtonNamesArray;

    const selectedValue = p_valueArray[i_selectedValueIndex];

    if(false) { //if it's on and there's an off message OR it's off and there's an on message, open the confirm floating box, otherwise just call the output f_onSwitch
      this.setState({s_confirmSwitchFloatingBoxIsOpenTF:true});
    }
    else {
      this.call_onselect(selectedValue);
    }
  }

  call_onselect = (i_selectedValue) => {
    this.onclick_cancel_confirm_switch();

    if(JSFUNC.is_function(this.props.f_onSelect)) {
      this.props.f_onSelect(i_selectedValue);
    }
  }

  onclick_cancel_confirm_switch = () => {
    this.setState({s_confirmSwitchFloatingBoxIsOpenTF:false});
  }

  onselect_next_option = () => {
    const p_valueArray = this.props.p_valueArray;
    const p_selectedValue = this.props.p_selectedValue;

    const numValues = p_valueArray.length;
    if(numValues > 1) { //have to be at least 2 options to switch between to do keydown action
      const selectedValueIndex = JSFUNC.array_index_of_first_matching_value(p_valueArray, p_selectedValue, -1);

      //go to next option, if on the last option, go back to the first option
      var nextSelectedValueIndex = (selectedValueIndex + 1);
      if(nextSelectedValueIndex >= numValues) {
        nextSelectedValueIndex = 0;
      }

      this.onclick_switch_zone(nextSelectedValueIndex);
    }
  }

  onselect_previous_option = () => {
    const p_valueArray = this.props.p_valueArray;
    const p_selectedValue = this.props.p_selectedValue;

    const numValues = p_valueArray.length;
    if(numValues > 1) { //have to be at least 2 options to switch between to do keydown action
      const selectedValueIndex = JSFUNC.array_index_of_first_matching_value(p_valueArray, p_selectedValue, 1);

      //go to previous option, if on the first option [0], go to the last option
      var previousSelectedValueIndex = (selectedValueIndex - 1);
      if(previousSelectedValueIndex < 0) {
        previousSelectedValueIndex = (numValues - 1);
      }

      this.onclick_switch_zone(previousSelectedValueIndex);
    }
  }

  render() {
    const s_confirmSwitchFloatingBoxIsOpenTF = this.state.s_confirmSwitchFloatingBoxIsOpenTF;

    const p_valueArray = this.props.p_valueArray;
    const p_displayArray = this.props.p_displayArray;
    const p_selectConfirmMessagesArray = this.props.p_selectConfirmMessagesArray;
    const p_selectConfirmButtonNamesArray = this.props.p_selectConfirmButtonNamesArray;
    const p_selectedValue = this.props.p_selectedValue;
    const p_rowHeightEm = JSFUNC.prop_number_positive(this.props.p_rowHeightEm, 1.5);
    const p_sizeMultiplier = JSFUNC.prop_number_positive(this.props.p_sizeMultiplier, 1);
    const p_selectedColor = JSFUNC.prop_value(this.props.p_selectedColor, "005da3");
    const p_unselectedColor = JSFUNC.prop_value(this.props.p_unselectedColor, "6b6b6b");
    const p_selectedDisplayFontClass = JSFUNC.prop_value(this.props.p_selectedDisplayFontClass, "fontBold fontBlue");
    const p_unselectedDisplayFontClass = JSFUNC.prop_value(this.props.p_unselectedDisplayFontClass, "fontTextLight");
    const p_tabIndex = this.props.p_tabIndex;

    var errorMessage = undefined;

    if(!JSFUNC.is_array(p_valueArray)) {
      errorMessage = "--p_valueArray is not an array (" + typeof(p_valueArray) + ")--";
    }

    if(!JSFUNC.is_array(p_displayArray)) {
      errorMessage = "--p_displayArray is not an array (" + typeof(p_displayArray) + ")--";
    }

    const numValues = p_valueArray.length;
    const numDisplays = p_displayArray.length;
    if(numValues !== numDisplays) {
      errorMessage = "--p_valueArray (" + numValues + ") and p_displayArray (" + numDisplays + ") lengths do not match--";
    }

    if(numValues === 0) {
      errorMessage = "--Vertical Switch has 0 options--";
    }

    if(errorMessage !== undefined) {
      return(
        <div className="displayFlexColumnHcVc border1bbb smallFullPad textCenter">
          <font className="fontItalic fontTextLight">
            {errorMessage}
          </font>
        </div>
      );
    }

    //variability parameters
    const channelBgColor = "bbbbbb";
    const channelCoverBgColor = "f3f3f3";
    const dotsContainerWidthEm = (p_sizeMultiplier * 2);
    const selectedDotWidthEm = (p_sizeMultiplier * 1.15);
    const selectedDotBoxShadow = "0 0 " + (selectedDotWidthEm / 5) + "em " + (selectedDotWidthEm / 7) + "em #" + p_selectedColor;
    const selectedDotTransition = "0.15s";
    const holeWidthEm = (selectedDotWidthEm * 1.1);
    const channelWidthEm = (selectedDotWidthEm * 0.3);
    const displayBgColor = "fafafa";
    const displayMaxHeightEm = (p_rowHeightEm * 0.8);

    //find the index of the current selected value within the p_valueArray
    const selectedValueIndex = JSFUNC.array_index_of_first_matching_value(p_valueArray, p_selectedValue, -1); //-1 is a flag that an invalid option is selected

    //calculated sizes
    const allRowsHeightEm = (numValues * p_rowHeightEm);
    const selectedDotLeftEm = ((dotsContainerWidthEm - selectedDotWidthEm) / 2);
    const selectedDotTopEm = (selectedValueIndex * p_rowHeightEm) + ((p_rowHeightEm - selectedDotWidthEm) / 2);
    const channelWidth0to100 = ((channelWidthEm / dotsContainerWidthEm) * 100);
    const channelLeft0to100 = (50 - (channelWidth0to100 / 2));
    const channelRight0to100 = (50 + (channelWidth0to100 / 2));
    const holeYScaleFactor = (dotsContainerWidthEm / p_rowHeightEm);
    const holeRadius0to100 = (((holeWidthEm / dotsContainerWidthEm) / 2) * 100);
    const holeLeft0to100 = (50 - holeRadius0to100);
    const holeRight0to100 = (50 + holeRadius0to100);
    const holeTop0to100 = (50 - (holeRadius0to100 * holeYScaleFactor));
    const holeBottom0to100 = (50 + (holeRadius0to100 * holeYScaleFactor));

    const circle24CosXArray = [1, 0.966, 0.866, 0.707, 0.5, 0.259, 0, -0.259, -0.5, -0.707, -0.866, -0.966, -1, -0.966, -0.866, -0.707, -0.5, -0.259, 0, 0.259, 0.5, 0.707, 0.866, 0.966, 1, 0.966, 0.866, 0.707, 0.5, 0.259, 0, -0.259, -0.5, -0.707, -0.866, -0.966, -1, -0.966, -0.866, -0.707, -0.5, -0.259, 0, 0.259, 0.5, 0.707, 0.866, 0.966];
    const circle24SinXArray = [0, 0.259, 0.5, 0.707, 0.866, 0.966, 1, 0.966, 0.866, 0.707, 0.5, 0.259, 0, -0.259, -0.5, -0.707, -0.866, -0.966, -1, -0.966, -0.866, -0.707, -0.5, -0.259, 0, 0.259, 0.5, 0.707, 0.866, 0.966, 1, 0.966, 0.866, 0.707, 0.5, 0.259, 0, -0.259, -0.5, -0.707, -0.866, -0.966, -1, -0.966, -0.866, -0.707, -0.5, -0.259];
    var polygonPointsStringsPerValue1Array = [];
    var polygonPointsStringsPerValue2Array = []; //middle segments need 2 halves drawn, while top and bottom only need single polygon
    for(let i = 0; i < numValues; i++) {
      var polygonPointsString1 = undefined;
      var polygonPointsString2 = undefined;

      if((i === 0) || (i === (numValues - 1))) { //top or bottom segment like U or upsidedown U
        //all straignt edges of shape
        var topOrBottomXYCoords0to100ArrayOfArrays = [[channelLeft0to100, holeBottom0to100],[channelLeft0to100,100],[0,100],[0,0],[100,0],[100,100],[channelRight0to100,100],[channelRight0to100,holeBottom0to100]];

        //apply almost full circle of points
        for(let c = 21; c <= 39; c++) {
          var circleX = ((circle24CosXArray[c] * holeRadius0to100) + 50);
          var circleY = (50 - (circle24SinXArray[c] * holeRadius0to100 * holeYScaleFactor));
          topOrBottomXYCoords0to100ArrayOfArrays.push([circleX, circleY]);
        }

        //reapply initial point at end to close the polygon
        topOrBottomXYCoords0to100ArrayOfArrays.push([channelLeft0to100, holeBottom0to100]);

        //if this is the bottom, flip every y coordinate (100 - Y) to flip the shape
        if(i > 0) {
          for(let xyCoord0to100 of topOrBottomXYCoords0to100ArrayOfArrays) {
            xyCoord0to100[1] = (100 - xyCoord0to100[1]);
          }
        }

        //convert the arrayOfArrays to a string of points (all also scaled 0 to 100)
        polygonPointsString1 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(topOrBottomXYCoords0to100ArrayOfArrays, 100);
      }
      else { //middle segments 2 plygons to form middle channel with bubble
        //all straignt edges of shape
        var leftXYCoords0to100ArrayOfArrays = [[channelLeft0to100, holeBottom0to100],[channelLeft0to100,100],[0,100],[0,0],[channelLeft0to100,0],[channelLeft0to100,holeTop0to100]];

        //apply almost half circle of points
        for(let c = 9; c <= 15; c++) {
          var circleX = ((circle24CosXArray[c] * holeRadius0to100) + 50);
          var circleY = (50 - (circle24SinXArray[c] * holeRadius0to100 * holeYScaleFactor));
          leftXYCoords0to100ArrayOfArrays.push([circleX, circleY]);
        }

        //reapply initial point at end to close the polygon
        leftXYCoords0to100ArrayOfArrays.push([channelLeft0to100, holeBottom0to100]);

        //copy flipped to make the right half, flip every x coordinate (100 - X)
        var rightXYCoords0to100ArrayOfArrays = [];
        for(let xyCoord0to100 of leftXYCoords0to100ArrayOfArrays) {
          var rightX = (100 - xyCoord0to100[0]);
          var rightY = xyCoord0to100[1];
          rightXYCoords0to100ArrayOfArrays.push([rightX, rightY]);
        }

        //convert the arrayOfArrays to a string of points (all also scaled 0 to 100)
        polygonPointsString1 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(leftXYCoords0to100ArrayOfArrays, 100);
        polygonPointsString2 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(rightXYCoords0to100ArrayOfArrays, 100);
      }

      polygonPointsStringsPerValue1Array.push(polygonPointsString1);
      polygonPointsStringsPerValue2Array.push(polygonPointsString2);
    }

    return(
      <LibraryReact.InteractiveDiv
        p_class="displayFlexColumn border1bbb"
        p_tabIndex={p_tabIndex}
        f_onKeyDownUpArrow={this.onselect_previous_option}
        f_onKeyDownDownArrow={this.onselect_next_option}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}>
        <div className="flex11a displayFlexRow" style={{height:allRowsHeightEm + "em"}}>
          <div className="flex00a positionRelative" style={{flexBasis:dotsContainerWidthEm + "em", background:"#" + channelBgColor}}>
            {(selectedValueIndex >= 0) &&
              <div
                className="positionAbsolute"
                style={{left:selectedDotLeftEm + "em", top:selectedDotTopEm + "em", width:selectedDotWidthEm + "em", height:selectedDotWidthEm + "em", borderRadius:selectedDotWidthEm + "em", background:"#" + p_selectedColor, boxShadow:selectedDotBoxShadow, transition:selectedDotTransition}}
              />
            }
            {p_valueArray.map((m_value, m_index) =>
              <div
                key={m_index}
                className={"positionAbsolute " + ((m_index !== selectedValueIndex) ? ("cursorPointer") : (""))}
                style={{left:0, top:(m_index * p_rowHeightEm) + "em", right:0, height:p_rowHeightEm + "em"}}
                title={((m_index !== selectedValueIndex) ? ("Click to select option '" + p_displayArray[m_index] + "'") : (""))}
                onClick={((m_index !== selectedValueIndex) ? (() => { this.onclick_switch_zone(m_index); }) : (undefined))}>
                <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none" style={{background:"transparent"}}>
                  {(polygonPointsStringsPerValue1Array[m_index] !== undefined) &&
                    <polygon points={polygonPointsStringsPerValue1Array[m_index]} style={{fill:"#" + channelCoverBgColor}} />
                  }
                  {(polygonPointsStringsPerValue2Array[m_index] !== undefined) &&
                    <polygon points={polygonPointsStringsPerValue2Array[m_index]} style={{fill:"#" + channelCoverBgColor}} />
                  }
                </svg>
              </div>
            )}
          </div>
          <div className="flex11a displayFlexColumn lrPad" style={{background:"#" + displayBgColor}}>
            {p_displayArray.map((m_display, m_index) =>
              <div
                key={m_index}
                className={"flex11a displayFlexRowVc " + ((m_index > 0) ? ("borderT1ddd") : ("")) + " hoverLightestBlueGradient " + ((m_index !== selectedValueIndex) ? ("cursorPointer") : (""))}
                style={{flexBasis:"100em"}}
                title={m_display + ((m_index !== selectedValueIndex) ? ("\n[click to select this option]") : ("\n[currently selected option]"))}
                onClick={((m_index !== selectedValueIndex) ? (() => { this.onclick_switch_zone(m_index); }) : (undefined))}>
                <LibraryReact.MaxHeightWrap p_maxHeight={displayMaxHeightEm + "em"} p_fontClass={((m_index === selectedValueIndex) ? (p_selectedDisplayFontClass) : (p_unselectedDisplayFontClass))}>
                  {m_display}
                </LibraryReact.MaxHeightWrap>
              </div>
            )}
          </div>
        </div>
      </LibraryReact.InteractiveDiv>
    );
  }
}



export function HeaderSortArrow(props) { //props: p_isSortedTF, p_sortedAscTF
  var sortArrow = "\u25bc"; //all arrows start as down arrow
  if(props.p_isSortedTF) {
    sortArrow = ((props.p_sortedAscTF) ? ("\u25bc") : ("\u25b2"));
  }

  const sortArrowColor = ((props.p_isSortedTF) ? ("#444") : ("#ddd"));

  return(
    <font style={{color:sortArrowColor}}>
      {sortArrow}
    </font>
  );
}


export function PageIncrementOrDecrementButton(props) { //p_incTrueDecFalse, p_canClickTF, p_widthEm, p_heightEm, p_canClickBgClass, p_canClickFontClass, p_cantClickBgClass, p_cantClickFontClass, f_onClick
  const p_incTrueDecFalse = props.p_incTrueDecFalse;
  const p_canClickTF = props.p_canClickTF;
  const p_widthEm = JSFUNC.prop_value(props.p_widthEm, 2.5);
  const p_heightEm = JSFUNC.prop_value(props.p_heightEm, 1.6);
  const p_canClickBgClass = JSFUNC.prop_value(props.p_canClickBgClass, "bgLighterGrayGradient hoverLightestGrayGradient");
  const p_canClickFontClass = JSFUNC.prop_value(props.p_canClickFontClass, "");
  const p_cantClickBgClass = JSFUNC.prop_value(props.p_cantClickBgClass, "bgDarkGrayGradient");
  const p_cantClickFontClass = JSFUNC.prop_value(props.p_cantClickFontClass, "fontTextLighter");

  return(
    <div
      className={"displayFlexColumnHcVc border bevelBorderColors borderRadius05 " + ((p_canClickTF) ? (p_canClickBgClass + " cursorPointer") : (p_cantClickBgClass)) + " textCenter"}
      style={{width:p_widthEm + "em", height:p_heightEm + "em"}}
      onClick={((p_canClickTF) ? (props.f_onClick) : (undefined))}>
      <font className={((p_canClickTF) ? (p_canClickFontClass) : (p_cantClickFontClass))}>
        {((p_incTrueDecFalse) ? (">") : ("<"))}
      </font>
    </div>
  );
}



export function Message(props) { //props: p_messageObj{type, message}
  if(!(props.p_messageObj && props.p_messageObj.message && props.p_messageObj.message.length > 0)) {
    return(null);
  }

  return(
    <div className={"width100 medTopMargin medBottomMargin smallFullPad messageBorder textCenter fontItalic message_" + props.p_messageObj.type}>
      {props.p_messageObj.message}
    </div>
  );
}



export function Notification(props) { //props: p_num
  if(props.p_num > 0) {
    return(
      <div className="notification notificationBottomRight lrMargin">
        {props.p_num}
      </div>
    );
  }
  return(null);
}

export function ErrorText(props) { //props: p_class, p_text
  return(
    <div className={props.p_class}>
      <font className="fontItalic font09 fontRed">
        {props.p_text}
      </font>
    </div>
  );
}

export function PhpWorkingMessage(props) { //props: p_text
  return(
    <div className="displayFlexColumnHcVc">
      <div className="">
        <font className="fontItalic fontTextLighter">
          {props.p_text}
        </font>
      </div>
      <LoadingAnimation />
    </div>
  );
}

export function EmptyScreenMessage(props) { //props: p_fontClass, children
  const p_fontClass = JSFUNC.prop_value(props.p_fontClass, "");
  return(
    <div className="flex11a displayFlexColumnHcVc yScroll medFullPad textCenter">
      <font className={p_fontClass}>
        {props.children}
      </font>
    </div>
  );
}

export function EmptyScreenWhite(props) { //props: p_fontClass, children
  const p_fontClass = JSFUNC.prop_value(props.p_fontClass, "");
  return(
    <div className="flex11a displayFlexColumnHcVc yScroll medFullPad bgWhite textCenter">
      <font className={"fontItalic fontTextLight " + p_fontClass}>
        {props.children}
      </font>
    </div>
  );
}

export function EmptyScreenGray(props) { //props: p_fontClass, children
  const p_fontClass = JSFUNC.prop_value(props.p_fontClass, "");
  return(
    <div className="flex11a displayFlexColumnHcVc yScroll medFullPad bgPanelLightGray textCenter">
      <font className={"fontItalic fontTextLight " + p_fontClass}>
        {props.children}
      </font>
    </div>
  );
}

export function EmptyScreenWhiteWithLoadingAnimation(props) { //props: children
  return(
    <div className="flex11a displayFlexColumnHcVc yScroll lrMedPad bgWhite textCenter">
      <div className="microBottomMargin">
        <font className="fontItalic fontTextLight">
          {props.children}
        </font>
      </div>
      <LoadingAnimation />
    </div>
  );
}

export function EmptyScreenGrayWithLoadingAnimation(props) { //props: children
  return(
    <div className="flex11a displayFlexColumnHcVc yScroll lrMedPad bgPanelLightGray textCenter">
      <div className="microBottomMargin">
        <font className="fontItalic fontTextLight">
          {props.children}
        </font>
      </div>
      <LoadingAnimation />
    </div>
  );
}

export function BulletList(props) { //props: p_linesArray, p_fontClass, p_dashFontClass
  const linesArray = ((JSFUNC.is_array(props.p_linesArray)) ? (props.p_linesArray) : ([]));
  const fontClass = JSFUNC.prop_value(props.p_fontClass, "");
  const dashFontClass = JSFUNC.prop_value(props.p_dashFontClass, fontClass);
  return(
    linesArray.map((m_descriptionLine, m_index) =>
      <div key={JSFUNC.key_rand()} className={"displayFlexRow " + ((m_index > 0) ? ("microTopMargin") : (""))}>
        <div className="flex00a lrMargin">
          <font className={dashFontClass}>
            {"-"}
          </font>
        </div>
        <div className="flex11a rMargin">
          <font className={fontClass}>
            {m_descriptionLine}
          </font>
        </div>
      </div>
    )
  );
}


export function RecursiveProgressBarFloatingBox(props) { //p_floatingBoxTitle, p_progressTextHtmlOrUndefined, p_currentItemNumber, p_totalNumItems, p_progressColor, f_onClickCancel
  const p_floatingBoxTitle = props.p_floatingBoxTitle;
  const p_progressTextHtmlOrUndefined = props.p_progressTextHtmlOrUndefined;
  const p_currentItemNumber = props.p_currentItemNumber; //1
  const p_totalNumItems = props.p_totalNumItems; //12
  const p_progressColor = props.p_progressColor; //"bd238f"

  var progressPercent0to100 = 0;
  if(p_totalNumItems > 0) {
    progressPercent0to100 = Math.round((p_currentItemNumber / p_totalNumItems) * 100);
  }

  return(
    <FloatingBoxWithSaveCancel
      p_trblFlag="confirmBox"
      p_title={p_floatingBoxTitle}
      f_onClickCancel={props.f_onClickCancel}>
      <div className="flex11a displayFlexColumnHcVc hugeTopPad hugeBottomPad lrMedPad">
        {(p_progressTextHtmlOrUndefined !== undefined) ? (
          p_progressTextHtmlOrUndefined
        ) : (
          <div className="">
            <font className="">
              {p_currentItemNumber + " of " + p_totalNumItems}
            </font>
          </div>
        )}
        <div className="medTopMargin" />
        <div
          key={JSFUNC.key_rand()}
          className="displayFlexColumnHcVc overflowHidden border bevelBorderColors"
          style={{width:"60%", height:"1.5em", background:"linear-gradient(90deg, #" + p_progressColor + " " + progressPercent0to100 + "%, #eee " + progressPercent0to100 + "%)"}}
        />
      </div>
    </FloatingBoxWithSaveCancel>
  );
}


export function RatingScoreBox1to5(props) { //props: p_rating, p_sizeEm
  var bgColor = "eee";
  var fontColor = "fontTextLightester";
  var ratingDisplay = "N/A";
  if((props.p_rating !== undefined) && (props.p_rating >= 1)) {
    const ratingPercent0to100 = ((props.p_rating - 1) * 25);
    //bgColor = JSFUNC.color_interpolation(ratingPercent0to100, 220, 110, 90, 160, 100, 90);
    bgColor = JSFUNC.color_interpolation(ratingPercent0to100, 170, 60, 0, 225, 70, 15);
    fontColor = "fontWhite";
    ratingDisplay = props.p_rating.toFixed(1);
  }

  var widthEm = JSFUNC.prop_value(props.p_sizeEm, 2.5);
  var fontClass = "font" + Math.round(4.5 * widthEm);

  return(
    <div className="displayFlexColumnHcVc border bevelBorderColors" style={{width:widthEm + "em", height:widthEm + "em", background:"#" + bgColor}}>
      <div className={fontClass + " fontBold " + fontColor}>
        {ratingDisplay}
      </div>
    </div>
  );
}

export class RatingNA1To5CELogos extends Component { //props: p_rating1to5NAm1, p_canEditTF, f_onSelectRating
  onclick_rating_na_minus1 = () => {
    if(JSFUNC.is_function(this.props.f_onSelectRating)) {
      this.props.f_onSelectRating(-1);
    }
  }

  render() {
    const rating1to5NAm1 = this.props.p_rating1to5NAm1;
    const canEditTF = this.props.p_canEditTF;

    const naIsSelectedTF = (!JSFUNC.is_number(rating1to5NAm1) || (rating1to5NAm1 < 1) || (rating1to5NAm1 > 5));

    var selectedRating1to5NAm1 = -1;
    if(!naIsSelectedTF) {
      selectedRating1to5NAm1 = rating1to5NAm1;
    }

    return(
      <div className="displayFlexRowVc">
        <div
          className={"flex00a displayFlexColumnHcVc " + ((naIsSelectedTF) ? ("bgLighterGray") : ("bgLightesterGray")) + " " + ((canEditTF) ? ("cursorPointer") : (""))}
          style={{marginRight:"0.2em", width:"2.2em", height:"2em", borderRadius:"0.3em", border:"solid 2px #" + ((naIsSelectedTF) ? ("77b") : ("ddd"))}}
          title="Mark this rating as Not Applicable"
          onClick={((naIsSelectedTF) ? (undefined) : (this.onclick_rating_na_minus1))}>
          <div className={"font09 " + ((naIsSelectedTF) ? ("fontBold fontTextLight") : ("fontTextLightester"))}>
            {"N/A"}
          </div>
        </div>
        {[1,2,3,4,5].map((m_rating1to5) =>
          <Rating1To5CELogo
            p_rating1to5={m_rating1to5}
            p_selectedRating1to5NAm1={selectedRating1to5NAm1}
            p_canEditTF={canEditTF}
            f_onSelectRating={this.props.f_onSelectRating}
          />
        )}
      </div>
    );
  }
}

const Rating1To5CELogo = inject("CaptureExecMobx", "UserMobx")(observer(
class Rating1To5CELogo extends Component { //props: p_rating1to5, p_selectedRating1to5NAm1, p_canEditTF, f_onSelectRating
  onclick_rating_ce_logo = () => {
    if(JSFUNC.is_function(this.props.f_onSelectRating)) {
      this.props.f_onSelectRating(this.props.p_rating1to5);
    }
  }

  render() {
    const p_rating1to5 = this.props.p_rating1to5;
    const p_selectedRating1to5NAm1 = this.props.p_selectedRating1to5NAm1;
    const p_canEditTF = this.props.p_canEditTF;

    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;
    const c_userTeammatesRatingsCELogoSizePx = this.props.UserMobx.c_userTeammatesRatingsCELogoSizePx;

    const isColorTF = (p_selectedRating1to5NAm1 >= p_rating1to5);

    const celogoratingFileName = "celogorating" + c_userTeammatesRatingsCELogoSizePx + "px" + ((isColorTF) ? ("color") : ("gray")) + ".png";
    const celogoratingFilePath = c_productWebsiteGraphicsSubfolderAddress + "/" + celogoratingFileName;

    return(
      <div
        className={"flex00a " + ((p_canEditTF) ? ("cursorPointer") : (""))}
        style={{padding:"0 0.22em"}}
        title={"Rate as a " + p_rating1to5 + " out of 5"}
        onClick={((p_canEditTF) ? (this.onclick_rating_ce_logo) : (undefined))}>
        <div style={{width:c_userTeammatesRatingsCELogoSizePx + "px", height:c_userTeammatesRatingsCELogoSizePx + "px"}}>
          <LibraryReact.Image p_src={celogoratingFilePath} p_alt="CE" />
        </div>
      </div>
    );
  }
}));

export function RatingNA1To5OutOf5Display(props) { //props: p_rating1to5NAm1
  const rating1to5NAm1 = props.p_rating1to5NAm1;

  if((rating1to5NAm1 === undefined) || (rating1to5NAm1 < 1) || (rating1to5NAm1 > 5)) {
    return(
      <font className="fontItalic fontTextLighter">
        {"N/A"}
      </font>
    );
  }

  return(
    <>
      {rating1to5NAm1.toFixed(1) + " "}
      <font className="fontItalic fontTextLighter">{"/ 5"}</font>
    </>
  );
}


export function CEGCSSDataSourceConnectedToIcon(props) { //props: p_dataSourceSam0GovWin1, p_title, f_onClick
  const p_dataSourceSam0GovWin1 = props.p_dataSourceSam0GovWin1;
  const p_title = props.p_title;

  const onClickIsFunctionTF = JSFUNC.is_function(props.f_onClick);

  var dataSourceName = "--Invalid Data Source Flag '" + p_dataSourceSam0GovWin1 + "'--";
  var connectedBorderColorClass = "bevelBorderColors";
  var connectedBgClass = "bgLighterGrayGradient";
  var connectedFontColorClass = "";
  if(p_dataSourceSam0GovWin1 === 0) {
    dataSourceName = "SAM.gov";
    connectedBorderColorClass = "bevelBorderColors";
    connectedBgClass = "bgLighterOrangeGradient";
    connectedFontColorClass = "fontDarkOrange";
  }
  else if(p_dataSourceSam0GovWin1 === 1) {
    dataSourceName = "GovWin";
    connectedBorderColorClass = "bevelBorderColorBlue";
    connectedBgClass = "bgLighterBlueGradient";
    connectedFontColorClass = "fontBlue";
  }
  else if(p_dataSourceSam0GovWin1 === 2) {
    dataSourceName = "Federal Compass";
    connectedBorderColorClass = "bevelBorderColors";
    connectedBgClass = "bgGcssFedCompLighterGradient";
    connectedFontColorClass = "fontGcssFedCompDark";
  }

  return(
    <div
      className={"flex00a tbMicroMargin lrMargin border " + connectedBorderColorClass + " borderRadius10 " + connectedBgClass + " tbMicroPad lrMedPad" + ((onClickIsFunctionTF) ? (" cursorPointer") : (undefined))}
      title={p_title}
      onClick={props.f_onClick}>
      <font className={"fontItalic " + connectedFontColorClass}>
        {"Connected to " + dataSourceName}
      </font>
    </div>
  );
}



export class LoadingAnimation extends Component { //props: p_frameDelay, p_class, p_styleObj
  constructor(props) {
    super(props);
    this.state = {
      s_loadingAnimationFrame: ((this.props.p_frameDelay === undefined) ? (0) : (-1 * this.props.p_frameDelay))
    }
  }

  componentDidMount() { //run the loading animation through the frames
    const waitTimeSec = 0.09;
    this.loadingAnimationTimerID = setInterval(this.advance_frame, (1000*waitTimeSec));
  }

  componentWillUnmount() {
    clearInterval(this.loadingAnimationTimerID);
  }

  advance_frame = () => { //count from 1 to 6 repeatedly
    this.setState({
      s_loadingAnimationFrame: ((this.state.s_loadingAnimationFrame > 6) ? (1) : (this.state.s_loadingAnimationFrame + 1))
    });
  }

  render() {
    const divClass = JSFUNC.prop_value(this.props.p_class, "");
    const styleObj = JSFUNC.prop_value(this.props.p_styleObj, {});

    //determine the color of each of the 3 loading dots based on which frame the animation is on
    var loadingAnimationColor1 = "transparent";
    var loadingAnimationColor2 = "transparent";
    var loadingAnimationColor3 = "transparent";
    if(this.state.s_loadingAnimationFrame === 2) { loadingAnimationColor1 = "#555"; }
    else if(this.state.s_loadingAnimationFrame === 3) { loadingAnimationColor1 = "#888"; loadingAnimationColor2 = "#555"; }
    else if(this.state.s_loadingAnimationFrame === 4) { loadingAnimationColor1 = "#aaa"; loadingAnimationColor2 = "#888"; loadingAnimationColor3 = "#555"; }
    else if(this.state.s_loadingAnimationFrame === 5) { loadingAnimationColor1 = "#ddd"; loadingAnimationColor2 = "#aaa"; loadingAnimationColor3 = "#888"; }
    else if(this.state.s_loadingAnimationFrame === 6) { loadingAnimationColor1 = "transparent"; loadingAnimationColor2 = "#ddd"; loadingAnimationColor3 = "#aaa"; }

    return(
      <div className={"inlineBlock " + divClass} style={styleObj}>
        <font style={{color:loadingAnimationColor1}}>{"\u25CF "}</font>
        <font style={{color:loadingAnimationColor2}}>{"\u25CF "}</font>
        <font style={{color:loadingAnimationColor3}}>{"\u25CF "}</font>
      </div>
    );
  }
}


export const GovWinLoadingAnimation = inject("CaptureExecMobx")(observer(
class GovWinLoadingAnimation extends Component {
  render() {
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    const govWinFromDeltekLogoFilePath = c_productWebsiteGraphicsSubfolderAddress + "/govwinfromdelteklogo.png";
    const govWinArrowFilePath = c_productWebsiteGraphicsSubfolderAddress + "/govwinarrow.png";

    return(
      <div className="displayFlexRow" style={{width:"130px"}}>
        <div className="flex00a displayFlexRowVc" style={{flexBasis:"100px"}}>
          <LibraryReact.Image p_src={govWinFromDeltekLogoFilePath} p_alt="GovWin Deltek" />
        </div>
        <div className="flex11a displayFlexColumnHcVc">
          <LibraryReact.Image p_src={govWinArrowFilePath} p_alt=">" p_class="animateDisappearReappear1sInfinite" />
        </div>
      </div>
    );
  }
}));


export const FedCompLoadingAnimation = inject("CaptureExecMobx")(observer(
class FedCompLoadingAnimation extends Component {
  render() {
    const c_productWebsiteGraphicsSubfolderAddress = this.props.CaptureExecMobx.c_productWebsiteGraphicsSubfolderAddress;

    const govWinFromDeltekLogoFilePath = c_productWebsiteGraphicsSubfolderAddress + "/gcss_loading_fedcomp_logo.png";
    const govWinArrowFilePath = c_productWebsiteGraphicsSubfolderAddress + "/gcss_loading_fedcomp_arrow.png";

    return(
      <div className="displayFlexRow" style={{background:"#172432", width:"130px"}}>
        <div className="flex00a displayFlexRowVc" style={{flexBasis:"100px"}}>
          <LibraryReact.Image p_src={govWinFromDeltekLogoFilePath} p_alt="Federal Compass" />
        </div>
        <div className="flex11a displayFlexColumnHcVc">
          <LibraryReact.Image p_src={govWinArrowFilePath} p_alt=">" p_class="animateDisappearReappear1sInfinite" />
        </div>
      </div>
    );
  }
}));





export function NewCaptureSectionContainer(props) { //props: p_drawBorderTF, children
  const p_drawBorderTF = JSFUNC.prop_value(props.p_drawBorderTF, false);

  var boxShadow = undefined;
  var border = undefined;
  var borderRadius = undefined;
  if(p_drawBorderTF) {
    boxShadow = "0.2em 0.2em 0.5em 0.03em #bbb";
    border = "solid 1px #cce";
    borderRadius = "1em";
  }

  return(
    <div
      className="flex11a displayFlexColumn"
      style={{flexBasis:"100em", boxShadow:boxShadow, border:border, borderRadius:borderRadius}}>
      {props.children}
    </div>
  );
}

export function NewCaptureSectionLabel(props) { //props: p_label, children
  const p_label = props.p_label;

  return(
    <div className="tbBigMargin lrMedMargin">
      <div className="smallBottomMargin textCenter">
        <font className="font13 fontBold fontTextLighter">
          {p_label}
        </font>
      </div>
      {props.children}
    </div>
  );
}

export function NewCaptureFieldLabel(props) { //props: p_label, p_widthEm
  const p_label = props.p_label;
  const p_widthEm = props.p_widthEm;
  
  return(
    <div className="flex00a lrPad" style={{flexBasis:p_widthEm + "em"}} title={p_label}>
      <LibraryReact.Nowrap p_fontClass="font11 fontBold fontTextLighter">
        {p_label}
      </LibraryReact.Nowrap>
    </div>
  );
}








//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Tabs List
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export class TabsList extends Component { //props:
  //p_canMultiSelectTabsFromSelectedDbNamesCommaTF, p_tabDbNamesArray, p_tabDisplayNamesArray, p_selectedTabDbName, p_tabHeight, p_textMaxHeight, p_tabWidth, 
  //p_selectedBgClass, p_selectedFontClass, p_unselectedBgClass, p_unselectedFontClass,
  //p_canEditTF, p_rowFlexWrapTF, p_verticalTabsTF, p_tabSpacing, p_borderRadiusClass, p_unselectValue
  //f_onSelect(i_selectedTabDbName)

  onclick_multi_select_all_tabs = () => {
    const p_tabDbNamesArray = this.props.p_tabDbNamesArray;
    const p_selectedTabDbName = this.props.p_selectedTabDbName;

    if(JSFUNC.is_function(this.props.f_onSelect)) {
      const oldSelectedTabDbNamesArray = JSFUNC.convert_comma_list_to_int_array(p_selectedTabDbName);
      if(!JSFUNC.all_of_array1_is_in_array2(p_tabDbNamesArray, oldSelectedTabDbNamesArray)) { //only need to update if not every tab is currently selected
        const updatedSelecteTabDbNamesComma = JSFUNC.convert_array_to_comma_list(p_tabDbNamesArray);
        this.props.f_onSelect(updatedSelecteTabDbNamesComma);
      }
    }
  }

  onclick_multi_clear_all_tabs = () => {
    const p_selectedTabDbName = this.props.p_selectedTabDbName;

    if(JSFUNC.is_function(this.props.f_onSelect)) {
      if(p_selectedTabDbName !== "") { //no need to update selection if already cleared
        this.props.f_onSelect("");
      }
    }
  }

  render() {
    const p_canMultiSelectTabsFromSelectedDbNamesCommaTF = JSFUNC.prop_value(this.props.p_canMultiSelectTabsFromSelectedDbNamesCommaTF, false); //default false, if true, input p_selectedTabDbName is a string comma list of selected tab dbNames (assumed to be integers like tab ID numbers) and multiple or 0 tabs can be selected
    const p_tabDbNamesArray = this.props.p_tabDbNamesArray;
    const p_tabDisplayNamesArray = this.props.p_tabDisplayNamesArray;
    const p_selectedTabDbName = this.props.p_selectedTabDbName;
    const p_tabHeight = JSFUNC.prop_value(this.props.p_tabHeight, "3.2em");
    const p_textMaxHeight = JSFUNC.prop_value(this.props.p_textMaxHeight, "3em"); //"nowrap" - Nowrap element, otherwise a MaxHeightWrap is used with this height
    const p_tabWidth = this.props.p_tabWidth; //undefined is a flag that will stretch the entire bar of all tabs to the full width of the container and make each tab an equal width
    const p_selectedBgClass = JSFUNC.prop_value(this.props.p_selectedBgClass, "bgLightBlueGradient");
    const p_selectedFontClass = this.props.p_selectedFontClass;
    const p_unselectedBgClass = JSFUNC.prop_value(this.props.p_unselectedBgClass, "bgLighterGrayGradient hoverLighterBlueGradient");
    const p_unselectedFontClass = JSFUNC.prop_value(this.props.p_unselectedFontClass, "font11 fontBold fontTextLight");
    const p_canEditTF = JSFUNC.prop_value(this.props.p_canEditTF, true);
    const p_rowFlexWrapTF = JSFUNC.prop_value(this.props.p_rowFlexWrapTF, false); //true - flexWrap row, false - xScroll in single row
    const p_verticalTabsTF = JSFUNC.prop_value(this.props.p_verticalTabsTF, false); //true - vertical centered tabs, false - horizontal tabs in a row (wrapped or not wrapped with x scroll)
    const p_tabSpacing = this.props.p_tabSpacing; //(default vertical/horizontal spacing values set below)
    const p_borderRadiusClass = JSFUNC.prop_value(this.props.p_borderRadiusClass, "borderRadius05");
    const p_unselectValue = this.props.p_unselectValue; //undefined (default) - cannot click a selected tab, value/dbName - clicking a selected tab unselects it (sends unselect value as output), meaning no tabs are selected

    //error printed if tab dbNames and displayNames inputs are not arrays
    if(!JSFUNC.is_array(p_tabDbNamesArray) || !JSFUNC.is_array(p_tabDisplayNamesArray)) {
      return(
        <div className="flex00a medFullPad bgLighterGray">
          <font className="fontItalic fontRed">
            <div>{"--CEGeneralReact/TabsList--"}</div>
            <div>{"p_tabDbNamesArray: " + JSFUNC.print(p_tabDbNamesArray)}</div>
            <div>{"p_tabDisplayNamesArray: " + JSFUNC.print(p_tabDisplayNamesArray)}</div>
            <div>{"p_selectedTabDbName: " + JSFUNC.print(p_selectedTabDbName)}</div>
          </font>
        </div>
      );
    }

    //precompute selected tabs as an array for multiselect mode
    var multiSelectedTabsDbNamesArray = [];
    if(p_canMultiSelectTabsFromSelectedDbNamesCommaTF) {
      multiSelectedTabsDbNamesArray = JSFUNC.convert_comma_list_to_int_array(p_selectedTabDbName);
    }

    //set defaults for vertical/horizontal tab spacing, or use the input p_tabSpacing
    var verticalOrHorizontalTabSpacing = p_tabSpacing;
    if(p_tabSpacing === undefined) {
      if(p_verticalTabsTF) {
        verticalOrHorizontalTabSpacing = "0.3em";
      }
      else {
        verticalOrHorizontalTabSpacing = "0.8em";
      }
    }
    
    const textIsNowrapTF = (p_textMaxHeight === "nowrap");
    const tavWidthIsFixedTF = (p_tabWidth !== undefined);

    var flexClass = "";
    if(tavWidthIsFixedTF) { //tabs are evenly sized to fit full container
      flexClass = "flex00a";
    }
    else {
      flexClass = "flex11a";
    }

    var tabsContainerDisplayFlexClass = "";
    if(p_verticalTabsTF) {
      tabsContainerDisplayFlexClass = "displayFlexColumn";
    }
    else {
      tabsContainerDisplayFlexClass = "displayFlexRowVc";
    }

    var flexScrollClass = "";
    if(p_rowFlexWrapTF) {
      flexScrollClass = "flexWrap";
    }
    else if(tavWidthIsFixedTF) {
      flexScrollClass = "xyScroll";
    }

    //select all/clear all buttons for multiselect
    var mutliselectControlButtonsComponent = null;
    if(p_canMultiSelectTabsFromSelectedDbNamesCommaTF) {
      mutliselectControlButtonsComponent = (
        <div className="flex00a displayFlexColumnVc" style={{marginRight:"0.5em"}}>
          <div
            className="flex00a displayFlexColumnHcVc border bevelBorderColors borderRadius05 bgLightGrayGradient hoverLightestGrayGradient textCenter cursorPointer"
            style={{width:"3.3em", height:"1.1em"}}
            onClick={this.onclick_multi_select_all_tabs}>
            <font className="font09">
              {"All"}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:"0.15em"}} />
          <div
            className="flex00a displayFlexColumnHcVc border bevelBorderColors borderRadius05 bgLightGrayGradient hoverLightestGrayGradient textCenter cursorPointer"
            style={{width:"3.3em", height:"1.1em"}}
            onClick={this.onclick_multi_clear_all_tabs}>
            <font className="font09">
              {"Clear"}
            </font>
          </div>
        </div>
      );
    }

    return(
      <div className={flexClass + " " + tabsContainerDisplayFlexClass + " " + flexScrollClass}>
        {mutliselectControlButtonsComponent}
        {p_tabDbNamesArray.map((m_tabDbName, m_index) =>
          <SingleTab
            key={m_tabDbName}
            p_canMultiSelectTabsFromSelectedDbNamesCommaTF={p_canMultiSelectTabsFromSelectedDbNamesCommaTF}
            p_tabIndex={m_index}
            p_tabDbName={m_tabDbName}
            p_tabDisplayName={p_tabDisplayNamesArray[m_index]}
            p_selectedTabDbName={p_selectedTabDbName}
            p_multiSelectedTabsDbNamesArray={multiSelectedTabsDbNamesArray}
            p_tabHeight={p_tabHeight}
            p_textMaxHeight={p_textMaxHeight}
            p_textIsNowrapTF={textIsNowrapTF}
            p_tabWidth={p_tabWidth}
            p_tavWidthIsFixedTF={tavWidthIsFixedTF}
            p_selectedBgClass={p_selectedBgClass}
            p_selectedFontClass={p_selectedFontClass}
            p_unselectedBgClass={p_unselectedBgClass}
            p_unselectedFontClass={p_unselectedFontClass}
            p_canEditTF={p_canEditTF}
            p_verticalTabsTF={p_verticalTabsTF}
            p_tabSpacing={verticalOrHorizontalTabSpacing}
            p_borderRadiusClass={p_borderRadiusClass}
            p_unselectValue={p_unselectValue}
            f_onClick={this.props.f_onSelect}
          />
        )}
      </div>
    );
  }
}

class SingleTab extends Component { //props:
  //p_canMultiSelectTabsFromSelectedDbNamesCommaTF, p_tabIndex, p_tabDbName, p_tabDisplayName, p_selectedTabDbName, p_multiSelectedTabsDbNamesArray, p_tabHeight, p_textMaxHeight, p_textIsNowrapTF, p_tabWidth, p_tavWidthIsFixedTF, 
  //p_selectedBgClass, p_selectedFontClass, p_unselectedBgClass, p_unselectedFontClass,
  //p_canEditTF, p_verticalTabsTF, p_tabSpacing, p_borderRadiusClass, p_unselectValue
  //f_onClick
  onclick_tab_checkbox_area = () => {
    const p_tabDbName = this.props.p_tabDbName;
    const p_selectedTabDbName = this.props.p_selectedTabDbName;
    const p_multiSelectedTabsDbNamesArray = this.props.p_multiSelectedTabsDbNamesArray;

    if(JSFUNC.is_function(this.props.f_onClick)) {
      var updatedSelecteTabDbName = undefined;
      if(JSFUNC.in_array(p_tabDbName, p_multiSelectedTabsDbNamesArray)) { //clicked tab was already selected, unselect it by removing it from the comma list
        const updatedSelecteTabDbNamesArray = JSFUNC.remove_all_values_from_array(p_tabDbName, p_multiSelectedTabsDbNamesArray);
        updatedSelecteTabDbName = JSFUNC.convert_array_to_comma_list(updatedSelecteTabDbNamesArray);
      }
      else { //clicked tab was not yet selected, add it to the comma list of selected tab dbNames
        updatedSelecteTabDbName = JSFUNC.add_value_to_comma_list(p_tabDbName, p_selectedTabDbName);
      }
    }

    this.props.f_onClick(updatedSelecteTabDbName);
  }
  
  onclick_tab = () => {
    const p_tabDbName = this.props.p_tabDbName;
    const p_selectedTabDbName = this.props.p_selectedTabDbName;
    const p_unselectValue = this.props.p_unselectValue;

    if(JSFUNC.is_function(this.props.f_onClick)) {
      var updatedSelecteTabDbName = p_tabDbName; //new selected tab is tab just clicked on
      if((p_unselectValue !== undefined) && (p_selectedTabDbName === updatedSelecteTabDbName)) { //if unselect is possible and the currently selected tab was just clicked, send the unselect value as output
        updatedSelecteTabDbName = p_unselectValue;
      }
      
      this.props.f_onClick(updatedSelecteTabDbName);
    }
  }

  render() {
    const p_canMultiSelectTabsFromSelectedDbNamesCommaTF = this.props.p_canMultiSelectTabsFromSelectedDbNamesCommaTF;
    const p_tabIndex = this.props.p_tabIndex;
    const p_tabDbName = this.props.p_tabDbName;
    const p_tabDisplayName = this.props.p_tabDisplayName;
    const p_selectedTabDbName = this.props.p_selectedTabDbName;
    const p_multiSelectedTabsDbNamesArray = this.props.p_multiSelectedTabsDbNamesArray;
    const p_tabHeight = this.props.p_tabHeight;
    const p_textMaxHeight = this.props.p_textMaxHeight;
    const p_textIsNowrapTF = this.props.p_textIsNowrapTF;
    const p_tabWidth = this.props.p_tabWidth;
    const p_tavWidthIsFixedTF = this.props.p_tavWidthIsFixedTF;
    const p_selectedBgClass = this.props.p_selectedBgClass;
    const p_selectedFontClass = this.props.p_selectedFontClass;
    const p_unselectedBgClass = this.props.p_unselectedBgClass;
    const p_unselectedFontClass = this.props.p_unselectedFontClass;
    const p_canEditTF = this.props.p_canEditTF;
    const p_verticalTabsTF = this.props.p_verticalTabsTF;
    const p_tabSpacing = this.props.p_tabSpacing;
    const p_borderRadiusClass = this.props.p_borderRadiusClass;
    const p_unselectValue = this.props.p_unselectValue;

    const canUnselectTF = (p_unselectValue !== undefined);

    var isSelectedTF = false;
    if(p_canMultiSelectTabsFromSelectedDbNamesCommaTF) { //can multiselect tabs from input p_selectedTabDbName as a comma list of selected tab dbNames
      isSelectedTF = JSFUNC.in_array(p_tabDbName, p_multiSelectedTabsDbNamesArray);
    }
    else { //single tab selected or not
      isSelectedTF = (p_tabDbName === p_selectedTabDbName);
    }

    var flexClass = "";
    var flexBasis = undefined;
    if(!p_tavWidthIsFixedTF && !p_verticalTabsTF) {
      flexClass = "flex11a";
      flexBasis = "100em";
    }

    var marginLeft = undefined; //no margin right for first tab
    var marginTop = undefined; //vertical tabs have marginTop instead of left
    if(p_tabIndex > 0) {
      if(p_verticalTabsTF) {
        marginTop = p_tabSpacing;
      }
      else {
        marginLeft = p_tabSpacing;
      }
    }

    var fontClass = "";
    var bgClass = "";
    if(isSelectedTF) {
      fontClass = ((p_selectedFontClass !== undefined) ? (p_selectedFontClass) : (p_unselectedFontClass));
      bgClass = p_selectedBgClass;
      if(p_canEditTF && (canUnselectTF || p_canMultiSelectTabsFromSelectedDbNamesCommaTF)) {
        bgClass += " cursorPointer";
      }
    }
    else {
      fontClass = p_unselectedFontClass;
      bgClass = p_unselectedBgClass;
      if(p_canEditTF) {
        bgClass += " cursorPointer";
      }
    }

    var tabTextComponent = null;
    if(p_textIsNowrapTF) {
      tabTextComponent = (
        <LibraryReact.Nowrap p_fontClass={fontClass}>
          {p_tabDisplayName}
        </LibraryReact.Nowrap>
      );
    }
    else {
      tabTextComponent = (
        <LibraryReact.MaxHeightWrap p_maxHeight={p_textMaxHeight} p_fontClass={fontClass}>
          {p_tabDisplayName}
        </LibraryReact.MaxHeightWrap>
      );
    }

    var displayFlexClass = "";
    var paddingClass = "";
    var tabInteriorComponent = null;
    if(p_canMultiSelectTabsFromSelectedDbNamesCommaTF) { //can multiselect tabs, have a checkbox in the topleft corner or each tab
      displayFlexClass = "displayFlexRow";

      tabInteriorComponent = (
        <>
          <div
            className="flex00a displayFlexColumnVc borderR1bbb"
            style={{paddingLeft:"0.4em", paddingRight:"0.3em"}}
            onClick={((p_canEditTF) ? (this.onclick_tab_checkbox_area) : (undefined))}>
            <LibraryReact.CheckBox
              p_u0_s1_p2_du3_ds4={((isSelectedTF) ? (1) : (0))}
              p_sizeEm={1}
            />
          </div>
          <div
            className="flex11a displayFlexRowVc"
            style={{paddingLeft:"0.1em", paddingTop:"0.2em", paddingRight:"0.4em", paddingBottom:"0.2em"}}
            onClick={((p_canEditTF) ? (this.onclick_tab) : (undefined))}>
            {tabTextComponent}
          </div>
        </>
      );
    }
    else { //normal single select tab inside small padding
      displayFlexClass = "displayFlexRowVc";
      paddingClass = "smallFullPad";
      tabInteriorComponent = tabTextComponent;
    }

    return(
      <div
        className={flexClass + " " + displayFlexClass + " border bevelBorderColors " + p_borderRadiusClass + " " + bgClass + " " + paddingClass + " textCenter"}
        style={{width:p_tabWidth, height:p_tabHeight, flexBasis:flexBasis, marginLeft:marginLeft, marginTop:marginTop}}
        onClick={((p_canEditTF && !p_canMultiSelectTabsFromSelectedDbNamesCommaTF) ? (this.onclick_tab) : (undefined))}>
        {tabInteriorComponent}
      </div>
    );
  }
}







//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Custom Inputs
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
class InputDateLocalFromRawDateTimeUtc extends Component { //props: p_value, p_tabIndex, f_onChange, f_onKeyDownEnter
  constructor(props) {
    super(props);

    const p_value = this.props.p_value; //input in datetime format

    const mysqlDateLocal = JSFUNC.convert_mysqldatetimeutc_to_mysqldatelocal(p_value);

    this.state = {
      s_selectedDateYmd: mysqlDateLocal
    };
  }

  onchange_date_ymd = (i_newValueDateYmdOrClearBlankString) => {
    //for edge/chrome, when a date is selected the raw value is "2024-04-15", but when the "Clear" button on the calendar interface is pushed, the raw value is "", not "0000-00-00"
    var newValueDateYmd = JSFUNC.blank_date();
    if(JSFUNC.date_is_filled_out_tf(i_newValueDateYmdOrClearBlankString)) {
      newValueDateYmd = i_newValueDateYmdOrClearBlankString
    }

    this.setState({s_selectedDateYmd:newValueDateYmd});

    if(JSFUNC.is_function(this.props.f_onChange)) {
      var newValueDateTimeUtc = JSFUNC.blank_datetime();
      if(JSFUNC.date_is_filled_out_tf(newValueDateYmd)) {
        newValueDateTimeUtc = newValueDateYmd + " 00:00:58";
      }
      this.props.f_onChange(newValueDateTimeUtc);
    }
  }

  render() {
    const s_selectedDateYmd = this.state.s_selectedDateYmd;

    const p_value = this.props.p_value;
    const p_tabIndex = this.props.p_tabIndex;

    return(
      <LibraryReact.Date
        p_value={s_selectedDateYmd}
        p_tabIndex={p_tabIndex}
        f_onChange={this.onchange_date_ymd}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}
      />
    );
  }
}


const InputDateWithRelativeDate = inject("DatabaseMobx")(observer(
class InputDateWithRelativeDate extends Component { //props: p_value, p_tabIndex, f_onChange, f_onKeyDownEnter
  constructor(props) {
    super(props);

    const fixedDateObj = JSFUNC.convert_relative_ymd_date_to_fixed_date_obj(this.props.p_value);

    this.state = {
      s_selectedRelativeType: fixedDateObj.relativeType,
      s_selectedRelativeOffset: fixedDateObj.relativeOffset
    }
  }

  componentDidUpdate(i_prevProps) {
    if(this.props.p_value !== i_prevProps.p_value) {
      const fixedDateObj = JSFUNC.convert_relative_ymd_date_to_fixed_date_obj(this.props.p_value);

      this.setState({
        s_selectedRelativeType: fixedDateObj.relativeType,
        s_selectedRelativeOffset: fixedDateObj.relativeOffset
      });
    }
  }

  onselect_relative_or_fixed = (i_newValue) => {
    if(i_newValue !== this.state.s_selectedRelativeType) { //if the value was changed
      this.setState({s_selectedRelativeType: i_newValue});

      var changedYmdDate = JSFUNC.blank_date();
      if(i_newValue === "fixed") { //fixed date
        changedYmdDate = JSFUNC.now_date(); //today's date in fixed format
      }
      else {
        var changedYmdDate = "9000"; //if switching from fixed, the offset number starts at 0
        if(this.state.s_selectedRelativeType !== "fixed") { //if switching from a different relative type, use the previous offset
          if(JSFUNC.is_string(this.props.p_value)) {
            changedYmdDate = this.props.p_value.substring(0, 4);
          }
        }

        if(i_newValue === "weeks") {
          changedYmdDate += "-01-02";
        }
        else if(i_newValue === "months") {
          changedYmdDate += "-01-03";
        }
        else if(i_newValue === "years") {
          changedYmdDate += "-01-04";
        }
        else { //days
          changedYmdDate += "-01-01";
        }
      }

      if(JSFUNC.is_function(this.props.f_onChange)) {
        this.props.f_onChange(changedYmdDate);
      }
    }
  }

  onchange_fixed_date = (i_newValue) => {
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(i_newValue);
    }
  }

  onchange_offset_days = (i_newValue) => {
    var newOffset = i_newValue;
    if(newOffset > 999) {
      newOffset = 999;
    }
    else if(newOffset < -999) {
      newOffset = -999;
    }

    this.setState({s_selectedRelativeOffset:newOffset});

    if(JSFUNC.is_function(this.props.f_onChange)) {
      const year = ((newOffset >= 0) ? (9000 + newOffset) : (8000 + (-1 * newOffset)));
      var changedYmdDate = year;
      if(JSFUNC.is_string(this.props.p_value)) {
        changedYmdDate += this.props.p_value.substring(4, 10);
      }
      else {
        changedYmdDate += "-01-01";
      }
      this.props.f_onChange(changedYmdDate);
    }
  }

  render() {
    const s_selectedRelativeType = this.state.s_selectedRelativeType;
    const s_selectedRelativeOffset = this.state.s_selectedRelativeOffset;

    const p_value = this.props.p_value;
    const p_tabIndex = this.props.p_tabIndex;

    const c_selectDateWithRelativeDateFixedOrRelativeDurationTypeFieldTypeObj = this.props.DatabaseMobx.c_selectDateWithRelativeDateFixedOrRelativeDurationTypeFieldTypeObj;

    return(
      <div>
        <div className="microBottomMargin">
          <GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectDateWithRelativeDateFixedOrRelativeDurationTypeFieldTypeObj}
            p_valueRaw={s_selectedRelativeType}
            f_onChangeOrOnSelect={this.onselect_relative_or_fixed}
          />
        </div>
        <div className="displayFlexRowVc" style={{height:"2em"}}>
          {(s_selectedRelativeType === "fixed") ? (
            <LibraryReact.Date
              p_value={p_value}
              p_tabIndex={p_tabIndex}
              f_onChange={this.onchange_fixed_date}
              f_onKeyDownEnter={this.props.f_onKeyDownEnter}
            />
          ) : (
            <div className="flex00a displayFlexRowVc">
              <div className="flex00a rMargin">
                {"[Today's Date] + "}
              </div>
              <div className="flex00a rMargin">
                <LibraryReact.Integer
                  p_value={s_selectedRelativeOffset}
                  p_styleObj={{width:"4.5em"}}
                  p_title="Enter the number of days (positive, negative, or 0) to offset from the computed [Today's Date] for this relative date field"
                  p_tabIndex={p_tabIndex}
                  f_onChange={this.onchange_offset_days}
                  f_onKeyDownEnter={this.props.f_onKeyDownEnter}
                />
              </div>
              <div className="flex11a">
                {s_selectedRelativeType}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}));



const InputDateWithDuration = inject("DatabaseMobx")(observer(
class InputDateWithDuration extends Component { //props: p_value, p_tabIndex, f_onChange, f_onKeyDownEnter
  constructor(props) {
    super(props);

    const inputDateWithDurationObj = JSFUNC.convert_date_with_duration_raw_datetime_to_date_with_duration_obj(this.props.p_value);

    this.state = {
      s_selectedDateYmd: inputDateWithDurationObj.dateYmd,
      s_numDaysWeeksMonthsYearsInt: inputDateWithDurationObj.numDaysWeeksMonthsYearsInt,
      s_days1Weeks2Months3Years4Int: inputDateWithDurationObj.days1Weeks2Months3Years4Int
    };
  }

  onchange_date_ymd = (i_newValueDateYmd) => {
    this.setState({s_selectedDateYmd:i_newValueDateYmd});

    const updatedDateWithDurationRawDateTime = JSFUNC.convert_date_with_duration_date_ymd_and_num_and_type1234_to_date_with_duration_raw_datetime(i_newValueDateYmd, this.state.s_numDaysWeeksMonthsYearsInt, this.state.s_days1Weeks2Months3Years4Int);
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(updatedDateWithDurationRawDateTime);
    }
  }

  onchange_num_daysweeksmonthsyears_int = (i_newValueInt) => {
    this.setState({s_numDaysWeeksMonthsYearsInt:i_newValueInt});

    const updatedDateWithDurationRawDateTime = JSFUNC.convert_date_with_duration_date_ymd_and_num_and_type1234_to_date_with_duration_raw_datetime(this.state.s_selectedDateYmd, i_newValueInt, this.state.s_days1Weeks2Months3Years4Int);
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(updatedDateWithDurationRawDateTime);
    }
  }

  onselect_duration_type_1234 = (i_newValueInt) => {
    this.setState({s_days1Weeks2Months3Years4Int:i_newValueInt});

    const updatedDateWithDurationRawDateTime = JSFUNC.convert_date_with_duration_date_ymd_and_num_and_type1234_to_date_with_duration_raw_datetime(this.state.s_selectedDateYmd, this.state.s_numDaysWeeksMonthsYearsInt, i_newValueInt);
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange(updatedDateWithDurationRawDateTime);
    }
  }

  render() {
    const s_selectedDateYmd = this.state.s_selectedDateYmd;
    const s_numDaysWeeksMonthsYearsInt = this.state.s_numDaysWeeksMonthsYearsInt;
    const s_days1Weeks2Months3Years4Int = this.state.s_days1Weeks2Months3Years4Int;

    const p_value = this.props.p_value;
    const p_tabIndex = this.props.p_tabIndex;

    const c_selectDurationType1234DaysWeeksMonthsYearsFieldTypeObj = this.props.DatabaseMobx.c_selectDurationType1234DaysWeeksMonthsYearsFieldTypeObj;

    return(
      <div>
        <div className="microBottomMargin">
          <LibraryReact.Date
            p_value={s_selectedDateYmd}
            p_tabIndex={p_tabIndex}
            f_onChange={this.onchange_date_ymd}
            f_onKeyDownEnter={this.props.f_onKeyDownEnter}
          />
        </div>
        <div className="displayFlexRowVc flexWrap">
          <div className="flex00a" style={{flexBasis:"4.5em"}}>
            <font className="fontItalic fontTextLight">
              {"Duration:"}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:"4em"}}>
            <LibraryReact.Integer
              p_value={s_numDaysWeeksMonthsYearsInt}
              p_min={0}
              p_max={5999}
              p_styleObj={{width:"100%"}}
              f_onChange={this.onchange_num_daysweeksmonthsyears_int}
              f_onKeyDownEnter={this.props.f_onKeyDownEnter}
            />
          </div>
          <div className="flex00a" style={{flexBasis:"0.5em"}} />
          <div className="flex00a" style={{flexBasis:"7em"}}>
            <GenericInputOrSelectFromInputType
              p_fieldTypeObj={c_selectDurationType1234DaysWeeksMonthsYearsFieldTypeObj}
              p_valueRaw={s_days1Weeks2Months3Years4Int}
              f_onChangeOrOnSelect={this.onselect_duration_type_1234}
            />
          </div>
          <div className="flex11a" />
        </div>
      </div>
    );
  }
}));






export class SearchInputTextWithClearX extends Component { //props: p_inputText, p_placeholderText, p_includeSearchIconTF, p_heightEm, p_bgHashColor, p_tabIndex, f_onChange, f_onClick, f_onKeyDownEnter, f_onKeyDownEsc
  //p_placeholderText is required to be filled out to make the magnifying glass icon work stylistically

  onclick_clear_capture_search_text = () => {
    if(JSFUNC.is_function(this.props.f_onChange)) {
      this.props.f_onChange("");
    }
  }

  render() {
    const p_inputText = this.props.p_inputText;
    const p_placeholderText = this.props.p_placeholderText;
    const p_includeSearchIconTF = JSFUNC.prop_value(this.props.p_includeSearchIconTF, false);
    const p_heightEm = JSFUNC.prop_value(this.props.p_heightEm, 2.1);
    const p_bgHashColor = this.props.p_bgHashColor;
    const p_tabIndex = this.props.p_tabIndex;

    return(
      <div className="displayFlexColumnVc positionRelative">
        <LibraryReact.Text
          p_value={p_inputText}
          p_class="borderRadius10 lrBigPad"
          p_styleObj={{width:"100%", height:p_heightEm + "em", background:p_bgHashColor}}
          p_tabIndex={p_tabIndex}
          p_placeholder={((p_includeSearchIconTF) ? ("\uD83D\uDD0D ") : ("")) + p_placeholderText}
          f_onChange={this.props.f_onChange}
          f_onClick={this.props.f_onClick}
          f_onKeyDownEnter={this.props.f_onKeyDownEnter}
          f_onKeyDownEsc={this.props.f_onKeyDownEsc}
        />
        <div
          className="positionAbsolute textCenter cursorPointer"
          style={{right:"1em", height:"1.4em", width:"1.4em"}}
          onClick={this.onclick_clear_capture_search_text}>
          <font className="fontDarkBlue">
            {"\u2715"}
          </font>
        </div>
      </div>
    );
  }
}







//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Floating Confirm Box
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const ConfirmBox = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class ConfirmBox extends Component { //props: p_type, p_title, p_button1Name, p_button1Type, p_button2Name, p_boxShadowColor, p_textareaHeight, p_textInitialValue, p_textMustBeFilledOutTF, p_textMustBeChangedForSaveTFU, f_onClickConfirm, f_onClickCancel, children
  //p_type: "ok", "help", "confirm", "confirmDelete", "cancel", "inputText", "inputTextarea", "loading", "error"
  //p_textMustBeChangedForSaveTFU (used for inputText and inputTextarea confirm types)
  //  undefined [default]   (used for edit item name) if text is unchanged the confirm box is closed but f_onClickConfirm is not called to perform a database change
  //  true                  (used for copy item where name can't be the same) prevents save when text is unchanged with error message
  //  false                 (used for create new item) always calls f_onClickConfirm even if text is unchanged

  constructor(props) {
    super(props);
    this.state = {
      s_inputTextInitialValue: this.props.p_textInitialValue,
      s_inputTextValue: JSFUNC.prop_value(this.props.p_textInitialValue, ""), //this way, if an initial value is not provided, the state initial value is undefined and the current value is "", which would call onClickConfirm with a new value of "" if confirmed to save
      s_inputTextErrorMessage: undefined
    };
  }

  onchange_input_text = (i_newValue) => {
    this.setState({
      s_inputTextValue: i_newValue,
      s_inputTextErrorMessage: undefined
    });
  }

  onclick_confirm = () => {
    const s_inputTextInitialValue = this.state.s_inputTextInitialValue;
    const s_inputTextValue = this.state.s_inputTextValue;
    const s_inputTextErrorMessage = this.state.s_inputTextErrorMessage;

    const p_type = this.props.p_type;
    const p_textMustBeFilledOutTF = JSFUNC.prop_value(this.props.p_textMustBeFilledOutTF, true);
    const p_textMustBeChangedForSaveTFU = this.props.p_textMustBeChangedForSaveTFU;

    if(JSFUNC.is_function(this.props.f_onClickConfirm)) {
      if(p_type === "inputText" || p_type === "inputTextarea") {
        if(p_textMustBeFilledOutTF && (s_inputTextValue.length === 0)) {
          this.setState({s_inputTextErrorMessage:"New name cannot be blank"});
        }
        else {
          if(p_textMustBeChangedForSaveTFU === true) { //usually for a 'create copy and give new name' operation (like for folders) where the new copy name shouldn't be the same
            if(s_inputTextValue !== s_inputTextInitialValue) {
              this.props.f_onClickConfirm(s_inputTextValue);
            }
            else {
              this.setState({s_inputTextErrorMessage:"New name must be different from the old name"});
            }
          }
          else if(p_textMustBeChangedForSaveTFU === undefined) { //usually for an 'edit existing name' operation
            if(s_inputTextValue !== s_inputTextInitialValue) { //if the new value has been changed from the initial value
              this.props.f_onClickConfirm(s_inputTextValue);
            }
            else { //otherwise call a cancel since the value was not changed, doesn't need to update the local or database value
              this.onclick_cancel();
            }
          }
          else if(p_textMustBeChangedForSaveTFU === false) { //usually for an 'create new item' operation where default given name is allowed to be unchanged
            this.props.f_onClickConfirm(s_inputTextValue);
          }
        }
      }
      else {
        this.props.f_onClickConfirm("--Confirm--");
      }
    }
  }

  onclick_cancel = () => {
    if(this.props.f_onClickCancel) {
      this.props.f_onClickCancel();
    }
  }

  render() {
    const s_inputTextInitialValue = this.state.s_inputTextInitialValue;
    const s_inputTextValue = this.state.s_inputTextValue;
    const s_inputTextErrorMessage = this.state.s_inputTextErrorMessage;

    const p_type = JSFUNC.prop_value(this.props.p_type, "ok");
    const p_title = this.props.p_title;
    const p_button1Name = this.props.p_button1Name;
    const p_button1Type = this.props.p_button1Type;
    const p_button2Name = this.props.p_button2Name;
    const p_boxShadowColor = this.props.p_boxShadowColor;
    const p_textareaHeight = this.props.p_textareaHeight;
    const p_textInitialValue = this.props.p_textInitialValue;
    const p_textMustBeFilledOutTF = this.props.p_textMustBeFilledOutTF;
    const p_textMustBeChangedForSaveTFU = this.props.p_textMustBeChangedForSaveTFU;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const inputTextErrorTF = (s_inputTextErrorMessage !== undefined);

    var trblFlag = "confirmBox";
    var floatingBoxContentMaxHeight = "50vh";
    var title = "--Title--";
    var boxShadowHashColor = "#005da3";
    var hasButton1TF = true; //blue button that activates confirm function
    var button1Type = "blue";
    var button1Name = "OK";
    var button2Name = "Cancel";
    var textareaHeight = "30vh";
    var hasCancelButtonTF = false; //gray cancel button that activates concel function
    var hasInputTextTF = false;
    var hasInputTextareaTF = false;
    var hasLoadingAnimationTF = false;
    if(p_type === "ok") {
      title = c_productStylingObj.productName + " Message";
      button1Name = "OK";
    }
    else if(p_type === "help") {
      title = c_productStylingObj.productName + " Help";
      button1Name = "Close";
    }
    else if(p_type === "confirm") {
      title = c_productStylingObj.productName + " Confirmation";
      button1Name = "Confirm";
      hasCancelButtonTF = true;
    }
    else if(p_type === "confirmDelete") {
      title = c_productStylingObj.productName + " Delete Confirmation";
      boxShadowHashColor = "#bd2326";
      button1Type = "red";
      button1Name = "Confirm";
      hasCancelButtonTF = true;
    }
    else if(p_type === "cancel") {
      title = c_productStylingObj.productName + " Confirmation";
      hasButton1TF = false;
      hasCancelButtonTF = true;
    }
    else if(p_type === "inputText") {
      title = "Input Text";
      button1Name = "Create";
      hasCancelButtonTF = true;
      hasInputTextTF = true;
    }
    else if(p_type === "inputTextarea") {
      title = "Input Textarea";
      button1Name = "Create";
      hasCancelButtonTF = true;
      hasInputTextareaTF = true;
    }
    else if(p_type === "loading") {
      title = "Loading";
      boxShadowHashColor = "#bdbc26";
      hasButton1TF = false;
      hasLoadingAnimationTF = true;
    }
    else if(p_type === "error") {
      title = "Error";
      boxShadowHashColor = "#bd8c26";
      button1Type = "gray";
      button1Name = "OK";
    }

    if(p_title !== undefined) {
      title = p_title;
    }

    if(p_button1Name !== undefined) {
      button1Name = p_button1Name;
    }

    if(p_button1Type !== undefined) {
      button1Type = p_button1Type;
    }

    if(p_button2Name !== undefined) {
      button2Name = p_button2Name;
    }

    if(p_boxShadowColor !== undefined) {
      boxShadowHashColor = "#" + p_boxShadowColor;
    }

    if(p_textareaHeight !== undefined) {
      textareaHeight = p_textareaHeight;
    }

    return(
      <FloatingBoxWithSaveCancel
        p_outsideBgOpacity={0.6}
        p_trblFlag={trblFlag}
        p_title={title}
        p_shadowColor={boxShadowHashColor}
        p_floatingLayerNumber={100}
        f_onClickCancel={this.props.f_onClickCancel}>
        <div className="flex11a yScroll smallTopPad lrPad hugeBottomPad" style={{maxHeight:floatingBoxContentMaxHeight}}>
          {this.props.children}
          {(hasInputTextTF) &&
            <div className="medTopMargin">
              <LibraryReact.Text
                p_value={s_inputTextValue}
                p_class=""
                p_styleObj={{width:"100%"}}
                p_tabIndex={1}
                p_errorTF={inputTextErrorTF}
                f_onChange={this.onchange_input_text}
                f_onKeyDownEnter={this.onclick_confirm}
              />
            </div>
          }
          {(hasInputTextareaTF) &&
            <div className="medTopMargin">
              <LibraryReact.Textarea
                p_value={s_inputTextValue}
                p_class=""
                p_styleObj={{width:"100%", height:textareaHeight}}
                p_tabIndex={1}
                p_errorTF={inputTextErrorTF}
                f_onChange={this.onchange_input_text}
                f_onKeyDownEnter={this.onclick_confirm}
              />
            </div>
          }
          {(inputTextErrorTF) &&
            <ErrorText
              p_class="tbMicroMargin"
              p_text={s_inputTextErrorMessage}
            />
          }
          {(hasLoadingAnimationTF) &&
            <div className="displayFlexColumnHcVc medTopMargin">
              <LoadingAnimation />
            </div>
          }
        </div>
        <div className="flex00a displayFlexRowHcVc tbMedPad">
          {(hasButton1TF) &&
            <div className="flex00a lrMedMargin">
              <CEButton
                p_type={button1Type}
                p_text={button1Name}
                p_tabIndex={((hasInputTextTF || hasInputTextareaTF) ? (2) : (1))}
                f_onClick={this.onclick_confirm}
              />
            </div>
          }
          {(hasCancelButtonTF) &&
            <div className="flex00a lrMedMargin">
              <CEButton
                p_type="gray"
                p_text={button2Name}
                p_tabIndex={3}
                f_onClick={this.onclick_cancel}
              />
            </div>
          }
        </div>
      </FloatingBoxWithSaveCancel>
    );
  }
}));


export class ButtonWithConfirmBox extends Component { //props: p_buttonType, p_buttonText, p_buttonFontClass, p_tabIndex, p_title, p_confirmType, p_confirmMessage, p_button1Name, p_textMustBeFilledOutTF, f_onClickConfirm
  constructor(props) {
    super(props);
    this.state = {
      s_isConfirmingButtonPushTF: false
    }
  }

  onclick_button = () => {
    this.setState({s_isConfirmingButtonPushTF:true});
  }

  onclick_confirm = (i_enteredValue) => {
    this.setState({s_isConfirmingButtonPushTF:false}); //confirming closes the confirm box and fires the confirm function
    if(this.props.f_onClickConfirm) {
      this.props.f_onClickConfirm(i_enteredValue);
    }
  }

  onclick_cancel = () => {
    this.setState({s_isConfirmingButtonPushTF:false}); //cancelling the confirmation closes the floating confirm box without firing any action
  }

  render() {
    const s_isConfirmingButtonPushTF = this.state.s_isConfirmingButtonPushTF;

    const p_confirmMessage = this.props.p_confirmMessage;

    var confirmMessageComponent = p_confirmMessage; //message as a single line string
    if(JSFUNC.is_array(p_confirmMessage)) { //message as a multi line array of strings
      confirmMessageComponent = p_confirmMessage.map((m_messageLine) =>
        <div className="smallBottomMargin">
          <font>
            {m_messageLine}
          </font>
        </div>
      )
    }

    return(
      <>
        <CEButton
          p_type={this.props.p_buttonType}
          p_text={this.props.p_buttonText}
          p_fontClass={this.props.p_buttonFontClass}
          p_tabIndex={this.props.p_tabIndex}
          p_title={this.props.p_title}
          f_onClick={this.onclick_button}
        />
        {(s_isConfirmingButtonPushTF) &&
          <ConfirmBox
            p_type={this.props.p_confirmType}
            p_button1Name={this.props.p_button1Name}
            p_textMustBeFilledOutTF={this.props.p_textMustBeFilledOutTF}
            f_onClickConfirm={this.onclick_confirm}
            f_onClickCancel={this.onclick_cancel}>
            {confirmMessageComponent}
          </ConfirmBox>
        }
      </>
    );
  }
}








//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Vertical Dots Menus
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export function DeleteMenu(props) { //props: p_message, p_menuItemText, p_noDeleteTF, p_dotsFontClass, p_tabIndex, f_onDelete
  //p_noDeleteTF
  //  - true: an "ok" floating box that shows the message with only a cancel button (delete is not possible in this case)
  //  - false (default): a "confirmDelete" floating box with two choices (confirm and cancel), where confirm calls the f_onDelete function

  const deleteMessage = props.p_message;
  const menuItemText = JSFUNC.prop_value(props.p_menuItemText, "Delete");
  const noDeleteTF = JSFUNC.prop_value(props.p_noDeleteTF, false);
  const dotsFontClass = props.p_dotsFontClass;
  const tabIndex = props.p_tabIndex;

  const menuItemsArrayOfObjs = [{
    displayName: menuItemText,
    confirmType: ((noDeleteTF) ? ("cancel") : ("confirmDelete")),
    confirmMessage: deleteMessage,
    functionOnClickConfirmButton: props.f_onDelete
  }];

  return(
    <VerticalDotsMenu
      p_menuItemsArrayOfObjs={menuItemsArrayOfObjs}
      p_dotsFontClass={dotsFontClass}
      p_tabIndex={tabIndex}
    />
  );
}


export class VerticalDotsMenu extends Component { //props: p_menuItemsArrayOfObjs, p_dotsFontClass, p_tabIndex
  //menuItemObj
  //  - displayName                           text for item within opened vertical dots menu
  //  - confirmType
  //        undefined (no floating box)
  //        "ok"
  //        "help"
  //        "confirm"
  //        "confirmDelete"
  //        "cancel"
  //        "inputText"
  //        "inputTextarea"
  //        "loading"
  //        "error"
  //  - confirmTitle                          title in top of floating box
  //  - confirmButton1Name                    [default: "Create"]
  //  - confirmButton1Type                    [default: "blue"]
  //  - button2Name                           [default: "Cancel"]
  //  - boxShadowColor
  //  - textareaHeight
  //  - confirmTextInitialValue               initial value in text input for "inputText" and "inputTextarea" types
  //  - confirmTextMustBeFilledOutTF          [default: true]
  //  - confirmTextMustBeChangedForSaveTFU
  //        undefined [default]               (used for edit text) allows you to save without changing text, but doesn't call functionOnClickConfirmButton
  //        true                              (used for edit text) wont allow you to save without changing text displaying red error message
  //        false                             (used for create new item) will allow you to save without changing text and calls functionOnClickConfirmButton even if text is unchanged
  //  - confirmMessage                        instructions text above the input text/textarea
  //  - functionOnClickMenuItem               when the item in the opened vertical dots menu is clicked (whether a floating box will be opened next or not)
  //  - functionOnClickConfirmButton(i_enteredValue)

  constructor(props) {
    super(props);
    this.state = {
      s_menuOpenTF: false,
      s_confirmingMenuItemObj: undefined
    }
  }

  onclose_menu = () => {
    this.setState({s_menuOpenTF:false});
  }

  ontoggle_menu_open_closed = (event) => {
    this.setState((i_state, i_props) => ({
      s_menuOpenTF: (!i_state.s_menuOpenTF)
    }));
  }

  onselect_menu_item = (i_menuItemObj) => {
    if(i_menuItemObj !== undefined) {
      //execute function when the menu item is clicked if provided
      if(JSFUNC.is_function(i_menuItemObj.functionOnClickMenuItem)) {
        i_menuItemObj.functionOnClickMenuItem();
      }

      this.onclose_menu();

      if(i_menuItemObj.confirmType === undefined) { //no confirmation box required for this menu item
        this.onclose_confirm_box();
      }
      else { //this menu items requires a specified confirm floating box to be presented
        this.setState({s_confirmingMenuItemObj:i_menuItemObj});
      }
    }
  }

  onclick_confirm_button_in_floating_box = (i_enteredValue) => {
    const s_confirmingMenuItemObj = this.state.s_confirmingMenuItemObj;

    this.onclose_confirm_box();
    this.onclose_menu();

    if(s_confirmingMenuItemObj !== undefined) {
      if(JSFUNC.is_function(s_confirmingMenuItemObj.functionOnClickConfirmButton)) {
        s_confirmingMenuItemObj.functionOnClickConfirmButton(i_enteredValue);
      }
    }
  }

  onclose_confirm_box = () => {
    this.setState({s_confirmingMenuItemObj:undefined});
  }

  render() {
    const s_menuOpenTF = this.state.s_menuOpenTF;
    const s_confirmingMenuItemObj = this.state.s_confirmingMenuItemObj;

    const p_menuItemsArrayOfObjs = this.props.p_menuItemsArrayOfObjs;
    const p_dotsFontClass = JSFUNC.prop_value(this.props.p_dotsFontClass, "");
    const p_tabIndex = this.props.p_tabIndex;

    const confirmFloatingBoxIsOpenTF = (s_confirmingMenuItemObj !== undefined);

    //determine the width of the menu that opens based on the longest menu item name
    var menuWidth = undefined;
    if(s_menuOpenTF) { //only need to determine this if the menu is opened
      var longestMenuItemLength = 5; //5 characters is a starting minimum width of the menu
      for(let menuItemObj of p_menuItemsArrayOfObjs) {
        if(JSFUNC.is_string(menuItemObj.displayName)) {
          var menuItemNameLength = menuItemObj.displayName.length;
          if(menuItemNameLength > longestMenuItemLength) {
            longestMenuItemLength = menuItemNameLength;
          }
        }
      }
      menuWidth = ((longestMenuItemLength / 2.75) + 5) + "em";
    }

    var confirmMessageWithSpacingFromArray = "";
    if(confirmFloatingBoxIsOpenTF) {
      if(JSFUNC.is_array(s_confirmingMenuItemObj.confirmMessage)) {
        confirmMessageWithSpacingFromArray = s_confirmingMenuItemObj.confirmMessage.map((m_messageLine) => (
          <div className="medBottomMargin">
            {m_messageLine}
          </div>
        ));
      }
      else {
        confirmMessageWithSpacingFromArray = (
          <div>
            {s_confirmingMenuItemObj.confirmMessage}
          </div>
        );
      }
    }

    return(
      <>
        <LibraryReact.InteractiveDiv
          p_class="positionRelative"
          p_styleObj={{height:"3em", width:"1.5em", borderRadius:"1em"}}
          p_tabIndex={p_tabIndex}
          f_onClick={((confirmFloatingBoxIsOpenTF) ? (undefined) : (this.ontoggle_menu_open_closed))}
          f_onKeyDownEnter={((confirmFloatingBoxIsOpenTF) ? (undefined) : (this.ontoggle_menu_open_closed))}
          f_onKeyDownEsc={((confirmFloatingBoxIsOpenTF) ? (undefined) : (this.onclose_menu))}>
          <div className="displayFlexColumnHcVc overflowHidden deleteMenuLauncherHover cursorPointer" style={{height:"3em", width:"1.5em", borderRadius:"1em"}}>
            <font className={"font15 " + p_dotsFontClass} style={{transform:"rotate(90deg)", marginLeft:"0.5em"}}>
              {"..."}
            </font>
          </div>
          {(s_menuOpenTF) &&
            <LibraryReact.DivWithOffClick
              p_offClickIncludesParentTF={true}
              p_preventClickDefaultAndPropagationTF={true}
              p_class="positionAbsolute border1bbb bgWhite"
              p_styleObj={{zIndex:999, top:"3em", right:0, width:menuWidth, borderRadius:"0.3em"}}
              f_offClick={this.onclose_menu}>
              {p_menuItemsArrayOfObjs.map((m_menuItemObj) =>
                <VerticalDotsMenuItem
                  p_menuItemObj={m_menuItemObj}
                  f_onSelectMenuItem={this.onselect_menu_item}
                />
              )}
            </LibraryReact.DivWithOffClick>
          }
        </LibraryReact.InteractiveDiv>
        {(confirmFloatingBoxIsOpenTF) &&
          <ConfirmBox
            p_type={s_confirmingMenuItemObj.confirmType}
            p_title={s_confirmingMenuItemObj.confirmTitle}
            p_button1Name={s_confirmingMenuItemObj.confirmButton1Name}
            p_button1Type={s_confirmingMenuItemObj.confirmButton1Type}
            p_button2Name={s_confirmingMenuItemObj.button2Name}
            p_boxShadowColor={s_confirmingMenuItemObj.boxShadowColor}
            p_textareaHeight={s_confirmingMenuItemObj.textareaHeight}
            p_textInitialValue={s_confirmingMenuItemObj.confirmTextInitialValue}
            p_textMustBeFilledOutTF={s_confirmingMenuItemObj.confirmTextMustBeFilledOutTF}
            p_textMustBeChangedForSaveTFU={s_confirmingMenuItemObj.confirmTextMustBeChangedForSaveTFU}
            f_onClickConfirm={this.onclick_confirm_button_in_floating_box}
            f_onClickCancel={this.onclose_confirm_box}>
            {confirmMessageWithSpacingFromArray}
          </ConfirmBox>
        }
      </>
    );
  }
}


class VerticalDotsMenuItem extends Component { //props: p_menuItemObj, f_onSelectMenuItem
  onclick_menu_item = () => {
    if(JSFUNC.is_function(this.props.f_onSelectMenuItem)) {
      this.props.f_onSelectMenuItem(this.props.p_menuItemObj);
    }
  }

  render() {
    const menuItemObj = this.props.p_menuItemObj;
    const onClickMenuItemOrConfirmButtonFunctionIsValidTF = (JSFUNC.is_function(menuItemObj.functionOnClickMenuItem) || JSFUNC.is_function(menuItemObj.functionOnClickConfirmButton));
    return(
      <LibraryReact.InteractiveDiv
        p_class={"smallFullPad " + ((onClickMenuItemOrConfirmButtonFunctionIsValidTF) ? ("hoverRed cursorPointer") : ("fontItalic fontTextLighter cursorNotAllowed"))}
        f_onClick={((onClickMenuItemOrConfirmButtonFunctionIsValidTF) ? (this.onclick_menu_item) : (undefined))}>
        {menuItemObj.displayName}
      </LibraryReact.InteractiveDiv>
    );
  }
}











//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Drag/Drop to reorder for a table with rows of items
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export class CEDragToResortItemWithinDbTbl extends Component { //props:
  //p_canResortTF, p_uniqueString, p_itemID, p_itemSort, p_itemSortColumnWidth, p_itemSortNumberContainerClass, p_itemSortNumberFontClass, p_isOnlyItemTF, p_isLastItemTF, 
  //p_outerPadClass, p_itemClass, p_itemStyleObj, p_lastItemExtraDropZoneHeight, p_dragEntireItemTrueOnlySortNumberFalse, 
  //p_tblName, p_tblSortFieldDbName, p_filterFieldNamesArray, p_filterValuesArray,
  //f_onDropForeignItem, children
  ondrop_item_resort_items_in_tbl = (i_draggedItemID, i_droppedOnItemID) => {
    const p_tblName = this.props.p_tblName;
    const p_tblSortFieldDbName = this.props.p_tblSortFieldDbName;
    const p_filterFieldNamesArray = JSFUNC.prop_value(this.props.p_filterFieldNamesArray, []);
    const p_filterValuesArray = JSFUNC.prop_value(this.props.p_filterValuesArray, []);

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - CEDragToResortItemWithinDbTbl", "ondrop_item_resort_items_in_tbl", ["i_draggedItemID", "i_droppedOnItemID"], [i_draggedItemID, i_droppedOnItemID]);
    const C_CallPhpTblUIDInsertFfsRecord = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUIDInsertFfsRecord.add_resort(p_tblName, p_tblSortFieldDbName, p_filterFieldNamesArray, p_filterValuesArray, i_draggedItemID, i_droppedOnItemID);
    C_CallPhpTblUIDInsertFfsRecord.execute();
  }

  render() {
    return(
      <CEDragToResortItem
        p_canResortTF={this.props.p_canResortTF}
        p_uniqueString={this.props.p_uniqueString}
        p_itemID={this.props.p_itemID}
        p_itemSort={this.props.p_itemSort}
        p_itemSortColumnWidth={this.props.p_itemSortColumnWidth}
        p_itemSortNumberContainerClass={this.props.p_itemSortNumberContainerClass}
        p_itemSortNumberFontClass={this.props.p_itemSortNumberFontClass}
        p_isOnlyItemTF={this.props.p_isOnlyItemTF}
        p_isLastItemTF={this.props.p_isLastItemTF}
        p_outerPadClass={this.props.p_outerPadClass}
        p_itemClass={this.props.p_itemClass}
        p_itemStyleObj={this.props.p_itemStyleObj}
        p_lastItemExtraDropZoneHeight={this.props.p_lastItemExtraDropZoneHeight}
        p_dragEntireItemTrueOnlySortNumberFalse={this.props.p_dragEntireItemTrueOnlySortNumberFalse}
        f_onDropResortMatchingItem={this.ondrop_item_resort_items_in_tbl}
        f_onDropResortForeignItem={this.props.f_onDropForeignItem}>
        {this.props.children}
      </CEDragToResortItem>
    );
  }
}



export class CEDragToResortItemWithinCommaListWithinDbTbl extends Component { ////props:
  //p_canResortTF, p_uniqueString, p_itemIndex, p_itemID, p_itemSortColumnWidth, p_itemSortNumberContainerClass, p_itemSortNumberFontClass, p_isOnlyItemTF, p_isLastItemTF,
  //p_outerPadClass, p_itemClass, p_itemStyleObj, p_lastItemExtraDropZoneHeight, p_dragEntireItemTrueOnlySortNumberFalse, 
  //p_tblName, p_tblRowID, p_tblCommaListColumnName, p_commaList, children
  ondrop_item_resort_items_in_comma_list_within_tbl = (i_draggedItemIndexPlus1, i_droppedOnItemIndexPlus1) => {
    const p_tblName = this.props.p_tblName;
    const p_tblRowID = this.props.p_tblRowID;
    const p_tblCommaListColumnName = this.props.p_tblCommaListColumnName;
    const p_commaList = this.props.p_commaList;

    const idsArray = JSFUNC.convert_comma_list_to_int_array(p_commaList);
    const numInputIDs = idsArray.length;

    //if the input i_idsComma is "" or a single value like "15", return that input comma list, as there is nothing that can be resorted differently
    if(numInputIDs >= 2) {
      var arrayOfObjsWithIDAndSort = [];
      for(let i = 0; i < numInputIDs; i++) {
        arrayOfObjsWithIDAndSort.push({
          id: (i + 1),
          sort: (i + 1)
        });
      }

      //the last item in a library resort list has a blank droppable space below it that always has an id value of -1, if -1 is the droppedOnItemID, then this dragged item is to be placed at the very bottom of the list
      const resortAboveTrueBottomFalseNoneUndefined = (i_droppedOnItemIndexPlus1 > 0);

      const resortArrayOfObjs = JSFUNC.drag_drop_resort_arrayOfObjs(arrayOfObjsWithIDAndSort, "id", "sort", i_draggedItemIndexPlus1, i_droppedOnItemIndexPlus1, resortAboveTrueBottomFalseNoneUndefined);
      const resortItemIndicesPlus1Array = JSFUNC.get_column_vector_from_arrayOfObjs(resortArrayOfObjs, "id");

      var resortIDsArray = [];
      for(let i = 0; i < numInputIDs; i++) {
        var resortItemIndexPlus1 = resortItemIndicesPlus1Array[i];
        var resortItemIndex = (resortItemIndexPlus1 - 1);
        var resortID = idsArray[resortItemIndex];
        resortIDsArray.push(resortID);
      }
      const resortIDsComma = JSFUNC.convert_array_to_comma_list(resortIDsArray);

      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - CEDragToResortItemWithinCommaListWithinDbTbl", "ondrop_item_resort_items_in_comma_list_within_tbl", ["i_draggedItemIndexPlus1", "i_droppedOnItemIndexPlus1"], [i_draggedItemIndexPlus1, i_droppedOnItemIndexPlus1]);
      const C_CallPhpTblUIDInsertFfsRecord = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUIDInsertFfsRecord.add_update(p_tblName, p_tblRowID, p_tblCommaListColumnName, resortIDsComma, "s");
      C_CallPhpTblUIDInsertFfsRecord.execute();
    }
  }

  render() {
    const itemIndexPlus1 = (this.props.p_itemIndex + 1); //create a fake 1-N id for the comma list using the itemIndex + 1

    return(
      <CEDragToResortItem
        p_canResortTF={this.props.p_canResortTF}
        p_uniqueString={this.props.p_uniqueString}
        p_itemID={itemIndexPlus1}
        p_itemSort={itemIndexPlus1}
        p_itemSortColumnWidth={this.props.p_itemSortColumnWidth}
        p_itemSortNumberContainerClass={this.props.p_itemSortNumberContainerClass}
        p_itemSortNumberFontClass={this.props.p_itemSortNumberFontClass}
        p_isOnlyItemTF={this.props.p_isOnlyItemTF}
        p_isLastItemTF={this.props.p_isLastItemTF}
        p_outerPadClass={this.props.p_outerPadClass}
        p_itemClass={this.props.p_itemClass}
        p_itemStyleObj={this.props.p_itemStyleObj}
        p_lastItemExtraDropZoneHeight={this.props.p_lastItemExtraDropZoneHeight}
        p_dragEntireItemTrueOnlySortNumberFalse={this.props.p_dragEntireItemTrueOnlySortNumberFalse}
        f_onDropResortMatchingItem={this.ondrop_item_resort_items_in_comma_list_within_tbl}>
        {this.props.children}
      </CEDragToResortItem>
    );
  }
}



export class CEDragToResortItem extends Component { //props: 
  //p_canResortTF, p_uniqueString, p_itemID, p_itemSort, p_itemSortColumnWidth, p_itemSortNumberContainerClass, p_itemSortNumberFontClass, p_isOnlyItemTF, p_isLastItemTF, 
  //p_outerPadClass, p_itemClass, p_itemStyleObj, p_lastItemExtraDropZoneHeight, p_dragEntireItemTrueOnlySortNumberFalse
  //f_onDropResortMatchingItem(i_draggedItemID, i_droppedOnItemID), 
  //f_onDropResortForeignItem(i_foreignUniqueString, i_foreignDraggedItemID, i_droppedOnItemID), 
  //children
  //
  //two drag/drop shells are nested so that the outer drop shell with padding only is an entire drop zone (otherwise the gaps between the items would not be droppable)
  //p_canResortTF
  //  - true (default)  sort number area on left side of item is grabbable and can be dragged/dropped onto other rows to resort them among the list (if p_itemSort is undefined, there is no area to grab and the item cannot be resorted)
  //  - false           sort number area on left side is not grabbable so item cannot be resorted
  //p_itemSort
  //  - 1-N             sort number displayed in a special area on the left side of the item that is grabbable to resort
  //  - undefined       this left side area is not drawn, the item is basically a regular div containing the passed children
  //f_onDropResortMatchingItem has 2 inputs when an onDrop occurs f_onDropResortMatchingItem(i_draggedItemID, i_droppedOnItemID)   i_droppedOnItemID is -1 if the item was dropped in the very bottom 'extra' row to sort the item to the bottom of the list, all other drops assume the dragged item will be placed above the dropped on item

  ondrop_matching_item = (i_draggedItemID, event) => {
    const p_itemID = this.props.p_itemID;
    if(JSFUNC.is_function(this.props.f_onDropResortMatchingItem)) {
      this.props.f_onDropResortMatchingItem(i_draggedItemID, p_itemID);
    }
  }

  ondrop_foreign_item = (i_foreignUniqueString, i_foreignDraggedItemID, event) => {
    const p_itemID = this.props.p_itemID;
    if(JSFUNC.is_function(this.props.f_onDropResortForeignItem)) {
      this.props.f_onDropResortForeignItem(i_foreignUniqueString, i_foreignDraggedItemID, p_itemID);
    }
  }

  ondrop_matching_item_in_last_item_extra_drop_zone = (i_draggedItemID, event) => {
    if(JSFUNC.is_function(this.props.f_onDropResortMatchingItem)) {
      this.props.f_onDropResortMatchingItem(i_draggedItemID, -1); //pass -1 for the droppedOnItemID to resort where the dragged item becomes the bottom item
    }
  }

  ondrop_foreign_item_in_last_item_extra_drop_zone = (i_foreignUniqueString, i_foreignDraggedItemID, event) => {
    if(JSFUNC.is_function(this.props.f_onDropResortForeignItem)) {
      this.props.f_onDropResortForeignItem(i_foreignUniqueString, i_foreignDraggedItemID, -1); //pass -1 for the droppedOnItemID to resort where the dragged item becomes the bottom item
    }
  }

  render() {
    const p_canResortTF = JSFUNC.prop_value(this.props.p_canResortTF, true);
    const p_uniqueString = JSFUNC.prop_value(this.props.p_uniqueString, "dragtoresortitem");
    const p_itemID = this.props.p_itemID;
    const p_itemSort = this.props.p_itemSort;
    const p_itemSortColumnWidth = JSFUNC.prop_value(this.props.p_itemSortColumnWidth, "2.5em");
    const p_itemSortNumberContainerClass = JSFUNC.prop_value(this.props.p_itemSortNumberContainerClass, "");
    const p_itemSortNumberFontClass = this.props.p_itemSortNumberFontClass;
    const p_isOnlyItemTF = JSFUNC.prop_value(this.props.p_isOnlyItemTF, false); //not able to drag or drop when this is the only item in the list
    const p_isLastItemTF = JSFUNC.prop_value(this.props.p_isLastItemTF, false); //if this is the last item, provide a drop zone below the item to drag another item below this last one (default drag onto an item places it above it, so placing an item last would be impossible without this)
    const p_outerPadClass = JSFUNC.prop_value(this.props.p_outerPadClass, "");
    const p_itemClass = JSFUNC.prop_value(this.props.p_itemClass, "");
    const p_itemStyleObj = this.props.p_itemStyleObj;
    const p_lastItemExtraDropZoneHeight = this.props.p_lastItemExtraDropZoneHeight;
    const p_dragEntireItemTrueOnlySortNumberFalse = JSFUNC.prop_value(this.props.p_dragEntireItemTrueOnlySortNumberFalse, true);

    const itemHasSortNumberTF = (p_itemSort !== undefined);
    const canDragDropItemTF = (p_canResortTF && itemHasSortNumberTF && !p_isOnlyItemTF);

    const resortRowDragOverStyleObj = {border:"solid 2px #0ae", background:"rgba(0,0,0,0.3)"};

    var belowLastItemDropZoneComponent = null;
    if(p_canResortTF && itemHasSortNumberTF && p_isLastItemTF && (p_lastItemExtraDropZoneHeight !== undefined)) {
      belowLastItemDropZoneComponent = (
        <CE3Drag3Drop3Shell3
          p_uniqueString={p_uniqueString}
          p_itemID={-1}
          p_draggableTF={false}
          p_droppableTF={true}
          p_dropZoneIsInvisibleOverlayTF={true}
          p_dropZoneOversizeWidthEm={undefined}
          p_class={p_outerPadClass}
          p_dragOverStyleObj={resortRowDragOverStyleObj}
          f_onDropMatchingItem={this.ondrop_matching_item_in_last_item_extra_drop_zone}
          f_onDropForeignItem={this.ondrop_foreign_item_in_last_item_extra_drop_zone}>
          <div style={{height:p_lastItemExtraDropZoneHeight}} />
        </CE3Drag3Drop3Shell3>
      );
    }
    
    //drag grab is in sort number box, but visuals and drop zones are full size of the item container
    if(p_dragEntireItemTrueOnlySortNumberFalse) {
      return(
        <>
          <CE3Drag3Drop3Shell3
            p_uniqueString={p_uniqueString}
            p_itemID={p_itemID}
            p_draggableTF={canDragDropItemTF}
            p_droppableTF={canDragDropItemTF}
            p_dropZoneIsInvisibleOverlayTF={true}
            p_dropZoneOversizeWidthEm={undefined}
            p_class={p_outerPadClass}
            p_styleObj={undefined}
            p_title={undefined}
            p_dragOverClass={undefined}
            p_dragOverStyleObj={resortRowDragOverStyleObj}
            p_fileUploadIsProcessingTF={false}
            f_isDragOverTF={undefined}
            f_onDropMatchingItem={this.ondrop_matching_item}
            f_onDropForeignItem={this.ondrop_foreign_item}
            f_onDropFiles={undefined}
            f_onClick={undefined}>
            <div
              className={"displayFlexRow " + p_itemClass}
              style={p_itemStyleObj}>
              {(itemHasSortNumberTF) &&
                <div className={"flex00a displayFlexColumnHcVc " + p_itemSortNumberContainerClass + " " + ((canDragDropItemTF) ? ("cursorGrab") : (""))} style={{flexBasis:p_itemSortColumnWidth, borderRight:"solid 1px #ccc"}}>
                  <font className={p_itemSortNumberFontClass}>
                    {p_itemSort}
                  </font>
                </div>
              }
              {this.props.children}
            </div>
          </CE3Drag3Drop3Shell3>
          {belowLastItemDropZoneComponent}
        </>
      );
    }
    
    //drag/drop zones and visuals restricted to only sort number box on left side of item (this allows further drag items to be inside of the 'children' of this item and still work)
    return(
      <>
        <div className={p_outerPadClass}>
          <div className={"displayFlexRow " + p_itemClass} style={p_itemStyleObj}>
            <div className="flex00a displayFlexColumn" style={{flexBasis:p_itemSortColumnWidth, borderRight:"solid 1px #ccc"}}>
              <CE3Drag3Drop3Shell3
                p_uniqueString={p_uniqueString}
                p_itemID={p_itemID}
                p_draggableTF={canDragDropItemTF}
                p_droppableTF={canDragDropItemTF}
                p_dropZoneIsInvisibleOverlayTF={true}
                p_dropZoneOversizeWidthEm={undefined}
                p_class="flex11a displayFlexColumn"
                p_styleObj={undefined}
                p_title={undefined}
                p_dragOverClass={undefined}
                p_dragOverStyleObj={resortRowDragOverStyleObj}
                p_fileUploadIsProcessingTF={false}
                f_isDragOverTF={undefined}
                f_onDropMatchingItem={this.ondrop_matching_item}
                f_onDropForeignItem={this.ondrop_foreign_item}
                f_onDropFiles={undefined}
                f_onClick={undefined}>
                {(itemHasSortNumberTF) &&
                  <div className={"flex11a displayFlexColumnHcVc " + p_itemSortNumberContainerClass + " " + ((canDragDropItemTF) ? ("cursorGrab") : (""))}>
                    <font className={p_itemSortNumberFontClass}>
                      {p_itemSort}
                    </font>
                  </div>
                }
              </CE3Drag3Drop3Shell3>
            </div>
            {this.props.children}
          </div>
        </div>
        {belowLastItemDropZoneComponent}
      </>
    );

    //overlay sibling elements solutions
    /*return(
      <>
        <div className={p_outerPadClass}>
          <div className={"positionRelative displayFlexRow " + p_itemClass} style={p_itemStyleObj}>
            <div className="flex00a" style={{flexBasis:p_itemSortColumnWidth}} />
            {this.props.children}
            <CE3Drag3Drop3Shell3
              p_uniqueString={p_uniqueString}
              p_itemID={p_itemID}
              p_draggableTF={canDragDropItemTF}
              p_droppableTF={canDragDropItemTF}
              p_dropZoneIsInvisibleOverlayTF={true}
              p_dropZoneOversizeWidthEm={undefined}
              p_class="positionAbsolute displayFlexRow"
              p_styleObj={{width:"100%", height:"100%"}}
              p_title={undefined}
              p_dragOverClass={undefined}
              p_dragOverStyleObj={resortRowDragOverStyleObj}
              p_fileUploadIsProcessingTF={false}
              f_isDragOverTF={undefined}
              f_onDropMatchingItem={this.ondrop_matching_item}
              f_onDropForeignItem={this.ondrop_foreign_item}
              f_onDropFiles={undefined}
              f_onClick={undefined}>
              {(itemHasSortNumberTF) &&
                <div
                  className={"flex00a displayFlexColumnHcVc " + p_itemSortNumberContainerClass + " " + ((canDragDropItemTF) ? ("cursorGrab") : (""))}
                  style={{flexBasis:p_itemSortColumnWidth, borderRight:"solid 1px #ccc"}}>
                  <font className={p_itemSortNumberFontClass}>
                    {p_itemSort}
                  </font>
                </div>
              }
              <div className="flex11a" />
            </CE3Drag3Drop3Shell3>
          </div>
        </div>
        {(p_canResortTF && itemHasSortNumberTF && p_isLastItemTF && (p_lastItemExtraDropZoneHeight !== undefined)) &&
          <CE3Drag3Drop3Shell3
            p_uniqueString={p_uniqueString}
            p_itemID={-1}
            p_draggableTF={false}
            p_droppableTF={true}
            p_dropZoneIsInvisibleOverlayTF={true}
            p_dropZoneOversizeWidthEm={undefined}
            p_class={p_outerPadClass}
            p_dragOverStyleObj={resortRowDragOverStyleObj}
            f_onDropMatchingItem={this.ondrop_matching_item_in_last_item_extra_drop_zone}
            f_onDropForeignItem={this.ondrop_foreign_item_in_last_item_extra_drop_zone}>
            <div style={{height:p_lastItemExtraDropZoneHeight}} />
          </CE3Drag3Drop3Shell3>
        }
      </>
    );*/
  }
}



export const CE3Drag3Drop3Shell3 = inject("CaptureExecMobx")(observer(
class CE3Drag3Drop3Shell3 extends Component { //props: 
  //p_uniqueString, p_itemID, p_draggableTF, p_droppableTF, p_dropZoneIsInvisibleOverlayTF, p_dropZoneOversizeWidthEm, p_class, p_styleObj, p_title, p_dragOverClass, p_dragOverStyleObj, p_fileUploadIsProcessingTF, 
  //f_isDragOverTF(i_isDragOverTF), 
  //f_onDropMatchingItem(i_droppedItemID, event), 
  //f_onDropForeignItem(i_droppedItemUniqueString, i_droppedItemID, event), 
  //f_onDropFiles(i_dataTransferFilesArray, event), 
  //f_onClick(event), 
  //children

  //intercept drag start and end functions to add to the process updating the CaptureExec 'dragger' ids, indicating that any item on the entire website is currently being dragged by the user's mouse
  ondragstart_item = (i_uniqueString, i_itemID, event) => {
    this.props.CaptureExecMobx.a_set_captureexec_dragged_item_obj(i_uniqueString, i_itemID, event.clientX, event.clientY);
  }

  ondragend_item = (i_uniqueString, i_itemID, event) => {
    this.props.CaptureExecMobx.a_unset_captureexec_dragged_item_obj();
  }

  ondragenter_dropzone = (i_dropZoneUniqueString, i_dropZoneItemID, event) => {
    this.props.CaptureExecMobx.a_set_captureexec_dragged_item_currently_over_dropzone_obj(i_dropZoneUniqueString, i_dropZoneItemID);
  }

  ondrop_matching_item = (i_droppedItemID, event) => {
    this.props.f_onDropMatchingItem(i_droppedItemID, event);
  }

  ondrop_foreign_item = (i_droppedItemUniqueString, i_droppedItemID, event) => {
    this.props.f_onDropForeignItem(i_droppedItemUniqueString, i_droppedItemID, event);
  }

  ondrop_files = (i_dataTransferFilesArray, event) => {
    this.props.f_onDropFiles(i_dataTransferFilesArray, event);
  }

  ondrop_html_or_computer_file_onto_ce_drag_drop_shell = () => {
    this.props.CaptureExecMobx.a_unset_captureexec_dragged_item_currently_over_dropzone_obj();
    this.props.CaptureExecMobx.a_set_any_file_or_ce_item_dragged_over_any_part_of_website_tf(false);
  }

  render() {
    const p_uniqueString = this.props.p_uniqueString;
    const p_itemID = this.props.p_itemID;
    const p_draggableTF = this.props.p_draggableTF;
    const p_droppableTF = this.props.p_droppableTF;
    const p_dropZoneIsInvisibleOverlayTF = this.props.p_dropZoneIsInvisibleOverlayTF;
    const p_dropZoneOversizeWidthEm = this.props.p_dropZoneOversizeWidthEm;
    const p_class = this.props.p_class;
    const p_styleObj = this.props.p_styleObj;
    const p_title = this.props.p_title;
    const p_dragOverClass = this.props.p_dragOverClass;
    const p_dragOverStyleObj = this.props.p_dragOverStyleObj;
    const p_fileUploadIsProcessingTF = this.props.p_fileUploadIsProcessingTF;
    
    const o_ceDraggedItemObjOrUndefined = this.props.CaptureExecMobx.o_ceDraggedItemObjOrUndefined;
    const o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined = this.props.CaptureExecMobx.o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined;

    const fOnDropMatchingItemIsFunctionTF = JSFUNC.is_function(this.props.f_onDropMatchingItem);
    const fOnDropForeignItemIsFunctionTF = JSFUNC.is_function(this.props.f_onDropForeignItem);
    const fOnDropFilesIsFunctionTF = JSFUNC.is_function(this.props.f_onDropFiles);

    var userCurrentlyDraggingItemUniqueStringOrUndefined = undefined;
    if(o_ceDraggedItemObjOrUndefined !== undefined) {
      userCurrentlyDraggingItemUniqueStringOrUndefined = o_ceDraggedItemObjOrUndefined.uniqueString;
    }

    return(
      <LibraryReact.Drag2Drop2Shell2
        p_uniqueString={p_uniqueString}
        p_itemID={p_itemID}
        p_userCurrentlyDraggingItemUniqueStringOrUndefined={userCurrentlyDraggingItemUniqueStringOrUndefined}
        p_userCurrentlyDraggingItemOverDropZoneObjOrUndefined={o_ceDraggedItemCurrentlyOverDropZoneObjOrUndefined}
        p_draggableTF={p_draggableTF}
        p_droppableTF={p_droppableTF}
        p_dropZoneIsInvisibleOverlayTF={p_dropZoneIsInvisibleOverlayTF}
        p_dropZoneOversizeWidthEm={p_dropZoneOversizeWidthEm}
        p_class={p_class}
        p_styleObj={p_styleObj}
        p_title={p_title}
        p_dragOverClass={p_dragOverClass}
        p_dragOverStyleObj={p_dragOverStyleObj}
        p_fileUploadIsProcessingTF={p_fileUploadIsProcessingTF}
        f_onDragStart={this.ondragstart_item}
        f_onDragEnd={this.ondragend_item}
        f_onDragEnterDropZone={this.ondragenter_dropzone}
        f_isDragOverTF={this.props.f_isDragOverTF}
        f_onDropMatchingItem={((fOnDropMatchingItemIsFunctionTF) ? (this.ondrop_matching_item) : (undefined))}
        f_onDropForeignItem={((fOnDropForeignItemIsFunctionTF) ? (this.ondrop_foreign_item) : (undefined))}
        f_onDropFiles={((fOnDropFilesIsFunctionTF) ? (this.ondrop_files) : (undefined))}
        f_onDrop={this.ondrop_html_or_computer_file_onto_ce_drag_drop_shell}
        f_onClick={this.props.f_onClick}>
        {this.props.children}
      </LibraryReact.Drag2Drop2Shell2>
    );
  }
}));



export const CEResortItemWithinDbTblFromEditSaveCancelSelectSortNumber = inject("DatabaseMobx")(observer(
class CEResortItemWithinDbTblFromEditSaveCancelSelectSortNumber extends Component { //props: p_itemID, p_allItemsArrayOfObjs, p_tblName, p_tblSortFieldDbName, p_filterFieldNamesArray, p_filterValuesArray, p_ceEditItemString, p_fieldDisplayName, p_containerClass, p_fieldClass, p_fieldWidth
  onsave_changed_new_sort_number = (i_selectedSortNumber) => {
    const p_itemID = this.props.p_itemID;
    const p_allItemsArrayOfObjs = this.props.p_allItemsArrayOfObjs;
    const p_tblName = this.props.p_tblName;
    const p_tblSortFieldDbName = this.props.p_tblSortFieldDbName;
    const p_filterFieldNamesArray = JSFUNC.prop_value(this.props.p_filterFieldNamesArray, []);
    const p_filterValuesArray = JSFUNC.prop_value(this.props.p_filterValuesArray, []);

    var oldItemSort = undefined;
    var selectedSortPositionItemID = undefined;
    for(let itemObj of p_allItemsArrayOfObjs) {
      if(itemObj.id === p_itemID) {
        oldItemSort = itemObj[p_tblSortFieldDbName];
      }

      if(itemObj[p_tblSortFieldDbName] === i_selectedSortNumber) {
        selectedSortPositionItemID = itemObj.id;
      }
    }

    if(i_selectedSortNumber !== oldItemSort) { //no need to update the database if the same sort position was chosen for the item in question
      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - CEResortItemWithinDbTblFromEditSaveCancelSelectSortNumber", "onsave_changed_new_sort_number", ["i_selectedSortNumber"], [i_selectedSortNumber]);
      const C_CallPhpTblUIDInsertFfsRecord = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const draggedItemID = p_itemID;
      const droppedOnItemID = selectedSortPositionItemID;
      C_CallPhpTblUIDInsertFfsRecord.add_resort(p_tblName, p_tblSortFieldDbName, p_filterFieldNamesArray, p_filterValuesArray, draggedItemID, droppedOnItemID);

      C_CallPhpTblUIDInsertFfsRecord.execute();
    }
  }

  render() {
    const p_itemID = this.props.p_itemID;
    const p_allItemsArrayOfObjs = this.props.p_allItemsArrayOfObjs;
    const p_tblName = this.props.p_tblName;
    const p_tblSortFieldDbName = this.props.p_tblSortFieldDbName;
    const p_filterFieldNamesArray = this.props.p_filterFieldNamesArray;
    const p_filterValuesArray = this.props.p_filterValuesArray;
    const p_ceEditItemString = this.props.p_ceEditItemString;
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_containerClass = this.props.p_containerClass;
    const p_fieldClass = this.props.p_fieldClass;
    const p_fieldWidth = this.props.p_fieldWidth;

    var valueArray = [];
    var displayArray = [];
    for(let itemObj of p_allItemsArrayOfObjs) {
      valueArray.push(itemObj.id);
      displayArray.push(itemObj[p_tblSortFieldDbName]);
    }
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Sort Number", valueArray, false, displayArray, swsOptionsObj);
    const selectNewSortNumberFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

    return(
      <CaptureExecFieldEditSaveCancel
        p_ceEditItemString={p_ceEditItemString}
        p_fieldDisplayName={p_fieldDisplayName}
        p_fieldTypeObj={selectNewSortNumberFieldTypeObj}
        p_valueRaw={p_itemID}
        p_valueIsEditableTFU={true}
        p_containerClass={p_containerClass}
        p_fieldClass={p_fieldClass}
        p_fieldWidth={p_fieldWidth}
        f_onSaveChanged={this.onsave_changed_new_sort_number}
      />
    );
  }
}));













export class ColumnHeaderRow extends Component { //props: p_flex01Array, p_flexBasisEmArray, p_columnKeysArray, p_columnDbNamesArray, p_columnDisplayNamesArray, f_onClickHeader
  onclick_header = () => {

  }

  render() {
    const p_flex01Array = this.props.p_flex01Array;
    const p_flexBasisEmArray = this.props.p_flexBasisEmArray;
    const p_columnKeysArray = this.props.p_columnKeysArray;
    const p_columnDbNamesArray = this.props.p_columnDbNamesArray;
    const p_columnDisplayNamesArray = this.props.p_columnDisplayNamesArray;

    return(
      <div className="flex00a displayFlexRowVc textCenter fontItalic fontBold fontTextLighter" style={{flexBasis:"2em", borderBottom:"solid 1px #aaa"}}>
        {p_columnDisplayNamesArray.map((m_columnDisplayName, m_index) =>
          <ColumnHeader
            p_flex01={p_flex01Array[m_index]}
            p_flexBasisEm={p_flexBasisEmArray[m_index]}
            p_columnKey={p_columnKeysArray[m_index]}
            p_columnDbName={p_columnDbNamesArray[m_index]}
            p_columnDisplayName={m_columnDisplayName}
            p_firstColumnTF={(m_index === 0)}
            f_onClick={this.props.f_onClickHeader}
          />
        )}
      </div>
    );
  }
}

class ColumnHeader extends Component { //props: p_flex01, p_flexBasisEm, p_columnKey, p_columnDbName, p_columnDisplayName, p_firstColumnTF, f_onClick
  onclick_header = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(this.props.p_columnDbName);
    }
  }

  render() {
    const p_flex01 = this.props.p_flex01;
    const p_flexBasisEm = this.props.p_flexBasisEm;
    const p_columnKey = this.props.p_columnKey;
    const p_columnDbName = this.props.p_columnDbName;
    const p_columnDisplayName = this.props.p_columnDisplayName;
    const p_firstColumnTF = this.props.p_firstColumnTF;

    const hasOnClickTF = JSFUNC.is_function(this.props.f_onClick);

    return(
      <div
        key={p_columnKey}
        className={((p_flex01 === 1) ? ("flex11a") : ("flex00a")) + " " + ((p_firstColumnTF) ? ("") : ("borderL1ddd")) + " lrPad " + ((hasOnClickTF) ? ("cursorPointer") : (""))}
        style={{flexBasis:p_flexBasisEm + "em"}}
        onClick={((hasOnClickTF) ? (this.onclick_header) : (undefined))}>
        <LibraryReact.Nowrap>
          {p_columnDisplayName}
        </LibraryReact.Nowrap>
      </div>
    );
  }
}

export class DataRow extends Component { //props: p_itemID, p_flex01Array, p_flexBasisEmArray, p_columnKeysArray, p_dataArray, p_alignArray, p_class, p_heightLimit, p_title, f_onClick
  onclick_data_row = () => {
    this.props.f_onClick(this.props.p_itemID);
  }

  render() {
    const p_itemID = this.props.p_itemID;
    const p_flex01Array = this.props.p_flex01Array;
    const p_flexBasisEmArray = this.props.p_flexBasisEmArray;
    const p_columnKeysArray = this.props.p_columnKeysArray;
    const p_dataArray = this.props.p_dataArray;
    const p_alignArray = this.props.p_alignArray;
    const p_class = JSFUNC.prop_value(this.props.p_class, "");
    const p_heightLimit = this.props.p_heightLimit;
    const p_title = this.props.p_title;

    var alignArray = [];
    for(let a = 0; a < p_columnKeysArray.length; a++) {
      if(!p_alignArray) {
        alignArray.push("");
      }
      else if(p_alignArray[a] === "center") {
        alignArray.push("textCenter");
      }
      else if(p_alignArray[a] === "right") {
        alignArray.push("textRight");
      }
      else {
        alignArray.push("");
      }
    }

    var rowStyleObj = {borderBottom:"solid 1px #eee"};
    if(p_heightLimit) {
      rowStyleObj.maxHeight = p_heightLimit;
      rowStyleObj.overflow = "hidden";
    }

    const hasOnClickTF = JSFUNC.is_function(this.props.f_onClick);

    return(
      <div
        className={"displayFlexRow " + ((hasOnClickTF) ? ("hoverLighterBlueGradient cursorPointer ") : ("")) + p_class}
        style={rowStyleObj}
        title={p_title}
        onClick={((hasOnClickTF) ? (this.onclick_data_row) : (undefined))}>
        {p_columnKeysArray.map((m_columnKey, m_index) =>
          <div
            key={m_columnKey + "_" + p_itemID}
            className={((p_flex01Array[m_index] === 1) ? ("flex11a") : ("flex00a")) + " smallFullPad breakWord " + alignArray[m_index]}
            style={{flexBasis:p_flexBasisEmArray[m_index] + "em"}}>
            {p_dataArray[m_index]}
          </div>
        )}
      </div>
    );
  }
}







//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Upload single .csv file to read data columns into local variables
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export class ImportDataFromCsvFileButtonWithFloatingBoxInterface extends Component {
  //props: p_buttonLabel, p_trblFlag, p_importTitle, p_instructionsHtmlOrUndefined, p_existingValuesArray, p_maxNumColumnsInCsvOrUndefined, p_maxNumColumnsToShow, p_combineAll2DDataInto1DTF, p_markAndPreventDuplicateEntriesTF,
  //f_onClickImportCsvData(i_csvDataArrayOrArrayOfArrays, i_runThisFunctionUponSuccess, i_runThisFunctionUponError)
  //f_onOpenCloseFloatingBoxTF(i_openTrueClosedFalse)
  constructor(props) {
    super(props);

    if(JSFUNC.is_function(this.props.f_onOpenCloseFloatingBoxTF)) {
      this.props.f_onOpenCloseFloatingBoxTF(false);
    }

    this.state = {
      s_importCsvFloatingBoxIsOpenTF: false
    }
  }

  onclick_open_import_csv_floating_box = () => {
    this.setState({s_importCsvFloatingBoxIsOpenTF:true});

    if(JSFUNC.is_function(this.props.f_onOpenCloseFloatingBoxTF)) {
      this.props.f_onOpenCloseFloatingBoxTF(true);
    }
  }

  onclick_close_import_csv_floating_box = () => {
    this.setState({s_importCsvFloatingBoxIsOpenTF:false});

    if(JSFUNC.is_function(this.props.f_onOpenCloseFloatingBoxTF)) {
      this.props.f_onOpenCloseFloatingBoxTF(false);
    }
  }

  render() {
    const s_importCsvFloatingBoxIsOpenTF = this.state.s_importCsvFloatingBoxIsOpenTF;

    const p_buttonLabel = this.props.p_buttonLabel;
    const p_trblFlag = this.props.p_trblFlag;
    const p_importTitle = this.props.p_importTitle;
    const p_instructionsHtmlOrUndefined = this.props.p_instructionsHtmlOrUndefined;
    const p_existingValuesArray = this.props.p_existingValuesArray;
    const p_maxNumColumnsInCsvOrUndefined = this.props.p_maxNumColumnsInCsvOrUndefined;
    const p_maxNumColumnsToShow = this.props.p_maxNumColumnsToShow;
    const p_combineAll2DDataInto1DTF = this.props.p_combineAll2DDataInto1DTF;
    const p_markAndPreventDuplicateEntriesTF = this.props.p_markAndPreventDuplicateEntriesTF;

    return(
      <>
        <CEButton
          p_type="add"
          p_text={p_buttonLabel}
          f_onClick={this.onclick_open_import_csv_floating_box}
        />
        {(s_importCsvFloatingBoxIsOpenTF) &&
          <ImportDataFromCsvFileFloatingBox
            p_trblFlag={p_trblFlag}
            p_importTitle={p_importTitle}
            p_instructionsHtmlOrUndefined={p_instructionsHtmlOrUndefined}
            p_existingValuesArray={p_existingValuesArray}
            p_maxNumColumnsInCsvOrUndefined={p_maxNumColumnsInCsvOrUndefined}
            p_maxNumColumnsToShow={p_maxNumColumnsToShow}
            p_combineAll2DDataInto1DTF={p_combineAll2DDataInto1DTF}
            p_markAndPreventDuplicateEntriesTF={p_markAndPreventDuplicateEntriesTF}
            f_onClickImportCsvData={this.props.f_onClickImportCsvData}
            f_onClickClose={this.onclick_close_import_csv_floating_box}
          />
        }
      </>
    );
  }
}

export class ImportDataFromCsvFileFloatingBox extends Component {
  //props: p_trblFlag, p_importTitle, p_instructionsHtmlOrUndefined, p_existingValuesArray, p_maxNumColumnsInCsvOrUndefined, p_maxNumColumnsToShow, p_combineAll2DDataInto1DTF, p_markAndPreventDuplicateEntriesTF,
  //f_onClickImportCsvData(i_csvDataArrayOrArrayOfArrays, i_runThisFunctionUponSuccess, i_runThisFunctionUponError), f_onClickClose
  constructor(props) {
    super(props);
    this.state = {
      s_fileImportingState: "notLoaded", //"notLoaded", "loading", "loaded", "importing", "finishedSuccess", "finishedError"
      s_uploadErrorMessage: "",
      s_uploadedFileNameAndExt: "",
      s_csvDataWithDuplicateFlagsArrayOfObjs: [],
      s_numNonDuplicateEntries: 0,
      s_numDuplicateEntries: 0
    }
  }

  onclick_import_a_different_csv = () => {
    this.setState({
      s_fileImportingState: "notLoaded",
      s_uploadErrorMessage: "",
      s_uploadedFileNameAndExt: "",
      s_csvDataWithDuplicateFlagsArrayOfObjs: [],
      s_numNonDuplicateEntries: 0,
      s_numDuplicateEntries: 0
    });
  }

  upload_csv_files = (i_dataTransferFilesArray) => {
    const p_existingValuesArray = this.props.p_existingValuesArray;
    const p_maxNumColumnsInCsvOrUndefined = this.props.p_maxNumColumnsInCsvOrUndefined
    const p_combineAll2DDataInto1DTF = this.props.p_combineAll2DDataInto1DTF;
    const p_markAndPreventDuplicateEntriesTF = this.props.p_markAndPreventDuplicateEntriesTF;

    if(i_dataTransferFilesArray.length !== 1) {
      this.setState({s_uploadErrorMessage:"Can only import 1 .csv file at a time"});
    }
    else {
      const dataTransferFileObj = i_dataTransferFilesArray[0];
      const uploadedFileNameAndExt = dataTransferFileObj.name;

      const filePartsObj = JSFUNC.file_parts_obj(uploadedFileNameAndExt);
      if(filePartsObj.fileExt !== "csv") {
        this.setState({s_uploadErrorMessage:"Uploaded file must have a '.csv' extension (Attempted to import '" + uploadedFileNameAndExt + "')"});
      }
      else {
        this.setState({
          s_fileImportingState: "loading",
          s_uploadErrorMessage: ""
        });

        const functionOnSuccess = (i_fileDataString) => {
          //initialize output arrayOfObjs
          var csvDataWithDuplicateFlagsArrayOfObjs = [];
          var numNonDuplicateEntries = 0;
          var numDuplicateEntries = 0;

          //read csv data file 1D or 2D and convert to 2D format
          var csvDataArrayOfArrays = [];
          if(p_combineAll2DDataInto1DTF) {
            //gather csv data from all columns into a single array
            const allCsvStringsArray = JSFUNC.read_csv_file_data_string_into_single_array_of_all_values(i_fileDataString);

            //put data into 2D format
            for(let csvString of allCsvStringsArray) {
              csvDataArrayOfArrays.push([csvString]);
            }
          }
          else {
            csvDataArrayOfArrays = JSFUNC.read_csv_file_data_string_into_arrayOfArrays(i_fileDataString);
          }

          //only proceed if there are any rows of data to import
          if(JSFUNC.is_array(csvDataArrayOfArrays) && (csvDataArrayOfArrays.length > 0)) {
            //get all input existing values in lowercase
            var existingValuesLowercaseArray = [];
            if(p_markAndPreventDuplicateEntriesTF) {
              existingValuesLowercaseArray = JSFUNC.convert_array_to_lowercase_array(p_existingValuesArray);
            }

            //transfer imported csv strings into arrayOfObjs with flags for duplicates
            var uniqueCsvStringsLowerArray = [];
            for(let csvDataRowArray of csvDataArrayOfArrays) {
              if(JSFUNC.is_array(csvDataRowArray)) {
                var numColumnsInDataRow = csvDataRowArray.length;
                if(numColumnsInDataRow > 0) {
                  var csvDataRowFirstColumnString = csvDataRowArray[0];
                  if((csvDataRowFirstColumnString !== undefined) && (csvDataRowFirstColumnString !== "")) { //don't include rows that have a blank value in column 1
                    var csvStringLowercase = csvDataRowFirstColumnString.toLowerCase();

                    var duplicateExistingTF = false;
                    var duplicateInCsvTF = false;
                    if(p_markAndPreventDuplicateEntriesTF) {
                      var duplicateExistingTF = JSFUNC.in_array(csvStringLowercase, existingValuesLowercaseArray);
                      var duplicateInCsvTF = JSFUNC.in_array(csvStringLowercase, uniqueCsvStringsLowerArray);
                    }

                    //reduce the number of columns 2-N using the input p_maxNumColumnsInCsvOrUndefined
                    var column2ToNValuesArray = [];
                    var maxNumColumns = ((JSFUNC.is_number_not_nan_gt_0(p_maxNumColumnsInCsvOrUndefined)) ? (p_maxNumColumnsInCsvOrUndefined) : (numColumnsInDataRow));
                    for(let c = 1; c < maxNumColumns; c++) {
                      var column2ToNValue = csvDataRowArray[c];
                      if(column2ToNValue === undefined) { //beyond length of this row
                        column2ToNValue = "";
                      }
                      column2ToNValuesArray.push(column2ToNValue);
                    }

                    csvDataWithDuplicateFlagsArrayOfObjs.push({
                      csvString: csvDataRowFirstColumnString,
                      duplicateExistingTF: duplicateExistingTF,
                      duplicateInCsvTF: duplicateInCsvTF,
                      column2ToNValuesArray: column2ToNValuesArray
                    });

                    if(duplicateExistingTF || duplicateInCsvTF) {
                      numDuplicateEntries++;
                    }
                    else {
                      numNonDuplicateEntries++;
                    }

                    //if this value is unique in the csv, add it to the unique csv strings array for the next loop
                    if(!duplicateInCsvTF) {
                      uniqueCsvStringsLowerArray.push(csvStringLowercase);
                    }
                  }
                }
              }
            }
          }

          this.setState({
            s_fileImportingState: "loaded",
            s_uploadErrorMessage: "",
            s_uploadedFileNameAndExt: uploadedFileNameAndExt,
            s_csvDataWithDuplicateFlagsArrayOfObjs: csvDataWithDuplicateFlagsArrayOfObjs,
            s_numNonDuplicateEntries: numNonDuplicateEntries,
            s_numDuplicateEntries: numDuplicateEntries
          });
        };

        const functionOnError = () => {
          this.setState({
            s_fileImportingState: "notLoaded",
            s_uploadErrorMessage: "Error reading the uploaded file ('" + uploadedFileNameAndExt + "')"
          });
        };

        JSFUNC.read_file_data_string_from_uploaded_data_transfer_file_obj(dataTransferFileObj, functionOnSuccess, functionOnError);
      }
    }
  }

  onclick_import_loaded_items = () => {
    const s_csvDataWithDuplicateFlagsArrayOfObjs = this.state.s_csvDataWithDuplicateFlagsArrayOfObjs;

    const p_combineAll2DDataInto1DTF = this.props.p_combineAll2DDataInto1DTF;

    if(!JSFUNC.is_function(this.props.f_onClickImportCsvData)) {
      this.setState({s_fileImportingState:"notLoaded"});
    }
    else {
      this.setState({s_fileImportingState:"importing"});

      var csvDataArrayOrArrayOfArrays = [];
      for(let csvDataWithDuplicateFlagsObj of s_csvDataWithDuplicateFlagsArrayOfObjs) {
        if(!csvDataWithDuplicateFlagsObj.duplicateExistingTF && !csvDataWithDuplicateFlagsObj.duplicateInCsvTF) {
          if(p_combineAll2DDataInto1DTF) { //combining all data into 1D outputs an array of new strings to import
            csvDataArrayOrArrayOfArrays.push(csvDataWithDuplicateFlagsObj.csvString);
          }
          else { //2D output producess and arrayOfArrays (array containing all rows, each containing an array of strings for each column)
            var rowArray = [csvDataWithDuplicateFlagsObj.csvString];
            for(let column2ToNValue of csvDataWithDuplicateFlagsObj.column2ToNValuesArray) {
              rowArray.push(column2ToNValue);
            }
            csvDataArrayOrArrayOfArrays.push(rowArray);
          }
        }
      }

      const functionOnSuccess = () => {
        this.setState({s_fileImportingState:"finishedSuccess"});
      };

      const functionOnError = () => {
        this.setState({s_fileImportingState:"finishedError"});
      };

      this.props.f_onClickImportCsvData(csvDataArrayOrArrayOfArrays, functionOnSuccess, functionOnError);
    }
  }

  render() {
    const s_fileImportingState = this.state.s_fileImportingState;
    const s_uploadErrorMessage = this.state.s_uploadErrorMessage;
    const s_uploadedFileNameAndExt = this.state.s_uploadedFileNameAndExt;
    const s_csvDataWithDuplicateFlagsArrayOfObjs = this.state.s_csvDataWithDuplicateFlagsArrayOfObjs;
    const s_numNonDuplicateEntries = this.state.s_numNonDuplicateEntries;
    const s_numDuplicateEntries = this.state.s_numDuplicateEntries;

    const p_trblFlag = JSFUNC.prop_value(this.props.p_trblFlag, "addEditDeleteTableEditItem");
    const p_importTitle = this.props.p_importTitle;
    const p_instructionsHtmlOrUndefined = this.props.p_instructionsHtmlOrUndefined;
    const p_existingValuesArray = JSFUNC.prop_value(this.props.p_existingValuesArray, []);
    const p_maxNumColumnsInCsvOrUndefined = this.props.p_maxNumColumnsInCsvOrUndefined;
    const p_maxNumColumnsToShow = JSFUNC.prop_value(this.props.p_maxNumColumnsToShow, 1);
    const p_combineAll2DDataInto1DTF = JSFUNC.prop_value(this.props.p_combineAll2DDataInto1DTF, true);
    const p_markAndPreventDuplicateEntriesTF = JSFUNC.prop_value(this.props.p_markAndPreventDuplicateEntriesTF, true);

    var topComponent = null;
    if(s_fileImportingState === "notLoaded") {
      topComponent = (
        <>
          <CEDropZone
            p_width="15em"
            p_heightEm={3}
            p_allowDropTF={true}
            p_uniqueString="aedTableCsvFileImport"
            p_fileUploadIsProcessingTF={false}
            p_message="Click or Drag a .csv File to Begin Import..."
            p_dragOverMatchingItemMessage="Drop .csv File to Begin Import"
            f_onDropFiles={this.upload_csv_files}
          />
          {(s_uploadErrorMessage !== "") &&
            <ErrorText p_class="smallTopMargin" p_text={s_uploadErrorMessage} />
          }
        </>
      );
    }
    else if(s_fileImportingState === "loading") {
      topComponent = (
        <>
          <div className="smallBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Uploading file '" + s_uploadedFileNameAndExt + "'"}
            </font>
          </div>
          <LoadingAnimation />
        </>
      );
    }
    else if((s_fileImportingState === "loaded") || (s_fileImportingState === "importing")) {
      var topLabel = "Found " + s_numNonDuplicateEntries + " " + JSFUNC.plural(s_numNonDuplicateEntries, "entry", "entries") + " ready to import from file '" + s_uploadedFileNameAndExt + "'";
      if(s_numDuplicateEntries > 0) {
        topLabel += " (" + s_numDuplicateEntries + " duplicate " + JSFUNC.plural(s_numDuplicateEntries, "entry", "entries") + " are marked in red and will not be imported)";
      }
      topComponent = (
        <font className="fontItalic fontTextLight">
          {topLabel}
        </font>
      );
    }
    else if(s_fileImportingState === "finishedSuccess") {
      topComponent = (
        <font className="fontDarkGreen">
          {"Successfully imported " + s_numNonDuplicateEntries + " " + JSFUNC.plural(s_numNonDuplicateEntries, "entry", "entries") + " into CaptureExec"}
        </font>
      );
    }
    else if(s_fileImportingState === "finishedError") {
      topComponent = (
        <font className="fontRed">
          {"Import of these items was not successful. Please retry this import."}
        </font>
      );
    }

    var middleComponent = null;
    if((s_fileImportingState === "notLoaded") && (p_instructionsHtmlOrUndefined !== undefined)) {
      middleComponent = p_instructionsHtmlOrUndefined;
    }
    else if((s_fileImportingState === "loaded") || (s_fileImportingState === "importing")) {
      middleComponent = (
        s_csvDataWithDuplicateFlagsArrayOfObjs.map((m_csvDataWithDuplicateFlagsObj, m_index) =>
          <ImportDataFromCsvFileEntryRow
            p_csvDataWithDuplicateFlagsObj={m_csvDataWithDuplicateFlagsObj}
            p_rowIndex={m_index}
            p_maxNumColumnsToShow={p_maxNumColumnsToShow}
          />
        )
      );
    }

    var bottomComponent = null;
    if((s_fileImportingState === "notLoaded") || (s_fileImportingState === "loading")) {
      bottomComponent = (
        <div className="flex11a displayFlexColumnHcVc">
          <font className="fontItalic fontTextLight">
            {"Upload a .csv file above to preview its contents before importing"}
          </font>
        </div>
      );
    }
    else if(s_fileImportingState === "loaded") {
      bottomComponent = (
        <div className="flex11a displayFlexRowHcVc flexWrap">
          {(s_numNonDuplicateEntries > 0) &&
            <div className="flex00a lrMedMargin">
              <CEButton
                p_type="blue"
                p_text={"Import " + s_numNonDuplicateEntries + " " + JSFUNC.plural(s_numNonDuplicateEntries, "Item", "Items") + " and Close"}
                f_onClick={this.onclick_import_loaded_items}
              />
            </div>
          }
          <div className="flex00a lrMedMargin">
            <CEButton
              p_type="gray"
              p_text="Restart with Different .csv"
              f_onClick={this.onclick_import_a_different_csv}
            />
          </div>
          <div className="flex00a lrMedMargin">
            <CEButton
              p_type="gray"
              p_text="Cancel"
              f_onClick={this.props.f_onClickClose}
            />
          </div>
        </div>
      );
    }
    else if(s_fileImportingState === "importing") {
      bottomComponent = (
        <div className="flex11a displayFlexColumnHcVc">
          <div className="smallBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Importing new values into CaptureExec..."}
            </font>
          </div>
          <LoadingAnimation />
        </div>
      );
    }
    else if((s_fileImportingState === "finishedSuccess") || (s_fileImportingState === "finishedError")) {
      bottomComponent = (
        <div className="flex11a displayFlexRowHcVc flexWrap">
          <div className="flex00a lrMedMargin">
            <CEButton
              p_type="gray"
              p_text="Import Another .csv"
              f_onClick={this.onclick_import_a_different_csv}
            />
          </div>
          <div className="flex00a lrMedMargin">
            <CEButton
              p_type="gray"
              p_text="Close"
              f_onClick={this.props.f_onClickClose}
            />
          </div>
        </div>
      );
    }

    return(
      <FloatingBoxWithSaveCancel
        p_trblFlag={p_trblFlag}
        p_title="Importing Items from .csv File"
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex00a tbPad lrMedPad borderB1ddd">
          <font className="fontBold fontBlue">
            {p_importTitle}
          </font>
        </div>
        <div className="flex00a displayFlexColumnHcVc tbPad lrMedPad borderB1ddd">
          {topComponent}
        </div>
        <div className="flex11a yScroll medFullPad">
          {middleComponent}
        </div>
        <div className="flex00a displayFlexColumn tbPad lrMedPad borderT1ddd">
          {bottomComponent}
        </div>
      </FloatingBoxWithSaveCancel>
    );
  }
}

function ImportDataFromCsvFileEntryRow(props) { //props: p_csvDataWithDuplicateFlagsObj, p_rowIndex, p_maxNumColumnsToShow
  const csvDataWithDuplicateFlagsObj = props.p_csvDataWithDuplicateFlagsObj;
  const rowIndex = props.p_rowIndex;
  const p_maxNumColumnsToShow = props.p_maxNumColumnsToShow;

  const csvString = csvDataWithDuplicateFlagsObj.csvString;
  const duplicateExistingTF = csvDataWithDuplicateFlagsObj.duplicateExistingTF;
  const duplicateInCsvTF = csvDataWithDuplicateFlagsObj.duplicateInCsvTF;
  const column2ToNValuesArray = csvDataWithDuplicateFlagsObj.column2ToNValuesArray;

  var bgClass = "";
  var fontClass = "";
  if(duplicateExistingTF || duplicateInCsvTF) {
    bgClass = "bgLightRed";
    fontClass = "fontItalic fontTextLighter";
  }
  else if((rowIndex % 2) === 1) {
    bgClass = "bgLightesterGray";
  }
  else {
    bgClass = "bgWhite";
  }

  return(
    <div className={"displayFlexRow borderB1ddd tbMicroPad " + bgClass}>
      <div className="flex11a lrPad" style={{flexBasis:"200em"}}>
        <font className={fontClass}>
          {csvString}
        </font>
      </div>
      {column2ToNValuesArray.map((m_column2toNValue, m_index) =>
        ((m_index + 1) < p_maxNumColumnsToShow) && 
        <div className="flex11a borderL1ddd lrPad" style={{flexBasis:"100em"}}>
          <font className={fontClass}>
            {m_column2toNValue}
          </font>
        </div>
      )}
    </div>
  );
}








//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//Folders/Files
//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
export const FileFolderSystem = inject("DatabaseMobx", "UserMobx")(observer(
class FileFolderSystem extends Component { //props: 
  //p_tblName, p_tblMapOfMaps, p_tblFilterValuesObj, p_title, p_defaultXmlType, p_allowedExtsArray, p_disableUploadFilesTF, p_disableCreateOnlineLinkDocsTF, p_disableCreateFoldersTF, p_viewWideTrueNarrowFalse, p_navigateToFolderID, 
  //f_onPerformFFSAction(i_actionText, i_descriptionText)
  //f_onNavigateToFolder(p_navigateToFolderIDOrUndefined)
  //
  //p_tblName:                      db tbl name in the fileFolderSystem format
  //p_tblMapOfMaps:                 local memory mapOfMaps version of db fileFolderSystem tbl (unfiltered, needs to be filtered and split into files and folders with each sorted within this function)
  //p_tblFilterValuesObj:           {capture_id:22, teammate_id:4, contract_type_id:2} all columns in tbl to filter on for this fileFolderSystem, uses these variables when saving new entries to db to fill in values for these columns
  //p_title:                        (optional) places a title centered above the upload boxes in the gray space
  //p_defaultXmlType:               (optional) default value for "xml_type" field for an upload
  //p_allowedExtsArray:             (optional) array of file extension strings (without '.') that will only be accepted for upload, if any of the multiple files to upload do not match these, a warming box is put up and 0 files are uploaded
  //p_disableUploadFilesTF:         (optional) [default false], if true, removes the 'Click or Drag to Upload Files' dashed blue box for dragging file uploads
  //p_disableCreateOnlineLinkDocsTF:(optional) [default false], if true, removes the 'Create New Online Link Document' button so that link files cannot be created in the FFS
  //p_disableCreateFoldersTF:       (optional) [default false], if true, removes the 'Create New Folder' button so that folders cannot be created in the FFS
  //p_viewWideTrueNarrowFalse:      (optional) true - (default) file rows are 1 line tall with all fields of info on the same line, false - file rows are 2 lines high for narrow screens (mobile) so that file name has full length to show characters
  //p_navigateToFolderID:           (optional) when this input changes value, it forces the internal state "s_folderIDOpen" to change to this folderID to navigate to this specified folder
  //f_onPerformFFSAction:           (optional) external function run when the file(s)/folder(s) are successfully uploaded, created, moved, or deleted
  //f_onNavigateToFolder:           (optional) external function run when the input p_navigateToFolderID has detected and change and updated the internal s_folderIDOpen state, this function can reset a global variable holding an input value for p_navigateToFolderID to undefined
  //
  //fileFolderSystem db tbl columns (standardized for every copy of this table, seaparate tables because each has different filtering columns):
  //  - "id"
  //  - [custom filter column examples: "capture_id", "teammate_id", "contract_type_id"  (optional filter fields in an example table)]
  //  - "folder0_file1"             0-folder, 1-file
  //  - "parent_folder_id"          -1 file or folder at root level of this system, 1-N file or folder is within parent subfolder with id equal to this number
  //  - "fileupload0_onlinelink1"   0-uploaded file from user's desktop that is physically copied to our AWS servers, 1-user pastes a URL link to an online document
  //  - "file_loc"                  files - file path (after server upload folder) "tbl_name/capture_id22/teammate_id4/contract_type_id2/file_name.ext", folders - db_name underscore version of the folder display_name
  //  - "display_name"              masked file name with ext, masked folder name
  //  - "keywords"
  //  - "notes"
  //  - "content_unique_lowercase"  all unique words stripped from file text content
  //  - "upload_date"
  //  - "upload_user_id"
  //  - "upload_user_name"
  //  - "access"                    only used for admin template uploads
  //  - "xml_type"                  only used for admin template uploads (default value for new upload affected by input p_defaultXmlType)

  constructor(props) {
    super(props);
    this.state = {
      s_currentSortFieldDbName: "display_name",
      s_currentSortIsAscTF: true,
      s_folderIDOpen: -1, //-1 flag currently at the top level seeing all folders where parent_folder_id is -1 and all files where folder_id is -1, positive id means you are in a subfolder viewing those subfolders and files
      s_creatingNewLinkToOnlineDocumentTF: false,
      s_creatingNewFolderTF: false
    };
  }

  componentDidMount() {
    this.navigate_to_folder_id_and_call_on_navigate_to_folder(this.props.p_navigateToFolderID);
  }

  componentDidUpdate(prevProps) { //handle forced incoming input to redirect what subfolder is currently displayed (comes from clicking a file within documents search)
    if(this.props.p_navigateToFolderID !== prevProps.p_navigateToFolderID) {
      this.navigate_to_folder_id_and_call_on_navigate_to_folder(this.props.p_navigateToFolderID);
    }
  }

  navigate_to_folder_id_and_call_on_navigate_to_folder = (i_navigateToFolderID) => {
    if(JSFUNC.is_number(i_navigateToFolderID) && (i_navigateToFolderID !== this.state.s_folderIDOpen)) { //force navigation if the input is a valid number (-1 for root level or any positive id number) and is not the folder that is currently open (no need to update it in that case)
      this.setState({
        s_folderIDOpen: this.props.p_navigateToFolderID,
        s_creatingNewFolderTF: false
      });

      if(JSFUNC.is_function(this.props.f_onNavigateToFolder)) {
        this.props.f_onNavigateToFolder(undefined); //used reset global variable from forced folder entry
      }
    }
  }

  get_filter_field_names_and_values = () => {
    const p_tblFilterValuesObj = this.props.p_tblFilterValuesObj; //given {capture_id:22, teammate_id:4, contract_type_id:2}

    var filterFieldNamesArray = []; //becomes ["capture_id", "teammate_id", "contract_type_id"]
    var filterValuesArray = []; //becomes [22, 4, 2]
    if(JSFUNC.is_obj(p_tblFilterValuesObj)) {
      for(var property in p_tblFilterValuesObj) {
        if(p_tblFilterValuesObj.hasOwnProperty(property)) {
          filterFieldNamesArray.push(property);
          filterValuesArray.push(p_tblFilterValuesObj[property]);
        }
      }
    }
    return([filterFieldNamesArray, filterValuesArray]);
  }

  get_root_folders_path = () => {
    const p_tblName = this.props.p_tblName;

    const [filterFieldNamesArray, filterValuesArray] = this.get_filter_field_names_and_values();
    var rootFoldersPath = p_tblName; //becomes "tbl_name/capture_id22/teammate_id4/contract_type_id2"
    for(let f = 0; f < filterFieldNamesArray.length; f++) {
      rootFoldersPath += "/" + filterFieldNamesArray[f] + filterValuesArray[f];
    }
    return(rootFoldersPath);
  }

  onclick_create_new_link_to_online_document = () => {
    this.setState({s_creatingNewLinkToOnlineDocumentTF:true});
  }

  onclick_confirm_create_new_link_to_online_document = (i_newLinkToOnlineDocumentObj) => {
    const s_folderIDOpen = this.state.s_folderIDOpen;

    const p_tblName = this.props.p_tblName;

    const o_userID = this.props.UserMobx.o_userID;
    const c_userName = this.props.UserMobx.c_userName;

    //call database update in fileFolderSystem tbl
    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FileFolderSystem", "onclick_confirm_create_new_link_to_online_document", ["i_newLinkToOnlineDocumentObj"], [i_newLinkToOnlineDocumentObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //fields required in the fileFolderSystem db tbl (with values for a new folder)
    var tblFieldNamesArray = ["folder0_file1", "parent_folder_id", "fileupload0_onlinelink1", "file_loc", "display_name", "keywords", "notes", "content_unique_lowercase", "upload_date", "upload_user_id", "upload_user_name", "access", "xml_type"];
    var newValuesArray = [1, s_folderIDOpen, 1, i_newLinkToOnlineDocumentObj.url, i_newLinkToOnlineDocumentObj.name, i_newLinkToOnlineDocumentObj.keywords, i_newLinkToOnlineDocumentObj.descriptionNotes, "", JSFUNC.now_date(), o_userID, c_userName, "open", "generic"];
    var newValuesIdsbArray = ["i", "i", "i", "s", "s", "s", "s", "s", "s", "i", "s", "s", "s"];

    //filter field columns provided for this particular fileFolderSystem
    const [filterFieldNamesArray, filterValuesArray] = this.get_filter_field_names_and_values();
    for(let f = 0; f < filterFieldNamesArray.length; f++) {
      tblFieldNamesArray.push(filterFieldNamesArray[f]);
      newValuesArray.push(filterValuesArray[f]);
      newValuesIdsbArray.push("i"); //all filter values are assumed to be ints
    }

    C_CallPhpTblUID.add_insert(p_tblName, tblFieldNamesArray, newValuesArray, newValuesIdsbArray);

    //call perform action function
    if(JSFUNC.is_function(this.props.f_onPerformFFSAction)) {
      const functionOnSuccess = (i_parseResponse) => {
        const actionText = "Create New Link to Online Document";
        const descriptionText = i_newLinkToOnlineDocumentObj.name + " [" + i_newLinkToOnlineDocumentObj.url + "]";
        this.props.f_onPerformFFSAction(actionText, descriptionText);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();

    this.setState({s_creatingNewLinkToOnlineDocumentTF:false});
  }

  onclick_cancel_create_new_link_to_online_document = () => {
    this.setState({s_creatingNewLinkToOnlineDocumentTF:false});
  }

  onclick_create_new_folder = () => {
    this.setState({s_creatingNewFolderTF:true});
  }

  onclick_confirm_create_new_folder = (i_newFolderName) => {
    const s_folderIDOpen = this.state.s_folderIDOpen;

    const p_tblName = this.props.p_tblName;

    const o_userID = this.props.UserMobx.o_userID;
    const c_userName = this.props.UserMobx.c_userName;

    //call database update in fileFolderSystem tbl
    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FileFolderSystem", "onclick_confirm_create_new_folder", ["i_newFolderName"], [i_newFolderName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //fields required in the fileFolderSystem db tbl (with values for a new folder)
    var tblFieldNamesArray = ["folder0_file1", "parent_folder_id", "fileupload0_onlinelink1", "file_loc", "display_name", "keywords", "notes", "content_unique_lowercase", "upload_date", "upload_user_id", "upload_user_name", "access", "xml_type"];
    var newValuesArray = [0, s_folderIDOpen, 0, JSFUNC.db_name_from_display_name(i_newFolderName), i_newFolderName, "", "", "", JSFUNC.now_date(), o_userID, c_userName, "", ""];
    var newValuesIdsbArray = ["i", "i", "i", "s", "s", "s", "s", "s", "s", "i", "s", "s", "s"];

    //filter field columns provided for this particular fileFolderSystem
    const [filterFieldNamesArray, filterValuesArray] = this.get_filter_field_names_and_values();
    for(let f = 0; f < filterFieldNamesArray.length; f++) {
      tblFieldNamesArray.push(filterFieldNamesArray[f]);
      newValuesArray.push(filterValuesArray[f]);
      newValuesIdsbArray.push("i"); //all filter values are assumed to be ints
    }

    C_CallPhpTblUID.add_insert(p_tblName, tblFieldNamesArray, newValuesArray, newValuesIdsbArray);

    //call perform action function
    if(JSFUNC.is_function(this.props.f_onPerformFFSAction)) {
      const functionOnSuccess = (i_parseResponse) => {
        const actionText = "Create New Folder";
        const descriptionText = "'" + i_newFolderName + "'";
        this.props.f_onPerformFFSAction(actionText, descriptionText);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
    }

    C_CallPhpTblUID.execute();

    this.setState({s_creatingNewFolderTF:false});
  }

  onclick_cancel_create_new_folder = () => {
    this.setState({s_creatingNewFolderTF:false});
  }

  onclick_ffs_table_header = (i_fieldDbName) => {
    const s_currentSortFieldDbName = this.state.s_currentSortFieldDbName;
    const s_currentSortIsAscTF = this.state.s_currentSortIsAscTF;
    
    var updatedSortFieldDbName = i_fieldDbName;
    var updatedSortIsAscTF = true;
    if(i_fieldDbName === s_currentSortFieldDbName) { //reverse sort direction if clicking on column that is already sorted
      updatedSortIsAscTF = (!s_currentSortIsAscTF);
    }

    this.setState({
      s_currentSortFieldDbName: updatedSortFieldDbName,
      s_currentSortIsAscTF: updatedSortIsAscTF
    });
  }

  onclick_folder = (i_folderID) => {
    this.setState({s_folderIDOpen:i_folderID});
  }

  ondelete_folders_and_files = (i_folderIDsToDeleteArray, i_fileIDsToDeleteArray) => {
    const p_tblName = this.props.p_tblName; //tbl name of the filefoldersystem that stores file and folder info together
    const p_tblMapOfMaps = this.props.p_tblMapOfMaps; //local copy of tblName with only the data for these filters

    var foldersArrayOfObjs = [];
    var filesArrayOfObjs = [];
    var folderAndFileIDsToDeleteArray = [];

    for(let folderID of i_folderIDsToDeleteArray) {
      var fileFolderItemMap = p_tblMapOfMaps.get(folderID);
      if(fileFolderItemMap !== undefined) {
        foldersArrayOfObjs.push(JSFUNC.obj_from_map(fileFolderItemMap));
        folderAndFileIDsToDeleteArray.push(folderID);
      }
    }

    for(let fileID of i_fileIDsToDeleteArray) {
      var fileFolderItemMap = p_tblMapOfMaps.get(fileID);
      if(fileFolderItemMap !== undefined) {
        filesArrayOfObjs.push(JSFUNC.obj_from_map(fileFolderItemMap));
        folderAndFileIDsToDeleteArray.push(fileID);
      }
    }

    const numFoldersToDelete = foldersArrayOfObjs.length;
    const numFilesToDelete = filesArrayOfObjs.length;

    if((numFoldersToDelete > 0) || (numFilesToDelete > 0)) { //if anything was deleted
      //construct the tblUID class instance
      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FileFolderSystem", "ondelete_folders_and_files", ["i_folderIDsToDeleteArray", "i_fileIDsToDeleteArray"], [i_folderIDsToDeleteArray, i_fileIDsToDeleteArray]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //for each file being deleted, get the file loc from the filefoldersystem tbl and call a file_delete to remove it from the server
      for(let fileObj of filesArrayOfObjs) {
        if(fileObj.fileupload0_onlinelink1 === 0) { //only fileupload types have an actual file stored on the server that must be unlinked
          C_CallPhpTblUID.add_file_delete(fileObj.file_loc); //php unlink the file from the server
        }
      }

      //delete all ffs tbl records of files and folders being deleted from the filefoldersystem database tbl and local tbl
      C_CallPhpTblUID.add_delete(p_tblName, folderAndFileIDsToDeleteArray); //no sort column in filefoldersystem tbls

      //call onperform action function
      if(JSFUNC.is_function(this.props.f_onPerformFFSAction)) {
        const functionOnSuccess = (i_parseResponse) => {
          var actionText = "";
          var descriptionText = "";
          if(numFoldersToDelete === 0) { //no folders, only file(s) deleted
            if(numFilesToDelete === 1) { //delete 1 file
              actionText = "Delete File";
              descriptionText = "Delete File '" + filesArrayOfObjs[0].display_name + "'";
            }
            else { //delete multiple files
              actionText = "Delete Files";
              descriptionText = "Deleted " + numFilesToDelete + " Files:";
              for(let fileObj of filesArrayOfObjs) {
                descriptionText += "\n - " + fileObj.display_name;
              }
            }
          }
          else if(numFilesToDelete === 0) { //no files, only folder(s) deleted
            if(numFoldersToDelete === 1) { //delete 1 folder
              actionText = "Delete Folder";
              descriptionText = "Delete Folder '" + foldersArrayOfObjs[0].display_name + "'";
            }
            else { //delete multiple folders
              actionText = "Delete Folders";
              descriptionText = "Deleted " + numFoldersToDelete + " Folders:";
              for(let folderObj of foldersArrayOfObjs) {
                descriptionText += "\n - " + folderObj.display_name;
              }
            }
          }
          else { //deleted at least 1 file and 1 folder
            actionText = "Delete Folders/Files";
            descriptionText = "Deleted " + numFoldersToDelete + " " + JSFUNC.plural(numFoldersToDelete, "Folder", "Folders") + ":";
            for(let folderObj of foldersArrayOfObjs) {
              descriptionText += "\n - " + folderObj.display_name;
            }

            descriptionText += "\n\nDeleted " + numFilesToDelete + " " + JSFUNC.plural(numFilesToDelete, "File", "Files") + ":";
            for(let fileObj of filesArrayOfObjs) {
              descriptionText += "\n - " + fileObj.display_name;
            }
          }

          this.props.f_onPerformFFSAction(actionText, descriptionText);
        }
        C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);
      }

      C_CallPhpTblUID.execute();
    }
  }

  render() {
    const s_currentSortFieldDbName = this.state.s_currentSortFieldDbName;
    const s_currentSortIsAscTF = this.state.s_currentSortIsAscTF;
    const s_folderIDOpen = this.state.s_folderIDOpen;
    const s_creatingNewLinkToOnlineDocumentTF = this.state.s_creatingNewLinkToOnlineDocumentTF;
    const s_creatingNewFolderTF = this.state.s_creatingNewFolderTF;

    const p_tblName = this.props.p_tblName;
    const p_tblMapOfMaps = this.props.p_tblMapOfMaps;
    const p_tblFilterValuesObj = this.props.p_tblFilterValuesObj;
    const p_title = this.props.p_title;
    const p_defaultXmlType = JSFUNC.prop_value(this.props.p_defaultXmlType, "generic");
    const p_allowedExtsArray = this.props.p_allowedExtsArray;
    const p_disableUploadFilesTF = JSFUNC.prop_value(this.props.p_disableUploadFilesTF, false);
    const p_disableCreateOnlineLinkDocsTF = JSFUNC.prop_value(this.props.p_disableCreateOnlineLinkDocsTF, false);
    const p_disableCreateFoldersTF = JSFUNC.prop_value(this.props.p_disableCreateFoldersTF, false);
    const p_viewWideTrueNarrowFalse = JSFUNC.prop_value(this.props.p_viewWideTrueNarrowFalse, true);

    const c_userCanUploadFilesTF = this.props.UserMobx.c_userCanUploadFilesTF;

    //determine the requested tbl filter columns and values from the p_tblFilterValuesObj input
    const [filterFieldNamesArray, filterValuesArray] = this.get_filter_field_names_and_values();
    const folderAndFileFilterFieldNamesArray = ["folder0_file1"].concat(filterFieldNamesArray); //becomes ["folder0_file1", "capture_id", "teammate_id", "contract_type_id"]
    const folderFilterValuesArray = [0].concat(filterValuesArray); //becomes [0, 22, 4, 2]
    const fileFilterValuesArray = [1].concat(filterValuesArray); //becomes [1, 22, 4, 2]

    //filter and sort the folders
    var foldersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(p_tblMapOfMaps, folderAndFileFilterFieldNamesArray, folderFilterValuesArray, "display_name", true, true);
    if((s_currentSortFieldDbName === "display_name") && !s_currentSortIsAscTF) { //sorted by name ASC by default, only need to change it when sorting by name DESC (all other sort columns don't affect folders)
      JSFUNC.sort_arrayOfObjs(foldersArrayOfObjs, s_currentSortFieldDbName, s_currentSortIsAscTF);
    }

    //filter and sort the files
    var filesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(p_tblMapOfMaps, folderAndFileFilterFieldNamesArray, fileFilterValuesArray, "display_name", true, true);
    for(let fileObj of filesArrayOfObjs) {
      //get user name mask from the upload_user_id if it exists, otherwise use the upload_user_name
      var uploadUserNameMaskSortIfoObj = this.props.DatabaseMobx.user_name_mask_sort_ifo_obj_from_user_id(fileObj.upload_user_id);
      var uploadUserNameMaskPlainText = uploadUserNameMaskSortIfoObj.valueMaskPlainText;
      if(!uploadUserNameMaskSortIfoObj.isFilledOutTF) {
        uploadUserNameMaskPlainText = fileObj.upload_user_name;
      }

      fileObj.isOnlineLinkTF = (fileObj.fileupload0_onlinelink1 === 1); //compute TF flag for online link document files
      fileObj.uploadUserNameMaskPlainText = uploadUserNameMaskPlainText;
    }
    JSFUNC.sort_arrayOfObjs(filesArrayOfObjs, s_currentSortFieldDbName, s_currentSortIsAscTF);

    //compute folder trail to currently open folder
    const folderTrailArrayOfObjs = JSFUNC.tree_field_array_from_top_to_node(foldersArrayOfObjs, "parent_folder_id", s_folderIDOpen, "Obj");

    //get full folder path
    const rootFoldersPath = this.get_root_folders_path(); //root path specific to this fileFolderSystem with tblName and filters "tbl_name/capture_id22/teammate_id4/contract_type_id2"

    //determine if the current level is the top home level or in a folder
    const currentLevelIsTopHomeTF = (s_folderIDOpen <= 0);

    //determine if there are any folders or files directly within the currently open folder
    var currentLocationContainsAnyFoldersOrFilesTF = JSFUNC.obj_with_field_matching_value_is_in_arrayOfObjs_tf(foldersArrayOfObjs, "parent_folder_id", s_folderIDOpen);
    if(!currentLocationContainsAnyFoldersOrFilesTF) {
      currentLocationContainsAnyFoldersOrFilesTF = JSFUNC.obj_with_field_matching_value_is_in_arrayOfObjs_tf(filesArrayOfObjs, "parent_folder_id", s_folderIDOpen);
    }

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a bgPanelLightGray borderB1bbb">
          <div className="smallTopMargin" />
          {(p_title !== undefined) &&
            <div className="displayFlexColumnHcVc microBottomMargin lrMargin textCenter">
              <font className="font11 fontBold fontTextLighter">
                {p_title}
              </font>
            </div>
          }
          {(c_userCanUploadFilesTF) &&
            <div className="displayFlexRowHcVc flexWrap">
              {(!p_disableUploadFilesTF) &&
                <div className="flex00a positionRelative lrMargin">
                  <FileFolderSystemUploadFilesButtonDropZone
                    p_tblName={p_tblName}
                    p_subfoldersPath={rootFoldersPath}
                    p_tblFilterFieldNamesArray={filterFieldNamesArray}
                    p_tblFilterValuesArray={filterValuesArray}
                    p_parentFolderID={s_folderIDOpen}
                    p_defaultXmlType={p_defaultXmlType}
                    p_allowedExtsArray={p_allowedExtsArray}
                    f_onPerformFFSAction={this.props.f_onPerformFFSAction}
                  />
                </div>
              }
              {(!p_disableCreateOnlineLinkDocsTF) &&
                <div
                  className="flex00a displayFlexColumnHcVc lrMargin border bevelBorderColors borderRadius05 bgLightGray hoverLightGrayGradient cursorPointer"
                  style={{width:"3.5em", height:"3.5em"}}
                  title="Create a New Link to an Online Document"
                  onClick={this.onclick_create_new_link_to_online_document}>
                  <SvgChain
                    p_widthEm={3}
                    p_heightEm={3}
                    p_xStart0to100={0}
                    p_xEnd0to100={100}
                    p_yStart0to100={30}
                    p_yEnd0to100={70}
                    p_lineWidth="6%"
                    p_lineColor="666"
                    p_plusTF={true}
                  />
                </div>
              }
              {(!p_disableCreateFoldersTF) &&
                <div
                  className="flex00a displayFlexColumnHcVc lrMargin border bevelBorderColors borderRadius05 bgLightPurple hoverLighterPurpleGradient cursorPointer"
                  style={{width:"3.5em", height:"3.5em"}}
                  title="Create New Folder"
                  onClick={this.onclick_create_new_folder}>
                  <SvgFolder
                    p_widthEm={3}
                    p_heightEm={3}
                    p_xStart0to100={5}
                    p_xEnd0to100={95}
                    p_yStart0to100={15}
                    p_yEnd0to100={70}
                    p_lineColor="737"
                    p_plusTF={true}
                  />
                </div>
              }
            </div>
          }
          <div className="lrPad">
            <FolderTrail
              p_tblName={p_tblName}
              p_folderIDOpen={s_folderIDOpen}
              p_folderTrailArrayOfObjs={folderTrailArrayOfObjs}
              f_onClickFolderTrailLink={this.onclick_folder}
            />
          </div>
        </div>
        <FFSTableHeadersRow
          p_currentSortFieldDbName={s_currentSortFieldDbName}
          p_currentSortIsAscTF={s_currentSortIsAscTF}
          p_viewWideTrueNarrowFalse={p_viewWideTrueNarrowFalse}
          f_onClickHeader={this.onclick_ffs_table_header}
        />
        {(!currentLevelIsTopHomeTF) &&
          <ReturnToPreviousFolder
            p_tblName={p_tblName}
            p_folderIDOpen={s_folderIDOpen}
            p_foldersArrayOfObjs={foldersArrayOfObjs}
            f_onClick={this.onclick_folder}
          />
        }
        <div className={"flex11a yScroll bgLightestestGray " + ((currentLocationContainsAnyFoldersOrFilesTF) ? ("displayFlexColumn yScrollBottomPad") : ("displayFlexColumnHcVc"))}>
          {(currentLocationContainsAnyFoldersOrFilesTF) ? (
            <>
              <FoldersList
                p_tblName={p_tblName}
                p_folderIDOpen={s_folderIDOpen}
                p_foldersArrayOfObjs={foldersArrayOfObjs}
                p_filesArrayOfObjs={filesArrayOfObjs}
                f_onClickFolder={this.onclick_folder}
                f_onDeleteFoldersAndFiles={this.ondelete_folders_and_files}
              />
              <FilesList
                p_tblName={p_tblName}
                p_folderIDOpen={s_folderIDOpen}
                p_filesArrayOfObjs={filesArrayOfObjs}
                p_viewWideTrueNarrowFalse={p_viewWideTrueNarrowFalse}
                f_onDeleteFoldersAndFiles={this.ondelete_folders_and_files}
              />
            </>
          ) : (
            <font className="font11 fontItalic fontTextLighter">
              {"No files or folders created yet in this location"}
            </font>
          )}
        </div>
        {(s_creatingNewLinkToOnlineDocumentTF) &&
          <CreateNewLinkToOnlineDocumentFloatingBox
            f_onClickConfirm={this.onclick_confirm_create_new_link_to_online_document}
            f_onClickCancel={this.onclick_cancel_create_new_link_to_online_document}
          />
        }
        {(s_creatingNewFolderTF) &&
          <ConfirmBox
            p_type="inputText"
            p_title="Create a New Folder"
            f_onClickConfirm={this.onclick_confirm_create_new_folder}
            f_onClickCancel={this.onclick_cancel_create_new_folder}>
            {"Enter a name for the new folder"}
          </ConfirmBox>
        }
      </div>
    );
  }
}));


function FFSTableHeadersRow(props) { //props: p_currentSortFieldDbName, p_currentSortIsAscTF, p_viewWideTrueNarrowFalse, f_onClickHeader(i_fieldDbName)
  const p_currentSortFieldDbName = props.p_currentSortFieldDbName;
  const p_currentSortIsAscTF = props.p_currentSortIsAscTF;
  const p_viewWideTrueNarrowFalse = props.p_viewWideTrueNarrowFalse;
  const f_onClickHeader = props.f_onClickHeader;

  return(
    <div className="displayFlexRow">
      <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"300em"}}>
        <FFSTableHeaderWithSort
          p_fieldDbName="display_name"
          p_fieldDisplayName={((p_viewWideTrueNarrowFalse) ? ("Document Name") : ("Name"))}
          p_currentSortFieldDbName={p_currentSortFieldDbName}
          p_currentSortIsAscTF={p_currentSortIsAscTF}
          f_onClick={f_onClickHeader}
        />
      </div>
      {(p_viewWideTrueNarrowFalse) &&
        <>
          <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
            <FFSTableHeaderWithSort
              p_fieldDbName="keywords"
              p_fieldDisplayName="Keywords"
              p_currentSortFieldDbName={p_currentSortFieldDbName}
              p_currentSortIsAscTF={p_currentSortIsAscTF}
              f_onClick={f_onClickHeader}
            />
          </div>
          <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
            <FFSTableHeaderWithSort
              p_fieldDbName="notes"
              p_fieldDisplayName="Description/Notes"
              p_currentSortFieldDbName={p_currentSortFieldDbName}
              p_currentSortIsAscTF={p_currentSortIsAscTF}
              f_onClick={f_onClickHeader}
            />
          </div>
        </>
      }
      <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}}>
        <FFSTableHeaderWithSort
          p_fieldDbName="uploadUserNameMaskPlainText"
          p_fieldDisplayName={((p_viewWideTrueNarrowFalse) ? ("Uploaded By") : ("User"))}
          p_currentSortFieldDbName={p_currentSortFieldDbName}
          p_currentSortIsAscTF={p_currentSortIsAscTF}
          f_onClick={f_onClickHeader}
        />
      </div>
      <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"13em"}}>
        <FFSTableHeaderWithSort
          p_fieldDbName="upload_date"
          p_fieldDisplayName={((p_viewWideTrueNarrowFalse) ? ("Upload Date") : ("Date"))}
          p_currentSortFieldDbName={p_currentSortFieldDbName}
          p_currentSortIsAscTF={p_currentSortIsAscTF}
          f_onClick={f_onClickHeader}
        />
      </div>
    </div>
  );
}


class FFSTableHeaderWithSort extends Component { //props: p_fieldDbName, p_fieldDisplayName, p_currentSortFieldDbName, p_currentSortIsAscTF, f_onClick(i_fieldDbName)
  onclick_header = () => {
    const p_fieldDbName = this.props.p_fieldDbName;
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(p_fieldDbName);
    }
  }

  render() {
    const p_fieldDbName = this.props.p_fieldDbName;
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_currentSortFieldDbName = this.props.p_currentSortFieldDbName;
    const p_currentSortIsAscTF = this.props.p_currentSortIsAscTF;

    const isSortedTF = (p_fieldDbName === p_currentSortFieldDbName);

    return(
      <div
        className="displayFlexRow tbMicroPad lrPad cursorPointer"
        title={"Sort folders and files by " + p_fieldDisplayName}
        onClick={this.onclick_header}>
        <div className="flex00a displayFlexRowVc">
          <font className={"font11 fontBold " + ((isSortedTF) ? ("fontTextLight") : ("fontTextLightest"))}>
            {p_fieldDisplayName}
          </font>
        </div>
        <div className="flex00a displayFlexRowVc lrMargin">
          <HeaderSortArrow p_isSortedTF={isSortedTF} p_sortedAscTF={p_currentSortIsAscTF} />
        </div>
      </div>
    );
  }
}


class CreateNewLinkToOnlineDocumentFloatingBox extends Component { //props: f_onClickConfirm, f_onClickCancel
  constructor(props) {
    super(props);
    this.state = {
      s_newLinkToOnlineDocumentUrl: "",
      s_newLinkToOnlineDocumentName: "",
      s_newLinkToOnlineDocumentKeywords: "",
      s_newLinkToOnlineDocumentDescriptionNotes: "",
      s_triedFirstSaveTF: false
    };
  }

  onchange_new_link_to_online_document_url = (i_newValueString) => { this.setState({s_newLinkToOnlineDocumentUrl:i_newValueString}); }
  onchange_new_link_to_online_document_name = (i_newValueString) => { this.setState({s_newLinkToOnlineDocumentName:i_newValueString}); }
  onchange_new_link_to_online_document_keywords = (i_newValueString) => { this.setState({s_newLinkToOnlineDocumentKeywords:i_newValueString}); }
  onchange_new_link_to_online_document_description_notes = (i_newValueString) => { this.setState({s_newLinkToOnlineDocumentDescriptionNotes:i_newValueString}); }

  onclick_confirm_button = () => {
    const s_newLinkToOnlineDocumentUrl = this.state.s_newLinkToOnlineDocumentUrl;
    const s_newLinkToOnlineDocumentName = this.state.s_newLinkToOnlineDocumentName;
    const s_newLinkToOnlineDocumentKeywords = this.state.s_newLinkToOnlineDocumentKeywords;
    const s_newLinkToOnlineDocumentDescriptionNotes = this.state.s_newLinkToOnlineDocumentDescriptionNotes;

    if(!JSFUNC.text_or_number_is_filled_out_tf(s_newLinkToOnlineDocumentUrl) || !JSFUNC.text_or_number_is_filled_out_tf(s_newLinkToOnlineDocumentName)) {
      this.setState({s_triedFirstSaveTF:true});
    }
    else {
      if(JSFUNC.is_function(this.props.f_onClickConfirm)) {
        this.props.f_onClickConfirm({
          url: s_newLinkToOnlineDocumentUrl,
          name: s_newLinkToOnlineDocumentName,
          keywords: s_newLinkToOnlineDocumentKeywords,
          descriptionNotes: s_newLinkToOnlineDocumentDescriptionNotes
        });
      }
    }
  }

  render() {
    const s_newLinkToOnlineDocumentUrl = this.state.s_newLinkToOnlineDocumentUrl;
    const s_newLinkToOnlineDocumentName = this.state.s_newLinkToOnlineDocumentName;
    const s_newLinkToOnlineDocumentKeywords = this.state.s_newLinkToOnlineDocumentKeywords;
    const s_newLinkToOnlineDocumentDescriptionNotes = this.state.s_newLinkToOnlineDocumentDescriptionNotes;
    const s_triedFirstSaveTF = this.state.s_triedFirstSaveTF;

    const urlBlankErrorTF = (s_triedFirstSaveTF && !JSFUNC.text_or_number_is_filled_out_tf(s_newLinkToOnlineDocumentUrl));
    const nameBlankErrorTF = (s_triedFirstSaveTF && !JSFUNC.text_or_number_is_filled_out_tf(s_newLinkToOnlineDocumentName));

    return(
      <FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title="Create a New Link to an Online Document"
        f_onClickCancel={this.props.f_onClickCancel}>
        <div className="flex11a displayFlexColumn yScroll medFullPad">
          <div className="microBottomMargin">
            <font className="">
              {"Enter the hyperlink (full URL) to an online document (Sharepoint, GovWin, etc)"}
            </font>
          </div>
          <LibraryReact.Text
            p_value={s_newLinkToOnlineDocumentUrl}
            p_styleObj={{width:"100%"}}
            p_tabIndex={1}
            p_placeholder="https://www.website.com/online_document.pdf"
            p_errorTF={urlBlankErrorTF}
            f_onChange={this.onchange_new_link_to_online_document_url}
          />
          {(urlBlankErrorTF) &&
            <ErrorText p_class="microTopMargin" p_text="URL cannot be blank for new online document" />
          }
          <div className="bigTopMargin microBottomMargin">
            <font className="">
              {"Enter a name to display for this online document"}
            </font>
          </div>
          <LibraryReact.Text
            p_value={s_newLinkToOnlineDocumentName}
            p_styleObj={{width:"100%"}}
            p_tabIndex={2}
            p_placeholder="Document Name"
            p_errorTF={nameBlankErrorTF}
            f_onChange={this.onchange_new_link_to_online_document_name}
          />
          {(nameBlankErrorTF) &&
            <ErrorText p_class="microTopMargin" p_text="Document Name cannot be blank for new online document" />
          }
          <div className="hugeTopMargin borderB1ddd" />
          <div className="flex00a smallTopMargin">
              <font className="fontItalic fontTextLighter">
                {"(optional)"}
              </font>
            </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:"8.5em"}}>
              <font className="">
                {"Keywords"}
              </font>
            </div>
            <div className="flex11a">
              <LibraryReact.Text
                p_value={s_newLinkToOnlineDocumentKeywords}
                p_styleObj={{width:"100%"}}
                p_tabIndex={3}
                f_onChange={this.onchange_new_link_to_online_document_keywords}
              />
            </div>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:"8.5em"}}>
              <font className="">
                {"Description/Notes"}
              </font>
            </div>
            <div className="flex11a">
              <LibraryReact.Text
                p_value={s_newLinkToOnlineDocumentDescriptionNotes}
                p_styleObj={{width:"100%"}}
                p_tabIndex={4}
                f_onChange={this.onchange_new_link_to_online_document_description_notes}
              />
            </div>
          </div>
        </div>
        <div className="flex00a displayFlexRowHcVc tbMedPad">
          <div className="flex00a lrMedMargin">
            <CEButton
              p_type="blue"
              p_text="Create Online Document"
              p_tabIndex={5}
              f_onClick={this.onclick_confirm_button}
            />
          </div>
          <div className="flex00a lrMedMargin">
            <CEButton
              p_type="gray"
              p_text="Cancel"
              p_tabIndex={6}
              f_onClick={this.props.f_onClickCancel}
            />
          </div>
        </div>
      </FloatingBoxWithSaveCancel>
    );
  }
}

class FolderTrail extends Component { //props: p_tblName, p_folderIDOpen, p_folderTrailArrayOfObjs, f_onClickFolderTrailLink
  render() {
    const p_tblName = this.props.p_tblName;
    const p_folderIDOpen = this.props.p_folderIDOpen;
    const p_folderTrailArrayOfObjs = this.props.p_folderTrailArrayOfObjs;

    return(
      <div className="displayFlexRowVc flexWrap">
        <FolderTrailItem
          p_tblName={p_tblName}
          p_folderObj={{id:-1, display_name:"Top Level"}} //display_name is a house used by FolderTrail
          p_isCurrentFolderTF={(p_folderIDOpen === -1)}
          f_onClick={this.props.f_onClickFolderTrailLink}
        />
        {p_folderTrailArrayOfObjs.map((m_folderObj) =>
          <FolderTrailItem
            key={m_folderObj.id}
            p_tblName={p_tblName}
            p_folderObj={m_folderObj}
            p_isCurrentFolderTF={(p_folderIDOpen === m_folderObj.id)}
            f_onClick={this.props.f_onClickFolderTrailLink}
          />
        )}
      </div>
    );
  }
}

class FolderTrailItem extends Component { //props: p_tblName, p_folderObj, p_isCurrentFolderTF, f_onClick
  onclick_folder_link = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(this.props.p_folderObj.id);
    }
  }

  render() {
    const p_tblName = this.props.p_tblName;
    const p_folderObj = this.props.p_folderObj;
    const p_isCurrentFolderTF = this.props.p_isCurrentFolderTF;

    const isTopHomeTF = (p_folderObj.id <= 0);

    return(
      <>
        {(!isTopHomeTF) &&
          <div className="flex00a" style={{marginLeft:"0.3em", marginRight:"0.1em"}}>
            <font className="fontTextLight">
              {"\u25b6"}
            </font>
          </div>
        }
        <FolderDropShellWithClick
          p_tblName={p_tblName}
          p_folderID={p_folderObj.id}
          p_class={"overflowHidden lrPad " + ((p_isCurrentFolderTF) ? ("") : ("cursorPointer"))}
          p_styleObj={{height:"1.5em"}}
          p_title={((p_isCurrentFolderTF) ? ("Currently viewing contents of folder '" + p_folderObj.display_name + "'") : ("Click to view contents of folder '" + p_folderObj.display_name + "'"))}
          p_allowDropTF={(!p_isCurrentFolderTF)}
          p_dragOverBorderColor="bad"
          f_onClick={((p_isCurrentFolderTF) ? (undefined) : (this.onclick_folder_link))}>
          <div style={{lineHeight:"1.6em"}}>
            <font className={"fontItalic " + ((p_isCurrentFolderTF) ? ("fontBold") : ("fontTextLight"))}>
              {p_folderObj.display_name}
            </font>
          </div>
        </FolderDropShellWithClick>
      </>
    );
  }
}

class FoldersList extends Component { //p_tblName, p_folderIDOpen, p_foldersArrayOfObjs, p_filesArrayOfObjs, f_onClickFolder, f_onDeleteFoldersAndFiles
  render() {
    return(
      this.props.p_foldersArrayOfObjs.map((i_folderObj) =>
        (i_folderObj.parent_folder_id === this.props.p_folderIDOpen) &&
        <Folder
          key={i_folderObj.id}
          p_tblName={this.props.p_tblName}
          p_folderObj={i_folderObj}
          p_foldersArrayOfObjs={this.props.p_foldersArrayOfObjs}
          p_filesArrayOfObjs={this.props.p_filesArrayOfObjs}
          f_onClick={this.props.f_onClickFolder}
          f_onDeleteFoldersAndFiles={this.props.f_onDeleteFoldersAndFiles}
        />
      )
    );
  }
}

class ReturnToPreviousFolder extends Component { //props: p_tblName, p_folderIDOpen, p_foldersArrayOfObjs, f_onClick
  onclick_return_to_previous_folder = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      const previousFolderID = this.compute_previous_folder_id();
      this.props.f_onClick(previousFolderID);
    }
  }

  compute_previous_folder_id = () => {
    const folderTrailIDsArray = JSFUNC.tree_field_array_from_top_to_node(this.props.p_foldersArrayOfObjs, "parent_folder_id", this.props.p_folderIDOpen, "id");
    const numTrailFolders = folderTrailIDsArray.length;
    if(numTrailFolders > 1) { //if deeper than 1 level
      return(folderTrailIDsArray[numTrailFolders - 2]); //return to the 2nd to last id
    }
    return(-1); //in 1 level deep, return to top level at -1
  }

  render() {
    const p_tblName = this.props.p_tblName;
    const p_folderIDOpen = this.props.p_folderIDOpen;
    const p_foldersArrayOfObjs = this.props.p_foldersArrayOfObjs;

    const previousFolderID = this.compute_previous_folder_id();
    
    return(
      <FolderDropShellWithClick
        p_tblName={p_tblName}
        p_folderID={previousFolderID}
        p_class="displayFlexRowVc hoverLighterBlueGradient cursorPointer"
        p_styleObj={{height:"3em", backgroundColor:"#f4f4f4", border:"solid 1px", borderColor:"#eee #eee #bbb #eee"}}
        p_title="Click to return to the previous folder"
        p_allowDropTF={true}
        p_dragOverBorderColor="bbb"
        f_onClick={this.onclick_return_to_previous_folder}>
        <div className="flex00a lrMargin">
          <font className="font20">
            {"\u21a9"}
          </font>
        </div>
        <div className="flex11a">
          <LibraryReact.Nowrap p_fontClass="fontItalic">
            {"Return to Previous Folder"}
          </LibraryReact.Nowrap>
        </div>
      </FolderDropShellWithClick>
    );
  }
}

const Folder = inject("UserMobx")(observer(
class Folder extends Component { //props: p_tblName, p_folderObj, p_foldersArrayOfObjs, p_filesArrayOfObjs, f_onClick, f_onDeleteFoldersAndFiles
  onclick_folder = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(this.props.p_folderObj.id);
    }
  }

  onsave_rename_folder = (i_updatedFolderName) => {
    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - Folder", "onsave_rename_folder", ["i_updatedFolderName"], [i_updatedFolderName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(this.props.p_tblName, this.props.p_folderObj.id, "display_name", i_updatedFolderName, "s");
    C_CallPhpTblUID.execute();
  }

  ondelete_folder = () => {
    if(this.props.f_onDeleteFoldersAndFiles) {
      const [allFolderIDsArray, fileIDsContainedInFoldersArray] = this.compute_delete_folder_contents();
      this.props.f_onDeleteFoldersAndFiles(allFolderIDsArray, fileIDsContainedInFoldersArray);
    }
  }

  compute_delete_folder_contents = () => {
    //recursive call to find all folderIDs within this tree below and including the folder being deleted
    var allFolderIDsArray = JSFUNC.recursive_array_of_ids_under_node_in_tree(this.props.p_foldersArrayOfObjs, "parent_folder_id", this.props.p_folderObj.id);
    allFolderIDsArray = JSFUNC.merge_unique([this.props.p_folderObj.id], allFolderIDsArray);

    //get all fileIDs that are in any of the folders listed
    var fileIDsContainedInFoldersArray = [];
    for(let f = 0; f < this.props.p_filesArrayOfObjs.length; f++) {
      if(JSFUNC.in_array(this.props.p_filesArrayOfObjs[f].parent_folder_id, allFolderIDsArray)) {
        fileIDsContainedInFoldersArray.push(this.props.p_filesArrayOfObjs[f].id);
      }
    }

    return([allFolderIDsArray, fileIDsContainedInFoldersArray]);
  }

  render() {
    const p_tblName = this.props.p_tblName;
    const p_folderObj = this.props.p_folderObj;
    const p_foldersArrayOfObjs = this.props.p_foldersArrayOfObjs;
    const p_filesArrayOfObjs = this.props.p_filesArrayOfObjs;

    const c_userCanDeleteFilesTF = this.props.UserMobx.c_userCanDeleteFilesTF;

    //compute all folders and files that would be deleted if the folder was deleted
    const [allFolderIDsArray, fileIDsContainedInFoldersArray] = this.compute_delete_folder_contents();
    const numFoldersToDelete = allFolderIDsArray.length;
    const numFilesWithinFolderAndSubfolders = fileIDsContainedInFoldersArray.length;
    var deleteMessage = "Are you sure you want to delete folder '" + p_folderObj.display_name + "'?\r\n\r\n";
    deleteMessage += "Contents to be deleted:\r\n";
    deleteMessage += "Folders (" + numFoldersToDelete + "):\r\n";
    for(let folderID of allFolderIDsArray) {
      var folderObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(p_foldersArrayOfObjs, "id", folderID);
      var folderName = ((folderObj === undefined) ? ("--Folder Does Not Exist (ID:" + folderID + ")--") : (folderObj.display_name));
      deleteMessage += " - " + folderName + "\r\n";
    }
    deleteMessage += "\r\nFiles (" + numFilesWithinFolderAndSubfolders + "):\r\n";
    for(let fileID of fileIDsContainedInFoldersArray) {
      var fileObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(p_filesArrayOfObjs, "id", fileID);
      var fileName = ((fileObj === undefined) ? ("--Folder Does Not Exist (ID:" + fileID + ")--") : (fileObj.display_name));
      deleteMessage += " - " + fileName + "\r\n";
    }

    const folderMenuItemsArrayOfObjs = [
      {
        displayName: "Rename Folder",
        confirmType: "inputText",
        confirmTitle: "Rename Folder",
        confirmTextInitialValue: p_folderObj.display_name,
        confirmButton1Name: "Rename",
        confirmMessage: "Rename",
        functionOnClickConfirmButton: this.onsave_rename_folder
      },
      {
        displayName: "Delete",
        confirmType: "confirmDelete",
        confirmMessage: deleteMessage,
        functionOnClickConfirmButton: this.ondelete_folder
      }
    ];

    return(
      <FolderDropShellWithClick
        p_tblName={p_tblName}
        p_folderID={p_folderObj.id}
        p_class="displayFlexRowVc hoverLighterBlueGradient cursorPointer"
        p_styleObj={{height:"3.4em", backgroundColor:"#f4eef8", border:"solid 1px", borderColor:"#dbe #dbe #bad #dbe"}}
        p_title={"Click to view contents of folder '" + p_folderObj.display_name + "'"}
        p_allowDropTF={true}
        p_dragOverBorderColor="bad"
        f_onClick={this.onclick_folder}>
        <div className="flex00a lrPad">
          <SvgFolder p_widthEm={3} p_heightEm={1.8} p_lineColor="777" />
        </div>
        <div className="flex11a lrPad">
          <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLighter">
            {p_folderObj.display_name}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex00a lrPad">
          <font className="fontItalic fontTextLighter">
            {numFilesWithinFolderAndSubfolders + " " + JSFUNC.plural(numFilesWithinFolderAndSubfolders, "File", "Files")}
          </font>
        </div>
        <div className="flex00a lrPad">
          {(c_userCanDeleteFilesTF) &&
            <VerticalDotsMenu p_menuItemsArrayOfObjs={folderMenuItemsArrayOfObjs} />
          }
        </div>
      </FolderDropShellWithClick>
    );
  }
}));

class FolderDropShellWithClick extends Component { //props: p_tblName, p_folderID, p_class, p_styleObj, p_title, p_allowDropTF, p_dragOverBorderColor, f_onClick, children
  ondrop_file_onto_folder_shell = (i_droppedFileID, event) => {
    const p_tblName = this.props.p_tblName;
    const p_folderID = this.props.p_folderID;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FolderDropShellWithClick", "ondrop_file_onto_folder_shell", ["i_droppedFileID"], [i_droppedFileID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(p_tblName, i_droppedFileID, "parent_folder_id", p_folderID, "i");
    C_CallPhpTblUID.execute();
  }

  onclick_folder_shell = (event) => {
    const p_folderID = this.props.p_folderID;

    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(p_folderID);
    }
  }

  render() {
    const p_tblName = this.props.p_tblName;
    const p_folderID = this.props.p_folderID;
    const p_class = this.props.p_class;
    const p_styleObj = this.props.p_styleObj;
    const p_title = this.props.p_title;
    const p_allowDropTF = JSFUNC.prop_value(this.props.p_allowDropTF, true);
    const p_dragOverBorderColor = this.props.p_dragOverBorderColor;
    
    return(
      <CE3Drag3Drop3Shell3
        p_uniqueString={"ffs_" + p_tblName}
        p_itemID={p_folderID}
        p_draggableTF={false}
        p_droppableTF={p_allowDropTF}
        p_dropZoneIsInvisibleOverlayTF={true}
        p_dropZoneOversizeWidthEm={undefined}
        p_class={p_class}
        p_styleObj={p_styleObj}
        p_title={p_title}
        p_dragOverClass={undefined}
        p_dragOverStyleObj={{border:"solid 2px #" + p_dragOverBorderColor, backgroundColor:"rgba(0,0,0,0.1)"}}
        f_isDragOverTF={undefined}
        f_onDropMatchingItem={this.ondrop_file_onto_folder_shell}
        f_onDropForeignItem={undefined}
        f_onDropFiles={undefined}
        f_onClick={this.onclick_folder_shell}>
        {this.props.children}
      </CE3Drag3Drop3Shell3>
    );
  }
}

class FilesList extends Component { //props: p_tblName, p_folderIDOpen, p_filesArrayOfObjs, p_viewWideTrueNarrowFalse, f_onDeleteFoldersAndFiles
  render() {
    return(
      this.props.p_filesArrayOfObjs.map((m_fileObj) =>
        (m_fileObj.parent_folder_id === this.props.p_folderIDOpen) &&
        <FileItem
          key={m_fileObj.id}
          p_tblName={this.props.p_tblName}
          p_fileObj={m_fileObj}
          p_viewWideTrueNarrowFalse={this.props.p_viewWideTrueNarrowFalse}
          f_onDeleteFoldersAndFiles={this.props.f_onDeleteFoldersAndFiles}
        />
      )
    );
  }
}

const FileItem = inject("DatabaseMobx", "UserMobx")(observer(
class FileItem extends Component { //props: p_tblName, p_fileObj, p_viewWideTrueNarrowFalse, f_onDeleteFoldersAndFiles
  constructor(props) {
    super(props);
    this.state = {
      s_editingFileInfoTF: false
    }
  }

  onclick_edit_file_info = (event) => {
    this.setState({s_editingFileInfoTF:true});
  }

  onclick_close_edit_file_info = () => {
    this.setState({s_editingFileInfoTF:false});
  }

  onclick_online_link_open_in_new_tab = (event) => {
    const p_fileObj = this.props.p_fileObj;

    event.preventDefault();
    event.stopPropagation(); //prevent clicking the file row beneath this icon which opens the edit file info floating box

    window.open(p_fileObj.file_loc, "_blank");
  }

  ondelete_file = () => {
    if(this.props.f_onDeleteFoldersAndFiles) {
      this.props.f_onDeleteFoldersAndFiles([], [this.props.p_fileObj.id]); //delete 0 folders and 1 file
    }
  }

  render() {
    const s_editingFileInfoTF = this.state.s_editingFileInfoTF;

    const p_tblName = this.props.p_tblName;
    const p_fileObj = this.props.p_fileObj;
    const p_viewWideTrueNarrowFalse = this.props.p_viewWideTrueNarrowFalse;

    const c_userCanEditInfoOrMoveFilesTF = this.props.UserMobx.c_userCanEditInfoOrMoveFilesTF;
    const c_userCanDeleteFilesTF = this.props.UserMobx.c_userCanDeleteFilesTF;

    //get file extension for file type hover title info
    var fileIconTitle = undefined;
    if(p_fileObj.isOnlineLinkTF) {
      //for online documents, try to find the file extension on the end of the full web address, if not simply say 'Online Document'
      const fileLocPeriodSplitArray = p_fileObj.file_loc.split(".");
      const fileLocLastPeriodSplitString = fileLocPeriodSplitArray[fileLocPeriodSplitArray.length - 1];
      if(fileLocLastPeriodSplitString.length <= 5) {
        fileIconTitle = "Online '." + fileLocLastPeriodSplitString + "' File Type";
      }
      else {
        fileIconTitle = "Online Document";
      }
    }
    else {
      const fileLocFilePartsObj = JSFUNC.file_parts_obj(p_fileObj.file_loc);
      fileIconTitle = "'." + fileLocFilePartsObj.fileExt + "' File Type";
    }

    //get upload date mask
    const maskUploadDate = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(p_fileObj.upload_date, this.props.DatabaseMobx.c_genericDateFieldTypeObj);

    const fileEditDragTitleLine = "\n[Click to edit name/keywords/notes for this file]\n[Drag/drop file onto a folder, or the 'Return to Previous Folder', to change its location]";
    const displayNameTitle = "File Name: " + p_fileObj.display_name + fileEditDragTitleLine;
    const keywordsTitle = "File Keywords: " + p_fileObj.keywords + fileEditDragTitleLine;
    const notesTitle = "File Description/Notes: " + p_fileObj.notes + fileEditDragTitleLine;
    const uploadUserNameTitle = "File Uploaded By: " + p_fileObj.uploadUserNameMaskPlainText + fileEditDragTitleLine;
    const uploadDateTitle = "File Upload Date: " + maskUploadDate + fileEditDragTitleLine;

    const fileDisplayNameComponent = (
      <div className={"flex11a displayFlexRowVc lrMedPad " + ((p_viewWideTrueNarrowFalse) ? ("borderR1ddd") : (""))} style={{flexBasis:"300em"}} title={displayNameTitle}>
        <LibraryReact.Nowrap p_fontClass="font11 fontBold fontPurple">
          {p_fileObj.display_name}
        </LibraryReact.Nowrap>
      </div>
    );

    const fileKeywordsComponent = (
      <div className="flex11a displayFlexRowVc lrMedPad borderR1ddd" style={{flexBasis:"100em"}} title={keywordsTitle}>
        <LibraryReact.Nowrap p_fontClass="fontTextLight">
          {p_fileObj.keywords}
        </LibraryReact.Nowrap>
      </div>
    );

    const fileNotesComponent = (
      <div className="flex11a displayFlexRowVc lrMedPad borderR1ddd" style={{flexBasis:"100em"}} title={notesTitle}>
        <LibraryReact.Nowrap p_fontClass="fontTextLight">
          {p_fileObj.notes}
        </LibraryReact.Nowrap>
      </div>
    );

    const fileUploadUserNameComponent = (
      <div className="flex11a displayFlexRowVc lrMedPad borderR1ddd" style={{flexBasis:"100em"}} title={uploadUserNameTitle}>
        <LibraryReact.Nowrap p_fontClass="fontItalic">
          {p_fileObj.uploadUserNameMaskPlainText}
        </LibraryReact.Nowrap>
      </div>
    );

    const fileUploadDateComponent = (
      <div className="flex00a displayFlexRowVc lrMedPad borderR1ddd" style={{flexBasis:"8em"}} title={uploadDateTitle}>
        <font className="fontItalic">
          {maskUploadDate}
        </font>
      </div>
    );

    var fileRowHeightEm = 3.4;
    var fileNameKeywordsNotesUserComponent = null;
    if(p_viewWideTrueNarrowFalse) { //wide view, all fields in single row
      fileRowHeightEm = 3.4;
      fileNameKeywordsNotesUserComponent = (
        <>
          {fileDisplayNameComponent}
          {fileKeywordsComponent}
          {fileNotesComponent}
          {fileUploadUserNameComponent}
          {fileUploadDateComponent}
        </>
      );
    }
    else { //narrow view, display file name on top half, keywords/notes/user on bottom half
      fileRowHeightEm = 3.4;
      fileNameKeywordsNotesUserComponent = (
        <div className="flex11a displayFlexColumnVc">
          <div className="">
            {fileDisplayNameComponent}
          </div>
          <div className="displayFlexRow microTopMargin">
            {fileKeywordsComponent}
            {fileNotesComponent}
            {fileUploadUserNameComponent}
            {fileUploadDateComponent}
          </div>
        </div>
      );
    }

    return(
      <>
        <CE3Drag3Drop3Shell3
          p_uniqueString={"ffs_" + p_tblName}
          p_itemID={p_fileObj.id}
          p_draggableTF={c_userCanEditInfoOrMoveFilesTF}
          p_droppableTF={false}
          p_dropZoneIsInvisibleOverlayTF={false}
          p_dropZoneOversizeWidthEm={undefined}
          p_class={"displayFlexRow lrMargin tbPad bgWhite border1ddd borderB1bbb hoverLighterBlueGradient noSelect " + ((c_userCanEditInfoOrMoveFilesTF) ? ("cursorPointer") : (""))}
          p_styleObj={{height:fileRowHeightEm + "em"}}
          p_title={undefined}
          p_dragOverClass={undefined}
          p_dragOverStyleObj={undefined}
          f_isDragOverTF={undefined}
          f_onDropMatchingItem={undefined}
          f_onDropForeignItem={undefined}
          f_onDropFiles={undefined}
          f_onClick={((c_userCanEditInfoOrMoveFilesTF) ? (this.onclick_edit_file_info) : (undefined))}>
          <div
            className="flex00a displayFlexColumnHcVc"
            style={{flexBasis:"3.5em"}}
            title={fileIconTitle}>
            <SvgFile p_fileLoc={p_fileObj.file_loc} p_xmlType={p_fileObj.xml_type} p_sizeEm={2.8} />
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3.2em"}}>
            {(p_fileObj.isOnlineLinkTF) ? (
              <div
                className="displayFlexColumnHcVc bgLighterGrayGradient hoverLightestGrayGradient border1 bevelBorderLightColors borderRadius05"
                style={{width:"2.4em", height:"2.4em"}}
                title={"Click to access/download '" + p_fileObj.display_name + "' from its online source in a new tab"}
                onClick={this.onclick_online_link_open_in_new_tab}>
                <SvgChain
                  p_widthEm={2.1}
                  p_heightEm={2.1}
                  p_xStart0to100={0}
                  p_xEnd0to100={100}
                  p_yStart0to100={30}
                  p_yEnd0to100={70}
                  p_lineWidth="6%"
                  p_lineColor="666"
                />
              </div>
            ) : (
              <SmallDownloadFileButtonForServerFile
                p_fileLocRootUploadsOrWeb="uploads"
                p_fileLocRelativeFromRoot={p_fileObj.file_loc}
                p_downloadSaveAsFileName={p_fileObj.display_name}
                p_title={"Click to download '" + p_fileObj.display_name + "'"}
              />
            )}
          </div>
          {fileNameKeywordsNotesUserComponent}
          {(c_userCanDeleteFilesTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
              <DeleteMenu
                p_message={"Are you sure you want to delete file '" + p_fileObj.display_name + "'?"}
                f_onDelete={this.ondelete_file}
              />
            </div>
          }
        </CE3Drag3Drop3Shell3>
        {(s_editingFileInfoTF) &&
          <FileEditFileInfoFloatingBox
            p_tblName={p_tblName}
            p_fileObj={p_fileObj}
            p_uploadUserNameMaskPlainText={p_fileObj.uploadUserNameMaskPlainText}
            f_onClickClose={this.onclick_close_edit_file_info}
          />
        }
      </>
    );
  }
}));

const FileEditFileInfoFloatingBox = inject("DatabaseMobx")(observer(
class FileEditFileInfoFloatingBox extends Component { //props: p_tblName, p_fileObj, p_uploadUserNameMaskPlainText, f_onClickClose
  onsave_file_display_name = (i_newValue) => { this.update_file_field("display_name", i_newValue, "s"); }
  onsave_file_file_loc = (i_newValue) => { this.update_file_field("file_loc", i_newValue, "s"); }
  onsave_file_keywords = (i_newValue) => { this.update_file_field("keywords", i_newValue, "s"); }
  onsave_file_notes = (i_newValue) => { this.update_file_field("notes", i_newValue, "s"); }
  onsave_parent_folder_id = (i_newValue) => { this.update_file_field("parent_folder_id", i_newValue, "i"); }
  onsave_file_access = (i_newValue) => { this.update_file_field("access", i_newValue, "s"); }
  onsave_file_xml_type = (i_newValue) => { this.update_file_field("xml_type", i_newValue, "s"); }

  update_file_field = (i_fieldDbName, i_newValue, i_idsb) => {
    const p_tblName = this.props.p_tblName;
    const p_fileObj = this.props.p_fileObj;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FileEditFileInfoFloatingBox", "update_file_field", ["i_fieldDbName", "i_newValue", "i_idsb"], [i_fieldDbName, i_newValue, i_idsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(p_tblName, p_fileObj.id, i_fieldDbName, i_newValue, i_idsb);
    C_CallPhpTblUID.execute();
  }

  render() {
    const p_tblName = this.props.p_tblName;
    const p_fileObj = this.props.p_fileObj;

    const c_selectFileFolderSystemFileAccessFieldTypeObj = this.props.DatabaseMobx.c_selectFileFolderSystemFileAccessFieldTypeObj;
    const c_selectFileFolderSystemXmlTypeFieldTypeObj = this.props.DatabaseMobx.c_selectFileFolderSystemXmlTypeFieldTypeObj;

    const containerClass = "tbMargin";
    const fieldClass = "fontBlue";
    const fieldWidth = "10em";
    const parentFolderColor = "f4eef8";

    //build a selectWithSearchDataObj for selecting a parent folder
    var parentFolderValueArray = [-1];
    var parentFolderDisplayArray = ["<<Top Level>>"];
    var parentFolderTreeIDArray = ["00"];
    var parentFolderColorArray = [parentFolderColor];
    const tblRefMapOfMaps = this.props.DatabaseMobx.tbl_ref_from_tbl_name(p_tblName);
    const allFoldersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(tblRefMapOfMaps, "folder0_file1", 0, "display_name", true, true);
    const orderedFolderIDsArray = JSFUNC.recursive_array_of_ids_under_node_in_tree(allFoldersArrayOfObjs, "parent_folder_id", -1);
    for(let folderID of orderedFolderIDsArray) {
      var folderNamesFromTopToNodeArray = JSFUNC.tree_field_array_from_top_to_node(allFoldersArrayOfObjs, "parent_folder_id", folderID, "display_name");
      if(JSFUNC.is_array(folderNamesFromTopToNodeArray)) {
        var numFoldersFromTop = folderNamesFromTopToNodeArray.length;
        if(numFoldersFromTop > 0) {
          var folderName = folderNamesFromTopToNodeArray[(numFoldersFromTop - 1)];
          var treeIDString = "00";
          for(let t = 0; t < numFoldersFromTop; t++) {
            treeIDString += "00";
          }
          parentFolderValueArray.push(folderID);
          parentFolderDisplayArray.push(folderName);
          parentFolderTreeIDArray.push(treeIDString);
          parentFolderColorArray.push(parentFolderColor);
        }
      }
    }
    const swsOptionsObj = {treeIDArray:parentFolderTreeIDArray, colorArray:parentFolderColorArray};
    const parentFolderSwsDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Parent Folder", parentFolderValueArray, true, parentFolderDisplayArray, swsOptionsObj);
    const selectParentFolderFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", parentFolderSwsDataObj);

    //if the file extensions is '.xml' display the field 'xml_type' for editing
    const fileLocFilePartsObj = JSFUNC.file_parts_obj(p_fileObj.file_loc);
    const fileExtIsXmlTF = (fileLocFilePartsObj.fileExt === "xml");

    return(
      <FloatingBoxWithSaveCancel
        p_trblFlag="addEditDeleteTableEditItem"
        p_title={"Editing Info for File '" + p_fileObj.display_name + "'"}
        f_onClickSave={this.props.f_onClickClose}>
        <div className="flex11a yScroll medFullPad">
          <CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_parent_folder_id"}
            p_fieldDisplayName="Parent Folder"
            p_fieldTypeObj={selectParentFolderFieldTypeObj}
            p_valueRaw={p_fileObj.parent_folder_id}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_parent_folder_id}
          />
          <div className="medTopMargin medBottomMargin borderT1ddd" />
          {(p_fileObj.isOnlineLinkTF) &&
            <CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_file_loc"}
              p_fieldDisplayName="Online Link URL"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
              p_valueRaw={p_fileObj.file_loc}
              p_valueIsEditableTFU={true}
              p_containerClass={containerClass}
              p_fieldClass={fieldClass}
              p_fieldWidth={fieldWidth}
              f_onSaveChanged={this.onsave_file_file_loc}
            />
          }
          <CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_display_name"}
            p_fieldDisplayName="File Name"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            p_valueRaw={p_fileObj.display_name}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_file_display_name}
          />
          {(fileExtIsXmlTF) &&
            <CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_xml_type"}
              p_fieldDisplayName="XML File Type"
              p_fieldTypeObj={c_selectFileFolderSystemXmlTypeFieldTypeObj}
              p_valueRaw={p_fileObj.xml_type}
              p_valueIsEditableTFU={true}
              p_containerClass={containerClass}
              p_fieldClass={fieldClass}
              p_fieldWidth={fieldWidth}
              f_onSaveChanged={this.onsave_file_xml_type}
            />
          }
          <CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_keywords"}
            p_fieldDisplayName="Search Keywords"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            p_valueRaw={p_fileObj.keywords}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_file_keywords}
          />
          <CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_notes"}
            p_fieldDisplayName="Description/Notes"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            p_valueRaw={p_fileObj.notes}
            p_valueIsEditableTFU={true}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
            f_onSaveChanged={this.onsave_file_notes}
          />
          {(false) &&
            <CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_access"}
              p_fieldDisplayName="Access"
              p_fieldTypeObj={c_selectFileFolderSystemFileAccessFieldTypeObj}
              p_valueRaw={"open"}
              p_valueIsEditableTFU={true}
              p_containerClass={containerClass}
              p_fieldClass={fieldClass}
              p_fieldWidth={fieldWidth}
              f_onSaveChanged={this.onsave_file_access}
            />
          }
          <CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_upload_date"}
            p_fieldDisplayName="Upload Date"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
            p_valueRaw={p_fileObj.upload_date}
            p_valueIsEditableTFU={false}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
          />
          <CaptureExecFieldEditSaveCancel
            p_ceEditItemString={"ffs_" + p_tblName + "fileID" + p_fileObj.id + "_upload_user_name"}
            p_fieldDisplayName="Uploaded By"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            p_valueRaw={this.props.p_uploadUserNameMaskPlainText}
            p_valueIsEditableTFU={false}
            p_containerClass={containerClass}
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidth}
          />
        </div>
      </FloatingBoxWithSaveCancel>
    );
  }
}));



const FileFolderSystemUploadFilesButtonDropZone = inject("CaptureExecMobx", "UserMobx")(observer(
class FileFolderSystemUploadFilesButtonDropZone extends Component { //props: p_tblName, p_subfoldersPath, p_tblFilterFieldNamesArray, p_tblFilterValuesArray, p_parentFolderID, p_defaultXmlType, p_allowedExtsArray, f_onPerformFFSAction(i_successFilesArrayOfObjs)
  //f_onPerformFFSAction(i_actionText, i_descriptionText)

  constructor(props) {
    super(props);
    this.state = {
      s_uploadState: "initial", //"initial", "uploading", "postUploadFloatingBox"
      s_totalNumFilesToUpload: 0,
      s_currentFileNumberUploading: 0,
      s_currentFileDisplayNameAndExtUploading: "",
      s_successFilesArrayOfObjs: [],
      s_badExtFilesArrayOfObjs: [],
      s_errorFilesArrayOfObjs: []
    };
  }

  ondrop_upload_files = (i_dataTransferFilesArray) => {
    const tblName = this.props.p_tblName;
    const foldersPathFromCompanyUploadsFolder = this.props.p_subfoldersPath;
    const tblFilterFieldNamesArray = this.props.p_tblFilterFieldNamesArray;
    const tblFilterValuesArray = this.props.p_tblFilterValuesArray;
    const parentFolderID = this.props.p_parentFolderID;
    const defaultXmlType = this.props.p_defaultXmlType;
    const p_allowedExtsArray = this.props.p_allowedExtsArray;

    //determine the number of files to upload
    const maxNumFilesUploadLimit = 100; //limit the maximum number of files to be simultaneously uploaded to 100
    const numFilesAttemptingToUpload = i_dataTransferFilesArray.length;

    //initialize inputs to the recursive function call
    const initialTotalNumFilesToUpload = ((numFilesAttemptingToUpload > maxNumFilesUploadLimit) ? (maxNumFilesUploadLimit) : (numFilesAttemptingToUpload));
    const initialCurrentFileNumberUploading = 1;
    const initialCurrentFileDisplayNameAndExtUploading = "";
    const initialSuccessFilesArrayOfObjs = [];
    const initialBadExtFilesArrayOfObjs = [];
    const initialErrorFilesArrayOfObjs = [];

    //set the button to the uploading state where it can't be pushed
    this.setState({
      s_uploadState: "uploading",
      s_totalNumFilesToUpload: initialTotalNumFilesToUpload,
      s_currentFileNumberUploading: initialCurrentFileNumberUploading,
      s_currentFileDisplayNameAndExtUploading: initialCurrentFileDisplayNameAndExtUploading,
      s_successFilesArrayOfObjs: initialSuccessFilesArrayOfObjs,
      s_badExtFilesArrayOfObjs: initialBadExtFilesArrayOfObjs,
      s_errorFilesArrayOfObjs: initialErrorFilesArrayOfObjs
    });

    //get the date/time for this upload
    const uploadDate = JSFUNC.now_date();
    const uploadDateTimeNumber = JSFUNC.now_datetime_utc_number();

    //begin the recursive call to upload all files
    this.recursive_upload_single_file(i_dataTransferFilesArray, uploadDate, uploadDateTimeNumber, initialTotalNumFilesToUpload, initialCurrentFileNumberUploading, initialSuccessFilesArrayOfObjs, initialBadExtFilesArrayOfObjs, initialErrorFilesArrayOfObjs);
  }

  recursive_upload_single_file = (i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, i_currentFileNumberUploading, i_successFilesArrayOfObjs, i_badExtFilesArrayOfObjs, i_errorFilesArrayOfObjs) => {
    const tblName = this.props.p_tblName;
    const foldersPathFromCompanyUploadsFolder = this.props.p_subfoldersPath;
    const tblFilterFieldNamesArray = this.props.p_tblFilterFieldNamesArray;
    const tblFilterValuesArray = this.props.p_tblFilterValuesArray;
    const parentFolderID = this.props.p_parentFolderID;
    const defaultXmlType = this.props.p_defaultXmlType;
    const p_allowedExtsArray = this.props.p_allowedExtsArray;

    const uploadUserID = this.props.UserMobx.o_userID;
    const uploadUserName = this.props.UserMobx.c_userName;

    if((i_totalNumFilesToUpload === 0) || (i_currentFileNumberUploading > i_totalNumFilesToUpload)) { //recursive function is finished uploading all files, call the final input function
      this.after_recursive_uploads_change_upload_state_to_post_uploads_floating_box(i_successFilesArrayOfObjs, i_badExtFilesArrayOfObjs, i_errorFilesArrayOfObjs);
    }
    else { //upload this recursive file
      var updatedCurrentFileNumberUploading = (i_currentFileNumberUploading + 1); //increment the file number for the next recursive call of this function (i_currentFileNumberUploading is used throughout this iteration)
      var updatedSuccessFilesArrayOfObjs = i_successFilesArrayOfObjs;
      var updatedBadExtFilesArrayOfObjs = i_badExtFilesArrayOfObjs;
      var updatedErrorFilesArrayOfObjs = i_errorFilesArrayOfObjs;

      //index the fileDataTransferArray to get the file data obj, file count number is 1 ahead of data transfer array file index
      const dataTransferFileObj = i_dataTransferFilesArray[(i_currentFileNumberUploading - 1)];

      if(dataTransferFileObj === undefined) { //if the file transfer data obj does not have a 'name' property, list this file has an error
        this.setState({
          s_currentFileNumberUploading: i_currentFileNumberUploading,
          s_currentFileDisplayNameAndExtUploading: "[Invalid File Transfer Data from Uploaded Files List]"
        });

        updatedErrorFilesArrayOfObjs = updatedErrorFilesArrayOfObjs.concat(["Invalid File Transfer Data from Uploaded Files List [File #" + i_currentFileNumberUploading + "]"]);
        this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
      }
      else { //file data transfer obj is valid
        var currentFileDisplayNameAndExtUploading = dataTransferFileObj.name;

        //mark the state that this next file number is currently being worked on
        this.setState({
          s_currentFileNumberUploading: i_currentFileNumberUploading,
          s_currentFileDisplayNameAndExtUploading: currentFileDisplayNameAndExtUploading
        });

        //break down the file given "File Display Name.ext" into its file parts
        const filePartsObj = JSFUNC.file_parts_obj(currentFileDisplayNameAndExtUploading);
        const fileDisplayName = filePartsObj.fileName; //"File Display Name"
        const fileExt = filePartsObj.fileExt; //"ext"

        const fileExtIsValidTF = (JSFUNC.is_string(fileExt) && (fileExt.length > 0)); //the uploaded file must have an extension with at least 1 letter in it to be uploaded, otherwise an error is recorded for this file
        const fileExtIsAllowedTF = (!JSFUNC.is_array(p_allowedExtsArray) || (JSFUNC.in_array(fileExt, p_allowedExtsArray))); //if p_allowedExtsArray is not provided, all exts are allowed, if it is an array, make sure that this fileExt is within that list

        //when uploading multiple files, each one must have a unique name, if not, the first one is uploaded, but all duplicates are errors
        const alreadyUploadedIdenticalDisplayNameFileObj = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(updatedSuccessFilesArrayOfObjs, "fileDisplayNameAndExt", currentFileDisplayNameAndExtUploading);
        const fileDisplayNameAndExtIsUniqueTF = (alreadyUploadedIdenticalDisplayNameFileObj === undefined);

        if(!fileExtIsValidTF) { //no ext is a bad ext
          updatedBadExtFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"No File Extension for this file"});
          this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
        }
        else if(!fileExtIsAllowedTF) { //ext not allowed is a bad ext
          updatedBadExtFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"File Extension '." + fileExt + "' not allowed for upload here"});
          this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
        }
        else if(!fileDisplayNameAndExtIsUniqueTF) { //duplicate file name is a bad ext
          updatedErrorFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"File Name is not unique within this multi-upload"});
          this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
        }
        else {
          const jsDescription = "CEGeneralReact - FileFolderSystemUploadFilesButtonDropZone - recursive_upload_single_file";
          const C_CallPhpTblUIDFileUpload = new JSPHP.ClassCallPhpTblUID(jsDescription);

          //create the server file name based on the uploaded file name lowercase and underscored (shorten it if it is too long) along with a starting timestamp so that the name is unique in the folder
          const maxServerFileNameNumChars = 64; //arbitrarily make 64 characters the maximum length for the file name part of the file stored on the server (display name stored in the table can be unlimited length)
          const fileDbName = JSFUNC.db_name_from_display_name(fileDisplayName); //"file_display_name"
          const fileDbNameTrim = JSFUNC.trim_string_max_chars(fileDbName, maxServerFileNameNumChars); //"file_display_name"
          const fileDbNameTrimTimeStampExt = i_uploadDateTimeNumber + "_" + fileDbNameTrim + "." + fileExt; //"20180415113259_file_display_name.ext"

          //upload the file to the server through php xhr in tblUID operation
          C_CallPhpTblUIDFileUpload.add_file_upload(dataTransferFileObj, foldersPathFromCompanyUploadsFolder, fileDbNameTrimTimeStampExt);

          //when file successfully uploads, send another db php to insert the ffs tbl record for this file, then call the next recursion
          const functionOnSuccessFileUpload = (i_parseResponse) => {
            if(i_parseResponse.outputObj.fu0 !== "1") { //error uploading file
              updatedErrorFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"Failed to upload file to server"});
              this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
            }
            else { //successful file upload
              const C_CallPhpTblUIDInsertFfsRecord = new JSPHP.ClassCallPhpTblUID("CEGeneralReact - FileFolderSystemUploadFilesButtonDropZone - recursive_upload_single_file - functionOnSuccess");

              //create the file_loc name for the file path on the server
              const fileLoc = foldersPathFromCompanyUploadsFolder + "/" + fileDbNameTrimTimeStampExt;

              //create the tbl filter values idsb array of all "i" for each filter field
              const tblFilterValuesIdsbArray = JSFUNC.array_fill(tblFilterFieldNamesArray.length, "i");

              //create the insert entry for the filefoldersystem keeping a record of this uploaded file
              const ffsFieldNamesArray = tblFilterFieldNamesArray.concat(["folder0_file1", "parent_folder_id", "fileupload0_onlinelink1", "file_loc", "display_name", "keywords", "notes", "content_unique_lowercase", "upload_date", "upload_user_id", "upload_user_name", "access", "xml_type"]);
              const ffsValuesArray = tblFilterValuesArray.concat([1, parentFolderID, 0, fileLoc, currentFileDisplayNameAndExtUploading, "", "", "", i_uploadDate, uploadUserID, uploadUserName, "open", defaultXmlType]);
              const ffsValueIdsbArray = tblFilterValuesIdsbArray.concat(["i", "i", "i", "s", "s", "s", "s", "s", "s", "i", "s", "s", "s"]);
              C_CallPhpTblUIDInsertFfsRecord.add_insert(tblName, ffsFieldNamesArray, ffsValuesArray, ffsValueIdsbArray);

              //when the ffs record is successfully inserted, record this file name as a successful upload in the state of this component
              const functionOnSuccessInsertFfsRecord = (i_parseResponse) => {
                const ffsTblRowID = i_parseResponse.outputObj.i0;
                if(!(ffsTblRowID > 0)) { //error inserting ffs record in database
                  updatedErrorFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"Failed to record file upload record"});
                }
                else {
                  updatedSuccessFilesArrayOfObjs.push({
                    fileDisplayNameAndExt: currentFileDisplayNameAndExtUploading,
                    ffsTblRowID: ffsTblRowID,
                    fileLoc: fileDbNameTrimTimeStampExt,
                    fileExt: fileExt,
                    isXmlTF: (fileExt === "xml"),
                    xmlType: defaultXmlType
                  });
                }
                this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
              }
              C_CallPhpTblUIDInsertFfsRecord.add_function("onSuccess", functionOnSuccessInsertFfsRecord);

              //if there is an error recording the ffs data row, mark this upload as an error (leave the file abandoned on the server if this happens)
              const functionOnErrorInsertFfsRecord = () => {
                updatedErrorFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"Failed to record file upload record"});
                this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
              }
              C_CallPhpTblUIDInsertFfsRecord.add_function("onError", functionOnErrorInsertFfsRecord);

              C_CallPhpTblUIDInsertFfsRecord.execute();
            }
          }
          C_CallPhpTblUIDFileUpload.add_function("onSuccess", functionOnSuccessFileUpload);

          const functionOnErrorFileUpload = () => {
            updatedErrorFilesArrayOfObjs.push({fileDisplayNameAndExt:currentFileDisplayNameAndExtUploading, errorMessage:"Failed to upload file to server"});
            this.recursive_upload_single_file(i_dataTransferFilesArray, i_uploadDate, i_uploadDateTimeNumber, i_totalNumFilesToUpload, updatedCurrentFileNumberUploading, updatedSuccessFilesArrayOfObjs, updatedBadExtFilesArrayOfObjs, updatedErrorFilesArrayOfObjs);
          }
          C_CallPhpTblUIDFileUpload.add_function("onError", functionOnErrorFileUpload);

          C_CallPhpTblUIDFileUpload.execute();
        }
      }
    }
  }

  after_recursive_uploads_change_upload_state_to_post_uploads_floating_box = (i_successFilesArrayOfObjs, i_badExtFilesArrayOfObjs, i_errorFilesArrayOfObjs) => {
    const p_tblName = this.props.p_tblName;
    const p_tblFilterFieldNamesArray = this.props.p_tblFilterFieldNamesArray;
    const p_tblFilterValuesArray = this.props.p_tblFilterValuesArray;

    const numSuccessFiles = i_successFilesArrayOfObjs.length;
    const numBadExtFiles = i_badExtFilesArrayOfObjs.length;
    const numErrorFiles = i_errorFilesArrayOfObjs.length;

    if(numSuccessFiles > 0) { //at the very end of uploading, if at least 1 file was successfully uploaded, call the input function f_onPerformFFSAction
      if(JSFUNC.is_function(this.props.f_onPerformFFSAction)) {
        //create successful file(s) upload text string
        var actionText = "";
        var descriptionText = "";
        if(numSuccessFiles === 1) {
          actionText = "Upload File";
          descriptionText = "Uploaded File '" + i_successFilesArrayOfObjs[0].fileDisplayNameAndExt + "'";
        }
        else {
          actionText = "Upload Files";
          descriptionText = "Uploaded " + numSuccessFiles + " " + JSFUNC.plural(numSuccessFiles, "File", "Files");
          for(let successFileObj of i_successFilesArrayOfObjs) {
            descriptionText += "\n - " + successFileObj.fileDisplayNameAndExt;
          }
        }
        this.props.f_onPerformFFSAction(actionText, descriptionText);
      }
    }

    //send BIT an error message when files have errors
    if(numBadExtFiles || numErrorFiles) {

      var errorMessage = "FFS tblName: " + p_tblName;
      errorMessage += "\ntblFilterFieldNamesArray: " + JSFUNC.print_array(p_tblFilterFieldNamesArray);
      errorMessage += "\ntblFilterValuesArray: " + JSFUNC.print_array(p_tblFilterValuesArray);
      for(let badExtFileObj of i_badExtFilesArrayOfObjs) {
        errorMessage += "\nBadExt File: '" + badExtFileObj.fileDisplayNameAndExt + "' [" + badExtFileObj.errorMessage + "]";
      }
      for(let errorFileObj of i_errorFilesArrayOfObjs) {
        errorMessage += "\nError File: '" + errorFileObj.fileDisplayNameAndExt + "' [" + errorFileObj.errorMessage + "]";
      }
      JSPHP.record_z_error(undefined, errorMessage);
    }

    this.setState({
      s_uploadState: "postUploadFloatingBox",
      s_successFilesArrayOfObjs: i_successFilesArrayOfObjs,
      s_badExtFilesArrayOfObjs: i_badExtFilesArrayOfObjs,
      s_errorFilesArrayOfObjs: i_errorFilesArrayOfObjs
    });
  }

  reset_upload_state_to_initial = () => {
    this.setState({
      s_uploadState: "initial",
      s_totalNumFilesToUpload: 0,
      s_currentFileNumberUploading: 0,
      s_currentFileDisplayNameAndExtUploading: "",
      s_successFilesArrayOfObjs: [],
      s_badExtFilesArrayOfObjs: [],
      s_errorFilesArrayOfObjs: []
    });
  }

  render() {
    const uploadState = this.state.s_uploadState;
    const totalNumFilesToUpload = this.state.s_totalNumFilesToUpload;
    const currentFileNumberUploading = this.state.s_currentFileNumberUploading;
    const currentFileDisplayNameAndExtUploading = this.state.s_currentFileDisplayNameAndExtUploading;
    const successFilesArrayOfObjs = this.state.s_successFilesArrayOfObjs;
    const badExtFilesArrayOfObjs = this.state.s_badExtFilesArrayOfObjs;
    const errorFilesArrayOfObjs = this.state.s_errorFilesArrayOfObjs;

    const tblName = this.props.p_tblName;
    const foldersPathFromCompanyUploadsFolder = this.props.p_subfoldersPath;
    const tblFilterFieldNamesArray = this.props.p_tblFilterFieldNamesArray;
    const tblFilterValuesArray = this.props.p_tblFilterValuesArray;
    const parentFolderID = this.props.p_parentFolderID;
    const defaultXmlType = this.props.p_defaultXmlType;
    const p_allowedExtsArray = this.props.p_allowedExtsArray;

    const isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;

    var isWorkingTF = false; //uploader becomes busy while uploading, preventing drops or uploads until it is finished
    var displayText = undefined;
    if(uploadState === "uploading") {
      isWorkingTF = true;
      displayText = "Uploading File " + currentFileNumberUploading + " of " + totalNumFilesToUpload + "\n" + currentFileDisplayNameAndExtUploading;
    }
    else { //"initial"
      displayText = ((isMobileOrTabletTF) ? ("Tap to\nUpload Files") : ("Click or Drag\nFiles to Upload"));
    }

    return(
      <>
        <CEDropZone
          p_width="17em"
          p_heightEm={3.8}
          p_allowDropTF={true}
          p_uniqueString="ffsDropZone"
          p_fileUploadIsProcessingTF={isWorkingTF}
          p_message={displayText}
          p_dragOverMatchingItemMessage="Drop File(s) to Upload"
          f_onDropFiles={this.ondrop_upload_files}
        />
        {(uploadState === "postUploadFloatingBox") &&
          <FileFolderSystemUploadFilesPostUploadFloatingBox
            p_tblName={tblName}
            p_allowedExtsArray={p_allowedExtsArray}
            p_successFilesArrayOfObjs={successFilesArrayOfObjs}
            p_badExtFilesArrayOfObjs={badExtFilesArrayOfObjs}
            p_errorFilesArrayOfObjs={errorFilesArrayOfObjs}
            f_onClickClose={this.reset_upload_state_to_initial}
          />
        }
      </>
    );
  }
}));


const FileFolderSystemUploadFilesPostUploadFloatingBox = inject("DatabaseMobx")(observer(
class FileFolderSystemUploadFilesPostUploadFloatingBox extends Component { //props: p_tblName, p_allowedExtsArray, p_successFilesArrayOfObjs, p_badExtFilesArrayOfObjs, p_errorFilesArrayOfObjs, f_onClickClose
  constructor(props) {
    super(props);

    const successFilesArrayOfObjs = this.props.p_successFilesArrayOfObjs;
    const numSuccessFiles = successFilesArrayOfObjs.length;

    const initialSameKeywordsTab = ((numSuccessFiles > 1) ? ("same") : ("unique")); //default is use same keywords for multiple files, but manual entry for just one file

    this.state = {
      s_sameKeywordsTab: initialSameKeywordsTab,
      s_sameKeywordsText: "",
      s_successFilesDisplayNamesArray: JSFUNC.get_column_vector_from_arrayOfObjs(successFilesArrayOfObjs, "fileDisplayNameAndExt"),
      s_successFilesKeywordsArray: JSFUNC.array_fill(numSuccessFiles, ""),
      s_successFilesDescriptionsArray: JSFUNC.array_fill(numSuccessFiles, "")
    };
  }

  onselect_same_keywords_tab = (i_selectedTabDbName) => {
    const successFilesArrayOfObjs = this.props.p_successFilesArrayOfObjs;

    const allFilesKeywords = ((i_selectedTabDbName === "unique") ? (this.state.s_sameKeywordsText) : ("")); //copy the top keywords to every individual file if switching to the unique tab
    const updatedSuccessFilesKeywordsArray = JSFUNC.array_fill(successFilesArrayOfObjs.length, allFilesKeywords)
    this.setState({
      s_sameKeywordsTab: i_selectedTabDbName,
      s_sameKeywordsText: "",
      s_successFilesKeywordsArray: updatedSuccessFilesKeywordsArray
    });
  }

  onchange_same_keywords_text = (i_newValue) => {
    this.setState({s_sameKeywordsText:i_newValue});
  }

  onchange_file_local_display_name = (i_fileIndex, i_newValue) => {
    var updatedSuccessFilesDisplayNamesArray = this.state.s_successFilesDisplayNamesArray;
    updatedSuccessFilesDisplayNamesArray[i_fileIndex] = i_newValue;
    this.setState({s_successFilesDisplayNamesArray:updatedSuccessFilesDisplayNamesArray});
  }

  onchange_file_local_keywords = (i_fileIndex, i_newValue) => {
    var updatedSuccessFilesKeywordsArray = this.state.s_successFilesKeywordsArray;
    updatedSuccessFilesKeywordsArray[i_fileIndex] = i_newValue;
    this.setState({s_successFilesKeywordsArray:updatedSuccessFilesKeywordsArray});
  }

  onchange_file_local_description = (i_fileIndex, i_newValue) => {
    var updatedSuccessFilesDescriptionsArray = this.state.s_successFilesDescriptionsArray;
    updatedSuccessFilesDescriptionsArray[i_fileIndex] = i_newValue;
    this.setState({s_successFilesDescriptionsArray:updatedSuccessFilesDescriptionsArray});
  }

  onclick_save_keywords_and_descriptions = () => {
    const sameKeywordsTab = this.state.s_sameKeywordsTab;
    const sameKeywordsText = this.state.s_sameKeywordsText;
    const successFilesDisplayNamesArray = this.state.s_successFilesDisplayNamesArray;
    const successFilesKeywordsArray = this.state.s_successFilesKeywordsArray;
    const successFilesDescriptionsArray = this.state.s_successFilesDescriptionsArray;

    const tblName = this.props.p_tblName;
    const successFilesArrayOfObjs = this.props.p_successFilesArrayOfObjs;

    const filesUseUniqueKeywordsTF = (sameKeywordsTab === "unique");
    const numSuccessFiles = successFilesArrayOfObjs.length;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FileFolderSystemUploadFilesPostUploadFloatingBox", "onclick_save_keywords_and_descriptions", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    for(let s = 0; s < numSuccessFiles; s++) {
      var successFileObj = successFilesArrayOfObjs[s];

      var fileKeywords = ((filesUseUniqueKeywordsTF) ? (successFilesKeywordsArray[s]) : (sameKeywordsText));

      var fieldNamesArray = ["display_name", "keywords", "notes"];
      var valuesArray = [successFilesDisplayNamesArray[s], fileKeywords, successFilesDescriptionsArray[s]];
      var idsbArray = ["s", "s", "s"];
      C_CallPhpTblUID.add_update(tblName, successFileObj.ffsTblRowID, fieldNamesArray, valuesArray, idsbArray);
    }
    C_CallPhpTblUID.execute();

    if(JSFUNC.is_function(this.props.f_onClickClose)) {
      this.props.f_onClickClose();
    }
  }

  render() {
    const sameKeywordsTab = this.state.s_sameKeywordsTab;
    const sameKeywordsText = this.state.s_sameKeywordsText;
    const successFilesDisplayNamesArray = this.state.s_successFilesDisplayNamesArray;
    const successFilesKeywordsArray = this.state.s_successFilesKeywordsArray;
    const successFilesDescriptionsArray = this.state.s_successFilesDescriptionsArray;

    const tblName = this.props.p_tblName;
    const p_allowedExtsArray = this.props.p_allowedExtsArray;
    const successFilesArrayOfObjs = this.props.p_successFilesArrayOfObjs;
    const badExtFilesArrayOfObjs = this.props.p_badExtFilesArrayOfObjs;
    const errorFilesArrayOfObjs = this.props.p_errorFilesArrayOfObjs;

    const numSuccessFiles = successFilesArrayOfObjs.length;
    const numBadExtFiles = badExtFilesArrayOfObjs.length;
    const numErrorFiles = errorFilesArrayOfObjs.length;

    const filesUseUniqueKeywordsTF = (sameKeywordsTab === "unique");

    var successMessage = "";
    if((numSuccessFiles === 0) && (numBadExtFiles === 0) && (numErrorFiles === 0)) {
      successMessage = "No Files Uploaded";
    }
    else {
      successMessage = "Successfully Uploaded " + numSuccessFiles + " " + JSFUNC.plural(numSuccessFiles, "File", "Files");
    }

    return(
      <FloatingBoxWithSaveCancel
        p_trblFlag="medium"
        p_title="Uploaded File(s) Keywords/Descriptions"
        f_onClickSave={this.onclick_save_keywords_and_descriptions}>
        <div className="flex11a yScroll medFullPad">
          {(numErrorFiles > 0) &&
            <div className="hugeBottomMargin lrMedMargin medFullPad border borderColorRed">
              <div className="smallBottomMargin">
                <font className="font11 fontBold fontRed">
                  {numErrorFiles + " " + JSFUNC.plural(numErrorFiles, "File was", "Files were") + " unsuccessful uploading to the server. Please note there is a 75MB size limit per file, otherwise note the file " + JSFUNC.plural(numErrorFiles, "name", "names") + " listed below and try reuploading:"}
                </font>
              </div>
              {errorFilesArrayOfObjs.map((m_errorFileObj) =>
                <div className="smallBottomMargin lrMedPad">
                  <font className="font11 fontRed">
                    {" - " + m_errorFileObj.fileDisplayNameAndExt + " [" + m_errorFileObj.errorMessage + "]"}
                  </font>
                </div>
              )}
            </div>
          }
          {(numBadExtFiles > 0) &&
            <div className="hugeBottomMargin lrMedMargin medFullPad border1bbb">
              <div className="smallBottomMargin">
                <font className="font11 fontBold fontDarkOrange">
                  {numBadExtFiles + " " + JSFUNC.plural(numBadExtFiles, "File was not uploaded because of an issue with its file name or extension:", "Files were not uploaded because of issues with their file names or extensions:")}
                </font>
              </div>
              {badExtFilesArrayOfObjs.map((m_badExtFileObj) =>
                <div className="smallBottomMargin lrMedPad">
                  <font className="font11 fontDarkOrange">
                    {" - " + m_badExtFileObj.fileDisplayNameAndExt + " [" + m_badExtFileObj.errorMessage + "]"}
                  </font>
                </div>
              )}
              {(JSFUNC.is_array(p_allowedExtsArray)) &&
                <div className="smallBottomMargin">
                  <font className="font11 fontBold fontDarkOrange">
                    {"Allowed Extensions: [" + JSFUNC.convert_array_to_display_comma_list_different_left_right(p_allowedExtsArray, "'.", "'") + "]"}
                  </font>
                </div>
              }
            </div>
          }
          <div className="medBottomMargin textCenter">
            <font className="font18 fontBold fontTextLight">
              {successMessage}
            </font>
          </div>
          {(numSuccessFiles > 1) &&
            <div className="displayFlexColumnHcVc medBottomMargin">
              <div className="flex00a medFullPad border bgLightGray" style={{width:"70%", borderColor:"#999"}}>
                <div className="displayFlexColumnHcVc smallFullPad">
                  <TabsList
                    p_tabDbNamesArray={["same", "unique"]}
                    p_tabDisplayNamesArray={["Use Same Keywords for all Files", "Enter Unique Keywords"]}
                    p_selectedTabDbName={sameKeywordsTab}
                    p_tabHeight="2.7em"
                    p_textMaxHeight="2.4em"
                    p_tabWidth="17em"
                    p_selectedBgClass="bgGoldGradient"
                    p_selectedFontClass=""
                    p_unselectedBgClass="bgLighterGray"
                    p_unselectedFontClass="fontTextLighter"
                    p_rowFlexWrapTF={true}
                    f_onSelect={this.onselect_same_keywords_tab}
                  />
                </div>
                {(sameKeywordsTab === "same") &&
                  <div className="displayFlexRowVc smallTopMargin">
                    <div className="flex00a" style={{flexBasis:"8em"}}>
                      <font className="">
                        {"Keywords applied to every file"}
                      </font>
                    </div>
                    <div className="flex11a displayFlexColumn lrMedPad">
                      <LibraryReact.Text
                        p_value={sameKeywordsText}
                        p_style={{width:"100%"}}
                        p_tabIndex={1}
                        f_onChange={this.onchange_same_keywords_text}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          {successFilesArrayOfObjs.map((m_successFileObj, m_index) =>
            <>
              {(m_index > 0) &&
                <div className="bigTopMargin bigBottomMargin lrMedMargin borderT1ddd" />
              }
              <FileFolderSystemUploadFilesPostUploadFloatingBoxSuccessFileRow
                p_fileIndex={m_index}
                p_successFileObj={m_successFileObj}
                p_localDisplayName={successFilesDisplayNamesArray[m_index]}
                p_localKeywords={successFilesKeywordsArray[m_index]}
                p_localDescription={successFilesDescriptionsArray[m_index]}
                p_tblName={tblName}
                p_filesUseUniqueKeywordsTF={filesUseUniqueKeywordsTF}
                f_onChangeLocalDisplayName={this.onchange_file_local_display_name}
                f_onChangeLocalKeywords={this.onchange_file_local_keywords}
                f_onChangeLocalDescription={this.onchange_file_local_description}
              />
            </>
          )}
          <div className="displayFlexColumnHcVc hugeTopMargin hugeBottomMargin">
            <CEButton
              p_type={((numSuccessFiles > 0) ? ("blue") : ("gray"))}
              p_text={((numSuccessFiles > 0) ? ("Save File Data") : ("Close"))}
              p_tabIndex={((numSuccessFiles + 3) * 3)}
              f_onClick={this.onclick_save_keywords_and_descriptions}
            />
          </div>
        </div>
      </FloatingBoxWithSaveCancel>
    );
  }
}));

const FileFolderSystemUploadFilesPostUploadFloatingBoxSuccessFileRow = inject("DatabaseMobx")(observer(
class FileFolderSystemUploadFilesPostUploadFloatingBoxSuccessFileRow extends Component { //props: p_fileIndex, p_successFileObj, p_localDisplayName, p_localKeywords, p_localDescription, p_tblName, p_filesUseUniqueKeywordsTF, f_onChangeLocalDisplayName, f_onChangeLocalKeywords, f_onChangeLocalDescription
  constructor(props) {
    super(props);
    this.state = {
      s_fileLocalXmlType: this.props.p_successFileObj.xmlType
    };
  }

  onchange_local_display_name = (i_newValue) => {
    this.props.f_onChangeLocalDisplayName(this.props.p_fileIndex, i_newValue);
  }

  onchange_local_keywords = (i_newValue) => {
    this.props.f_onChangeLocalKeywords(this.props.p_fileIndex, i_newValue);
  }

  onchange_local_description = (i_newValue) => {
    this.props.f_onChangeLocalDescription(this.props.p_fileIndex, i_newValue);
  }

  onclick_xml_type_icon = (i_selectedXmlTypeString) => {
    const tblName = this.props.p_tblName;
    const successFileObj = this.props.p_successFileObj;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - FileFolderSystemUploadFilesPostUploadFloatingBoxSuccessFileRow", "onclick_xml_type_icon", ["i_selectedXmlTypeString"], [i_selectedXmlTypeString]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(tblName, successFileObj.ffsTblRowID, "xml_type", i_selectedXmlTypeString, "s");
    C_CallPhpTblUID.execute();

    this.setState({s_fileLocalXmlType:i_selectedXmlTypeString});
  }

  render() {
    const fileLocalXmlType = this.state.s_fileLocalXmlType;

    const fileIndex = this.props.p_fileIndex;
    const successFileObj = this.props.p_successFileObj;
    const localDisplayName = this.props.p_localDisplayName;
    const localKeywords = this.props.p_localKeywords;
    const localDescription = this.props.p_localDescription;
    const tblName = this.props.p_tblName;
    const filesUseUniqueKeywordsTF = this.props.p_filesUseUniqueKeywordsTF;

    return(
      <div className="displayFlexRow">
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"4.4em"}}>
          <font className="font20 fontTextLight">
            {(fileIndex + 1)}
          </font>
        </div>
        <div className="flex11a">
          <div className="displayFlexRowVc" style={{height:"2em"}}>
            <div className="flex00a" style={{flexBasis:"6em"}}>
              {"File Name"}
            </div>
            <div className="flex11a displayFlexColumn">
              <LibraryReact.Text
                p_value={localDisplayName}
                p_style={{width:"100%"}}
                p_tabIndex={(((fileIndex + 1) * 3) + 0)}
                f_onChange={this.onchange_local_display_name}
              />
            </div>
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}} title={"File Type: ." + successFileObj.fileExt}>
              <SvgFile p_fileLoc={successFileObj.fileLoc} p_sizeEm={1.8} />
            </div>
          </div>
          {(filesUseUniqueKeywordsTF) &&
            <div className="displayFlexRowVc" style={{height:"2em"}}>
              <div className="flex00a" style={{flexBasis:"9em"}}>
                {"Keywords"}
              </div>
              <div className="flex11a displayFlexColumn">
                <LibraryReact.Text
                  p_value={localKeywords}
                  p_style={{width:"100%"}}
                  p_tabIndex={(((fileIndex + 1) * 3) + 1)}
                  f_onChange={this.onchange_local_keywords}
                />
              </div>
            </div>
          }
          <div className="displayFlexRowVc" style={{height:"2em"}}>
            <div className="flex00a" style={{flexBasis:"9em"}}>
              {"Description/Notes"}
            </div>
            <div className="flex11a displayFlexColumn">
              <LibraryReact.Text
                p_value={localDescription}
                p_style={{width:"100%"}}
                p_tabIndex={(((fileIndex + 1) * 3) + 2)}
                f_onChange={this.onchange_local_description}
              />
            </div>
          </div>
          {(successFileObj.isXmlTF) &&
            <div className="displayFlexRowVc flexWrap medTopMargin smallFullPad border1bbb bgLighterGray">
              <div className="flex00a smallFullPad" style={{flexBasis:"10em"}}>
                <font className="fontItalic">
                  {"Select the XML Template Type"}
                </font>
              </div>
              <FFSSelectXmlTypeIcon p_xmlType="word" p_isSelectedTF={(fileLocalXmlType === "word")} f_onClick={this.onclick_xml_type_icon} />
              <FFSSelectXmlTypeIcon p_xmlType="excel" p_isSelectedTF={(fileLocalXmlType === "excel")} f_onClick={this.onclick_xml_type_icon} />
              <FFSSelectXmlTypeIcon p_xmlType="ppt" p_isSelectedTF={(fileLocalXmlType === "ppt")} f_onClick={this.onclick_xml_type_icon} />
              <FFSSelectXmlTypeIcon p_xmlType="generic" p_isSelectedTF={(fileLocalXmlType === "generic")} f_onClick={this.onclick_xml_type_icon} />
            </div>
          }
        </div>
      </div>
    );
  }
}));

class FFSSelectXmlTypeIcon extends Component { //props: p_xmlType, p_isSelectedTF, f_onClick
  onclick_xml_type_icon = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(this.props.p_xmlType);
    }
  }

  render() {
    const xmlType = this.props.p_xmlType;
    const isSelectedTF = this.props.p_isSelectedTF;

    var label = "Other";
    if(xmlType === "word") { label = "Word"; }
    else if(xmlType === "excel") { label = "Excel"; }
    else if(xmlType === "ppt") { label = "PowerPoint"; }

    return(
      <div
        className={"displayFlexColumnHcVc lrMargin border bevelBorderColors " + ((isSelectedTF) ? ("bgLightBlueGradient") : ("bgWhite hoverLighterBlueGradient cursorPointer"))}
        style={{height:"6em", width:"6em"}}
        onClick={((isSelectedTF) ? (undefined) : (this.onclick_xml_type_icon))}>
        <div className="flex00a smallBottomMargin textCenter">
          <font className={"fontBold " + ((isSelectedTF) ? ("") : ("fontTextLighter"))}>
            {label}
          </font>
        </div>
        <div className="flex00a">
          <SvgFile p_fileLoc=".xml" p_xmlType={xmlType} p_sizeEm={3.5} />
        </div>
      </div>
    );
  }
}





export const CEDropZone = inject("CaptureExecMobx")(observer(
class CEDropZone extends Component {//props: p_width, p_heightEm, p_allowDropTF, p_uniqueString, p_fileUploadIsProcessingTF, p_message, p_dragOverMatchingItemMessage, f_onDropMatchingItem, f_onDropFiles
  constructor(props) {
    super(props);
    this.state = {
      s_isDragOverTF: false
    };
  }

  ondragover_drop_zone = (i_isDragOverTF) => {
    this.setState({s_isDragOverTF:i_isDragOverTF});
  }

  render() {
    const s_isDragOverTF = this.state.s_isDragOverTF;

    const p_width = this.props.p_width;
    const p_heightEm = JSFUNC.prop_value(this.props.p_heightEm, 1.5);
    const p_allowDropTF = JSFUNC.prop_value(this.props.p_allowDropTF, true);
    const p_uniqueString = this.props.p_uniqueString;
    const p_fileUploadIsProcessingTF = JSFUNC.prop_value(this.props.p_fileUploadIsProcessingTF, false);
    const p_message = this.props.p_message;
    const p_dragOverMatchingItemMessage = this.props.p_dragOverMatchingItemMessage;

    const o_ceDraggedItemObjOrUndefined = this.props.CaptureExecMobx.o_ceDraggedItemObjOrUndefined;
    const c_computerFileIsCurrentlyBeingDraggedAcrossWebsiteTF = this.props.CaptureExecMobx.c_computerFileIsCurrentlyBeingDraggedAcrossWebsiteTF;

    const fOnDropFilesIsFunctionTF = JSFUNC.is_function(this.props.f_onDropFiles);
    const fOnDropMatchingItemIsFunctionTF = JSFUNC.is_function(this.props.f_onDropMatchingItem);

    //dropzone styling
    var bgColor = "e3e8ff"; //initial state blue
    var borderColor = "005da3";
    var message = p_message;
    if(p_fileUploadIsProcessingTF) {
      bgColor = "ddd"; //processing upload gray
      borderColor = "888";
      message = p_message;
    }
    else if(s_isDragOverTF) { //something just dragged over this dropzone
      var allowDropTF = false;
      if(p_allowDropTF) { //if drop is allowed here for any item/file
        if(o_ceDraggedItemObjOrUndefined === undefined) { //if files are being dragged (this o_ceDraggedItemObjOrUndefined would be filled out if a CE html item was currently being dragged)
          allowDropTF = fOnDropFilesIsFunctionTF; //allow file drop if the f_onDropFiles handling function is defined
        }
        else {
          if(fOnDropMatchingItemIsFunctionTF) { //if the f_onDropMatchingItem function is defined for handling drag/drop of html items in CE
            allowDropTF = (o_ceDraggedItemObjOrUndefined.uniqueString === p_uniqueString); //allow drop if the drag and drop unique strings match
          }
        }
      }

      if(allowDropTF) {
        bgColor = "b0f0b0"; //dragover allowed green
        borderColor = "5a5";
        message = p_dragOverMatchingItemMessage;
      }
      else {
        bgColor = "f0b0b0"; //dragover not allowed red
        borderColor = "a55";
        const allowedDropPreventedDueToForeignDraggedItemMessage = "This item cannot be dropped here"; //if drop allowed but it's a foreign item, show this message
        message = ((p_allowDropTF) ? (allowedDropPreventedDueToForeignDraggedItemMessage) : (p_dragOverMatchingItemMessage)); //otherwise if drop not allowed by input tf, display user message from p_dragOverMatchingItemMessage
      }
    }

    return(
      <CE3Drag3Drop3Shell3
        p_uniqueString={p_uniqueString}
        p_itemID={undefined}
        p_draggableTF={false}
        p_droppableTF={true}
        p_dropZoneIsInvisibleOverlayTF={true}
        p_dropZoneOversizeWidthEm={((c_computerFileIsCurrentlyBeingDraggedAcrossWebsiteTF) ? (3) : (undefined))}
        p_class={"positionRelative displayFlexRowVc lrMedPad textCenter " + ((fOnDropFilesIsFunctionTF) ? ("hoverLightestBlueGradient"): (""))}
        p_styleObj={{height:p_heightEm + "em", width:p_width, float:"left", backgroundColor:"#" + bgColor, border:"dashed 2px #" + borderColor, borderRadius:"0.4em"}}
        p_title="Click or drag/drop files here to upload"
        p_dragOverClass={undefined}
        p_dragOverStyleObj={undefined}
        p_fileUploadIsProcessingTF={p_fileUploadIsProcessingTF}
        f_isDragOverTF={this.ondragover_drop_zone}
        f_onDropMatchingItem={this.props.f_onDropMatchingItem}
        f_onDropForeignItem={undefined}
        f_onDropFiles={this.props.f_onDropFiles}
        f_onClick={undefined}>
        <LibraryReact.MaxHeightWrap p_maxHeight={(p_heightEm - 0.5) + "em"} p_fontClass="fontBold fontItalic fontTextLighter">
          {message}
          {(p_fileUploadIsProcessingTF) &&
            <div className="displayFlexColumnHcVc">
              <LoadingAnimation p_frameDelay={0} />
            </div>
          }
        </LibraryReact.MaxHeightWrap>
      </CE3Drag3Drop3Shell3>
    );
  }
}));














//========================================================================================================================================================================================================================================
//Generate Capture Templates, Teammate Contract Templates, Excel Report Templates
//========================================================================================================================================================================================================================================
export const GenerateAndDownloadSingleCaptureTemplateButtonProcess = inject("OpenCaptureMobx", "DatabaseMobx")(observer(
class GenerateAndDownloadSingleCaptureTemplateButtonProcess extends Component { //p_fromOpenCaptureTrueAdminFalse, p_captureID, p_singleCaptureTemplateID, p_buttonText, p_buttonTitle, p_tabIndex, p_canClickButtonTF, f_onClick
  constructor(props) {
    super(props);
    this.state = {
      s_buttonState: "init", //"init", "error", "working", "success"
      s_buttonText: this.props.p_buttonText
    }
  }

  set_button_text_error_and_wait_for_reset = (i_buttonText) => {
    this.setState({s_buttonState:"error", s_buttonText:i_buttonText});
    this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
  }

  set_button_text_working = (i_buttonText) => {
    this.setState({s_buttonState:"working", s_buttonText:i_buttonText});
  }

  set_button_text_success_and_wait_for_reset = (i_buttonText) => {
    this.setState({s_buttonState:"success", s_buttonText:i_buttonText});
    this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
  }

  reset_button_to_initial_state_and_clear_timeout = () => {
    this.setState({s_buttonState:"init", s_buttonText:this.props.p_buttonText});
    clearTimeout(this.timeoutID);
  }

  onclick_generate_single_capture_template = () => {
    const p_fromOpenCaptureTrueAdminFalse = this.props.p_fromOpenCaptureTrueAdminFalse;
    const p_captureID = this.props.p_captureID;
    const p_singleCaptureTemplateID = this.props.p_singleCaptureTemplateID;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - GenerateAndDownloadSingleCaptureTemplateButtonProcess", "onclick_generate_single_capture_template", ["p_fromOpenCaptureTrueAdminFalse", "p_captureID", "p_singleCaptureTemplateID"], [p_fromOpenCaptureTrueAdminFalse, p_captureID, p_singleCaptureTemplateID]);

    //get capture map from p_captureID
    const captureMap = this.props.DatabaseMobx.o_tbl_captures.get(p_captureID);
    if(captureMap === undefined) {
      this.set_button_text_error_and_wait_for_reset("Capture Does Not Exist (ID: " + p_captureID + ")");
      return;
    }
    const captureName = this.props.DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);

    //get single capture template map from p_singleCaptureTemplateID
    const singleCaptureTemplateMap = this.props.DatabaseMobx.o_tbl_a_single_capture_templates_filefoldersystem.get(p_singleCaptureTemplateID);
    if(singleCaptureTemplateMap === undefined) {
      this.set_button_text_error_and_wait_for_reset("Template Does Not Exist (ID: " + p_singleCaptureTemplateID + ")");
      return;
    }
    const templateXmlFileLoc = singleCaptureTemplateMap.get("file_loc");
    const templateDisplayName = singleCaptureTemplateMap.get("display_name");

    //setup the save as download file name
    const templateDisplayNameFilePartsObj = JSFUNC.file_parts_obj(templateDisplayName); //remove any extension on the display name for this template file
    const downloadSaveAsFileNameAndExt = JSFUNC.trim_string_max_chars(templateDisplayNameFilePartsObj.fileName + " " + JSFUNC.now_date() + " - " + captureName, 100) + ".xml"; //add the capture name after the file display name, trim the save as file name to 100 chars and add .xml

    if(p_fromOpenCaptureTrueAdminFalse) { //template requested from inside an open capture
      this.set_button_text_working("Fetching Template File..."); //set button into a yellow working state for loading file from server
      const functionOnSuccessfulLoadTemplateFromServer = (i_fileDataString) => {
        this.set_button_text_working("Replacing Codewords..."); //set button into a yellow working state for loading file from server
        const replacedFileDataString = JSPHP.replace_all_codewords_in_xml_data_string(i_fileDataString, captureMap, undefined); //replace all codewords in the string
        this.set_button_text_success_and_wait_for_reset("Successfully Created New File for Download"); //set button into a green complete state for loading file from server
        JSFUNC.browser_offer_file_download_from_file_data_string(replacedFileDataString, downloadSaveAsFileNameAndExt); //turn the string into a local memory file and offer it for download
      }

      const functionOnErrorLoadTemplateFromServer = (i_errorMessage) => {
        this.set_button_text_error_and_wait_for_reset(i_errorMessage);
      }

      JSPHP.load_server_file_data_as_string(templateXmlFileLoc, functionOnSuccessfulLoadTemplateFromServer, functionOnErrorLoadTemplateFromServer, jsDescription);
    }
    else { //template requsted from outside like Admin (need to load supplemental single capture data into memory, then clear it)
      this.set_button_text_working("Loading Capture Data..."); //set button into a yellow working state for loading the single capture data into memory (teammates, competitors, shaping answers, risks, etc)
      const functionOnSuccessfulCaptureSupplementaryDataLoad = () => {
        this.set_button_text_working("Fetching Template File..."); //set button into a yellow working state for loading file from server
        const functionOnSuccessfulLoadTemplateFromServer = (i_fileDataString) => {
          this.set_button_text_working("Replacing Codewords..."); //set button into a yellow working state for loading file from server
          const replacedFileDataString = JSPHP.replace_all_codewords_in_xml_data_string(i_fileDataString, captureMap, undefined); //replace all codewords in the string
          this.props.OpenCaptureMobx.a_clear_single_capture_from_local_memory(); //clear single capture supplemental data mostly so the full textarea data does not interfere with the capture table cells displaying slim textarea data from tbl_captures columns
          this.set_button_text_success_and_wait_for_reset("Successfully Created New File for Download"); //set button into a green complete state for loading file from server
          JSFUNC.browser_offer_file_download_from_file_data_string(replacedFileDataString, downloadSaveAsFileNameAndExt); //turn the string into a local memory file and offer it for download
        }

        const functionOnErrorLoadTemplateFromServer = (i_errorMessage) => {
          this.set_button_text_error_and_wait_for_reset(i_errorMessage);
          this.props.OpenCaptureMobx.a_clear_single_capture_from_local_memory(); //clear single capture supplemental data from memory
        }

        JSPHP.load_server_file_data_as_string(templateXmlFileLoc, functionOnSuccessfulLoadTemplateFromServer, functionOnErrorLoadTemplateFromServer, jsDescription);
      }

      JSPHP.single_capture_load_supplementary_data(p_captureID, "", functionOnSuccessfulCaptureSupplementaryDataLoad);
    }
  }

  render() {
    const s_buttonState = this.state.s_buttonState;
    const s_buttonText = this.state.s_buttonText;

    return(
      <CEDownloadFileButton
        p_buttonState={s_buttonState}
        p_buttonText={s_buttonText}
        p_buttonWidth="15em"
        p_title={this.props.p_buttonTitle}
        p_tabIndex={this.props.p_tabIndex}
        p_canClickButtonTF={this.props.p_canClickButtonTF}
        f_onClickGenerate={this.onclick_generate_single_capture_template}
        f_onClickReset={this.reset_button_to_initial_state_and_clear_timeout}
        f_onClick={this.props.f_onClick}
      />
    );
  }
}));



export const GenerateAndDownloadExcelReportButtonProcess = inject("DatabaseMobx")(observer(
class GenerateAndDownloadExcelReportButtonProcess extends Component { //p_excelReportTemplateID, p_buttonText, p_buttonTitle, p_tabIndex, p_canClickButtonTF, f_onClick
  constructor(props) {
    super(props);
    this.state = {
      s_buttonState: "init", //"init", "error", "working", "success"
      s_buttonText: this.props.p_buttonText
    }
  }

  set_button_text_error_and_wait_for_reset = (i_buttonText) => {
    this.setState({s_buttonState:"error", s_buttonText:i_buttonText});
    this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
  }

  set_button_text_working = (i_buttonText) => {
    this.setState({s_buttonState:"working", s_buttonText:i_buttonText});
  }

  set_button_text_success_and_wait_for_reset = (i_buttonText) => {
    this.setState({s_buttonState:"success", s_buttonText:i_buttonText});
    this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
  }

  reset_button_to_initial_state_and_clear_timeout = () => {
    this.setState({s_buttonState:"init", s_buttonText:this.props.p_buttonText});
    clearTimeout(this.timeoutID);
  }

  onclick_generate_excel_report_from_template = () => {
    const excelReportTemplateID = this.props.p_excelReportTemplateID;

    //get single capture template map from excelReportTemplateID
    const excelReportTemplateMap = this.props.DatabaseMobx.o_tbl_a_excel_report_templates_filefoldersystem.get(excelReportTemplateID);
    if(excelReportTemplateMap === undefined) {
      this.set_button_text_error_and_wait_for_reset("Template Does Not Exist (ID: " + excelReportTemplateID + ")");
      return;
    }
    const templateXmlFileLoc = excelReportTemplateMap.get("file_loc");
    const templateDisplayName = excelReportTemplateMap.get("display_name");

    //set button into a yellow working state for loading file from server
    this.set_button_text_working("Writing Report..."); //Fetching Template File

    const functionOnSuccessfulLoadTemplateFromServer = (i_fileDataString) => {
      this.set_button_text_working("Writing Report..."); //set button into a yellow working state for loading file from server

      const functionOnFinishWritingExcelReport = (i_finishedExcelReportXmlString) => {
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        this.set_button_text_success_and_wait_for_reset("Successfully Created New File for Download"); //set button into a green complete state for loading file from server

        //setup the save as download file name
        const templateDisplayNameFilePartsObj = JSFUNC.file_parts_obj(templateDisplayName); //remove any extension on the display name for this template file
        var downloadSaveAsFileNameAndExt = templateDisplayNameFilePartsObj.fileName + " " + JSFUNC.now_date();
        if(downloadSaveAsFileNameAndExt.length > 100) { //trim the save as file name to 100 chars
          downloadSaveAsFileNameAndExt = downloadSaveAsFileNameAndExt.substring(0, 100);
        }
        downloadSaveAsFileNameAndExt += ".xml";

        //turn the string into a local memory file and offer it for download
        JSFUNC.browser_offer_file_download_from_file_data_string(i_finishedExcelReportXmlString, downloadSaveAsFileNameAndExt);
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
      }
      JSPHP.write_excel_report_xml_from_template_xml_data_string(i_fileDataString, functionOnFinishWritingExcelReport); //fill in entire excel report with all captures matching various specified filters on different sheets in the template
    }

    const functionOnErrorLoadTemplateFromServer = (i_errorMessage) => {
      this.set_button_text_error_and_wait_for_reset(i_errorMessage);
    }

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - GenerateAndDownloadExcelReportButtonProcess", "onclick_generate_excel_report_from_template", ["p_excelReportTemplateID"], [excelReportTemplateID]);
    JSPHP.load_server_file_data_as_string(templateXmlFileLoc, functionOnSuccessfulLoadTemplateFromServer, functionOnErrorLoadTemplateFromServer, jsDescription);
  }

  render() {
    const s_buttonState = this.state.s_buttonState;
    const s_buttonText = this.state.s_buttonText;

    return(
      <CEDownloadFileButton
        p_buttonState={s_buttonState}
        p_buttonText={s_buttonText}
        p_buttonWidth="15em"
        p_title={this.props.p_buttonTitle}
        p_tabIndex={this.props.p_tabIndex}
        p_canClickButtonTF={this.props.p_canClickButtonTF}
        f_onClickGenerate={this.onclick_generate_excel_report_from_template}
        f_onClickReset={this.reset_button_to_initial_state_and_clear_timeout}
        f_onClick={this.props.f_onClick}
      />
    );
  }
}));



export const GenerateAndUploadTeammateContractTemplateButtonProcess = inject("ContactsMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class GenerateAndUploadTeammateContractTemplateButtonProcess extends Component { //p_captureID, p_teammateContractID, p_teammateContractTemplateID, p_buttonText, p_buttonTitle, p_tabIndex, p_canClickButtonTF, p_adminDownloadNoUploadTF, f_onClick, f_onUploadSuccess
  constructor(props) {
    super(props);
    this.state = {
      s_buttonState: "init", //"init", "error", "working", "success"
      s_buttonText: this.props.p_buttonText
    }
  }

  set_button_text_error_and_wait_for_reset = (i_buttonText) => {
    this.setState({s_buttonState:"error", s_buttonText:i_buttonText});
    this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
  }

  set_button_text_working = (i_buttonText) => {
    this.setState({s_buttonState:"working", s_buttonText:i_buttonText});
  }

  set_button_text_success_and_wait_for_reset = (i_buttonText) => {
    this.setState({s_buttonState:"success", s_buttonText:i_buttonText});
    this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
  }

  reset_button_to_initial_state_and_clear_timeout = () => {
    this.setState({s_buttonState:"init", s_buttonText:this.props.p_buttonText});
    clearTimeout(this.timeoutID);
  }

  //1. Load teammate contract template file on the server, read the data into a string
  //2. replace all codewords in local js file data string
  //3. send replaced file data string to server to create a new file
  //4. insert a new record in the teammate contracts file folder system for this new replaced codewords file created from the template/capture/teammate/contract together
  onclick_generate_teammate_contract_template = () => {
    const p_captureID = this.props.p_captureID; //tbl_captures
    const teammateContractID = this.props.p_teammateContractID;
    const teammateContractTemplateID = this.props.p_teammateContractTemplateID; //tbl_a_teammates_contract_templates_filefoldersystem, accesses tbl_c_teammates_contracts (accesses tbl_c_teammates from teammate_id) (accesses tbl_g_contacts_companies from teammate contact_company_id) (accesses tbl_a_teammates_contract_types from teammate_contract_type_id)
    const adminDownloadNoUploadTF = JSFUNC.prop_value(this.props.p_adminDownloadNoUploadTF, false);

    const o_userID = this.props.UserMobx.o_userID;
    const c_userName = this.props.UserMobx.c_userName;
    const k_cardIDTeammates = this.props.DatabaseMobx.k_cardIDTeammates;

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - GenerateAndUploadTeammateContractTemplateButtonProcess", "onclick_generate_teammate_contract_template", ["p_captureID", "p_teammateContractID", "p_teammateContractTemplateID"], [p_captureID, teammateContractID, teammateContractTemplateID]);

    //get capture map from p_captureID
    const captureMap = this.props.DatabaseMobx.o_tbl_captures.get(p_captureID);
    if(captureMap === undefined) {
      this.set_button_text_error_and_wait_for_reset("Capture Does Not Exist (ID: " + p_captureID + ")");
      return;
    }

    //set button into a yellow working state for loading single capture data
    this.set_button_text_working("Loading Single Capture Data...");

    const functionOnSuccessfulCaptureSupplementaryDataLoad = () => {
      //get teammate contract map from teammateContractID
      const teammateContractMap = this.props.DatabaseMobx.o_tbl_c_teammates_contracts.get(teammateContractID);
      if(teammateContractMap === undefined) {
        this.set_button_text_error_and_wait_for_reset("Teammate Contract Does Not Exist (ID: " + teammateContractID + ")");
        return;
      }
      const teammateID = teammateContractMap.get("teammate_id");
      const teammateContractTypeID = teammateContractMap.get("teammate_contract_type_id");

      //get teammate map from teammateID
      const teammateMap = this.props.DatabaseMobx.o_tbl_c_teammates.get(teammateID);
      if(teammateMap === undefined) {
        this.set_button_text_error_and_wait_for_reset("Teammate Does Not Exist (ID: " + teammateID + ")");
        return;
      }
      const teammateContactCompanyID = teammateMap.get("contact_company_id");

      //get teammate contract template map from teammateContractTemplateID
      const teammateContractTemplateMap = this.props.DatabaseMobx.o_tbl_a_teammates_contract_templates_filefoldersystem.get(teammateContractTemplateID);
      if(teammateContractTemplateMap === undefined) {
        this.set_button_text_error_and_wait_for_reset("Contract Template Does Not Exist (ID: " + teammateContractTemplateID + ")");
        return;
      }
      const templateXmlFileLoc = teammateContractTemplateMap.get("file_loc");
      const templateDisplayName = teammateContractTemplateMap.get("display_name");
      const templateXmlType = teammateContractTemplateMap.get("xml_type");

      //set button into a yellow working state for loading file from server
      this.set_button_text_working("Fetching Template File...");

      const functionOnSuccessfulLoadTemplateFromServer = (i_fileDataString) => {
        this.set_button_text_working("Replacing Codewords..."); //set button into a yellow working state for loading file from server
        const replacedFileDataString = JSPHP.replace_all_codewords_in_xml_data_string(i_fileDataString, captureMap, teammateContractID); //replace all codewords in the string

        //clear single capture supplemental data mostly so the full textarea data does not interfere with the capture table cells displaying slim textarea data from tbl_captures columns
        this.props.OpenCaptureMobx.a_clear_single_capture_from_local_memory();

        if(adminDownloadNoUploadTF) { //in admin teammate contract templates, file is downloaded
          //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
          this.set_button_text_success_and_wait_for_reset("Successfully Created New File for Download"); //set button into a green complete state for loading file from server

          //setup the save as download file name
          const templateDisplayNameFilePartsObj = JSFUNC.file_parts_obj(templateDisplayName); //remove any extension on the display name for this template file
          const captureName = this.props.DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
          var downloadSaveAsFileNameAndExt = templateDisplayNameFilePartsObj.fileName + " - " + captureName; //add the capture name after the file display name
          if(downloadSaveAsFileNameAndExt.length > 100) { //trim the save as file name to 100 chars
            downloadSaveAsFileNameAndExt = downloadSaveAsFileNameAndExt.substring(0, 100);
          }
          downloadSaveAsFileNameAndExt += ".xml";

          //turn the string into a local memory file and offer it for download
          JSFUNC.browser_offer_file_download_from_file_data_string(replacedFileDataString, downloadSaveAsFileNameAndExt);
          //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        }
        else { //in contracts floating box process, file is generated, then reuploaded to a place on the server
          //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
          this.set_button_text_working("Uploading Completed File..."); //set button into a yellow working state for uploding the replaced new file data string to the server

          //setup the save as download file name
          const templateXmlFileLocFilePartsObj = JSFUNC.file_parts_obj(templateXmlFileLoc);
          const templateDisplayNameFilePartsObj = JSFUNC.file_parts_obj(templateDisplayName); //remove any extension on the display name for this template file
          const teammateName = this.props.ContactsMobx.contact_name_from_id(false, teammateContactCompanyID);
          const captureName = this.props.DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
          var uploadFileDisplayNameAndExt = JSFUNC.now_datetime_filenames() + "-" + templateDisplayNameFilePartsObj.fileName + " - " + teammateName + " - " + captureName; //add the capture name after the file display name
          if(uploadFileDisplayNameAndExt.length > 100) { //trim the save as file name to 100 chars
            uploadFileDisplayNameAndExt = uploadFileDisplayNameAndExt.substring(0, 100);
          }
          var uploadFileDbNameAndExt = JSFUNC.db_name_from_display_name(uploadFileDisplayNameAndExt);
          uploadFileDisplayNameAndExt += "." + templateXmlFileLocFilePartsObj.fileExt;
          uploadFileDbNameAndExt += "." + templateXmlFileLocFilePartsObj.fileExt;
          const uploadFileLoc = "tbl_c_teammates_contracts_filefoldersystem/capture_id" + p_captureID + "/teammate_id" + teammateID + "/contract_type_id" + teammateContractTypeID + "/" + uploadFileDbNameAndExt;

          //call the php function to write a new file onto the server with the replaced codewords
          const C_CallPhpScriptCreateFileOnServer = new JSPHP.ClassCallPhpScript("createNewServerFileFromStringData", jsDescription);

          C_CallPhpScriptCreateFileOnServer.add_post_var("i_fileLocRootUploadsOrWeb", "uploads");
          C_CallPhpScriptCreateFileOnServer.add_post_var("i_fileLocRelativeFromRoot", uploadFileLoc);
          C_CallPhpScriptCreateFileOnServer.add_post_var("i_dataString", replacedFileDataString);

          C_CallPhpScriptCreateFileOnServer.add_return_vars("success01String");

          //php success, file either was written successfully or not
          const functionOnSuccessfulNewServerFileCreation = (i_parseResponse) => {
            if(i_parseResponse.success01String === "1") {
              this.set_button_text_working("Inserting Record of New File...");
              //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
              //make a db entry for the new file in the teammate contracts filefoldersystem
              const C_CallPhpTblUIDInsertFfsRecord = new JSPHP.ClassCallPhpTblUID(jsDescription);

              const parentFolderID = -1; //TODO would need to know how deep the fileFolderSystem component is open to in its internal state
              const fieldNamesArray = ["capture_id", "teammate_id", "contract_type_id", "folder0_file1", "parent_folder_id", "fileupload0_onlinelink1", "file_loc", "display_name", "keywords", "notes", "content_unique_lowercase", "upload_date", "upload_user_id", "upload_user_name", "access", "xml_type"];
              const valuesArray = [p_captureID, teammateID, teammateContractTypeID, 1, parentFolderID, 0, uploadFileLoc, uploadFileDisplayNameAndExt, "", "", "", JSFUNC.now_date(), o_userID, c_userName, "open", templateXmlType];
              const valuesIdsbArray = ["i", "i", "i", "i", "i", "i", "s", "s", "s", "s", "s", "s", "i", "s", "s", "s"];
              C_CallPhpTblUIDInsertFfsRecord.add_insert("tbl_c_teammates_contracts_filefoldersystem", fieldNamesArray, valuesArray, valuesIdsbArray);

              const changeLogField = "Template Generated";
              const teammateContractTypeMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_teammates_contract_types", teammateContractTypeID);
              const changeLogValue = "Teammate: " + teammateName + ", Contract: " + teammateContractTypeMap.get("name") + ", Template File: " + templateDisplayName;
              C_CallPhpTblUIDInsertFfsRecord.add_changelog_details(p_captureID, k_cardIDTeammates, "tcgt", changeLogField, changeLogValue);

              const functionOnSuccessfulInsertOfUploadedFileRecord = (i_parseResponse) => {
                if(i_parseResponse.outputObj.i0 > 0) {
                  this.set_button_text_success_and_wait_for_reset("Successfully Created and Uploaded New File"); //set button into a green complete state

                  if(JSFUNC.is_function(this.props.f_onUploadSuccess)) {
                    this.props.f_onUploadSuccess("Generated and uploaded Contract file from template: '" + uploadFileDisplayNameAndExt + "' (" + changeLogValue + ")");
                  }
                }
                else {
                  this.set_button_text_error_and_wait_for_reset("Error Inserting File Record");
                }
              }
              C_CallPhpTblUIDInsertFfsRecord.add_function("onSuccess", functionOnSuccessfulInsertOfUploadedFileRecord);

              const functionOnErrorInsertOfUploadedFileRecord = () => {
                this.set_button_text_error_and_wait_for_reset("Error Inserting File Record");
              }
              C_CallPhpTblUIDInsertFfsRecord.add_function("onError", functionOnErrorInsertOfUploadedFileRecord);

              C_CallPhpTblUIDInsertFfsRecord.execute();
              //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
            }
            else {
              this.set_button_text_error_and_wait_for_reset("Upload of New File Unsuccessful");
            }
          }
          C_CallPhpScriptCreateFileOnServer.add_function("onSuccess", functionOnSuccessfulNewServerFileCreation);

          const functionOnErrorNewServerFileCreation = (i_errorMessage) => {
            this.set_button_text_error_and_wait_for_reset(i_errorMessage);
          }
          C_CallPhpScriptCreateFileOnServer.add_function("onError", functionOnErrorNewServerFileCreation);

          C_CallPhpScriptCreateFileOnServer.execute();
          //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        }
      }

      const functionOnErrorLoadTemplateFromServer = (i_errorMessage) => {
        this.set_button_text_error_and_wait_for_reset(i_errorMessage);
        this.props.OpenCaptureMobx.a_clear_single_capture_from_local_memory();
      }

      JSPHP.load_server_file_data_as_string(templateXmlFileLoc, functionOnSuccessfulLoadTemplateFromServer, functionOnErrorLoadTemplateFromServer, jsDescription);
    }

    JSPHP.single_capture_load_supplementary_data(p_captureID, "", functionOnSuccessfulCaptureSupplementaryDataLoad);
  }

  render() {
    const s_buttonState = this.state.s_buttonState;
    const s_buttonText = this.state.s_buttonText;

    return(
      <CEDownloadFileButton
        p_buttonState={s_buttonState}
        p_buttonText={s_buttonText}
        p_buttonWidth="16em"
        p_title={this.props.p_buttonTitle}
        p_tabIndex={this.props.p_tabIndex}
        p_canClickButtonTF={this.props.p_canClickButtonTF}
        f_onClickGenerate={this.onclick_generate_teammate_contract_template}
        f_onClickReset={this.reset_button_to_initial_state_and_clear_timeout}
        f_onClick={this.props.f_onClick}
      />
    );
  }
}));


export class SmallDownloadFileButtonForServerFile extends Component { //props: p_fileLocRootUploadsOrWeb, p_fileLocRelativeFromRoot, p_downloadSaveAsFileName, p_title
  constructor(props) {
    super(props);
    this.state = {
      s_downloadState: "init"
    }
  }

  onclick_file_download = () => {
    const p_fileLocRootUploadsOrWeb = this.props.p_fileLocRootUploadsOrWeb;
    const p_fileLocRelativeFromRoot = this.props.p_fileLocRelativeFromRoot;
    const p_downloadSaveAsFileName = this.props.p_downloadSaveAsFileName;

    const functionDownloadState = (i_downloadState) => {
      var updatedLocalFileDownloadState = i_downloadState;
      if(i_downloadState === "error") {
        alert("'" + p_downloadSaveAsFileName + "' was unsuccessful downloading from the server, please try redownloading the file");
      }
      else if(i_downloadState === "fileDoesNotExist") {
        updatedLocalFileDownloadState = "error";
        alert("'" + p_downloadSaveAsFileName + "' could not be downloaded from the server");
      }
      this.setState({s_downloadState:updatedLocalFileDownloadState});

      //download icon indicator stays in red 'error' or green 'success' state for 5 seconds, then returns back to white 'initial' state
      if(i_downloadState !== "working") {
        this.timeoutID = setTimeout(() => this.reset_button_to_initial_state_and_clear_timeout(), 5000);
      }
    }

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - SmallDownloadFileButtonForServerFile", "onclick_file_download", ["p_fileLocRootUploadsOrWeb", "p_fileLocRelativeFromRoot", "p_downloadSaveAsFileName"], [p_fileLocRootUploadsOrWeb, p_fileLocRelativeFromRoot, p_downloadSaveAsFileName]);

    JSPHP.check_file_exists_on_server_call_download_php_and_offer_download(p_fileLocRootUploadsOrWeb, p_fileLocRelativeFromRoot, p_downloadSaveAsFileName, functionDownloadState, jsDescription);
  }

  reset_button_to_initial_state_and_clear_timeout = () => {
    this.setState({s_downloadState:"init"});
    clearTimeout(this.timeoutID);
  }

  render() {
    const s_downloadState = this.state.s_downloadState;
    
    const p_title = this.props.p_title;

    return(
      <CEDownloadFileButton
        p_buttonState={s_downloadState}
        p_buttonText={undefined}
        p_buttonHeight="2.4em"
        p_buttonWidth="2.4em"
        p_iconSizeEm={1.3}
        p_title={p_title}
        p_canClickButtonTF={true}
        f_onClick={this.onclick_file_download}
      />
    );
  }
}



class CEDownloadFileButton extends Component { //props: p_buttonState, p_buttonText, p_buttonHeight, p_buttonWidth, p_iconSizeEm, p_title, p_tabIndex, p_canClickButtonTF, f_onClickGenerate, f_onClickReset, f_onClick
  onclick_button = () => {
    const p_buttonState = this.props.p_buttonState;

    if((p_buttonState === "init") && JSFUNC.is_function(this.props.f_onClickGenerate)) {
      this.props.f_onClickGenerate();
    }
    else if((p_buttonState === "success") && JSFUNC.is_function(this.props.f_onClickReset)) {
      this.props.f_onClickReset();
    }

    this.call_input_onclick();
  }

  call_input_onclick = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick();
    }
  }

  render() {
    const p_buttonState = this.props.p_buttonState; //"init", "error", "working", "success"
    const p_buttonText = this.props.p_buttonText;
    const p_buttonHeight = JSFUNC.prop_value(this.props.p_buttonHeight, "3.3em");
    const p_buttonWidth = JSFUNC.prop_value(this.props.p_buttonWidth, "13em");
    const p_iconSizeEm = JSFUNC.prop_value(this.props.p_iconSizeEm, 1.8);
    const p_canClickButtonTF = JSFUNC.prop_value(this.props.p_canClickButtonTF, true);

    var arrowColor = "fff";
    var textItalicTF = true;
    if(p_buttonState === "init") {
      textItalicTF = false;
    }
    else if(p_buttonState === "error") {
      arrowColor = "f33";
    }
    else if(p_buttonState === "working") {
      arrowColor = "ff5";
    }
    else if(p_buttonState === "success") {
      arrowColor = "3f3";
    }

    return(
      <LibraryReact.InteractiveDiv
        p_class="displayFlexRow generateTemplateButtonBgBorderAndHover textCenter cursorPointer"
        p_styleObj={{height:p_buttonHeight, width:p_buttonWidth}}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        f_onClick={((p_canClickButtonTF) ? (this.onclick_button) : (this.call_input_onclick))}
        f_onKeyDownEnter={((this.props.p_tabIndex > 0) ? (this.onclick_button) : (undefined))}>
        <div className={((p_buttonText !== undefined) ? ("flex00a") : ("flex11a")) + " displayFlexColumnHcVc"} style={{flexBasis:"3em"}}>
          <SvgDownloadIcon p_sizeEm={p_iconSizeEm} p_color={arrowColor} />
        </div>
        {(p_buttonText !== undefined) &&
          <div className="flex11a displayFlexRowVc lrPad">
            <LibraryReact.MaxHeightWrap p_maxHeight="2.7em" p_fontClass={"fontWhite " + ((textItalicTF) ? ("fontItalic") : (""))}>
              {p_buttonText}
            </LibraryReact.MaxHeightWrap>
          </div>
        }
      </LibraryReact.InteractiveDiv>
    );
  }
}








//========================================================================================================================================================================================================================================
//On Premise Authentication File Upload area (for Login and On Premise Admin subtab)
//========================================================================================================================================================================================================================================
export class OnPremiseUploadNewAuthenticationFile extends Component { //f_onUploadFile
  constructor(props) {
    super(props);
    this.state = {
      s_uploadIsWorkingTF: false
    };
  }

  ondrop_authentication_file = (i_dataTransferFilesArray) => {
    if(i_dataTransferFilesArray.length !== 1) {
      alert("Upload 1 CaptureExec On-Premise Authentication .txt File");
    }
    else {
      this.setState({s_uploadIsWorkingTF:true});
      const dataTransferFileObj = i_dataTransferFilesArray[0];

      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - OnPremiseUploadNewAuthenticationFile", "ondrop_authentication_file", [], []);
      const C_CallPhpScript = new JSPHP.ClassCallPhpScript("uploadNewOnPremiseCustomerAuthenticationQuarterlyPasscodeFile", jsDescription);
      C_CallPhpScript.add_post_var("i_dataTransferFileObj", dataTransferFileObj);
      C_CallPhpScript.add_return_vars("success01String");

      const functionOnSuccess = (i_parseResponse) => {
        this.setState({s_uploadIsWorkingTF:false});

        if(i_parseResponse.success01String === "1") {
          if(JSFUNC.is_function(this.props.f_onUploadFile)) {
            this.props.f_onUploadFile();
          }
        }
        else {
          alert("New On-Premise Authentication File '" + dataTransferFileObj.name + "' did not upload successfully");
        }
      }
      C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        this.setState({s_uploadIsWorkingTF:false});
        alert("New On-Premise Authentication File '" + dataTransferFileObj.name + "' did not upload successfully");
      }
      C_CallPhpScript.add_function("onError", functionOnError);

      C_CallPhpScript.execute();
    }
  }

  render() {
    const s_uploadIsWorkingTF = this.state.s_uploadIsWorkingTF;

    return(
      <CEDropZone
        p_width="20em"
        p_heightEm={3}
        p_allowDropTF={true}
        p_uniqueString="onPremiseAuthenticationFileUpload"
        p_fileUploadIsProcessingTF={s_uploadIsWorkingTF}
        p_message="Click or Drag a new Authentication Passcode File to Upload"
        p_dragOverMatchingItemMessage="Drop Authentication File Here"
        f_onDropFiles={this.ondrop_authentication_file}
      />
    );
  }
}











//========================================================================================================================================================================================================================================
//CaptureExec Components that handle edit and selection from every possible fieldInputType (generic inputs, selects, contacts, shared percents)  deal shaping questions are broken down as selects, and generic scoredText and scoredTextarea types
//========================================================================================================================================================================================================================================
export function CaptureExecFieldEditSaveCancelFromValueMaskSortIfoObj(props) {
  //props: p_ceEditItemString, p_isContactFieldTF, p_fieldDisplayName, p_fieldTypeObj, p_valueMaskSortIfoObj, p_valueIsEditableTFU, p_valuesToNotIncludeArray
  //p_containerClass, p_fieldClass, p_fieldWidth, p_fieldNowrapTF, p_fieldTitle, p_valueClass, p_valueMaxHeight, p_valueTitleTF, p_fieldValueVerticallyAlignedTF, p_floatingBoxTitle,
  //f_onSaveChanged
  return(
    <CaptureExecFieldEditSaveCancel
      p_ceEditItemString={props.p_ceEditItemString}
      p_isContactFieldTF={props.p_isContactFieldTF}
      p_fieldDisplayName={props.p_fieldDisplayName}
      p_fieldTypeObj={props.p_fieldTypeObj}
      p_valueRaw={props.p_valueMaskSortIfoObj.valueRaw}
      p_valueMask={props.p_valueMaskSortIfoObj.valueMask}
      p_valueMaskPlainText={props.p_valueMaskSortIfoObj.valueMaskPlainText}
      p_valueIsEditableTFU={props.p_valueIsEditableTFU}
      p_valuesToNotIncludeArray={props.p_valuesToNotIncludeArray}
      p_containerClass={props.p_containerClass}
      p_fieldClass={props.p_fieldClass}
      p_fieldWidth={props.p_fieldWidth}
      p_fieldNowrapTF={props.p_fieldNowrapTF}
      p_fieldTitle={props.p_fieldTitle}
      p_valueClass={props.p_valueClass}
      p_valueMaxHeight={props.p_valueMaxHeight}
      p_valueTitleTF={props.p_valueTitleTF}
      p_fieldValueVerticallyAlignedTF={props.p_fieldValueVerticallyAlignedTF}
      p_floatingBoxTitle={props.p_floatingBoxTitle}
      f_onSaveChanged={props.f_onSaveChanged}
    />
  );
}

export const CaptureExecFieldEditSaveCancel = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class CaptureExecFieldEditSaveCancel extends Component {
  //props: p_ceEditItemString, p_isContactFieldTF, p_fieldDisplayName, p_fieldTypeObj, p_valueRaw, p_valueMask, p_valueMaskPlainText, p_valueIsEditableTFU, p_valuesToNotIncludeArray
  //p_containerClass, p_fieldClass, p_fieldFontColor, p_fieldHighlightColor, p_fieldWidth, p_fieldNowrapTF, p_fieldTitle, p_valueClass, p_valueMaxHeight, p_valueTitleTF, p_fieldValueVerticallyAlignedTF, p_floatingBoxTitle,
  //f_onSaveChanged
  //
  //draws (when value is editable):
  //      [ Field Display Name       (edit) Masked Value                          ]
  //draws (when value is not editable):
  //      [ Field Display Name       Masked Value                                 ]
  //when edit is pushed with inline edit field type:
  //      [ Field Display Name       (save) (cancel) [Inline Input Mechanism]     ]
  //when edit is pushed with floating box edit field type (textarea):
  //      [ Field Display Name       (edit) Masked Value                          ]   [  Floating Box Edit (save) (cancel)   ]
  //
  //when save is pushed:
  //  - if the value/values were changed, it is sent out through f_onSaveChanged
  //  - otherwise this function closes its edit state internally without sending any outbound functions
  //
  //
  //  p_ceEditItemString [required]
  //      string used for the shared edit field in CaptureExecMobx "o_itemEditingCaptureDashCardDashItemID" to only allow 1 field in the entire system to be edited at any one time, if another is opened, it shuts the currently open one
  //      p_captureID, p_cardID, p_fieldID are used together to make this shared field unique for various capture card fields
  //  p_isContactFieldTF
  //      - false (default)       not a contact edit field (specifies together with p_ceEditItemString if the item being edited is a general item or an item within the Contacts system)
  //      - true                  is a contact field
  //  p_fieldDisplayName
  //      - undefined (default)   will not draw a field display name, leaving just the edit icon and value
  //      - "Field Name"          string will place a field name to the left of the edit icon, underlined when the edit icon is hovered over
  //  p_fieldTypeObj [required]
  //      large obj containing many true/false flags (created using DatabaseMobx.create_field_type_obj()), informs masking function for p_valueMask and input function for creating editing component
  //      also contains the "selectWithSearchDataObj" field which provides all flags and input data to draw the correct select/multiselect/sharedpercent input component
  //  p_valueRaw [required]
  //      the raw value used as the input to the editing component
  //  p_valueMask
  //      - undefined (default)   if no p_valueMask is provided, this function will call DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj() to create the mask using the provided p_valueRaw and p_fieldTypeObj
  //      - "Masked Value"        string or int or html displayed next to the p_fieldDisplayName and edit icon before editing it
  //  p_valueMaskPlainText
  //      - undefined (default)   if no valueMaskPlainText is provided, if p_valueTitleTF is true, then this function calls DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj() to compute the plaintext mask using the provided p_valueRaw and p_fieldTypeObj
  //      - "PlainText Value"     string displayed in value hover title if p_valueTitleTF is true
  //  p_valueIsEditableTFU
  //      - undefined (default)   no edit icon and no gap where it would be (used in snapshot views)
  //      - false                 no edit icon, but leaves a gap the same size as if it were there
  //      - true                  edit icon displayed
  //  p_valuesToNotIncludeArray
  //      - undefined (default)   no edit components are affected
  //      - [3,1,7,22]            for select or sharedpercent fieldTypes, all listed values in this array will be removed from the full list of choices in the select
  //  p_containerClass
  //      - undefined (default)   container is a div with no outer margins or padding
  //      - "cssClass cssClass2"  container div applies these styles
  //  p_fieldClass
  //      - "" (default)          p_fieldDisplayName has standard text color/size
  //      - "cssClass cssClass2"  p_fieldDisplayName text has these styles
  //  p_fieldFontColor
  //      - undefined (default)   p_fieldDisplayName no extra font color applied
  //      - "ff0000"              p_fieldDisplayName has font color that overrides p_fieldClass colors
  //  p_fieldHighlightColor
  //      - undefined (default)   p_fieldDisplayName no highlight color applied around text
  //      - "ff0000"              p_fieldDisplayName has highlight color
  //  p_fieldWidth
  //      - "max" (default)       max width as a flex11a (value is a flex00a pushed to the far right)
  //      - "45%"                 percent as a flex00a (value is a flex11a taking up the remaining right side)
  //      - "16em"                fixed width as a flex00a (value is a flex11a taking up the remaining right side)
  //  p_fieldNowrapTF
  //      - false (default)       field display wraps an unlimited number of lines to fit the whole string in its flex container
  //      - true                  the field cuts off in a single line <Nowrap>
  //  p_fieldTitle
  //      - undefined (default)   no hover title over the field display name
  //      - "Hover Text"          written title will display when hovering over only the field part of this field/value pairing, also field has dashed underline (this is used on Details card for custom explanation of field purpose)
  //  p_valueClass
  //      - "" (default)          p_valueMask has standard text color/size
  //      - "cssClass cssClass2"  p_valueMask text has these styles
  //  p_valueMaxHeight
  //      - "wrap" (default)      no limit to how many lines tall the field can be with all text included
  //      - "nowrap"              gives one line with ... cutoff in a Nowrap component
  //      - "4em"                 custom height value uses a MaxHeightWrap component to cut off the text at 4em high
  //  p_valueTitleTF
  //      - false (default)       no hover title when hovering over the value part of the field value pair when not editing
  //      - true                  hover over the value shows a hover title of the full valueMaskPlainText of the value
  //  p_fieldValueVerticallyAlignedTF
  //      - true (default)        field and edit/save/cancel and value are vertically centered with each other
  //      - false                 field and edit/save/cancel and value are at the top of their containers
  //  p_floatingBoxTitle
  //      - "" (default)          floating box edit has no title at the top
  //      - "Edit Box Title"      floating box edit this title

  onclick_edit_icon = () => {
    const p_ceEditItemString = this.props.p_ceEditItemString;
    const p_isContactFieldTF = JSFUNC.prop_value(this.props.p_isContactFieldTF, false);
    this.props.CaptureExecMobx.a_set_item_editing_capture_dash_card_dash_item_id(p_ceEditItemString, p_isContactFieldTF);
  }

  onsave_changed_input = (i_newValue) => {
    if(JSFUNC.is_function(this.props.f_onSaveChanged)) {
      this.props.f_onSaveChanged(i_newValue);
    }
    this.oncancel_close_item_editing();
  }

  oncancel_close_item_editing = () => {
    const p_isContactFieldTF = JSFUNC.prop_value(this.props.p_isContactFieldTF, false);
    this.props.CaptureExecMobx.a_close_item_editing(p_isContactFieldTF);
  }

  onclick_prevent_click = (event) => {
    event.stopPropagation();
  }

  render() {
    const p_ceEditItemString = this.props.p_ceEditItemString;
    const p_isContactFieldTF = JSFUNC.prop_value(this.props.p_isContactFieldTF, false);
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valueRaw = this.props.p_valueRaw;
    const p_valueMask = this.props.p_valueMask;
    const p_valueMaskPlainText = this.props.p_valueMaskPlainText;
    const p_valueIsEditableTFU = this.props.p_valueIsEditableTFU;
    const p_valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;
    const p_containerClass = JSFUNC.prop_value(this.props.p_containerClass, "");
    const p_fieldClass = this.props.p_fieldClass;
    const p_fieldFontColor = this.props.p_fieldFontColor;
    const p_fieldHighlightColor = this.props.p_fieldHighlightColor;
    const p_fieldWidth = JSFUNC.prop_value(this.props.p_fieldWidth, "max");
    const p_fieldNowrapTF = JSFUNC.prop_value(this.props.p_fieldNowrapTF, false);
    const p_fieldTitle = this.props.p_fieldTitle;
    const p_valueClass = JSFUNC.prop_value(this.props.p_valueClass, "");
    const p_valueMaxHeight = this.props.p_valueMaxHeight;
    const p_valueTitleTF = JSFUNC.prop_value(this.props.p_valueTitleTF, false);
    const p_fieldValueVerticallyAlignedTF = JSFUNC.prop_value(this.props.p_fieldValueVerticallyAlignedTF, true);
    const p_floatingBoxTitle = JSFUNC.prop_value(this.props.p_floatingBoxTitle, "");

    //verify the p_fieldTypeObj/selectWithSearchDataObj inputs are valid
    if((p_fieldTypeObj === undefined) || (p_fieldTypeObj.requiresSelectWithSearchDataObjTF && (p_fieldTypeObj.selectWithSearchDataObj === undefined))) {
      var inputErrorString = "CEGeneralReact - CaptureExecFieldEditSaveCancel - render()\n";
      if(p_fieldTypeObj === undefined) { inputErrorString += "p_fieldTypeObj undefined\n" }
      else { inputErrorString += "selectWithSearchDataObj undefined for a '" + p_fieldTypeObj.fieldInputType + "' p_fieldTypeObj\n"; }
      inputErrorString += " - p_ceEditItemString: " + p_ceEditItemString + "\n - p_fieldDisplayName: " + p_fieldDisplayName + "\n - p_valueRaw: " + p_valueRaw + "\n - p_valueMask: " + p_valueMask;
      alert(inputErrorString);
      return(inputErrorString);
    }

    //determine from the shared CaptureExec editing item variable if this is currently being edited
    const isEditingTF = this.props.CaptureExecMobx.is_editing_item_tf(p_ceEditItemString, p_isContactFieldTF);

    //determine which parts are visible depending on the editing state and the fieldInputType
    const fieldDisplayNameIsVisibleTF = JSFUNC.text_or_number_is_filled_out_tf(p_fieldDisplayName); //if a p_fieldDisplayName was provided

    //determine which field input types require a floating box edit vs inline edit (determines if the edit icon and p_valueMask should remain under the floating box, or if they should be replaced by the save/cancel and input component)
    const editInFloatingBoxTF = (p_fieldTypeObj.textareaTF || p_fieldTypeObj.scoredTextareaTF || (p_fieldTypeObj.scoredTextTF && this.props.CaptureExecMobx.c_isMobileTF));

    //masked value is not shown when editing unless the edit is in a floating box
    const displayValueMaskTF = (!isEditingTF || editInFloatingBoxTF);

    //container class for field/icons/value
    const containerFlexRowClass = ((p_fieldValueVerticallyAlignedTF) ? ("displayFlexRowVc") : ("displayFlexRow")); //vertical alignment of field display name, edit/save/cancel buttons, and value mask or inline edit
    const containerEditingClass = ((isEditingTF) ? ("bgCaptureItemEditing tbMedPad lrPad") : ("")); //add background color and padding when editing the field

    //field/value widths
    var fieldFlexClass = "flex00a";
    var fieldFlexBasis = p_fieldWidth;
    var valueFlexClass = "flex11a";
    if((p_fieldWidth === "max") && fieldDisplayNameIsVisibleTF) {
      fieldFlexClass = "flex11a";
      fieldFlexBasis = "auto";
      valueFlexClass = "flex00a";
    }

    //masked value when not editing
    var valueMaskedFontComponent = null;
    var valueIsFilledOutTFU = undefined; //undefined means this variable wasn't computed within this part when getting the valueMask for display, need it computed for select contacts value display for fake top padding
    if(displayValueMaskTF) {
      //mask the capture raw value if an override mask was not provided
      var valueMask = p_valueMask;
      var valueMaskPlainText = p_valueMaskPlainText;
      if(p_valueMask === undefined) {
        const valueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_valueRaw, p_fieldTypeObj);
        valueMask = valueMaskSortIfoObj.valueMask;
        valueMaskPlainText = valueMaskSortIfoObj.valueMaskPlainText;
        valueIsFilledOutTFU = valueMaskSortIfoObj.isFilledOutTF;
      }

      //put the valueMaskPlainText as the hover title for the value if specified by the p_valueTitleTF input being true
      var valueTitle = undefined;
      if(p_valueTitleTF) {
        valueTitle = valueMaskPlainText;
      }

      //frame the masked value using the specified wrap and max height settings
      if(p_valueMaxHeight === undefined) {
        valueMaskedFontComponent = (
          <font className={"breakWord " + p_valueClass}>
            {valueMask}
          </font>
        );
      }
      else if(p_valueMaxHeight === "nowrap") {
        valueMaskedFontComponent = (
          <LibraryReact.Nowrap p_fontClass={p_valueClass}>
            {valueMask}
          </LibraryReact.Nowrap>
        );
      }
      else { //custom defined max height wrap
        valueMaskedFontComponent = (
          <LibraryReact.MaxHeightWrap p_maxHeight={p_valueMaxHeight} p_fontClass={"breakWord " + p_valueClass}>
            {valueMask}
          </LibraryReact.MaxHeightWrap>
        );
      }
    }

    //when p_fieldValueVerticallyAlignedTF is false, the text for the field and the value (1.2em tall) are at the top of the container, the edit button (1.6em tall) also at the top makes this look jumbled, to vertically center this (if the value is 1 line) apply a pad above the field/value from the top
    var fieldDisplayNameVAlignFakeTopPadding = undefined;
    var valueMaskVAlignFakeTopPadding = undefined;
    if(!p_fieldValueVerticallyAlignedTF) { //when vertically aligned is true, don't need this fake top spacing sa the displayFlexRowVc automatically centers everything perfectly, when false, it needs this help to align the first line of text with the edit icon
      if(p_valueIsEditableTFU !== undefined) { //if you can't edit the value, there's no edit icon, so the text lines up anyway, otherwise it needs the fake spacing
        fieldDisplayNameVAlignFakeTopPadding = "0.2em";
        valueMaskVAlignFakeTopPadding = "0.2em";
        if(p_fieldTypeObj.selectContactCompanyTF || p_fieldTypeObj.selectContactPersonTF) { //don't include the spacing over the (confirmed filled out) valueMask for select contacts to give more space for the larger contact bubbles
          if(valueIsFilledOutTFU === undefined) { //if this filled out tf hasn't been calculated yet, calculate it here
            valueIsFilledOutTFU = this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(p_valueRaw, p_fieldTypeObj);
            if(valueIsFilledOutTFU) {
              valueMaskVAlignFakeTopPadding = undefined; //no fake spacing over the contact bubble valueMask
            }
          }
        }
      }
    }

    return(
      <div
        className={containerFlexRowClass + " " + containerEditingClass + " " + p_containerClass}
        onClick={((isEditingTF) ? (this.onclick_prevent_click) : (undefined))}>
        <CaptureExecFieldEditSaveCancelFieldAndEditIcon
          p_fieldDisplayNameIsVisibleTF={fieldDisplayNameIsVisibleTF}
          p_fieldFlexClass={fieldFlexClass}
          p_fieldFlexBasis={fieldFlexBasis}
          p_fieldTitle={p_fieldTitle}
          p_fieldClass={p_fieldClass}
          p_fieldFontColor={p_fieldFontColor}
          p_fieldHighlightColor={p_fieldHighlightColor}
          p_fieldNowrapTF={p_fieldNowrapTF}
          p_fieldDisplayName={p_fieldDisplayName}
          p_valueIsEditableTFU={p_valueIsEditableTFU}
          p_isEditingTF={isEditingTF}
          p_editInFloatingBoxTF={editInFloatingBoxTF}
          p_vAlignFakeTopPadding={fieldDisplayNameVAlignFakeTopPadding}
          f_onClickEditIcon={this.onclick_edit_icon}
        />
        {(displayValueMaskTF) &&
          <div
            className={valueFlexClass + " breakWord"}
            style={{paddingTop:valueMaskVAlignFakeTopPadding}}
            title={valueTitle}>
            {valueMaskedFontComponent}
          </div>
        }
        {(isEditingTF) &&
          <CaptureExecInputInlineOrFloatingWithSaveCancel
            p_fieldDisplayName={p_fieldDisplayName}
            p_fieldTypeObj={p_fieldTypeObj}
            p_valueRaw={p_valueRaw}
            p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
            p_selectInitOptionsBoxOpenTF={true}
            p_fieldValueVerticallyAlignedTF={p_fieldValueVerticallyAlignedTF}
            p_editInFloatingBoxTF={editInFloatingBoxTF}
            p_floatingBoxTitle={p_floatingBoxTitle}
            p_initialValueIsBlankValueTF={false}
            f_onSaveChanged={this.onsave_changed_input}
            f_onCancel={this.oncancel_close_item_editing}
          />
        }
      </div>
    );
  }
}));

class CaptureExecFieldEditSaveCancelFieldAndEditIcon extends Component { //props: p_fieldDisplayNameIsVisibleTF, p_fieldFlexClass, p_fieldFlexBasis, p_fieldTitle, p_fieldClass, p_fieldFontColor, p_fieldHighlightColor, p_fieldNowrapTF, p_fieldDisplayName, p_valueIsEditableTFU, p_isEditingTF, p_editInFloatingBoxTF, p_vAlignFakeTopPadding, f_onClickEditIcon
  constructor(props) {
    super(props);
    this.state = {
      s_hoverEditIconTF: false
    };
  }

  componentDidUpdate(prevProps) {
    if(this.props.p_isEditingTF !== prevProps.p_isEditingTF) {
      this.setState({s_hoverEditIconTF:false});
    }
  }

  onhover_edit_icon = (i_hoverTF) => {
    this.setState({s_hoverEditIconTF:i_hoverTF});
  }

  render() {
    const s_hoverEditIconTF = this.state.s_hoverEditIconTF;

    const p_fieldDisplayNameIsVisibleTF = this.props.p_fieldDisplayNameIsVisibleTF;
    const p_fieldFlexClass = this.props.p_fieldFlexClass;
    const p_fieldFlexBasis = this.props.p_fieldFlexBasis;
    const p_fieldTitle = this.props.p_fieldTitle;
    const p_fieldClass = this.props.p_fieldClass;
    const p_fieldFontColor = this.props.p_fieldFontColor;
    const p_fieldHighlightColor = this.props.p_fieldHighlightColor;
    const p_fieldNowrapTF = this.props.p_fieldNowrapTF;
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_valueIsEditableTFU = this.props.p_valueIsEditableTFU;
    const p_isEditingTF = this.props.p_isEditingTF;
    const p_editInFloatingBoxTF = this.props.p_editInFloatingBoxTF;
    const p_vAlignFakeTopPadding = this.props.p_vAlignFakeTopPadding;

    const currentlyEditingAnInlineEditTF = (p_isEditingTF && !p_editInFloatingBoxTF);

    //field display component (not drawn if not display name is provided)
    var fieldDisplayNameComponent = null;
    if(p_fieldDisplayNameIsVisibleTF) {
      const fieldTitleTF = JSFUNC.text_or_number_is_filled_out_tf(p_fieldTitle);
      const fieldContainerTextDecoration = ((s_hoverEditIconTF) ? ("underline") : ("none"));

      var usingFieldClassTF = false;
      var fieldClassStringOrUndefined = undefined;
      if(p_fieldClass !== undefined) {
        usingFieldClassTF = true;
        fieldClassStringOrUndefined = p_fieldClass;
        if(fieldTitleTF) {
          fieldClassStringOrUndefined += " fontUnderlineDotted";
        }
      }
      else if(fieldTitleTF) { //no class, but has a hover text title
        usingFieldClassTF = true;
        fieldClassStringOrUndefined = "fontUnderlineDotted";
      }

      var usingFieldFontColorTF = false;
      var fieldFontHashColorOrUndefined = undefined;
      if(p_fieldFontColor !== undefined) {
        usingFieldFontColorTF = true;
        fieldFontHashColorOrUndefined = "#" + p_fieldFontColor;
      }

      var fieldDisplayNameFontComponent = undefined;
      if(p_fieldNowrapTF) {
        fieldDisplayNameFontComponent = (
          <LibraryReact.Nowrap p_fontClass={fieldClassStringOrUndefined} p_styleObj={{color:fieldFontHashColorOrUndefined}}>
            {p_fieldDisplayName}
          </LibraryReact.Nowrap>
        );
      }
      else if(usingFieldClassTF || usingFieldFontColorTF) {
        fieldDisplayNameFontComponent = (
          <font className={fieldClassStringOrUndefined} style={{color:fieldFontHashColorOrUndefined}}>
            {p_fieldDisplayName}
          </font>
        );
      }
      else {
        fieldDisplayNameFontComponent = p_fieldDisplayName;
      }

      if(p_fieldHighlightColor !== undefined) {
        fieldDisplayNameFontComponent = (
          <div style={{background:"#" + p_fieldHighlightColor}}>
            {fieldDisplayNameFontComponent}
          </div>
        );
      }

      fieldDisplayNameComponent = (
        <div
          className={p_fieldFlexClass + " breakWord"}
          style={{flexBasis:p_fieldFlexBasis, textDecoration:fieldContainerTextDecoration, paddingTop:p_vAlignFakeTopPadding}}
          title={((fieldTitleTF) ? (p_fieldTitle) : (undefined))}>
          {fieldDisplayNameFontComponent}
        </div>
      );
    }

    return(
      <>
        {fieldDisplayNameComponent}
        {((p_valueIsEditableTFU !== undefined) && !currentlyEditingAnInlineEditTF) &&
          <div className="flex00a" style={{height:"1.6em"}}>
            <EditSaveCancelIcon
              p_iconType={((p_valueIsEditableTFU) ? ("edit") : ("blank"))}
              f_onClick={((p_valueIsEditableTFU) ? (this.props.f_onClickEditIcon) : (undefined))}
              f_onHover={((p_valueIsEditableTFU && p_fieldDisplayNameIsVisibleTF) ? (this.onhover_edit_icon) : (undefined))}
            />
          </div>
        }
      </>
    );
  }
}



export const CaptureExecFieldAddNewItemsButton = inject("DatabaseMobx")(observer(
class CaptureExecFieldAddNewItemsButton extends Component { //props: p_buttonType, p_buttonText, p_addInstructions, p_addInstructionsNowrapTF, p_fieldTypeObj, p_valuesToNotIncludeArray, p_contactsZoomedCompanyID, p_tabIndex, f_onAddSelectedItems
  //displays an add button
  //  1. when the button is clicked, the button is replaced by an inline input with save/cancel, or by a floating box input with save/cancel
  //  2. item(s) are selected, then save is pushed
  //  3. the selected item id/idsComma is passed out of the function, or a cancel command

  constructor(props) {
    super(props);
    this.state = {
      s_isAddingTF: false
    };
  }

  onclick_add_button = () => {
    this.setState({s_isAddingTF:true});
  }

  onclick_save_add_selected_items = (i_newValue) => {
    const p_fieldTypeObj = this.props.p_fieldTypeObj;

    if(JSFUNC.is_function(this.props.f_onAddSelectedItems)) {
      const selectedItemsValueIsFilledOutTF = this.props.DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(i_newValue, p_fieldTypeObj); //determine if something was selected (if newValue is considered "filled out" for this fieldTypeObj)
      if(selectedItemsValueIsFilledOutTF) { //only save if the field is filled out, otherwise cancel
        this.props.f_onAddSelectedItems(i_newValue); //returns a value in the format based on the fieldTypeObj (single int for select, string comma list for selectmulti, etc)
      }
    }
    this.setState({s_isAddingTF:false});
  }

  onclick_cancel = () => {
    this.setState({s_isAddingTF:false});
  }

  render() {
    const s_isAddingTF = this.state.s_isAddingTF;

    const p_buttonType = this.props.p_buttonType;
    const p_buttonText = this.props.p_buttonText;
    const p_addInstructions = this.props.p_addInstructions; //instructions to put above the inline input or as the floating box title
    const p_addInstructionsNowrapTF = JSFUNC.prop_value(this.props.p_addInstructionsNowrapTF);
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;
    const p_contactsZoomedCompanyID = this.props.p_contactsZoomedCompanyID;
    const p_tabIndex = this.props.p_tabIndex;

    const editInFloatingBoxTF = (p_fieldTypeObj.textareaTF);

    //add button
    const buttonComponent = (
      <CEButton
        p_type={p_buttonType}
        p_text={p_buttonText}
        p_tabIndex={p_tabIndex}
        f_onClick={this.onclick_add_button}
      />
    );

    //input component when editing the field
    var inputWithSaveCancelComponent = null;
    if(s_isAddingTF) {
      inputWithSaveCancelComponent = (
        <CaptureExecInputInlineOrFloatingWithSaveCancel
          p_fieldDisplayName={undefined}
          p_fieldTypeObj={p_fieldTypeObj}
          p_valueRaw={p_fieldTypeObj.blankValue} //edit component initializes with nothing filled out or selected
          p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
          p_contactsZoomedCompanyID={p_contactsZoomedCompanyID}
          p_selectInitOptionsBoxOpenTF={true}
          p_fieldValueVerticallyAlignedTF={true}
          p_editInFloatingBoxTF={editInFloatingBoxTF}
          p_floatingBoxTitle={p_addInstructions}
          p_initialValueIsBlankValueTF={true}
          f_onSaveChanged={this.onclick_save_add_selected_items}
          f_onCancel={this.onclick_cancel}
        />
      );
    }

    //floating box always shows button, input in floating box drawn when adding
    if(editInFloatingBoxTF) {
      return(
        <div className="displayFlexColumnHcVc">
          {buttonComponent}
          {inputWithSaveCancelComponent}
        </div>
      );
    }

    //inline edit with instructions above when adding
    if(s_isAddingTF) {
      return(
        <div className="medFullPad bgCaptureItemEditing">
          {(p_addInstructions !== undefined) &&
            <div
              className="smallBottomMargin textCenter"
              title={((p_addInstructionsNowrapTF) ? (p_addInstructions) : (undefined))}>
              {(p_addInstructionsNowrapTF) ? (
                <LibraryReact.Nowrap p_fontClass="fontBold fontItalic">
                  {p_addInstructions}
                </LibraryReact.Nowrap>
              ) : (
                <font className="fontBold fontItalic">
                  {p_addInstructions}
                </font>
              )}
            </div>
          }
          {inputWithSaveCancelComponent}
        </div>
      );
    }

    //inline edit before adding, just the button
    return(
      <div className="displayFlexColumnHcVc">
        {buttonComponent}
      </div>
    );
  }
}));



//=========================================================================================================================================================
//Components that access each of the individual input types based on fieldInputType (A Master Component that picks from: 1. Generic Inputs And Selects, 2. Contact Persons and Companies, 3. Shared Percents [owners and reasons won/lost where total share must add up to 100% before saving])
//=========================================================================================================================================================
function CaptureExecInputInlineOrFloatingWithSaveCancel(props) {
  //p_fieldDisplayName, p_fieldTypeObj, p_valueRaw, p_valuesToNotIncludeArray, p_contactsZoomedCompanyID, p_selectInitOptionsBoxOpenTF, p_fieldValueVerticallyAlignedTF, p_editInFloatingBoxTF, p_floatingBoxTitle, p_initialValueIsBlankValueTF, f_onSaveChanged, f_onCancel
  const p_fieldDisplayName = props.p_fieldDisplayName; //only used for scored_text/scored_textarea for deal shaping questions to rewrite the question above the inputs
  const p_fieldTypeObj = props.p_fieldTypeObj;
  const p_valueRaw = props.p_valueRaw;
  const p_valuesToNotIncludeArray = props.p_valuesToNotIncludeArray;
  const p_contactsZoomedCompanyID = props.p_contactsZoomedCompanyID;
  const p_selectInitOptionsBoxOpenTF = props.p_selectInitOptionsBoxOpenTF;
  const p_fieldValueVerticallyAlignedTF = props.p_fieldValueVerticallyAlignedTF;
  const p_editInFloatingBoxTF = props.p_editInFloatingBoxTF;
  const p_floatingBoxTitle = props.p_floatingBoxTitle;
  const p_initialValueIsBlankValueTF = props.p_initialValueIsBlankValueTF; //only true when using an Add Button setup so that saving the initial value actually fires the onSaveChanged function

  //select from contacts system floating box (but not a contacts sharedpercent)
  if((p_fieldTypeObj.selectContactCompanyTF || p_fieldTypeObj.selectContactPersonTF) && !p_fieldTypeObj.sharedPercentTF) {
    return(
      <SelectOrSelectMultiContactsFloatingBoxFromFieldTypeObj
        p_fieldTypeObj={p_fieldTypeObj}
        p_valueRaw={p_valueRaw}
        p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
        p_contactsZoomedCompanyID={p_contactsZoomedCompanyID}
        p_floatingBoxTitle={p_floatingBoxTitle}
        f_onSaveChanged={props.f_onSaveChanged}
        f_onCancel={props.f_onCancel}
      />
    );
  }

  //separate scored text/textarea components for deal shaping text answers, separate with their own save/cancel to prevent save when incomplete with error message
  if(p_fieldTypeObj.scoredTextTF || p_fieldTypeObj.scoredTextareaTF) {
    return(
      <TextWithScoreInlineOrFloatingWithSaveCancel
        p_textTrueTextareaFalse={p_fieldTypeObj.scoredTextTF}
        p_includeScoringTF={p_fieldTypeObj.scoredTextOrTextareaIncludeScoringTF}
        p_fieldDisplayName={p_fieldDisplayName}
        p_textareaScore0to100Obj={p_valueRaw}
        p_editInFloatingBoxTF={p_editInFloatingBoxTF}
        p_floatingBoxTitle={p_floatingBoxTitle}
        f_onSaveChanged={props.f_onSaveChanged}
        f_onCancel={props.f_onCancel}
      />
    );
  }

  return(
    <GenericInputOrSelectInlineOrFloatingWithSaveCancel
      p_fieldTypeObj={p_fieldTypeObj}
      p_valueRaw={p_valueRaw}
      p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
      p_selectInitOptionsBoxOpenTF={p_selectInitOptionsBoxOpenTF}
      p_fieldValueVerticallyAlignedTF={p_fieldValueVerticallyAlignedTF}
      p_editInFloatingBoxTF={p_editInFloatingBoxTF}
      p_floatingBoxTitle={p_floatingBoxTitle}
      p_initialValueIsBlankValueTF={p_initialValueIsBlankValueTF}
      f_onSaveChanged={props.f_onSaveChanged}
      f_onCancel={props.f_onCancel}
    />
  );
}



export function SelectOrSelectMultiContactsFloatingBoxFromFieldTypeObj(props) {
  //p_fieldTypeObj, p_valueRaw, p_valuesToNotIncludeArray, p_contactsZoomedCompanyID, p_floatingBoxTitle, f_onSaveChanged, f_onCancel
  const p_fieldTypeObj = props.p_fieldTypeObj;
  const p_valueRaw = props.p_valueRaw;
  const p_valuesToNotIncludeArray = props.p_valuesToNotIncludeArray;
  const p_contactsZoomedCompanyID = props.p_contactsZoomedCompanyID;
  const p_floatingBoxTitle = props.p_floatingBoxTitle;
 
  var isPersonTF = false;
  var isMultiSelectTF = false;
  if(p_fieldTypeObj !== undefined) {
    isPersonTF = (!(p_fieldTypeObj.selectContactCompanyTF === true));
    if(p_fieldTypeObj.selectWithSearchDataObj !== undefined) {
      isMultiSelectTF = p_fieldTypeObj.selectWithSearchDataObj.isMultiSelectTF;
    }
  }

  //determine select mode
  var contactsSelectMode = undefined;
  if(isPersonTF) {
    contactsSelectMode = ((isMultiSelectTF) ? ("selectmulti_contact_persons") : ("select_contact_person"));
  }
  else {
    contactsSelectMode = ((isMultiSelectTF) ? ("selectmulti_contact_companies") : ("select_contact_company"));
  }

  //if at least 1 contact is selected in p_valueRaw, set it to be initialized as open on the right side in the contact system
  var initialOpenContactID = undefined;
  if(isMultiSelectTF) {
    if(JSFUNC.selectmulti_is_filled_out_tf(p_valueRaw)) {
      const selectedContactIDsArray = JSFUNC.convert_comma_list_to_int_array(p_valueRaw);
      if(selectedContactIDsArray.length > 0) {
        initialOpenContactID = selectedContactIDsArray[0]; //use the first contact selected in the multiselect
      }
    }
  }
  else {
    if(JSFUNC.select_int_is_filled_out_tf(p_valueRaw)) {
      initialOpenContactID = p_valueRaw;
    }
  }

  return(
    <ContactsReact.SelectOrSelectMultiContactsFloatingBox
      p_selectMode={contactsSelectMode}
      p_selectedContactIDOrContactIDsComma={p_valueRaw}
      p_contactIDsAlreadyAddedArray={p_valuesToNotIncludeArray}
      p_title={p_floatingBoxTitle}
      p_openContactIsPersonTF={isPersonTF}
      p_openContactID={initialOpenContactID}
      p_initialZoomedCompanyID={p_contactsZoomedCompanyID}
      f_onSaveChanged={props.f_onSaveChanged}
      f_onCancel={props.f_onCancel}
    />
  );
}





const TextWithScoreInlineOrFloatingWithSaveCancel = inject("CaptureExecMobx")(observer(
class TextWithScoreInlineOrFloatingWithSaveCancel extends Component { //props: p_textTrueTextareaFalse, p_includeScoringTF, p_fieldDisplayName, p_textareaScore0to100Obj, p_editInFloatingBoxTF, p_floatingBoxTitle, f_onSaveChanged, f_onCancel
  constructor(props) {
    super(props);

    this.state = {
      s_initialRawValueObj: this.props.p_textareaScore0to100Obj,
      s_currentRawValueObj: this.props.p_textareaScore0to100Obj
    };
  }

  onselect_option = (i_newValue) => {
    this.setState({s_currentValue: i_newValue});
  }

  onchange_text = (i_newValue) => {
    this.setState({
      s_currentRawValueObj: {textarea:i_newValue, score0to100:this.state.s_currentRawValueObj.score0to100}
    });
  }

  onchange_score = (i_newValue) => {
    const newScoreInt = Math.round(i_newValue);
    this.setState({
      s_currentRawValueObj: {textarea:this.state.s_currentRawValueObj.textarea, score0to100:newScoreInt}
    });
  }

  onclick_save_icon = () => {
    const iObj = this.state.s_initialRawValueObj;
    const cObj = this.state.s_currentRawValueObj;
    const [textErrorTF, scoreErrorTF, redTextWarningMessage] = this.check_text_and_score_for_errors(cObj);
    if(!textErrorTF && !scoreErrorTF) { //only save with no red text errors, otherwise do not send the onSaveChanged or onCancel
      if(this.props.f_onSaveChanged && ((iObj.textarea !== cObj.textarea) || (iObj.score0to100 !== cObj.score0to100))) { //save if either text or score changed
        this.props.f_onSaveChanged(cObj);
      }
      else if(this.props.f_onCancel) {
        this.props.f_onCancel();
      }
    }
  }

  check_text_and_score_for_errors(i_textareaScore0to100Obj) {
    const p_includeScoringTF = JSFUNC.prop_value(this.props.p_includeScoringTF, true);

    var textErrorTF = false;
    var scoreErrorTF = false
    var redTextWarningMessage = undefined;

    //check score/text for errors (only if score is included, no need to check otherwise)
    if(p_includeScoringTF) {
      const text = i_textareaScore0to100Obj.textarea;
      const score0to100 = i_textareaScore0to100Obj.score0to100;

      if((score0to100 <= 0) && JSFUNC.text_or_number_is_filled_out_tf(text)) { //can't save a scored textarea that has something typed if the percent score is 0%, shows an error and highlights the input range slider
        redTextWarningMessage = "Any entered answer must be scored higher than 0%";
        scoreErrorTF = true;
      }
      else if((score0to100 > 0) && !JSFUNC.text_or_number_is_filled_out_tf(text)) { //can't save if scored textarea has no text and a score > 0%
        redTextWarningMessage = "A blank answer cannot be saved with a score higher than 0%";
        textErrorTF = true;
      }
    }

    return([textErrorTF, scoreErrorTF, redTextWarningMessage]);
  }

  render() {
    const s_initialRawValueObj = this.state.s_initialRawValueObj;
    const s_currentRawValueObj = this.state.s_currentRawValueObj;

    const p_textTrueTextareaFalse = JSFUNC.prop_value(this.props.p_textTrueTextareaFalse, false);
    const p_includeScoringTF = JSFUNC.prop_value(this.props.p_includeScoringTF, true);
    const p_fieldDisplayName = this.props.p_fieldDisplayName;
    const p_editInFloatingBoxTF = this.props.p_editInFloatingBoxTF;
    const p_floatingBoxTitle = this.props.p_floatingBoxTitle;

    const answerIsChangedTF = ((s_initialRawValueObj.textarea !== s_currentRawValueObj.textarea) || (s_initialRawValueObj.score0to100 !== s_currentRawValueObj.score0to100));
    const cancelConfirmationMessage = ((answerIsChangedTF) ? ("Are you sure you want to cancel editing this Deal Shaping Question? Any entered data will be lost.") : (undefined));

    //verify input is in correct obj format
    var errorMessage = undefined;
    if(!JSFUNC.is_obj(s_currentRawValueObj)) {
      errorMessage = "s_currentRawValueObj is not an obj (" + JSFUNC.print(s_currentRawValueObj) + ")";
    }
    else if(!s_currentRawValueObj.hasOwnProperty("textarea") || !s_currentRawValueObj.hasOwnProperty("score0to100")) {
      errorMessage = "s_currentRawValueObj does not contain 'textarea' and 'score0to100' (" + JSFUNC.print(s_currentRawValueObj) + ")";
    }

    if(errorMessage !== undefined) {
      const jsDescription = JSFUNC.js_description_from_action("Inputs - TextWithScoreInlineOrFloatingWithSaveCancel", "render", [], []);
      JSPHP.record_z_error(jsDescription, errorMessage);
      return(
        <EditInlineWithSaveCancel f_onClickSave={this.props.f_onCancel} f_onClickCancel={this.props.f_onCancel}>
          <font className="fontItalic fontTextLighter">
            {errorMessage}
          </font>
        </EditInlineWithSaveCancel>
      );
    }

    //draw the score and text input components
    const text = s_currentRawValueObj.textarea;
    const score0to100 = s_currentRawValueObj.score0to100;

    const [textErrorTF, scoreErrorTF, redTextWarningMessage] = this.check_text_and_score_for_errors(s_currentRawValueObj);

    //input score0to100 range component
    var scoreRangeInputComponent = null;
    if(p_includeScoringTF) {
      scoreRangeInputComponent = (
        <div className="flex00a smallBottomMargin">
          <div className="displayFlexRowVc flexWrap">
            <div className="flex00a rMargin" style={{flexBasis:"20em"}}>
              <LibraryReact.RangeWithPercentInt0to100
                p_value={score0to100}
                p_tabIndex={2}
                p_errorTF={scoreErrorTF}
                f_onChange={this.onchange_score}
              />
            </div>
            <div className="flex11a fontItalic fontTextLighter">
              {"Self-assessed completeness score (0-100%) for this answer"}
            </div>
          </div>
          {(textErrorTF || scoreErrorTF) && (
            <div className="microTopMargin">
              <font className="fontItalic font09 fontRed">
                {redTextWarningMessage}
              </font>
            </div>
          )}
        </div>
      );
    }

    //input text/textarea component
    var inputTextTextareaComponent = null;
    if(p_textTrueTextareaFalse) {
      inputTextTextareaComponent = (
        <LibraryReact.Text
          p_value={text}
          p_styleObj={{width:"100%"}}
          p_tabIndex={1}
          p_errorTF={textErrorTF}
          f_onChange={this.onchange_text}
          f_onKeyDownEnter={this.onclick_save_icon}
        />
      );
    }
    else {
      inputTextTextareaComponent = (
        <LibraryReact.Textarea
          p_value={text}
          p_class="flex11a"
          p_tabIndex={1}
          p_errorTF={textErrorTF}
          f_onChange={this.onchange_text}
        />
      );
    }


    if(p_editInFloatingBoxTF) { //textareas launch a full screen edit with save/close in the upper right corner
      return(
        <FloatingBoxWithSaveCancel
          p_title={p_floatingBoxTitle}
          p_cancelConfirmationMessage={cancelConfirmationMessage}
          f_onClickSave={this.onclick_save_icon}
          f_onClickCancel={this.props.f_onCancel}>
          <div className="flex11a displayFlexColumn lrMedMargin medBottomMargin">
            <div className="flex00a smallTopMargin smallBottomMargin">
              <div className="font11 fontBlue">
                {p_fieldDisplayName}
              </div>
            </div>
            {scoreRangeInputComponent}
            {inputTextTextareaComponent}
          </div>
        </FloatingBoxWithSaveCancel>
      );
    }

    //text is inline (unless the screen is mobile, then it is placed in a floating box)
    return(
      <EditInlineWithSaveCancel
        p_cancelConfirmationMessage={cancelConfirmationMessage}
        f_onClickSave={this.onclick_save_icon}
        f_onClickCancel={this.props.f_onCancel}>
        {scoreRangeInputComponent}
        <div className="flex11a displayFlexColumn">
          {inputTextTextareaComponent}
        </div>
      </EditInlineWithSaveCancel>
    );
  }
}));







class GenericInputOrSelectInlineOrFloatingWithSaveCancel extends Component { //props:
  //p_fieldTypeObj, p_valueRaw, p_valuesToNotIncludeArray, p_selectInitOptionsBoxOpenTF, p_fieldValueVerticallyAlignedTF, p_editInFloatingBoxTF, p_floatingBoxTitle, p_initialValueIsBlankValueTF,
  //f_onSaveChanged, f_onCancel
  constructor(props) {
    super(props);

    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valueRaw = this.props.p_valueRaw;
    const p_initialValueIsBlankValueTF = JSFUNC.prop_value(this.props.p_initialValueIsBlankValueTF, false); //add new item button requires the s_initialValueRaw to be undefined so that the first save operation (if the value is not changed from its initial value) actually calls onSaveChanged (otherwise the logic is to skip it if initial===current)

    //determine the initial valueRaw (different for sharedpercent)
    var initialRawValue = undefined;
    if(p_initialValueIsBlankValueTF) {
      initialRawValue = p_fieldTypeObj.blankValue;
    }
    else {
      initialRawValue = p_valueRaw;
      if(p_fieldTypeObj.sharedPercentTF) { //if the input is a sharedpercent, first sort the colon comma list by percent decreasing, the actual input interface does not change order while editing so that the items do not swap places while changing the valus
        initialRawValue = JSFUNC.sort_colon_comma_list(initialRawValue, 2, false);
      }
    }

    this.state = {
      s_initialValueRaw: initialRawValue,
      s_currentValueRaw: initialRawValue,
      s_triedFirstSaveTF: false
    };
  }

  onchange_value_or_onselect_option = (i_newValue) => {
    this.setState({s_currentValueRaw:i_newValue});
  }

  onclick_save_icon = () => {
    const s_initialValueRaw = this.state.s_initialValueRaw;
    const s_currentValueRaw = this.state.s_currentValueRaw;

    const p_fieldTypeObj = this.props.p_fieldTypeObj;

    var allowSaveTF = true;
    if(p_fieldTypeObj.emailTF) { //if trying to save an email field, don't allow save if the email is not valid (even if the current value wasn't changed from the initial value)
      if(s_currentValueRaw !== "") { //allow completely blank field to be saved (even though that's not a valid email, user might not know it)
        const emailErrorMessage = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(s_currentValueRaw);
        allowSaveTF = (emailErrorMessage === undefined); //only allow saving if this filled in email field is a valid email
      }
    }
    else if(p_fieldTypeObj.sharedPercentTF) { //for a sharedpercent, save can only be pushed if the percents total 100%
      const sumOfCurrentSharedPercents = JSFUNC.sum_of_colon_comma_list_int2(s_currentValueRaw, 100);
      allowSaveTF = (sumOfCurrentSharedPercents === 100);
    }

    if(allowSaveTF) { //save if allowed to save
      if(s_currentValueRaw !== s_initialValueRaw) { //current value has changed
        this.call_onsave_with_current_value_raw();
      }
      else { //current value is still the initial value, no need to save data and can simply cancel
        this.call_oncancel();
      }
    }
    else { //if not allowed to save, set the flag that the user tried to save for the first time unsuccessfully to true
      this.setState({s_triedFirstSaveTF:true});
    }
  }

  call_onsave_with_current_value_raw = () => {
    //cancel button is optional for this component, save button always shows up because of this onclick_save_icon function, if the save function is not defined, the single save button acts like a cancel
    if(JSFUNC.is_function(this.props.f_onSaveChanged)) {
      this.props.f_onSaveChanged(this.state.s_currentValueRaw);
    }
    else {
      this.call_oncancel();
    }
  }

  call_oncancel = () => {
    if(JSFUNC.is_function(this.props.f_onCancel)) {
      this.props.f_onCancel();
    }
  }

  render() {
    const s_initialValueRaw = this.state.s_initialValueRaw;
    const s_currentValueRaw = this.state.s_currentValueRaw;
    const s_triedFirstSaveTF = this.state.s_triedFirstSaveTF;

    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valueRaw = this.props.p_valueRaw;
    const p_valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;
    const p_selectInitOptionsBoxOpenTF = this.props.p_selectInitOptionsBoxOpenTF;
    const p_fieldValueVerticallyAlignedTF = this.props.p_fieldValueVerticallyAlignedTF;
    const p_editInFloatingBoxTF = this.props.p_editInFloatingBoxTF;
    const p_floatingBoxTitle = this.props.p_floatingBoxTitle;
    const p_initialValueIsBlankValueTF = this.props.p_initialValueIsBlankValueTF;

    if(p_fieldTypeObj === undefined) {
      return("--GenericInputOrSelectInlineOrFloatingWithSaveCancel p_fieldTypeObj is undefined (s_currentValueRaw: " + s_currentValueRaw + ")--")
    }

    var inputErrorMessage = undefined;
    if(s_triedFirstSaveTF) {
      if(p_fieldTypeObj.emailTF && JSFUNC.string_is_filled_out_tf(s_currentValueRaw)) {
        inputErrorMessage = JSFUNC.valid_email_address_undefined_or_invalid_email_error_message_string(s_currentValueRaw);
      }
    }

    var cancelConfirmationMessage = undefined;
    if(s_initialValueRaw !== s_currentValueRaw) { //if the current value is different from the initial value
      cancelConfirmationMessage = "Are you sure you want to cancel editing this field? Any entered data will be lost.";
    }

    const inputComponent = (
      <>
        <GenericInputOrSelectFromInputType
          p_fieldTypeObj={p_fieldTypeObj}
          p_valueRaw={s_currentValueRaw}
          p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
          p_selectInitOptionsBoxOpenTF={p_selectInitOptionsBoxOpenTF}
          p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF={true}
          p_forceTextareaToTextTF={false}
          p_tabIndex={1}
          p_title={undefined}
          p_errorTF={(inputErrorMessage !== undefined)}
          f_onChangeOrOnSelect={this.onchange_value_or_onselect_option}
          f_onKeyDownEnter={this.onclick_save_icon}
        />
        {(inputErrorMessage !== undefined) &&
          <ErrorText
            p_class="microTopMargin"
            p_text={inputErrorMessage}
          />
        }
      </>
    );

    //floating box save/cancel is a position fixed fullscreen no click area with a position fixed smaller centered box on top
    if(p_editInFloatingBoxTF) { //textareas launch a full screen edit with save/close in the upper right corner
      return(
        <FloatingBoxWithSaveCancel
          p_trblFlag="textareaEdit"
          p_title={p_floatingBoxTitle}
          p_cancelConfirmationMessage={cancelConfirmationMessage}
          f_onClickSave={this.onclick_save_icon}
          f_onClickCancel={this.props.f_onCancel}>
          <div className="flex11a displayFlexColumn lrMedMargin medBottomMargin medTopMargin">
            {inputComponent}
          </div>
        </FloatingBoxWithSaveCancel>
      );
    }

    //inline save/cancel component is a div with flex11a
    return(
      <EditInlineWithSaveCancel
        p_fieldValueVerticallyAlignedTF={p_fieldValueVerticallyAlignedTF}
        p_cancelConfirmationMessage={cancelConfirmationMessage}
        f_onClickSave={this.onclick_save_icon}
        f_onClickCancel={this.props.f_onCancel}>
        {inputComponent}
      </EditInlineWithSaveCancel>
    );
  }
}






export function GenericInputOrSelectFromInputType(props) { //props:
  //p_fieldTypeObj, p_valueRaw, p_valuesToNotIncludeArray, p_selectDisplayMaskOverwrite, p_selectInitOptionsBoxOpenTF, p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF, p_forceTextareaToTextTF, 
  //p_tabIndex, p_title, p_errorTF, p_focusTF
  //f_onChangeOrOnSelect, f_onKeyDownEnter
  const p_fieldTypeObj = props.p_fieldTypeObj;
  const p_valueRaw = props.p_valueRaw;
  const p_valuesToNotIncludeArray = props.p_valuesToNotIncludeArray;
  const p_selectDisplayMaskOverwrite = props.p_selectDisplayMaskOverwrite;
  const p_selectInitOptionsBoxOpenTF = props.p_selectInitOptionsBoxOpenTF;
  const p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF = props.p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF;
  const p_forceTextareaToTextTF = JSFUNC.prop_value(props.p_forceTextareaToTextTF, false);
  const p_tabIndex = props.p_tabIndex;
  const p_title = props.p_title;
  const p_errorTF = props.p_errorTF;
  const p_focusTF = props.p_focusTF;

  if(p_fieldTypeObj === undefined) {
    return("--GenericInputOrSelectFromInputType p_fieldTypeObj is undefined (valueRaw: " + p_valueRaw + ")--")
  }
  else if(p_fieldTypeObj.textTF || p_fieldTypeObj.emailTF || p_fieldTypeObj.phoneTF || (p_forceTextareaToTextTF && p_fieldTypeObj.textareaTF)) {
    return(
      <LibraryReact.Text
        p_value={p_valueRaw}
        p_isPasswordTF={false}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_placeholder={undefined}
        p_autocompleteName={undefined}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.textareaTF) {
    return(
      <LibraryReact.Textarea
        p_value={p_valueRaw}
        p_class="flex11a" //requires the container div to be displayFlexColumn class to stretch this textarea to 100% height
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_placeholder={undefined}
        p_autocompleteName={undefined}
        p_errorTF={p_errorTF}
        p_focusTF={p_focusTF}
        f_onChange={props.f_onChangeOrOnSelect}
      />
    );
  }
  else if(p_fieldTypeObj.intTF) {
    var min = undefined;
    var max = undefined;
    if(p_fieldTypeObj.editNumberOnly0to9TF) {
      min = 0;
      max = 9;
    }
    else {
      min = ((p_fieldTypeObj.editNumberOnlyPositiveTF) ? (0) : (-1 * JSFUNC.sort_max_mysqli_bigint()));
      max = JSFUNC.sort_max_mysqli_bigint();
    }

    return(
      <LibraryReact.Integer
        p_value={p_valueRaw}
        p_min={min}
        p_max={max}
        p_numZeroPad={undefined}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_placeholder={undefined}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.decimalTF) {
    const minValue = ((p_fieldTypeObj.editNumberOnlyPositiveTF) ? (0) : (-1 * JSFUNC.sort_max_mysqli_decimal_18_9()));
    const maxValue = JSFUNC.sort_max_mysqli_decimal_18_9();
    return(
      <LibraryReact.Decimal
        p_value={p_valueRaw}
        p_min={minValue}
        p_max={maxValue}
        p_blankValue={undefined}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.percentTF) {
    const decimalsTF = p_fieldTypeObj.editPercentHasDecimalsTF;
    const intDecimalMaxValue = ((decimalsTF) ? (JSFUNC.sort_max_mysqli_decimal_18_9()) : (JSFUNC.sort_max_mysqli_bigint()));

    var min = undefined;
    var max = undefined;
    var blankValue = undefined;
    if(p_fieldTypeObj.editNumberOnly0to100TF) {
      min = 0;
      max = 100;
      blankValue = 0; //percent_int_0to100 and percent_decimal_0to100 force a number to be typed, cannot be blank
    }
    else if(p_fieldTypeObj.editNumberOnlym1000to100TF) {
      min = -1000;
      max = 100;
      blankValue = 0;
    }
    else {
      min = (-1 * intDecimalMaxValue);
      max = intDecimalMaxValue;
      blankValue = undefined;
    }

    return(
      <LibraryReact.Percent
        p_value={p_valueRaw}
        p_min={min}
        p_max={max}
        p_decimalsTF={decimalsTF}
        p_blankValue={blankValue}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.moneyTF) {
    const includeCentsTF = p_fieldTypeObj.moneyIncludeCentsTF;
    return(
      <LibraryReact.Money
        p_value={p_valueRaw}
        p_centsTF={includeCentsTF}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_placeholder={undefined}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.websiteTF) {
    return(
      <LibraryReact.Website
        p_value={p_valueRaw}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.colorTF) {
    return(
      <LibraryReact.Color
        p_value={p_valueRaw}
        p_class={undefined}
        p_styleObj={{width:"100%"}}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.dateTF) {
    return(
      <LibraryReact.Date
        p_value={p_valueRaw}
        p_class={undefined}
        p_styleObj={undefined}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.dateLocalFromRawDateTimeUtcTF) {
    return(
      <InputDateLocalFromRawDateTimeUtc
        p_value={p_valueRaw}
        p_tabIndex={p_tabIndex}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.dateWithRelativeDateTF) {
    return(
      <InputDateWithRelativeDate
        p_value={p_valueRaw}
        p_tabIndex={p_tabIndex}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.dateWithDurationTF) {
    return(
      <InputDateWithDuration
        p_value={p_valueRaw}
        p_tabIndex={p_tabIndex}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.dateTimeTF) {
    return(
      <LibraryReact.DateTime
        p_valueDateTimeUTC={p_valueRaw}
        p_isSingleLineTF={false}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onChange={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.selectTF) {
    return(
      <InputSelectFromFieldTypeObj
        p_fieldTypeObj={p_fieldTypeObj}
        p_valueRaw={p_valueRaw}
        p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
        p_selectDisplayMaskOverwrite={p_selectDisplayMaskOverwrite}
        p_selectInitOptionsBoxOpenTF={p_selectInitOptionsBoxOpenTF}
        p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF={p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onSelect={props.f_onChangeOrOnSelect}
        f_onKeyDownEnterWhenSelectIsClosed={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.sharedPercentTF) {
    return(
      <InputSharedPercentWithMultiSelectAddItems
        p_fieldTypeObj={p_fieldTypeObj}
        p_valueRaw={p_valueRaw}
        p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
        p_tabIndex={p_tabIndex}
        f_onChange={props.f_onChangeOrOnSelect}
      />
    );
  }
  else if(p_fieldTypeObj.verticalSwitchTF) {
    var verticalSwitchValueArray = [];
    var verticalSwitchDisplayArray = [];
    var verticalSwitchRowHeightEm = 1.7;
    if(p_fieldTypeObj.selectWithSearchDataObj !== undefined) {
      if(JSFUNC.is_array(p_fieldTypeObj.selectWithSearchDataObj.valueArray) && JSFUNC.is_array(p_fieldTypeObj.selectWithSearchDataObj.displayArray)) {
        verticalSwitchValueArray = p_fieldTypeObj.selectWithSearchDataObj.valueArray;
        verticalSwitchDisplayArray = p_fieldTypeObj.selectWithSearchDataObj.displayArray;
        if(JSFUNC.is_number_not_nan_gt_0(p_fieldTypeObj.selectWithSearchDataObj.optionsHeightEm)) {
          verticalSwitchRowHeightEm = Math.max(p_fieldTypeObj.selectWithSearchDataObj.optionsHeightEm, 1.7);
        }
      }
    }

    return(
      <VerticalSwitch
        p_valueArray={verticalSwitchValueArray}
        p_displayArray={verticalSwitchDisplayArray}
        p_switchToMessageArray={undefined}
        p_switchToMessageButtonNamesArray={undefined}
        p_selectedValue={p_valueRaw}
        p_rowHeightEm={verticalSwitchRowHeightEm}
        p_sizeMultiplier={undefined}
        p_onColor={undefined}
        p_offColor={undefined}
        p_onTextFontClass={undefined}
        p_offTextFontClass={undefined}
        p_tabIndex={p_tabIndex}
        f_onSelect={props.f_onChangeOrOnSelect}
        f_onKeyDownEnter={props.f_onKeyDownEnter}
      />
    );
  }
  else if(p_fieldTypeObj.captureFavoritesTF) {
    return(
      <InputCaptureFavorite
        p_favoriteTF={p_valueRaw}
        p_standardSizeTrueCSTCellSizeFalse={true}
        f_onClick={props.f_onChangeOrOnSelect}
      />
    );
  }
  else if(p_fieldTypeObj.capturePriorityLevelTF) {
    return(
      <InputCapturePriorityLevel
        p_valueRaw={p_valueRaw}
        f_onSelect={props.f_onChangeOrOnSelect}
      />
    );
  }
  else if(p_fieldTypeObj.stylingStringTF) {
    return(
      <InputStylingStringOptions
        p_fieldTypeObj={p_fieldTypeObj}
        p_valueRaw={p_valueRaw}
        f_onChange={props.f_onChangeOrOnSelect}
      />
    );
  }

  return(
    <div>
      {"--Invalid Input Type: " + p_fieldTypeObj.fieldInputType + " (p_valueRaw: " + p_valueRaw + ")--"}
    </div>
  );
}





const InputSelectFromFieldTypeObj = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class InputSelectFromFieldTypeObj extends Component { //props: p_fieldTypeObj, p_valueRaw, p_valuesToNotIncludeArray, p_selectDisplayMaskOverwrite, p_selectInitOptionsBoxOpenTF, p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF, p_tabIndex, p_title, p_errorTF, f_onSelect, f_onKeyDownEnterWhenSelectIsClosed
  onadd_new_admin_table_entry = (i_newEntryName) => {
    //manually determine which tbl to update
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valueRaw = this.props.p_valueRaw;

    const selectWithSearchDataObj = p_fieldTypeObj.selectWithSearchDataObj;
    const isMultiSelectTF = selectWithSearchDataObj.isMultiSelectTF;
    const selectAddTblName = selectWithSearchDataObj.selectAddTblName;
    const selectAddNameFieldDbName = selectWithSearchDataObj.selectAddNameFieldDbName;
    const selectAddSortFieldDbName = selectWithSearchDataObj.selectAddSortFieldDbName; //undefined means there's no sort column

    //insert a new entry in that table (only inserts name field, thus id is auto increment and sort will be 0)
    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - InputSelectFromFieldTypeObj", "onadd_new_admin_table_entry", ["i_newEntryName"], [i_newEntryName]);
    const C_CallPhpTblUIDInsertFfsRecord = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = [selectAddNameFieldDbName];
    var valuesArray = [i_newEntryName];
    var idsbArray = ["s"];
    var resortSortColumnName = undefined;
    var resortFilterFieldNameOrFieldNamesArray = undefined;
    var resortFilterValueOrValuesArray = undefined;
    if(JSFUNC.is_string(selectAddSortFieldDbName)) {
      fieldNamesArray.push(selectAddSortFieldDbName);
      valuesArray.push(JSFUNC.sort_max_mysqli_int());
      idsbArray.push("i");
      resortSortColumnName = selectAddSortFieldDbName;
      resortFilterFieldNameOrFieldNamesArray = [];
      resortFilterValueOrValuesArray = [];
    }
    C_CallPhpTblUIDInsertFfsRecord.add_insert(selectAddTblName, fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedEntryID = i_parseResponse.outputObj.i0;
      if(this.props.f_onSelect) {
        //if this is a multiselect, merge the newly created id with the ids that were previously selected as a comma list
        var selectedIDOrIDsComma = undefined;
        if(isMultiSelectTF) { //merge the new id into the comma list of already selected ids
          selectedIDOrIDsComma = JSFUNC.add_value_to_comma_list(newlyInsertedEntryID, p_valueRaw);
        }
        else { //single select type, choose the single new itemID as an int to pass as selected output
          selectedIDOrIDsComma = newlyInsertedEntryID;
        }

        this.props.f_onSelect(selectedIDOrIDsComma);
      }
    }
    C_CallPhpTblUIDInsertFfsRecord.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUIDInsertFfsRecord.execute();
  }

  render() {
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valueRaw = this.props.p_valueRaw;
    const p_valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;
    const p_selectDisplayMaskOverwrite = this.props.p_selectDisplayMaskOverwrite;
    const p_selectInitOptionsBoxOpenTF = this.props.p_selectInitOptionsBoxOpenTF;
    const p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF = this.props.p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF;
    const p_tabIndex = this.props.p_tabIndex;
    const p_title = this.props.p_title;
    const p_errorTF = this.props.p_errorTF;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;

    const selectWithSearchDataObj = p_fieldTypeObj.selectWithSearchDataObj;

    var valueMaskPlainText = "";
    if(p_selectDisplayMaskOverwrite !== undefined) {
      valueMaskPlainText = p_selectDisplayMaskOverwrite;
    }
    else { //masking for selects uses the swsDataObj unselectedDisplay when the p_valueRaw is not found in the valueArray
      const valueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_valueRaw, p_fieldTypeObj);
      if(valueMaskSortIfoObj.isFilledOutTF) { //when the select has a valid option selected, use the valueMaskPlainText (so that clickable links for html buttons/progress are not drawn in the display of the Select)
        valueMaskPlainText = valueMaskSortIfoObj.valueMaskPlainText;
      }
      else { //if the Select is not filled out, display the valueMask italic/gray text (usually has the text '--No [Item] Selected--')
        valueMaskPlainText = valueMaskSortIfoObj.valueMask;
      }
    }

    const canAddToAdminTableTF = (JSFUNC.is_string(selectWithSearchDataObj.selectAddTblName) && JSFUNC.is_string(selectWithSearchDataObj.selectAddNameFieldDbName));

    return(
      <LibraryReact.SelectWithSearch
        p_valueArray={selectWithSearchDataObj.valueArray}
        p_displayArray={selectWithSearchDataObj.displayArray}
        p_treeIDArray={selectWithSearchDataObj.treeIDArray}
        p_colorArray={selectWithSearchDataObj.colorArray}
        p_bgColorArray={selectWithSearchDataObj.bgColorArray}
        p_hiddenUnlessCheckedTFArray={selectWithSearchDataObj.hiddenUnlessCheckedTFArray}
        p_unableToHighlightOrClickTFArray={selectWithSearchDataObj.unableToHighlightOrClickTFArray}
        p_optionDisplayOverwriteStringsArray={selectWithSearchDataObj.optionDisplayOverwriteStringsArray}
        p_multiIDsCheckedWhenCheckedArrayOfArrays={selectWithSearchDataObj.multiIDsCheckedWhenCheckedArrayOfArrays}
        p_selectedValue={p_valueRaw}
        p_selectedDisplay={valueMaskPlainText}
        p_valuesToNotIncludeArray={p_valuesToNotIncludeArray}
        p_valuesAreStringsTF={selectWithSearchDataObj.valuesAreStringsTF}
        p_isMultiSelectTF={selectWithSearchDataObj.isMultiSelectTF}
        p_hasClearSelectionTF={selectWithSearchDataObj.hasClearSelectionTF}
        p_searchTF={selectWithSearchDataObj.hasSearchTF}
        p_initOptionsBoxOpenTF={p_selectInitOptionsBoxOpenTF}
        p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF={p_selectSelectedDisplayFocusOnKeyDownEnterOpensSelectTF}
        p_optionsBoxForMobileTF={c_isMobileTF}
        p_width="100%" //this width 100% requires that the containing element for InputFromInputTypeAndDbName must be positionRelative so that the options list is also 100% width, otherwise options list is 100% width of the browser
        p_highlightColor={undefined}
        p_tabIndex={p_tabIndex}
        p_title={p_title}
        p_errorTF={p_errorTF}
        f_onOptionSelect={this.props.f_onSelect}
        f_onSaveNewEntryName={((canAddToAdminTableTF) ? (this.onadd_new_admin_table_entry) : (undefined))}
        f_onKeyDownEnterWhenSelectIsClosed={this.props.f_onKeyDownEnterWhenSelectIsClosed}
      />
    );
  }
}));








const InputSharedPercentWithMultiSelectAddItems = inject("CaptureExecMobx", "DatabaseMobx")(observer(
class InputSharedPercentWithMultiSelectAddItems extends Component { //props: p_fieldTypeObj, p_valueRaw, p_valuesToNotIncludeArray, p_tabIndex, f_onChange
  //Multiple Items with Shared Percentage Input (Capture Owners [Team/Division], Reasons Won/Lost, Contacts [without percents])
  constructor(props) {
    super(props);
    this.state = {
      s_isAddingNewItemTF: false,
      s_selectingShortcutPresetTF: false,
      s_selectedShortcutPresetsCaptureManagerPresetID: undefined, //if turned on in Admin (System Setup - Shortcut Presets subtab), this is used to select and inject a preset of Users to be Capture Managers with a preset percentage split
    };
  }

  onclick_add_item = () => {
    this.setState({s_isAddingNewItemTF: true});
  }

  onclick_cancel_add_item = () => {
    this.setState({s_isAddingNewItemTF: false});
  }

  onclick_save_add_selected_new_items = (i_newItemIDsComma) => {
    const newItemIDsArray = JSFUNC.convert_comma_list_to_int_array(i_newItemIDsComma);
    const numNewItems = newItemIDsArray.length;
    const previously0SelectedItemsTF = (!JSFUNC.text_or_number_is_filled_out_tf(this.props.p_valueRaw)); //prior to inserting these new items, were there any selected items in the list
    const newItemPercentsArray = ((previously0SelectedItemsTF) ? (JSFUNC.array_of_equal_ints_add_up_to_100(numNewItems)) : (JSFUNC.array_fill(numNewItems, 0))); //if these are the first items being added, split their new percents equally to add up to 100, otherwise the new items are set to 0 when appending to an existing list
    const updatedIdPercentColonCommaList = JSFUNC.insert_values_into_colon_comma_list(this.props.p_valueRaw, newItemIDsArray, newItemPercentsArray);
    this.setState({s_isAddingNewItemTF:false});
    this.call_onchange_sharedpercent(updatedIdPercentColonCommaList);
  }

  onchange_item_percent = (i_itemID, i_newPercent0to100) => {
    const updatedIdPercentColonCommaList = JSFUNC.update_or_insert_value_into_colon_comma_list(this.props.p_valueRaw, i_itemID, i_newPercent0to100);
    this.call_onchange_sharedpercent(updatedIdPercentColonCommaList);

    //reset any selected shortcut preset when editing the sharedpercent items
    this.setState({s_selectedShortcutPresetsCaptureManagerPresetID:undefined});
  }

  onremove_item = (i_itemID) => {
    var updatedIdPercentColonCommaList = JSFUNC.remove_item_from_colon_comma_list_by_id(this.props.p_valueRaw, i_itemID);

    //if there is only 1 item remaining, force its percentage to 100%
    const updatedIdPercentArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(updatedIdPercentColonCommaList, "itemID", "percent0to100");
    if(updatedIdPercentArrayOfObjs.length === 1) {
      updatedIdPercentColonCommaList = JSFUNC.update_or_insert_value_into_colon_comma_list(updatedIdPercentColonCommaList, updatedIdPercentArrayOfObjs[0].itemID, 100);
    }

    this.call_onchange_sharedpercent(updatedIdPercentColonCommaList);

    //reset any selected shortcut preset when editing the sharedpercent items
    this.setState({s_selectedShortcutPresetsCaptureManagerPresetID:undefined});
  }

  onclick_open_shortcut_preset = () => {
    this.setState({s_selectingShortcutPresetTF:true});
  }

  onclick_close_shortcut_preset = () => {
    this.setState({s_selectingShortcutPresetTF:false});
  }

  onchange_shortcut_presets_capture_manager_preset = (i_selectedShortcutPresetCaptureManagerPresetID) => {
    var updatedCaptureManagersIDsColonPercentComma = ""; //reset field if preset select is cleared
    if(JSFUNC.select_int_is_filled_out_tf(i_selectedShortcutPresetCaptureManagerPresetID)) { //if a preset is selected, inject the preset colon comma value as the current Capture Manager value
      const selectedShortcutPresetCaptureManagerPresetMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_shortcut_presets_capture_managers", i_selectedShortcutPresetCaptureManagerPresetID);
      updatedCaptureManagersIDsColonPercentComma = selectedShortcutPresetCaptureManagerPresetMap.get("user_ids_colon_percents_comma");
    }

    this.setState({s_selectedShortcutPresetsCaptureManagerPresetID:i_selectedShortcutPresetCaptureManagerPresetID});
    this.call_onchange_sharedpercent(updatedCaptureManagersIDsColonPercentComma);
  }

  call_onchange_sharedpercent = (i_updatedIdPercentColonCommaList) => {
    if(this.props.f_onChange) {
      this.props.f_onChange(i_updatedIdPercentColonCommaList);
    }
  }

  render() {
    const s_isAddingNewItemTF = this.state.s_isAddingNewItemTF;
    const s_selectingShortcutPresetTF = this.state.s_selectingShortcutPresetTF;
    const s_selectedShortcutPresetsCaptureManagerPresetID = this.state.s_selectedShortcutPresetsCaptureManagerPresetID;

    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_valueRaw = this.props.p_valueRaw;
    const p_valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;
    const p_tabIndex = this.props.p_tabIndex;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF = this.props.DatabaseMobx.c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF;
    const c_selectShortcutPresetsCaptureManagerPresetFieldTypeObj = this.props.DatabaseMobx.c_selectShortcutPresetsCaptureManagerPresetFieldTypeObj;

    const selectWithSearchDataObj = p_fieldTypeObj.selectWithSearchDataObj;

    //tab index
    const startTabIndex = ((JSFUNC.is_number(p_tabIndex) && (p_tabIndex > 0)) ? (p_tabIndex) : (0));

    //the p_fieldTypeObj used to create the multiselect when adding a new item has the same selectWithSearchDataObj as this sharedpercent, but needs its p_fieldTypeObj flags changed from a sharedpercent to a multiselect
    const itemName = selectWithSearchDataObj.itemName;
    const valueArray = selectWithSearchDataObj.valueArray;
    const valuesAreStringsTF = false;
    const displayArray = selectWithSearchDataObj.displayArray;

    //copy all optional fields from the original swsDataObj into the new swsOptionsObj, set multiselect, search, and clearselection to be true for this new select
    const allSelectWithSearchDataObjOptionalFieldNamesArray = this.props.DatabaseMobx.all_selectWithSearchDataObj_optional_field_names_array();
    var swsOptionsObj = {};
    for(let swsDataObjOptionalFieldName of allSelectWithSearchDataObjOptionalFieldNamesArray) {
      swsOptionsObj[swsDataObjOptionalFieldName] = selectWithSearchDataObj[swsDataObjOptionalFieldName];
    }
    swsOptionsObj.isMultiSelectTF = true;
    swsOptionsObj.hasSearchTF = true;
    swsOptionsObj.hasClearSelectionTF = true;

    const addNewItemMultiSelectSelectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array(itemName, valueArray, valuesAreStringsTF, displayArray, swsOptionsObj);
    const addNewItemMultiSelectFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", addNewItemMultiSelectSelectWithSearchDataObj);

    //convert given p_valueRaw colonCommaList input to an arrayOfObjs, sorted by percent desc
    var selectedItemsArrayOfObjs = JSFUNC.convert_colon_comma_list_to_ints_arrayOfObjs(p_valueRaw, "itemID", "percent0to100"); //each selectedItemObj contains fields "itemID", "percent0to100", and "valueMask"
    const numSelectedItems = selectedItemsArrayOfObjs.length;
    const zeroItemsSelectedTF = (numSelectedItems === 0);

    var itemIDsToNotIncludeOrAlreadyAddedArray = []; //get the selected itemIDs as an array to provide as the selectmulti valuesToNotIncludeArray when adding a new item
    if(JSFUNC.is_array(p_valuesToNotIncludeArray)) { //if an array of values to not include in the select is provided, initialize with those IDs to be hidden, then append items already added to the list below
      itemIDsToNotIncludeOrAlreadyAddedArray = p_valuesToNotIncludeArray;
    }

    var percentTotal = 0;
    var numFilledOutItemsAdded = 0; //numSelectedItems taking the length of selectedItemsArrayOfObjs also counts added items that are not valid (ids that do not exist), which causes the 'all items have been added' to show up at the wrong time, get an accurate count here
    if(zeroItemsSelectedTF) { //if there are 0 items added, the percent total is considered 100% "unassigned" and is ok to save
      percentTotal = 100;
    }
    else {
      const itemsAlreadyAddedArray = JSFUNC.get_column_vector_from_arrayOfObjs(selectedItemsArrayOfObjs, "itemID");
      itemIDsToNotIncludeOrAlreadyAddedArray = JSFUNC.concat_arrays_or_values_into_new_array(itemIDsToNotIncludeOrAlreadyAddedArray, itemsAlreadyAddedArray);

      //loop through each item and add the valueMask to the arrayOfObjs while totaling the percents
      for(let selectedItemObj of selectedItemsArrayOfObjs) {
        var valueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(selectedItemObj.itemID, addNewItemMultiSelectFieldTypeObj);
        selectedItemObj.valueMask = ((valueMaskSortIfoObj.isFilledOutTF) ? (valueMaskSortIfoObj.valueMaskPlainText) : (valueMaskSortIfoObj.valueMask)); //use plaintext if it's filled out to avoid using the capture link
        percentTotal += selectedItemObj.percent0to100;
        if(valueMaskSortIfoObj.isFilledOutTF) {
          numFilledOutItemsAdded++;
        }
      }
    }
    const percentTotalIsNot100TF = (percentTotal !== 100);
    const allItemsAddedTF = (numFilledOutItemsAdded >= valueArray.length); //true if every single user/division in the system has been added to this once, leaving none to pick from, do not allow the Add Item button to be pushed if this is the case

    //item list
    var itemsListComponent = null;
    if(zeroItemsSelectedTF) {
      itemsListComponent = (
        <div className="microTopMargin microBottomMargin textCenter">
          <font className="fontItalic fontTextLight">
            {selectWithSearchDataObj.unselectedDisplay}
          </font>
        </div>
      );
    }
    else {
      itemsListComponent = (
        <SharedPercentItemsList
          p_selectedItemsArrayOfObjs={selectedItemsArrayOfObjs}
          p_allPercentsErrorTF={percentTotalIsNot100TF}
          f_onChangeItemPercent={this.onchange_item_percent}
          p_startTabIndex={startTabIndex}
          f_onRemoveItem={this.onremove_item}
        />
      );
    }

    //error text
    var errorTextComponent = null;
    if(percentTotalIsNot100TF) {
      errorTextComponent = (
        <ErrorText
          p_class="smallTopMargin smallBottomMargin textCenter"
          p_text={"All percents must total 100 (currently " + percentTotal + ")"}
        />
      );
    }

    //add item button
    var addItemButtonComponent = null;
    if(!s_isAddingNewItemTF) {
      if(!allItemsAddedTF) { //still some items available to add, draw the add button ready to push
        addItemButtonComponent = (
          <div className="smallFullPad displayFlexColumnHcVc">
            <CEButton
              p_type="add"
              p_text={"Add " + itemName}
              p_tabIndex={startTabIndex + numSelectedItems + 1} //tabIndex of the add button put after the last percent box
              f_onClick={this.onclick_add_item}
            />
          </div>
        );
      }
      else { //every item in the select has been added and there are no more to choose from
        addItemButtonComponent = (
          <div className="smallFullPad displayFlexColumnHcVc">
            <font className="fontItalic fontTextLighter">
              {((zeroItemsSelectedTF) ? ("It is not possible to add an item as there are no options in this list") : ("All possible items have been added"))}
            </font>
          </div>
        );
      }
    }
    else {
      addItemButtonComponent = (
        <div className="smallFullPad bgLightGrayGradient" style={{border:"solid 1px #ddd"}}>
          <div className="displayFlexRow smallTopMargin">
            <CaptureExecInputInlineOrFloatingWithSaveCancel
              p_fieldDisplayName={undefined}
              p_fieldTypeObj={addNewItemMultiSelectFieldTypeObj}
              p_valueRaw=""
              p_valuesToNotIncludeArray={itemIDsToNotIncludeOrAlreadyAddedArray}
              p_contactsZoomedCompanyID={undefined}
              p_selectInitOptionsBoxOpenTF={true}
              p_fieldValueVerticallyAlignedTF={true}
              p_editInFloatingBoxTF={false}
              p_floatingBoxTitle={undefined}
              p_initialValueIsBlankValueTF={false}
              f_onSaveChanged={this.onclick_save_add_selected_new_items}
              f_onCancel={this.onclick_cancel_add_item}
            />
          </div>
        </div>
      );
    }

    var injectShortcutPresetComponent = null;
    if(p_fieldTypeObj.sharedPercentCaptureManagersTF) { //if this sharedpercent is specifically Capture Managers selecting CE Users in a sharedpercent
      if(c_companyShortcutPresetsCaptureManagersOnCreateNewCaptureTF) { //if this customer has the Admin setting in Shortcut Presets turned on for injecting preset Capture Manager setups
        injectShortcutPresetComponent = (
          <div className="displayFlexColumnHcVc smallTopMargin">
            <div className="borderT1ddd" style={{width:"80%", maxWidth:"40em"}} />
            {(s_selectingShortcutPresetTF) ? (
              <>
                <div className="medTopMargin border1bbb bgLighterGray bigBottomPad lrMedPad" style={{width:"100%", maxWidth:"50em"}}>
                  <div className="microBottomMargin displayFlexRow">
                    <div className="flex11a medTopPad">
                      <font className="font09 fontItalic fontTextLight">
                        {"Shortcut Presets available to quickly fill in " + c_productStylingObj.productName + " Users"}
                      </font>
                    </div>
                    <div className="flex00a displayFlexColumnHcVc tbMicroMargin borderL1ddd textCenter" style={{paddingLeft:"0.8em"}}>
                      <LibraryReact.InteractiveDiv
                        p_class="flex00a hoverFontUnderline cursorPointer"
                        f_onClick={this.onclick_close_shortcut_preset}>
                        <font className="font09 fontItalic fontTextLighter">
                          {"close"}
                        </font>
                      </LibraryReact.InteractiveDiv>
                    </div>
                  </div>
                  <GenericInputOrSelectFromInputType
                    p_fieldTypeObj={c_selectShortcutPresetsCaptureManagerPresetFieldTypeObj}
                    p_valueRaw={s_selectedShortcutPresetsCaptureManagerPresetID}
                    f_onChangeOrOnSelect={this.onchange_shortcut_presets_capture_manager_preset}
                  />
                </div>
              </>
            ) : (
              <LibraryReact.InteractiveDiv
                p_class="smallTopMargin lrMedPad textCenter hoverFontUnderline cursorPointer"
                p_title="Click this to choose Users from a list of Shortcut Presets set up by your Admin"
                f_onClick={this.onclick_open_shortcut_preset}>
                <font className="font09 fontItalic fontTextLight">
                  {"Shortcut Presets available to quickly fill in " + c_productStylingObj.productName + " Users"}
                </font>
              </LibraryReact.InteractiveDiv>
            )}
          </div>
        );
      }
    }

    return(
      <>
        {itemsListComponent}
        {errorTextComponent}
        {addItemButtonComponent}
        {injectShortcutPresetComponent}
      </>
    );
  }
}));

function SharedPercentItemsList(props) { //props: p_selectedItemsArrayOfObjs, p_allPercentsErrorTF, p_startTabIndex, f_onChangeItemPercent(i_itemID, i_newValue), f_onKeyDownEnterPercent(), f_onRemoveItem(i_itemID)
  return(
    props.p_selectedItemsArrayOfObjs.map((selectedItemObj, itemIndex) =>
      <SharedPercentItem
        key={JSFUNC.rc_unique_row_key(selectedItemObj.itemID, itemIndex)}
        p_selectedItemObj={selectedItemObj}
        p_onlyOneItemAddedTF={(props.p_selectedItemsArrayOfObjs.length === 1)}
        p_tabIndex={props.p_startTabIndex + 1 + itemIndex}
        p_percentErrorTF={props.p_allPercentsErrorTF}
        f_onChangeItemPercent={props.f_onChangeItemPercent}
        f_onKeyDownEnterPercent={props.f_onKeyDownEnterPercent}
        f_onRemoveItem={props.f_onRemoveItem}
      />
    )
  );
}

class SharedPercentItem extends Component { //props: p_selectedItemObj, p_onlyOneItemAddedTF, p_tabIndex, p_percentErrorTF, f_onChangeItemPercent, f_onKeyDownEnterPercent, f_onRemoveItem
  onchange_percent = (i_newPercent0to100) => {
    if(this.props.f_onChangeItemPercent) {
      this.props.f_onChangeItemPercent(this.props.p_selectedItemObj.itemID, i_newPercent0to100);
    }
  }

  onclick_remove_x = () => {
    if(this.props.f_onRemoveItem) {
      this.props.f_onRemoveItem(this.props.p_selectedItemObj.itemID);
    }
  }

  render() {
    return(
      <div className="displayFlexRowVc smallBottomMargin">
        <div className="flex11a lrMargin breakWord">
          {this.props.p_selectedItemObj.valueMask}
        </div>
        {(this.props.p_onlyOneItemAddedTF) ? (
          <div className="flex00a lrMargin fontItalic fontTextLight" style={{flexBasis:"4.5em"}}>
            {this.props.p_selectedItemObj.percent0to100 + "%"}
          </div>
        ) : (
          <div className="flex00a" style={{flexBasis:"5.5em"}}>
            <LibraryReact.Percent
              p_value={this.props.p_selectedItemObj.percent0to100}
              p_min={0}
              p_max={100}
              p_decimalsTF={false}
              p_blankValue={0} //percent_int_0to100 and percent_decimal_0to100 force a number to be typed, cannot be blank
              p_class={undefined}
              p_styleObj={{width:"100%"}}
              p_tabIndex={this.props.p_tabIndex}
              p_title={undefined}
              p_errorTF={this.props.p_percentErrorTF}
              f_onChange={this.onchange_percent}
              f_onKeyDownEnter={this.props.f_onKeyDownEnterPercent}
            />
          </div>
        )}
        <RemoveItemButton f_onClick={this.onclick_remove_x} />
      </div>
    );
  }
}



export const InputCaptureFavorite = inject("UserMobx")(observer(
class InputCaptureFavorite extends Component { //props: p_favoriteTF, p_standardSizeTrueCSTCellSizeFalse, f_onClick
  onclick_favorite_icon = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      const updatedCaptureFavoriteTF = ((this.props.p_favoriteTF) ? (false) : (true));
      this.props.f_onClick(updatedCaptureFavoriteTF);
    }
  }

  render() {
    const p_favoriteTF = this.props.p_favoriteTF; //input value raw
    const p_standardSizeTrueCSTCellSizeFalse = JSFUNC.prop_value(this.props.p_standardSizeTrueCSTCellSizeFalse, true);

    var sizeEm = 1.9;
    if(!p_standardSizeTrueCSTCellSizeFalse) {
      sizeEm = this.props.UserMobx.c_userCaptureTableRowHeightOptionsObj.cellCaptureFavoritesDirectEditSizeEm;
    }

    var svgFavoriteIconComponent = null;
    if(p_standardSizeTrueCSTCellSizeFalse) {
      if(p_favoriteTF) {
        svgFavoriteIconComponent = this.props.UserMobx.c_svgFavoriteIconStdSizeFav;
      }
      else {
        svgFavoriteIconComponent = this.props.UserMobx.c_svgFavoriteIconStdSizeNotFav;
      }
    }
    else {
      if(p_favoriteTF) {
        svgFavoriteIconComponent = this.props.UserMobx.c_svgFavoriteIconCSTSizeFav;
      }
      else {
        svgFavoriteIconComponent = this.props.UserMobx.c_svgFavoriteIconCSTSizeNotFav;
      }
    }

    return(
      <div
        className="flex00a displayFlexColumnHcVc cursorPointer"
        style={{height:sizeEm + "em", width:sizeEm + "em"}}
        title={((p_favoriteTF) ? ("Marked as Favorite") : ("Not marked as Favorite")) + "\n[Click to " + ((p_favoriteTF) ? ("unmark") : ("mark")) + " Capture as a favorite]"}
        onClick={this.onclick_favorite_icon}>
        {svgFavoriteIconComponent}
      </div>
    );
  }
}));




const InputCapturePriorityLevel = inject("DatabaseMobx")(observer(
class InputCapturePriorityLevel extends Component { //props: p_valueRaw, f_onSelect
  render() {
    const p_valueRaw = this.props.p_valueRaw;

    const c_capturePriorityLevelsEditConstantsObj = this.props.DatabaseMobx.c_capturePriorityLevelsEditConstantsObj;
    const c_capturePriorityLevelsArrayOfObjs = this.props.DatabaseMobx.c_capturePriorityLevelsArrayOfObjs;

    const numCapturePriorityLevels = c_capturePriorityLevelsEditConstantsObj.numCapturePriorityLevels;
    const zeroPriorityLevelsTF = c_capturePriorityLevelsEditConstantsObj.zeroPriorityLevelsTF;
    const onePriorityLevelTF = c_capturePriorityLevelsEditConstantsObj.onePriorityLevelTF;
    const containerWidthEm = c_capturePriorityLevelsEditConstantsObj.containerWidthEm;
    const containerHeightEm = c_capturePriorityLevelsEditConstantsObj.containerHeightEm;
    const containerHalfWidthEm = c_capturePriorityLevelsEditConstantsObj.containerHalfWidthEm;
    const containerHalfHeightEm = c_capturePriorityLevelsEditConstantsObj.containerHalfHeightEm;
    const centerCircleDiameterEm = c_capturePriorityLevelsEditConstantsObj.centerCircleDiameterEm;
    const centerCircleRadiusEm = c_capturePriorityLevelsEditConstantsObj.centerCircleRadiusEm;
    const centerCircleBorderColor = c_capturePriorityLevelsEditConstantsObj.centerCircleBorderColor;
    const centerCircleUnselectedBgColor = c_capturePriorityLevelsEditConstantsObj.centerCircleUnselectedBgColor;
    const centerCircleTopEm = c_capturePriorityLevelsEditConstantsObj.centerCircleTopEm;
    const centerCircleLeftEm = c_capturePriorityLevelsEditConstantsObj.centerCircleLeftEm;
    const orbitDiameterEm = c_capturePriorityLevelsEditConstantsObj.orbitDiameterEm;
    const orbitRadiusEm = c_capturePriorityLevelsEditConstantsObj.orbitRadiusEm;
    const orbitCenterRadiusFromCenterEm = c_capturePriorityLevelsEditConstantsObj.orbitCenterRadiusFromCenterEm;
    const orbitBorderColor = c_capturePriorityLevelsEditConstantsObj.orbitBorderColor;
    const orbitUnselectedBgColor = c_capturePriorityLevelsEditConstantsObj.orbitUnselectedBgColor;

    if(zeroPriorityLevelsTF) {
      return(null);
    }

    const onePriorityLevelAndIsCurrentlySelectedTF = (onePriorityLevelTF && (p_valueRaw === c_capturePriorityLevelsArrayOfObjs[0]["id"]));

    var selectionHoverText = "No Priority selected";
    var centerCircleInsideTextOrUndefined = undefined;
    for(let capturePriorityLevelObj of c_capturePriorityLevelsArrayOfObjs) {
      if(p_valueRaw === capturePriorityLevelObj.id) {
        selectionHoverText = "Marked as '" + capturePriorityLevelObj.name + "'";
        centerCircleInsideTextOrUndefined = capturePriorityLevelObj.sort;
        break;
      }
    }

    return(
      <div
        className="flex00a positionRelative"
        style={{width:containerHeightEm + "em", height:containerHeightEm + "em"}}
        title={selectionHoverText}>
        <InputCapturePriorityLevelSingleOrbitCircle
          p_valueRaw={p_valueRaw}
          p_capturePriorityLevelID={c_capturePriorityLevelsArrayOfObjs[0]["id"]}
          p_top={centerCircleTopEm}
          p_left={centerCircleLeftEm}
          p_diameterEm={centerCircleDiameterEm}
          p_borderColor={centerCircleBorderColor}
          p_bgColor={((onePriorityLevelAndIsCurrentlySelectedTF) ? (c_capturePriorityLevelsArrayOfObjs[0]["color"]) : (centerCircleUnselectedBgColor))}
          p_insideText={centerCircleInsideTextOrUndefined}
          p_title={((onePriorityLevelAndIsCurrentlySelectedTF) ? (c_capturePriorityLevelsArrayOfObjs[0]["name"]) : (undefined))}
          f_onClick={((onePriorityLevelTF) ? (this.props.f_onSelect) : (undefined))}
        />
        {c_capturePriorityLevelsArrayOfObjs.map((m_capturePriorityLevelObj, m_index) =>
          <InputCapturePriorityLevelSingleOrbitCircle
            p_valueRaw={p_valueRaw}
            p_capturePriorityLevelID={m_capturePriorityLevelObj.id}
            p_top={m_capturePriorityLevelObj.orbitTopEm}
            p_left={m_capturePriorityLevelObj.orbitLeftEm}
            p_diameterEm={orbitDiameterEm}
            p_borderColor={orbitBorderColor}
            p_bgColor={((p_valueRaw === m_capturePriorityLevelObj.id) ? (m_capturePriorityLevelObj.color) : (orbitUnselectedBgColor))}
            p_insideText={undefined}
            p_title={((p_valueRaw === m_capturePriorityLevelObj.id) ? ("[Click to unmark this with no Priority]") : ("[Click to mark as '" + m_capturePriorityLevelObj.name + "']"))}
            f_onClick={this.props.f_onSelect}
          />
        )}
      </div>
    );
  }
}));


class InputCapturePriorityLevelSingleOrbitCircle extends Component { //props: p_valueRaw, p_capturePriorityLevelID, p_top, p_left, p_diameterEm, p_borderColor, p_bgColor, p_insideText, p_title, f_onClick
  onclick_capture_priority_level_orbit = () => {
    const p_valueRaw = this.props.p_valueRaw;
    const p_capturePriorityLevelID = this.props.p_capturePriorityLevelID;

    if(JSFUNC.is_function(this.props.f_onClick)) {
      const newCapturePriorityLevelID = ((p_valueRaw === p_capturePriorityLevelID) ? (0) : (p_capturePriorityLevelID));
      this.props.f_onClick(newCapturePriorityLevelID);
    }
  }

  render() {
    const p_valueRaw = this.props.p_valueRaw;
    const p_capturePriorityLevelID = this.props.p_capturePriorityLevelID;
    const p_top = this.props.p_top;
    const p_left = this.props.p_left;
    const p_diameterEm = this.props.p_diameterEm;
    const p_borderColor = this.props.p_borderColor;
    const p_bgColor = this.props.p_bgColor;
    const p_insideText = this.props.p_insideText;
    const p_title = this.props.p_title;

    const hasClickFunctionTF = JSFUNC.is_function(this.props.f_onClick);

    return(
      <div
        className={"positionAbsolute displayFlexColumnHcVc textCenter " + ((hasClickFunctionTF) ? ("cursorPointer") : (""))}
        style={{transition:"0.35s", top:p_top + "em", left:p_left + "em", width:p_diameterEm + "em", height:p_diameterEm + "em", border:"solid 1px #" + p_borderColor, borderRadius:p_diameterEm + "em", background:"#" + p_bgColor}}
        title={p_title}
        onClick={this.onclick_capture_priority_level_orbit}>
        {(p_insideText !== undefined) &&
          <font className="fontItalic fontTextLight">
            {p_insideText}
          </font>
        }
      </div>
    );
  }
}







const InputStylingStringOptions = inject("DatabaseMobx")(observer(
class InputStylingStringOptions extends Component { //props: p_valueRaw, p_fieldTypeObj, f_onChange
  constructor(props) {
    super(props);

    const p_valueRaw = this.props.p_valueRaw;

    //determine if a preset is selected, start on the "loadPreset" tab if one is selected
    const presetStylingObj = this.props.DatabaseMobx.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(p_valueRaw);
    var initialSelectedStylingTabDbName = "customStyle";
    if(presetStylingObj.presetTF) {
      initialSelectedStylingTabDbName = "loadPreset";
    }

    this.state = {
      s_selectedStylingTabDbName: initialSelectedStylingTabDbName //"customStyle", "loadPreset"
    };
  }

  onselect_styling_tab = (i_selectedTabDbName) => {
    this.setState({s_selectedStylingTabDbName:i_selectedTabDbName});
  }

  onclick_styling_tab = (i_selectedTabDbName) => {
    this.onselect_styling_tab(i_selectedTabDbName);

    if(i_selectedTabDbName === "customStyle") {
      const p_valueRaw = this.props.p_valueRaw;

      //get all the properties of the selected preset style, convert it to a custom styling string without the preset
      var presetStylingObj = this.props.DatabaseMobx.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(p_valueRaw);
      presetStylingObj.presetTF = false; //remove that this is a preset, convert it to a custom style with the same properties as the preset

      this.call_onchange_with_styling_string_comma_list_or_styling_obj(presetStylingObj);
    }
    else if(i_selectedTabDbName === "loadPreset") {
      this.call_onchange_with_styling_string_comma_list_or_styling_obj("");
    }
  }

  call_onchange_with_styling_string_comma_list_or_styling_obj = (i_stylingStringCommaListOrStylingObj) => {
    if(JSFUNC.is_function(this.props.f_onChange)) {
      var stylingStringCommaList = "";
      if(JSFUNC.is_string(i_stylingStringCommaListOrStylingObj)) { //input is styling string comma list
        stylingStringCommaList = i_stylingStringCommaListOrStylingObj;
      }
      else { //input is presetStylingObj or stylingObj
        if(i_stylingStringCommaListOrStylingObj.presetTF && !JSFUNC.select_int_is_filled_out_tf(i_stylingStringCommaListOrStylingObj.presetID)) { //if the preset select is cleared, don't return "preset:-1", but rather return "" to have no preset selected
          stylingStringCommaList = "";
        }
        else { //convert stylingObj to a styling string comma list
          stylingStringCommaList = JSFUNC.convert_preset_styling_obj_to_preset_string_or_styling_string_comma_list(i_stylingStringCommaListOrStylingObj);
        }
      }
      this.props.f_onChange(stylingStringCommaList);
    }
  }

  render() {
    const s_selectedStylingTabDbName = this.state.s_selectedStylingTabDbName;

    const p_valueRaw = this.props.p_valueRaw;
    const p_fieldTypeObj = this.props.p_fieldTypeObj;

    const presetStylingObj = this.props.DatabaseMobx.get_preset_styling_obj_from_styling_string_comma_list_or_preset_colon_id_string(p_valueRaw);
    const valueMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(p_valueRaw, p_fieldTypeObj);

    var stylingTabContentComponent = null;
    if(s_selectedStylingTabDbName === "loadPreset") {
      stylingTabContentComponent = (
        <StylingStringPresetLoadEditDelete
          p_presetStylingObj={presetStylingObj}
          p_valueMask={valueMask}
          p_fieldTypeObj={p_fieldTypeObj}
          f_onChangeFromStylingStringCommaListOrStylingObj={this.call_onchange_with_styling_string_comma_list_or_styling_obj}
        />
      );
    }
    else {
      stylingTabContentComponent = (
        <StylingStringCustomStyleWithSave
          p_valueRaw={p_valueRaw}
          p_presetStylingObj={presetStylingObj}
          p_valueMask={valueMask}
          p_fieldTypeObj={p_fieldTypeObj}
          f_onSelectStylingTab={this.onselect_styling_tab}
          f_onChangeFromStylingStringCommaListOrStylingObj={this.call_onchange_with_styling_string_comma_list_or_styling_obj}
        />
      );
    }

    return(
      <div className="">
        <div className="smallFullPad">
          <TabsList
            p_tabDbNamesArray={["customStyle", "loadPreset"]}
            p_tabDisplayNamesArray={["Create Custom Style", "Select Style Preset"]}
            p_selectedTabDbName={s_selectedStylingTabDbName}
            p_tabHeight="2.5em"
            p_textMaxHeight="2.4em"
            p_tabWidth="7.8em"
            p_selectedBgClass="bgPurpleGradient"
            p_selectedFontClass="fontWhite"
            p_unselectedBgClass="bgLighterGrayGradient"
            p_unselectedFontClass="fontTextLight"
            p_rowFlexWrapTF={true}
            p_borderRadiusClass={undefined}
            f_onSelect={this.onclick_styling_tab}
          />
        </div>
        {stylingTabContentComponent}
        <div className="medTopMargin" />
      </div>
    );
  }
}));


const StylingStringCustomStyleWithSave = inject("DatabaseMobx")(observer(
class StylingStringCustomStyleWithSave extends Component { //props: p_valueRaw, p_presetStylingObj, p_valueMask, p_fieldTypeObj, f_onSelectStylingTab, f_onChangeFromStylingStringCommaListOrStylingObj
  constructor(props) {
    super(props);
    this.state = {
      s_saveAsPresetState: "saveAsButton", //"saveAsButton", "enterName", "working", "errorSaving"
      s_newPresetName: "",
      s_newPresetNameBlankErrorTF: false
    };
  }

  onclick_save_new_preset = () => {
    this.setState({
      s_saveAsPresetState: "enterName",
      s_newPresetNameBlankErrorTF: false
    });
  }

  onchange_new_preset_name = (i_newValueString) => {
    this.setState({
      s_newPresetName: i_newValueString,
      s_newPresetNameBlankErrorTF: false
    });
  }

  onclick_confirm_save_new_preset = () => {
    const s_newPresetName = this.state.s_newPresetName;

    const p_valueRaw = this.props.p_valueRaw;

    const c_tbl_a_styling_string_presets = this.props.DatabaseMobx.c_tbl_a_styling_string_presets;

    if(!JSFUNC.string_is_filled_out_tf(s_newPresetName)) { //if the new name is blank, set the error to true to highlight the input box red with a message
      this.setState({s_newPresetNameBlankErrorTF:true});
    }
    else {
      this.setState({
        s_saveAsPresetState: "working",
        s_newPresetNameBlankErrorTF: false
      });

      var existingPresetIDOrUndefined = undefined; //filled out if overwriting an existing preset with the same name

      const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - InputStylingStringOptions", "onclick_confirm_save_new_preset", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      //determine if the entered preset name already exists, if so overwrite, otherwise insert
      const newPresetNameLowercase = s_newPresetName.toLowerCase();
      const existingPresetMapOrUndefined = JSFUNC.get_first_map_matching_field_value(c_tbl_a_styling_string_presets, "nameLowercase", newPresetNameLowercase);
      if(existingPresetMapOrUndefined === undefined) { //unique name, insert a new record
        C_CallPhpTblUID.add_insert("tbl_a_styling_string_presets", ["name", "styling_string_comma_list"], [s_newPresetName, p_valueRaw], ["s", "s"]);
      }
      else { //lowercase name matches an existing preset name, overwrite it
        existingPresetIDOrUndefined = existingPresetMapOrUndefined.get("id");
        C_CallPhpTblUID.add_update("tbl_a_styling_string_presets", existingPresetIDOrUndefined, "styling_string_comma_list", p_valueRaw, "s");
      }

      const functionOnSuccess = (i_parseResponse) => {
        var insertedOrUpdatedPresetID = -1;
        if(existingPresetIDOrUndefined !== undefined) { //updated existing preset with same name
          if(i_parseResponse.outputObj.u0 === "1") {
            insertedOrUpdatedPresetID = existingPresetIDOrUndefined;
          }
        }
        else { //inserted new preset
          if(i_parseResponse.outputObj.i0 > 0) {
            insertedOrUpdatedPresetID = i_parseResponse.outputObj.i0;
          }
        }
        
        if(insertedOrUpdatedPresetID > 0) {
          const stylingStringCommaList = "presetID:" + insertedOrUpdatedPresetID;
          this.props.f_onChangeFromStylingStringCommaListOrStylingObj(stylingStringCommaList);
          this.props.f_onSelectStylingTab("loadPreset");
        }
        else {
          this.setState({s_saveAsPresetState:"errorSaving"});
        }
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      const functionOnError = () => {
        this.setState({s_saveAsPresetState:"errorSaving"});
      }
      C_CallPhpTblUID.add_function("onError", functionOnError);

      C_CallPhpTblUID.execute();
    }
  }

  onclick_cancel_save_new_preset = () => {
    this.setState({
      s_saveAsPresetState: "saveAsButton",
      s_newPresetNameBlankErrorTF: false
    });
  }

  render() {
    const s_saveAsPresetState = this.state.s_saveAsPresetState;
    const s_newPresetName = this.state.s_newPresetName;
    const s_newPresetNameBlankErrorTF = this.state.s_newPresetNameBlankErrorTF;

    const p_valueRaw = this.props.p_valueRaw;
    const p_presetStylingObj = this.props.p_presetStylingObj;
    const p_valueMask = this.props.p_valueMask;
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
        
    var saveAsPresetComponent = null;
    if(s_saveAsPresetState === "enterName") { //"enterName"
      saveAsPresetComponent = (
        <>
          <div className="microBottomMargin">
            <font className="fontItalic fontTextLight">
              {"Save Style with Preset Name:"}
            </font>
          </div>
          <div className="">
            <LibraryReact.Text
              p_value={s_newPresetName}
              p_styleObj={{width:"100%"}}
              p_tabIndex={1}
              p_errorTF={s_newPresetNameBlankErrorTF}
              p_focusTF={true}
              f_onChange={this.onchange_new_preset_name}
            />
          </div>
          {(s_newPresetNameBlankErrorTF) &&
            <ErrorText p_class="microTopMargin" p_text="New Preset Name cannot be blank" />
          }
          <div className="displayFlexRow smallTopMargin">
            <div className="flex00a">
              <CEButton
                p_type="blue"
                p_text="Save Preset"
                p_tabIndex={2}
                f_onClick={this.onclick_confirm_save_new_preset}
              />
            </div>
            <div className="flex00a" style={{flexBasis:"1em"}} />
            <div className="flex00a">
              <CEButton
                p_type="gray"
                p_text="Cancel"
                p_tabIndex={3}
                f_onClick={this.onclick_cancel_save_new_preset}
              />
            </div>
          </div>
        </>
      );
    }
    else if(s_saveAsPresetState === "working") { //"working"
      saveAsPresetComponent = (
        <div className="lrMedMargin border1ddd bgLightestGray tbPad lrMedPad textCenter">
          <div className="microBottomMargin">
            <font className="fontTextLight">
              {"Saving Preset..."}
            </font>
          </div>
          <LoadingAnimation />
        </div>
      );
    }
    else { //"saveAsButton" or "errorSaving"
      saveAsPresetComponent = (
        <>
          <StylingEditLinkButton
            p_text="save as preset"
            p_widthEm={8}
            f_onClick={this.onclick_save_new_preset}
          />
          {(s_saveAsPresetState === "errorSaving") &&
            <div className="smallTopMargin lrMedMargin border borderColorDarkRed bgLightRed tbPad lrMedPad textCenter">
              <font className="fontRed">
                {"There was an error creating the new Style Preset"}
              </font>
            </div>
          }
        </>
      );
    }

    return(
      <>
        <StylingStringControls
          p_stylingObj={p_presetStylingObj}
          p_valueMask={p_valueMask}
          p_fieldTypeObj={p_fieldTypeObj}
          f_onChange={this.props.f_onChangeFromStylingStringCommaListOrStylingObj}
        />
        <div className="smallTopMargin" />
        {saveAsPresetComponent}
      </>
    );
  }
}));


const StylingStringPresetLoadEditDelete = inject("DatabaseMobx")(observer(
class StylingStringPresetLoadEditDelete extends Component { //props: p_presetStylingObj, p_valueMask, p_fieldTypeObj, f_onChangeFromStylingStringCommaListOrStylingObj
  constructor(props) {
    super(props);
    this.state = {
      s_presetEditDeleteState: "editDeleteButtons", //"editDeleteButtons", "editPreset", "deletePreset"
      s_editPresetLocalStylingStringCommaList: ""
    };
  }

  onchange_load_selected_styling_string_preset = (i_selectedStylingPresetID) => {
    var loadedPresetColonIDString = "";
    if(JSFUNC.select_int_is_filled_out_tf(i_selectedStylingPresetID)) {
      loadedPresetColonIDString = "presetID:" + i_selectedStylingPresetID;
    }
    this.props.f_onChangeFromStylingStringCommaListOrStylingObj(loadedPresetColonIDString);
  }

  onclick_edit_preset = () => {
    const p_presetStylingObj = this.props.p_presetStylingObj;

    const editPresetLocalStylingStringCommaList = JSFUNC.convert_styling_obj_to_styling_string_comma_list(p_presetStylingObj);

    this.setState({
      s_presetEditDeleteState: "editPreset",
      s_editPresetLocalStylingStringCommaList: editPresetLocalStylingStringCommaList
    });
  }

  onchange_local_edit_preset_style = (i_stylingStringCommaList) => {
    this.setState({s_editPresetLocalStylingStringCommaList:i_stylingStringCommaList});
  }

  onclick_editing_preset_save_changes = () => {
    const s_editPresetLocalStylingStringCommaList = this.state.s_editPresetLocalStylingStringCommaList;

    const p_presetStylingObj = this.props.p_presetStylingObj;
    
    this.setState({s_presetEditDeleteState:"editDeleteButtons"});

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - StylingStringPresetLoadEditDelete", "onclick_editing_preset_save_changes", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update("tbl_a_styling_string_presets", p_presetStylingObj.presetID, "styling_string_comma_list", s_editPresetLocalStylingStringCommaList, "s");
    C_CallPhpTblUID.execute();
  }
  
  onclick_editing_preset_cancel = () => {
    this.setState({s_presetEditDeleteState:"editDeleteButtons"});
  }

  onclick_delete_preset = () => {
    this.setState({s_presetEditDeleteState:"deletePreset"});
  }

  onclick_confirm_delete_preset = () => {
    const p_presetStylingObj = this.props.p_presetStylingObj;

    //exit confirm delete floating box, return to edit/delete buttons
    this.setState({s_presetEditDeleteState:"editDeleteButtons"});

    //change selected preset styling string comma list from "presetID:X" to ""
    this.props.f_onChangeFromStylingStringCommaListOrStylingObj("");

    const jsDescription = JSFUNC.js_description_from_action("CEGeneralReact - StylingStringPresetLoadEditDelete", "onclick_confirm_delete_preset", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_delete("tbl_a_styling_string_presets", p_presetStylingObj.presetID);
    C_CallPhpTblUID.execute();
  }

  onclick_cancel_delete_preset = () => {
    this.setState({s_presetEditDeleteState:"editDeleteButtons"});
  }

  render() {
    const s_presetEditDeleteState = this.state.s_presetEditDeleteState;
    const s_editPresetLocalStylingStringCommaList = this.state.s_editPresetLocalStylingStringCommaList;

    const p_presetStylingObj = this.props.p_presetStylingObj;
    const p_valueMask = this.props.p_valueMask;
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    
    const c_selectStylingStringPresetFieldTypeObj = this.props.DatabaseMobx.c_selectStylingStringPresetFieldTypeObj;

    var stateEditDeleteButtonsTF = false;
    var stateEditPresetTF = false;
    var stateDeletePresetTF = false;
    if(s_presetEditDeleteState === "editPreset") { stateEditPresetTF = true; }
    else if(s_presetEditDeleteState === "deletePreset") { stateDeletePresetTF = true; }
    else { stateEditDeleteButtonsTF = true; }

    return(
      <>
        {(stateEditDeleteButtonsTF || stateDeletePresetTF) &&
          <div className="border1bbb tbMicroPad lrPad">
            <div className="medBottomMargin lrMedMargin textCenter">
              {p_valueMask}
            </div>
            <div className="microBottomMargin">
              <font className="fontItalic">
                {"Load existing Styling Preset"}
              </font>
            </div>
            <div className="medBottomMargin">
              <GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_selectStylingStringPresetFieldTypeObj}
                p_valueRaw={p_presetStylingObj.presetID}
                f_onChangeOrOnSelect={this.onchange_load_selected_styling_string_preset}
              />
            </div>
          </div>
        }
        {(stateEditPresetTF) &&
          <>
            <div className="smallTopMargin microBottomMargin">
              <font className="fontBold fontTextLight">
                {"Editing Preset '" + p_presetStylingObj.presetName + "':"}
              </font>
            </div>
            <StylingStringControls
              p_stylingObj={JSFUNC.convert_styling_string_comma_list_to_styling_obj(s_editPresetLocalStylingStringCommaList)}
              p_fieldTypeObj={p_fieldTypeObj}
              f_onChange={this.onchange_local_edit_preset_style}
            />
            <div className="smallTopMargin displayFlexRowVc flexWrap">
              <div className="flex00a">
                <CEButton
                  p_type="blue"
                  p_text="Save Changes"
                  f_onClick={this.onclick_editing_preset_save_changes}
                />
              </div>
              <div className="flex00a" style={{flexBasis:"0.8em"}} />
              <div className="flex00a">
                <CEButton
                  p_type="gray"
                  p_text="Cancel"
                  f_onClick={this.onclick_editing_preset_cancel}
                />
              </div>
            </div>
          </>
        }
        {((stateEditDeleteButtonsTF && p_presetStylingObj.presetExistsTF) || stateDeletePresetTF) &&
          <div className="smallTopMargin displayFlexRowVc flexWrap">
            <div className="flex00a">
              <StylingEditLinkButton
                p_text="edit preset"
                p_widthEm={6.5}
                f_onClick={this.onclick_edit_preset}
              />
            </div>
            <div className="flex00a">
              <StylingEditLinkButton
                p_text="delete preset"
                p_widthEm={8}
                f_onClick={this.onclick_delete_preset}
              />
            </div>
          </div>
        }
        {(stateDeletePresetTF) &&
          <ConfirmBox
            p_type="confirmDelete"
            p_button1Name="Delete Style Preset"
            f_onClickConfirm={this.onclick_confirm_delete_preset}
            f_onClickCancel={this.onclick_cancel_delete_preset}>
            {"Are you sure you want to delete Style Preset '" + p_presetStylingObj.presetName + "'?"}
          </ConfirmBox>
        }
      </>
    );
  }
}));


const StylingStringControls = inject("DatabaseMobx")(observer(
class StylingStringControls extends Component { //props: p_stylingObj, p_valueMask, p_fieldTypeObj, f_onChange(stylingStringCommaList)
  onswitch_bold = () => {
    const p_stylingObj = this.props.p_stylingObj;
    var updatedStylingObj = JSFUNC.copy_obj(p_stylingObj);
    updatedStylingObj.boldTF = (!updatedStylingObj.boldTF);
    this.call_onselect_from_updated_preset_styling_obj(updatedStylingObj);
  }

  onswitch_italic = () => {
    const p_stylingObj = this.props.p_stylingObj;
    var updatedStylingObj = JSFUNC.copy_obj(p_stylingObj);
    updatedStylingObj.italicTF = (!updatedStylingObj.italicTF);
    this.call_onselect_from_updated_preset_styling_obj(updatedStylingObj);
  }

  onswitch_font_color = () => {
    const p_stylingObj = this.props.p_stylingObj;
    var updatedStylingObj = JSFUNC.copy_obj(p_stylingObj);
    updatedStylingObj.fontColorTF = (!updatedStylingObj.fontColorTF);
    this.call_onselect_from_updated_preset_styling_obj(updatedStylingObj);
  }

  onselect_font_color = (i_selectedColor) => {
    const p_stylingObj = this.props.p_stylingObj;
    var updatedStylingObj = JSFUNC.copy_obj(p_stylingObj);
    updatedStylingObj.fontSelectedColor = i_selectedColor;
    this.call_onselect_from_updated_preset_styling_obj(updatedStylingObj);
  }

  onswitch_highlight = () => {
    const p_stylingObj = this.props.p_stylingObj;
    var updatedStylingObj = JSFUNC.copy_obj(p_stylingObj);
    updatedStylingObj.highlightTF = (!updatedStylingObj.highlightTF);
    this.call_onselect_from_updated_preset_styling_obj(updatedStylingObj);
  }

  onselect_highlight_color = (i_selectedColor) => {
    const p_stylingObj = this.props.p_stylingObj;
    var updatedStylingObj = JSFUNC.copy_obj(p_stylingObj);
    updatedStylingObj.highlightSelectedColor = i_selectedColor;
    this.call_onselect_from_updated_preset_styling_obj(updatedStylingObj);
  }

  call_onselect_from_updated_preset_styling_obj = (i_updatedPresetStylingObj) => {
    if(JSFUNC.is_function(this.props.f_onChange)) {
      const updatedStylingStringCommaList = JSFUNC.convert_styling_obj_to_styling_string_comma_list(i_updatedPresetStylingObj);
      this.props.f_onChange(updatedStylingStringCommaList);
    }
  }

  render() {
    const p_stylingObj = this.props.p_stylingObj;
    const p_valueMask = this.props.p_valueMask;
    const p_fieldTypeObj = this.props.p_fieldTypeObj;

    const optionsContainerHeightEm = 2.1;
    const optionsContainerClass = "displayFlexRowVc flexWrap";
    const optionLabelWidthEm = 5.5;
    const optionLabelFontClass = "fontDarkBlue";
    const optionSwitchContainerWidthEm = 4;
    const optionSwitchSizeEm = 3.4;
    const optionSwitchOnColor = "ddaa00";
    const optionColorContainerWidthEm = 6;
    const optionColorHeightEm = 2;

    //if p_valueMask is not provided as a precomputed mask for p_stylingObj, compute it here
    var valueMask = p_valueMask;
    if(p_valueMask === undefined) {
      const stylingStringCommaList = JSFUNC.convert_styling_obj_to_styling_string_comma_list(p_stylingObj);
      valueMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(stylingStringCommaList, p_fieldTypeObj);
    }

    return(
      <div className="border1bbb tbMicroPad lrPad">
        <div className="lrMedMargin textCenter">
          {valueMask}
        </div>
        <div className="medTopMargin" />
        <div className={optionsContainerClass} style={{height:optionsContainerHeightEm + "em"}}>
          <div className="flex00a" style={{flexBasis:optionLabelWidthEm + "em"}}>
            <font className={optionLabelFontClass}>
              {"Bold"}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:optionSwitchContainerWidthEm + "em"}} title={((p_fieldTypeObj.stylingStringBoldOptionTF) ? (undefined) : ("'Bold' styling is always applied by default for this text"))}>
            {(p_fieldTypeObj.stylingStringBoldOptionTF) ? (
              <SwitchWithTextAndConfirmBox
                p_isOnTF={p_stylingObj.boldTF}
                p_sizeEm={optionSwitchSizeEm}
                p_onColor={optionSwitchOnColor}
                p_tabIndex={1}
                p_title="Switch to turn on/off 'Bold' text styling"
                f_onSwitch={this.onswitch_bold}
              />
            ) : (
              <font className="fontItalic fontTextLighter">
                {"N/A"}
              </font>
            )}
          </div>
          <div className="flex11a" />
        </div>
        <div className={optionsContainerClass} style={{height:optionsContainerHeightEm + "em"}}>
          <div className="flex00a" style={{flexBasis:optionLabelWidthEm + "em"}}>
            <font className={optionLabelFontClass}>
              {"Italic"}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:optionSwitchContainerWidthEm + "em"}}>
            <SwitchWithTextAndConfirmBox
              p_isOnTF={p_stylingObj.italicTF}
              p_sizeEm={optionSwitchSizeEm}
              p_onColor={optionSwitchOnColor}
              p_tabIndex={2}
              p_title="Switch to turn on/off 'Italic' text styling"
              f_onSwitch={this.onswitch_italic}
            />
          </div>
          <div className="flex11a" />
        </div>
        <div className={optionsContainerClass} style={{height:optionsContainerHeightEm + "em"}}>
          <div className="flex00a" style={{flexBasis:optionLabelWidthEm + "em"}}>
            <font className={optionLabelFontClass}>
              {"Font Color"}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:optionSwitchContainerWidthEm + "em"}}>
            <SwitchWithTextAndConfirmBox
              p_isOnTF={p_stylingObj.fontColorTF}
              p_sizeEm={optionSwitchSizeEm}
              p_onColor={optionSwitchOnColor}
              p_tabIndex={3}
              p_title="Switch to turn on/off 'Font Color' text styling"
              f_onSwitch={this.onswitch_font_color}
            />
          </div>
          {(p_stylingObj.fontColorTF) &&
            <div className="flex00a" style={{flexBasis:optionColorContainerWidthEm + "em"}}>
              <LibraryReact.Color
                p_value={p_stylingObj.fontSelectedColor}
                p_class={undefined}
                p_styleObj={{width:"100%", height:optionColorHeightEm + "em"}}
                p_tabIndex={4}
                p_title="Select a custom Font Color"
                f_onChange={this.onselect_font_color}
              />
            </div>
          }
          <div className="flex11a" />
        </div>
        <div className={optionsContainerClass} style={{height:optionsContainerHeightEm + "em"}}>
          <div className="flex00a" style={{flexBasis:optionLabelWidthEm + "em"}}>
            <font className={optionLabelFontClass}>
              {"Highlight"}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:optionSwitchContainerWidthEm + "em"}}>
            <SwitchWithTextAndConfirmBox
              p_isOnTF={p_stylingObj.highlightTF}
              p_sizeEm={optionSwitchSizeEm}
              p_onColor={optionSwitchOnColor}
              p_tabIndex={5}
              p_title="Switch to turn on/off 'Highlight' text styling"
              f_onSwitch={this.onswitch_highlight}
            />
          </div>
          {(p_stylingObj.highlightTF) &&
            <div className="flex00a" style={{flexBasis:optionColorContainerWidthEm + "em"}}>
              <LibraryReact.Color
                p_value={p_stylingObj.highlightSelectedColor}
                p_class={undefined}
                p_styleObj={{width:"100%", height:optionColorHeightEm + "em"}}
                p_tabIndex={6}
                p_title="Select a custom Highlight Color behind the text"
                f_onChange={this.onselect_highlight_color}
              />
            </div>
          }
          <div className="flex11a" />
        </div>
      </div>
    );
  }
}));

function StylingEditLinkButton(props) { //props: p_text, p_widthEm, f_onClick
  const p_text = props.p_text;
  const p_widthEm = props.p_widthEm;

  return(
    <LibraryReact.InteractiveDiv
      p_class="displayFlexColumnHcVc textCenter hoverFontUnderline cursorPointer"
      p_styleObj={{width:p_widthEm + "em"}}
      f_onClick={props.f_onClick}>
      <font className="fontItalic fontTextLight">
        {p_text}
      </font>
    </LibraryReact.InteractiveDiv>
  );
}

export function StyledTextDiv(props) { //props: p_stylingObj, p_nowrapTF, children
  const p_stylingObj = props.p_stylingObj;
  const p_nowrapTF = props.p_nowrapTF;

  var fontClass = undefined;
  if(p_stylingObj.boldTF || p_stylingObj.italicTF) {
    fontClass = ((p_stylingObj.boldTF) ? ("fontBold") : ("")) + " " + ((p_stylingObj.italicTF) ? ("fontItalic") : (""));
  }

  var fontColor = undefined;
  if(p_stylingObj.fontColorTF) {
    fontColor = "#" + p_stylingObj.fontSelectedColor;
  }

  //if a background highlight needs to be drawn, use the p_nowrapTF input to reapply nowrap inside of the highlight div
  if(p_stylingObj.highlightTF) {
    return(
      <div style={{background:"#" + p_stylingObj.highlightSelectedColor, padding:"0 0.15em"}}>
        {(p_nowrapTF) ? (
          <LibraryReact.Nowrap p_fontClass={fontClass} p_styleObj={{color:fontColor}}>
            {props.children}
          </LibraryReact.Nowrap>
        ) : (
          <font className={fontClass} style={{color:fontColor}}>
            {props.children}
          </font>
        )}
      </div>
    );
  }

  //without highlight, no need for surrounding background color div, nowrap properties applied correctly from parent div
  return(
    <font className={fontClass} style={{color:fontColor}}>
      {props.children}
    </font>
  );
}











//=====================================================================================================================================================================================
export const FloatingBoxWithSaveCancel = inject("CaptureExecMobx", "RightPanelMobx")(observer(
class FloatingBoxWithSaveCancel extends Component { //props: p_trblFlag, p_title, p_cancelConfirmationMessage, p_outsideBgOpacity, p_bgColorClass, p_shadowColor, p_zIndex, p_floatingLayerNumber, f_onClickSave, f_onClickCancel, children
  //p_cancelConfirmationMessage - if not undefined, pushing cancel will first bring up a confirmation box that must be affirmed to complete the cancel operation (avoids data loss when trying to click save, but missing)
  constructor(props) {
    super(props);
    this.state = {
      s_isConfirmingCancelTF: false
    };
  }

  onclick_background = (event) => {
    event.stopPropagation(); //prevents clicks to any component underneath this black screen that have onclick functions
  }

  onclick_cancel_to_confirm = () => {
    this.setState({s_isConfirmingCancelTF:true});
  }

  onconfirm_cancellation = () => {
    this.setState({s_isConfirmingCancelTF:false});
    if(this.props.f_onClickCancel) {
      this.props.f_onClickCancel();
    }
  }

  oncancel_cancellation = () => {
    this.setState({s_isConfirmingCancelTF:false});
  }

  render() {
    const isConfirmingCancelTF = this.state.s_isConfirmingCancelTF;

    const trblFlag = JSFUNC.prop_value(this.props.p_trblFlag, "textareaEdit");
    const outsideBgOpacity = JSFUNC.prop_value(this.props.p_outsideBgOpacity, 0.1); //number 1 (black) to 0 (transparent)
    const cancelConfirmationMessage = this.props.p_cancelConfirmationMessage;
    const bgColorClass = JSFUNC.prop_value(this.props.p_bgColorClass, "bgWhite");
    const shadowColor = JSFUNC.prop_value(this.props.p_shadowColor, "#666");
    const zIndex = this.props.p_zIndex; //this will fix the zIndex to the given value, rather than using the floating layer number formula of stacking them (used for search results captures floating box)
    const floatingLayerNumber = JSFUNC.prop_value(this.props.p_floatingLayerNumber, 1); //multiple floating boxes stacked on top of each other need to have different zIndex values so that they reference the entire webpage size when computing their position

    const mqf = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const currentSystemRightEdgeDueToHelpPanel = this.props.RightPanelMobx.c_currentSystemRightEdgeDueToHelpPanel;

    var zIndexOutsideBg = 10;
    if(zIndex !== undefined) {
      zIndexOutsideBg = zIndex;
    }
    else {
      zIndexOutsideBg = (floatingLayerNumber * 3) + 10;
    }

    var styleObj = {zIndex:(zIndexOutsideBg + 2), boxShadow:"0em 0em 1.25em 0.5em " + shadowColor, border:"solid 1px #888"};

    if(trblFlag === "confirmBox") {
      var lrWidth = "5%";
      if(mqf === 1) { lrWidth = "5%"; }
      else if(mqf === 2) { lrWidth = "22%"; }
      else if(mqf === 3) { lrWidth = "28%"; }
      else if(mqf === 4) { lrWidth = "33%"; }
      else { lrWidth = "35%"; } //mqf 5/6 setting

      styleObj.top = "20%";
      styleObj.left = lrWidth;
      styleObj.right = lrWidth;
    }
    else if(trblFlag === "smallVertical") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "10%"; lrWidth = "13%"; }
      else if(mqf === 3) { tbWidth = "12%"; lrWidth = "24%"; }
      else if(mqf === 4) { tbWidth = "14%"; lrWidth = "31%"; }
      else { tbWidth = "15%"; lrWidth = "35%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "medium") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "5%"; }
      else if(mqf === 3) { tbWidth = "3%"; lrWidth = "18%"; }
      else if(mqf === 4) { tbWidth = "5%"; lrWidth = "22%"; }
      else { tbWidth = "7%"; lrWidth = "25%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "large") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 4) { tbWidth = "2%"; lrWidth = "2%"; }
      else { tbWidth = "3%"; lrWidth = "5%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "copyCapture") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 3) { tbWidth = "3%"; lrWidth = "15%"; }
      else if(mqf === 4) { tbWidth = "4%"; lrWidth = "25%"; }
      else { tbWidth = "5%"; lrWidth = "30%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "contactsSystemSelect") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "1%"; lrWidth = "3%"; }
      else if(mqf === 4) { tbWidth = "2%"; lrWidth = "5%"; }
      else { tbWidth = "3%"; lrWidth = "10%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "tasks") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "5%"; }
      else if(mqf === 4) { tbWidth = "3%"; lrWidth = "10%"; }
      else { tbWidth = "5%"; lrWidth = "15%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "teammateContracts") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "4%"; }
      else if(mqf === 4) { tbWidth = "2em"; lrWidth = "6%"; }
      else { tbWidth = "2em"; lrWidth = "8%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "teammateSurvey") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 4) { tbWidth = "2em"; lrWidth = "15%"; }
      else { tbWidth = "2em"; lrWidth = "20%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "themes") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "10%"; lrWidth = "4%"; }
      else if(mqf === 4) { tbWidth = "15%"; lrWidth = "6%"; }
      else { tbWidth = "15%"; lrWidth = "15%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "risks") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "10%"; lrWidth = "4%"; }
      else if(mqf === 4) { tbWidth = "15%"; lrWidth = "6%"; }
      else { tbWidth = "20%"; lrWidth = "8%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "budgetFundingRequest") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 4) { tbWidth = "3%"; lrWidth = "20%"; }
      else { tbWidth = "5%"; lrWidth = "25%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "conversations") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "5%"; }
      else if(mqf === 4) { tbWidth = "3%"; lrWidth = "10%"; }
      else { tbWidth = "5%"; lrWidth = "15%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "divexecGraphsEdit") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 4) { tbWidth = "2em"; lrWidth = "15%"; }
      else { tbWidth = "3%"; lrWidth = "20%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "addEditDeleteTableEditItem") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "15%"; }
      else if(mqf === 3) { tbWidth = "5%"; lrWidth = "25%"; }
      else if(mqf === 4) { tbWidth = "10%"; lrWidth = "30%"; }
      else { tbWidth = "10%"; lrWidth = "35%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "addEditDeleteTableNewCaptureField") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 4) { tbWidth = "2em"; lrWidth = "2em"; }
      else { tbWidth = "2em"; lrWidth = "2em"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "addEditDeleteTableEditQuestion") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 3) { tbWidth = "3%"; lrWidth = "15%"; }
      else if(mqf === 4) { tbWidth = "4%"; lrWidth = "20%"; }
      else { tbWidth = "5%"; lrWidth = "23%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "divexecPerformanceEditQuota") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "2em"; }
      else if(mqf === 3) { tbWidth = "3%"; lrWidth = "5%"; }
      else if(mqf === 4) { tbWidth = "4%"; lrWidth = "10%"; }
      else { tbWidth = "5%"; lrWidth = "15%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "divexecSelectPublicGraph") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 3) { tbWidth = "5%"; lrWidth = "15%"; }
      else if(mqf === 4) { tbWidth = "10%"; lrWidth = "25%"; }
      else { tbWidth = "15%"; lrWidth = "30%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "broadcastReader") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 3) { tbWidth = "5%"; lrWidth = "15%"; }
      else if(mqf === 4) { tbWidth = "10%"; lrWidth = "25%"; }
      else { tbWidth = "15%"; lrWidth = "30%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "uploadFileErrors") {
      var tbWidth = "1em"; var lrWidth = "1em";
      if(mqf === 1) { tbWidth = "1em"; lrWidth = "1em"; }
      else if(mqf === 2) { tbWidth = "2em"; lrWidth = "10%"; }
      else if(mqf === 3) { tbWidth = "5%"; lrWidth = "15%"; }
      else if(mqf === 4) { tbWidth = "10%"; lrWidth = "25%"; }
      else { tbWidth = "15%"; lrWidth = "30%"; }

      styleObj.top = tbWidth;
      styleObj.right = lrWidth;
      styleObj.bottom = tbWidth;
      styleObj.left = lrWidth;
    }
    else if(trblFlag === "adminCaptureTemplatesSelectXmlType") {
      if(mqf === 1) {
        styleObj.top = "20%";
        styleObj.right = "1em";
        styleObj.bottom = "30%";
        styleObj.left = "1em";
      }
      else {
        var lrWidth = "1em";
        if(mqf === 2) { lrWidth = "10%"; }
        else if(mqf === 3) { lrWidth = "20%"; }
        else if(mqf === 4) { lrWidth = "30%"; }
        else { lrWidth = "35%"; }

        styleObj.top = "20%";
        styleObj.right = lrWidth;
        styleObj.left = lrWidth;
        styleObj.height = "17em";
      }
    }
    else { //definitions where top/right/bottom/left distances are all equal
      var trblWidth = "1em";
      if(trblFlag === "debrief") {
        if(mqf === 1) { trblWidth = "1em"; }
        else if(mqf === 2) { trblWidth = "2em"; }
        else { trblWidth = "10%"; }
      }
      else if(trblFlag === "textareaEdit") { //basic default centered box
        if(mqf === 1) { trblWidth = "1em"; }
        else if(mqf === 2) { trblWidth = "2em"; }
        else if(mqf === 3) { trblWidth = "10%"; }
        else if(mqf === 4) { trblWidth = "15%"; }
        else { trblWidth = "20%"; }
      }
      else { //can override the top/right/bottom/left width that the floating box is away from the screen edges using this prop
        trblWidth = trblFlag;
      }

      //assign trblWidth equally to each edge
      styleObj.top = trblWidth;
      styleObj.right = trblWidth;
      styleObj.bottom = trblWidth;
      styleObj.left = trblWidth;
    }

    return(
      <>
        <LibraryReact.InteractiveDiv
          p_class="positionFixed t0 b0 l0"
          p_styleObj={{zIndex:zIndexOutsideBg, right:currentSystemRightEdgeDueToHelpPanel}}
          f_onKeyDownEsc={((cancelConfirmationMessage === undefined) ? (this.props.f_onClickCancel) : (this.onclick_cancel_to_confirm))}>
          <div
            className="positionFixed t0 b0 l0"
            style={{zIndex:(zIndexOutsideBg + 1), right:currentSystemRightEdgeDueToHelpPanel, backgroundColor:"#000", opacity:outsideBgOpacity}}
            onClick={this.onclick_background}
          />
          <div
            className={"positionAbsolute displayFlexColumn " + bgColorClass}
            style={styleObj}
            onClick={this.onclick_background}>
            <div className="flex00a displayFlexRow lrMedPad borderB1ddd">
              <div className="flex11a tbMedPad rMargin">
                <font className="font12 fontTextLight">
                  {this.props.p_title}
                </font>
              </div>
              {(this.props.f_onClickSave) &&
                <EditSaveCancelIcon
                  p_iconType="save"
                  p_tabIndex={998}
                  f_onClick={this.props.f_onClickSave}
                />
              }
              {(this.props.f_onClickCancel) &&
                <EditSaveCancelIcon
                  p_iconType="cancel"
                  p_tabIndex={999}
                  f_onClick={((cancelConfirmationMessage === undefined) ? (this.props.f_onClickCancel) : (this.onclick_cancel_to_confirm))}
                />
              }
            </div>
            {this.props.children}
          </div>
        </LibraryReact.InteractiveDiv>
        {(isConfirmingCancelTF) &&
          <ConfirmBox
            p_type="confirm"
            p_title="Confirm Cancellation"
            p_button1Name="Confirm Cancellation"
            p_button2Name="Go Back"
            f_onClickConfirm={this.onconfirm_cancellation}
            f_onClickCancel={this.oncancel_cancellation}>
            {cancelConfirmationMessage}
          </ConfirmBox>
        }
      </>
    );
  }
}));


export class EditInlineWithSaveCancel extends Component { //props: p_fieldValueVerticallyAlignedTF, p_cancelConfirmationMessage, f_onClickSave, f_onClickCancel, children
  constructor(props) {
    super(props);
    this.state = {
      s_isConfirmingCancelTF: false
    };
  }

  onclick_cancel_to_confirm = () => {
    this.setState({s_isConfirmingCancelTF:true});
  }

  onconfirm_cancellation = () => {
    this.setState({s_isConfirmingCancelTF:false});
    if(this.props.f_onClickCancel) {
      this.props.f_onClickCancel();
    }
  }

  oncancel_cancellation = () => {
    this.setState({s_isConfirmingCancelTF:false});
  }

  render() {
    const isConfirmingCancelTF = this.state.s_isConfirmingCancelTF;

    const fieldValueVerticallyAlignedTF = JSFUNC.prop_value(this.props.p_fieldValueVerticallyAlignedTF, false);
    const cancelConfirmationMessage = this.props.p_cancelConfirmationMessage;
    const containerFlexRowClass = ((fieldValueVerticallyAlignedTF) ? ("displayFlexRowVc") : ("displayFlexRow"));
    return(
      <>
        <LibraryReact.InteractiveDiv
          p_class={"flex11a " + containerFlexRowClass}
          f_onKeyDownEsc={((cancelConfirmationMessage === undefined) ? (this.props.f_onClickCancel) : (this.onclick_cancel_to_confirm))}>
          {(this.props.f_onClickSave !== undefined) &&
            <EditSaveCancelIcon
              p_iconType="save"
              p_tabIndex={998}
              f_onClick={this.props.f_onClickSave}
            />
          }
          {(this.props.f_onClickCancel !== undefined) &&
            <EditSaveCancelIcon
              p_iconType="cancel"
              p_tabIndex={999}
              f_onClick={((cancelConfirmationMessage === undefined) ? (this.props.f_onClickCancel) : (this.onclick_cancel_to_confirm))}
            />
          }
          <div className="flex11a positionRelative lrMargin">
            {this.props.children}
          </div>
        </LibraryReact.InteractiveDiv>
        {(isConfirmingCancelTF) &&
          <ConfirmBox
            p_type="confirm"
            p_title="Confirm Cancellation"
            p_button1Name="Confirm Cancellation"
            p_button2Name="Go Back"
            f_onClickConfirm={this.onconfirm_cancellation}
            f_onClickCancel={this.oncancel_cancellation}>
            {cancelConfirmationMessage}
          </ConfirmBox>
        }
      </>
    );
  }
}







//--------------------------------------------------------------------------------------------------------------------------------
//SVG Graphics
//--------------------------------------------------------------------------------------------------------------------------------
export const SvgEditPencil = inject("UserMobx")(observer(
class SvgEditPencil extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    const color = JSFUNC.prop_value(this.props.p_color, "999999");

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;

    const sizePx = Math.round(sizeEm * fontSizePx);
    const hashColor = "#" + color;

    const xyCoords0to100ArrayOfArrays1 = [[10,86],[11,74],[24,86]];
    const polygonPointsString1 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays1, sizePx);

    const xyCoords0to100ArrayOfArrays2 = [[13,65],[58,20],[75,38],[33,83]];
    const polygonPointsString2 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays2, sizePx);

    const xyCoords0to100ArrayOfArrays3 = [[63,16],[68,10],[75,9],[84,13],[88,19],[88,27],[81,34]];
    const polygonPointsString3 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays3, sizePx);

    return(
      <svg height={sizePx + "px"} width={sizePx + "px"}>
        <polygon points={polygonPointsString1} style={{fill:hashColor}} />
        <polygon points={polygonPointsString2} style={{fill:hashColor}} />
        <polygon points={polygonPointsString3} style={{fill:hashColor}} />
      </svg>
    );
  }
}));

export const SvgDraggableDots = inject("UserMobx")(observer(
class SvgDraggableDots extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    var color = JSFUNC.prop_value(this.props.p_color, "336666");
    color = "#" + color;

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const relativeSize = sizeEm * (fontSizePx / 12);

    const svgWidth = (relativeSize * 0.9) + "em";
    const svgHeight = (relativeSize * 2) + "em";

    const y1 = ((0.25 / 2) * 100) + "%";
    const y2 = ((0.75 / 2) * 100) + "%";
    const y3 = ((1.25 / 2) * 100) + "%";
    const y4 = ((1.75 / 2) * 100) + "%";
    const x1 = "25%";
    const x2 = "75%";
    const r = (relativeSize * 0.15) + "em";
    return(
      <svg width={svgWidth} height={svgHeight}>
        <circle cx={x1} cy={y1} r={r} fill={color} />
        <circle cx={x1} cy={y2} r={r} fill={color} />
        <circle cx={x1} cy={y3} r={r} fill={color} />
        <circle cx={x1} cy={y4} r={r} fill={color} />
        <circle cx={x2} cy={y1} r={r} fill={color} />
        <circle cx={x2} cy={y2} r={r} fill={color} />
        <circle cx={x2} cy={y3} r={r} fill={color} />
        <circle cx={x2} cy={y4} r={r} fill={color} />
      </svg>
    );
  }
}));



export const SvgUpDownCarat = inject("UserMobx")(observer(
class SvgUpDownCarat extends Component { //p_isDownTF, p_sizeEm
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;
    const color = "#666";

    const widthPx = Math.round(sizePx);
    const heightPx = Math.round(0.45 * widthPx);

    const xStartPx = Math.round(0.1 * widthPx);
    const xHalfPx = Math.round(0.5 * widthPx);
    const xFinishPx = Math.round(0.9 * widthPx);

    var yStartPx = 0;
    var yFinishPx = 0;
    if(this.props.p_isDownTF) {
      yStartPx = Math.round(0.1 * heightPx);
      yFinishPx = Math.round(0.9 * heightPx);
    }
    else {
      yStartPx = Math.round(0.9 * heightPx);
      yFinishPx = Math.round(0.1 * heightPx);
    }

    var lineWidth = Math.round(widthPx / 10);

    return(
      <svg height={heightPx} width={widthPx}>
        <line x1={xStartPx} y1={yStartPx} x2={xHalfPx} y2={yFinishPx} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:"round"}} />
        <line x1={xHalfPx} y1={yFinishPx} x2={xFinishPx} y2={yStartPx} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:"round"}} />
      </svg>
    );
  }
}));

export const SvgLeftRightCarat = inject("UserMobx")(observer(
class SvgLeftRightCarat extends Component { //p_leftFalseRightTrue, p_sizeEm
  render() {
    const leftFalseRightTrue = this.props.p_leftFalseRightTrue;
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;
    const color = "#666";

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(0.45 * heightPx);

    var xStartPx = 0;
    var xFinishPx = 0;
    if(leftFalseRightTrue) {
      xStartPx = Math.round(0.9 * widthPx);
      xFinishPx = Math.round(0.1 * widthPx);
    }
    else {
      xStartPx = Math.round(0.1 * widthPx);
      xFinishPx = Math.round(0.9 * widthPx);
    }

    var yStartPx = Math.round(0.1 * heightPx);
    var yHalfPx = Math.round(0.5 * heightPx);
    var yFinishPx = Math.round(0.9 * heightPx);

    var lineWidth = Math.round(widthPx / 10);

    return(
      <svg height={heightPx} width={widthPx}>
        <line x1={xStartPx} y1={yHalfPx} x2={xFinishPx} y2={yStartPx} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:"round"}} />
        <line x1={xStartPx} y1={yHalfPx} x2={xFinishPx} y2={yFinishPx} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:"round"}} />
      </svg>
    );
  }
}));

export const SvgFile = inject("UserMobx")(observer(
class SvgFile extends Component { //p_fileLoc, p_xmlType, p_sizeEm, p_color, p_innerColor, p_thinEdgeTF, p_thickLinesTF
  render() {
    const p_sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const p_fileLoc = this.props.p_fileLoc;
    const p_xmlType = this.props.p_xmlType;
    const p_thinEdgeTF = JSFUNC.prop_value(this.props.p_thinEdgeTF, false);
    const p_thickLinesTF = JSFUNC.prop_value(this.props.p_thickLinesTF, false);

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const sizePx = p_sizeEm * c_userFontSizePx;

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(0.75 * heightPx);

    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";

    const xStartPx = Math.round(0.1 * widthPx);
    const xTrianglePx = Math.round(0.63 * widthPx);
    const xFinishPx = Math.round(0.9 * widthPx);

    const yStartPx = Math.round(0.1 * heightPx);
    const yTrianglePx = Math.round(0.31 * heightPx);
    const yFinishPx = Math.round(0.9 * heightPx);

    var lineWidth = "3%";
    if(p_thinEdgeTF) {
      lineWidth = "1px";
    }
    else if(p_thickLinesTF) {
      lineWidth = "7%";
    }

    var contentWidth = "3%";
    if(p_thickLinesTF) {
      contentWidth = "7%";
    }

    const strokeLinecap = "round"

    var color = "bbbbbb";
    var contentColor = "bbbbbb";
    if(this.props.p_color !== undefined) {
      color = this.props.p_color;
      if(this.props.p_innerColor !== undefined) {
        contentColor = this.props.p_innerColor;
      }
      else {
        contentColor = this.props.p_color;
      }
    }

    const customColorsObj = JSFUNC.custom_colors_obj();

    var solidBgFileLabel = undefined;
    var xmlLabelTF = false;
    if(JSFUNC.is_string(p_fileLoc)) {
      const fileLocFilePartsObj = JSFUNC.file_parts_obj(p_fileLoc);
      if(fileLocFilePartsObj.fileExt === "xml") {
        xmlLabelTF = true;
        if(p_xmlType === "word") { //xml word
          color = customColorsObj.wordBlue;
        }
        else if(p_xmlType === "excel") { //xml excel
          color = customColorsObj.excelGreen;
        }
        else if(p_xmlType === "ppt") { //xml ppt
          color = customColorsObj.pptOrange;
        }
        else { //xml
          color = "777";
        }
      }
      else if(JSFUNC.in_array(fileLocFilePartsObj.fileExt, ["doc", "docx", "odt"])) {
        solidBgFileLabel = "doc";
        color = customColorsObj.wordBlue;
      }
      else if(JSFUNC.in_array(fileLocFilePartsObj.fileExt, ["xls", "xlsx", "ods"])) {
        solidBgFileLabel = "xls";
        color = customColorsObj.excelGreen;
      }
      else if(JSFUNC.in_array(fileLocFilePartsObj.fileExt, ["ppt", "pptx", "odp"])) {
        solidBgFileLabel = "ppt";
        color = customColorsObj.pptOrange;
      }
      else if(fileLocFilePartsObj.fileExt === "pdf") {
        solidBgFileLabel = "pdf";
        color = "bd2326";
      }
      else { //blank white file with gray edges and gray horiz lines

      }
      contentColor = color;
    }

    const xStart = xStartPx + "px";
    const xTriangle = xTrianglePx + "px";
    const xFinish = xFinishPx + "px";
    const yStart = yStartPx + "px"; //7px
    const yTriangle = yTrianglePx + "px"; //20px
    const yFinish = yFinishPx + "px"; //59px

    color = "#" + color;
    contentColor = "#" + contentColor;

    if(solidBgFileLabel !== undefined) {
      return(
        <svg height={svgHeight} width={svgWidth}>
          <rect x={xStart} y={yStart} width={(xTrianglePx-xStartPx) + "px"} height={(yFinishPx-yStartPx) + "px"} style={{fill:color}} />
          <rect x={xStart} y={yTriangle} width={(xFinishPx-xStartPx) + "px"} height={(yFinishPx-yTrianglePx) + "px"} style={{fill:color}} />

          <line x1={xTriangle} y1={yStart} x2={xFinish} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />

          <text x="17%" y="68%" fill="#ffffff" style={{fontSize:Math.round(0.29 * sizePx) + "px"}}>{solidBgFileLabel}</text>
        </svg>
      );
    }

    return(
      <svg height={svgHeight} width={svgWidth}>
        <line x1={xStart} y1={yStart} x2={xTriangle} y2={yStart} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xTriangle} y1={yStart} x2={xFinish} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xFinish} y1={yTriangle} x2={xFinish} y2={yFinish} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xFinish} y1={yFinish} x2={xStart} y2={yFinish} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xStart} y1={yFinish} x2={xStart} y2={yStart} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xTriangle} y1={yStart} x2={xTriangle} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xTriangle} y1={yTriangle} x2={xFinish} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />

        <line x1="15%" y1="40%" x2="75%" y2="40%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="15%" y1="50%" x2="75%" y2="50%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="15%" y1="60%" x2="75%" y2="60%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="15%" y1="70%" x2="75%" y2="70%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="15%" y1="80%" x2="75%" y2="80%" style={{stroke:contentColor, strokeWidth:contentWidth}} />

        {(xmlLabelTF) &&
          <>
            <rect x="1%" y="36%" rx="15%" ry="40%" width="98%" height="40%" style={{fill:contentColor}} />
            <text x="10%" y="68%" fill="#ffffff" style={{fontSize:Math.round(0.29 * sizePx) + "px"}}>{"XML"}</text>
          </>
        }
      </svg>
    );
  }
}));

export const SvgFolder = inject("UserMobx")(observer(
class SvgFolder extends Component { //p_widthEm, p_heightEm, p_xStart0to100, p_xEnd0to100, p_yStart0to100, p_yEnd0to100, p_lineColor, p_plusTF
  render() {
    const p_widthEm = JSFUNC.prop_value(this.props.p_widthEm, 1);
    const p_heightEm = JSFUNC.prop_value(this.props.p_heightEm, 1);
    const p_xStart0to100 = JSFUNC.prop_value(this.props.p_xStart0to100, 0);
    const p_xEnd0to100 = JSFUNC.prop_value(this.props.p_xEnd0to100, 100);
    const p_yStart0to100 = JSFUNC.prop_value(this.props.p_yStart0to100, 0);
    const p_yEnd0to100 = JSFUNC.prop_value(this.props.p_yEnd0to100, 100);
    const p_lineColor = JSFUNC.prop_value(this.props.p_lineColor, "bbbbbb");
    const p_plusTF = JSFUNC.prop_value(this.props.p_plusTF, false);

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const widthPx = Math.round(p_widthEm * c_userFontSizePx);
    const heightPx = Math.round(p_heightEm * c_userFontSizePx);

    const lineWidth = 1;
    const strokeLinecap = "round";

    //can 'window' the SVG line positions to scale and shift the picture within the frame
    // - p_xStart0to100 = 0 and p_xEnd0to100 = 100, 3 -> "3%"
    // - p_xStart0to100 = 10 and p_xEnd0to100 = 70, 3 -> ((3 / 100) * (70-10) + 10) = "11.8%"
    var xb1 = 3;
    var xb2 = 13;
    var xb3 = 15;
    var xb4 = 39;
    var xb5 = 41;
    var xb6 = 78;
    var xf2 = 25;
    var xf4 = 97;
    var yb1 = 97;
    var yb2 = 17;
    var yb3 = 3;
    var yf2 = 33;

    if((p_xStart0to100 !== 0) || (p_xEnd0to100 !== 100)) {
      xb1 = (((xb1 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xb2 = (((xb2 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xb3 = (((xb3 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xb4 = (((xb4 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xb5 = (((xb5 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xb6 = (((xb6 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xf2 = (((xf2 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
      xf4 = (((xf4 / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
    }

    if((p_yStart0to100 !== 0) || (p_yEnd0to100 !== 100)) {
      yb1 = (((yb1 / 100) * (p_yEnd0to100 - p_yStart0to100)) + p_yStart0to100);
      yb2 = (((yb2 / 100) * (p_yEnd0to100 - p_yStart0to100)) + p_yStart0to100);
      yb3 = (((yb3 / 100) * (p_yEnd0to100 - p_yStart0to100)) + p_yStart0to100);
      yf2 = (((yf2 / 100) * (p_yEnd0to100 - p_yStart0to100)) + p_yStart0to100);
    }

    xb1 = xb1 + "%";
    xb2 = xb2 + "%";
    xb3 = xb3 + "%";
    xb4 = xb4 + "%";
    xb5 = xb5 + "%";
    xb6 = xb6 + "%";
    xf2 = xf2 + "%";
    xf4 = xf4 + "%";
    yb1 = yb1 + "%";
    yb2 = yb2 + "%";
    yb3 = yb3 + "%";
    yf2 = yf2 + "%";

    return(
      <svg height={heightPx + "px"} width={widthPx + "px"}>
        <line x1={xb1} x2={xb1} y1={yb1} y2={yb2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb1} x2={xb2} y1={yb2} y2={yb2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb2} x2={xb3} y1={yb2} y2={yb3} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb3} x2={xb4} y1={yb3} y2={yb3} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb4} x2={xb5} y1={yb3} y2={yb2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb5} x2={xb6} y1={yb2} y2={yb2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb6} x2={xb6} y1={yb2} y2={yf2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />

        <line x1={xb1} x2={xf2} y1={yb1} y2={yf2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xf2} x2={xf4} y1={yf2} y2={yf2} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xf4} x2={xb6} y1={yf2} y2={yb1} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xb6} x2={xb1} y1={yb1} y2={yb1} style={{stroke:"#" + p_lineColor, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />

        {(p_plusTF) && 
        <>
          <LibraryReact.SvgHLine p_x1p={70} p_x2p={100} p_ycp={80} p_widthP={7} p_color="#77c" />
          <LibraryReact.SvgVLine p_y1p={65} p_y2p={95} p_xcp={85} p_widthP={7} p_color="#77c" />
        </>
      }
      </svg>
    );
  }
}));


export const SvgChain = inject("UserMobx")(observer(
class SvgChain extends Component { //p_widthEm, p_heightEm, p_xStart0to100, p_xEnd0to100, p_yStart0to100, p_yEnd0to100, p_lineWidth, p_lineColor, p_plusTF
  render() {
    const p_widthEm = JSFUNC.prop_value(this.props.p_widthEm, 1);
    const p_heightEm = JSFUNC.prop_value(this.props.p_heightEm, 1);
    const p_xStart0to100 = JSFUNC.prop_value(this.props.p_xStart0to100, 0);
    const p_xEnd0to100 = JSFUNC.prop_value(this.props.p_xEnd0to100, 100);
    const p_yStart0to100 = JSFUNC.prop_value(this.props.p_yStart0to100, 0);
    const p_yEnd0to100 = JSFUNC.prop_value(this.props.p_yEnd0to100, 100);
    const p_lineColor = JSFUNC.prop_value(this.props.p_lineColor, "bbbbbb");
    const p_lineWidth = JSFUNC.prop_value(this.props.p_lineWidth, "6%");
    const p_plusTF = JSFUNC.prop_value(this.props.p_plusTF, false);

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const widthPx = Math.round(p_widthEm * c_userFontSizePx);
    const heightPx = Math.round(p_heightEm * c_userFontSizePx);

    /*const v = JSFUNC.calculate_circle_points_xy_0to1_arrays_obj(90, 5, true, 37, 4);
    const x0to1Array = v.x0to1Array;
    const y0to1Array = v.y0to1Array;

    var xyCoords0to100ArrayOfArrays1 = [];
    for(let c = 0; c < x0to1Array.length; c++) {
      var x = (28 + Math.round(x0to1Array[c] * 23));
      var y = (50 + Math.round(y0to1Array[c] * 45));
      xyCoords0to100ArrayOfArrays1.push([x, y]);
    }

    var s = "[";
    for(let c of xyCoords0to100ArrayOfArrays1) {
      s += "[" + c[0] + "," + c[1] + "],";
    }alert(s)*/

    //left chain link
    var xyCoords0to100ArrayOfArrays1 = [
      [35,95],
      [26,95],[24,94],[22,93],[20,92],[18,91],[17,89],[15,87],[13,84],[12,82],[10,79],[9,76],[8,73],[7,69],[6,65],[6,62],[5,58],[5,54],[5,50],
      [5,46],[5,42],[6,38],[6,35],[7,31],[8,28],[9,24],[10,21],[12,18],[13,16],[15,13],[17,11],[18,9],[20,8],[22,7],[24,6],[26,5],
      [37,5],[39,6],[41,7],[43,8],[45,9],[46,11],[48,13],[50,16],[51,18],[53,21],[54,24],[55,28],[56,31],[57,35],[57,38],[56,42],[56,46],[56,50],[56,54],[56,58],[55,62],[55,65]
    ];

    //scale into desired x/y start/end coordinates within svg width/height frame
    if((p_xStart0to100 !== 0) || (p_xEnd0to100 !== 100) || (p_yStart0to100 !== 0) || (p_yEnd0to100 !== 100)) {
      for(let xyCoords0to100Array of xyCoords0to100ArrayOfArrays1) {
        xyCoords0to100Array[0] = (((xyCoords0to100Array[0] / 100) * (p_xEnd0to100 - p_xStart0to100)) + p_xStart0to100);
        xyCoords0to100Array[1] = (((xyCoords0to100Array[1] / 100) * (p_yEnd0to100 - p_yStart0to100)) + p_yStart0to100);
      }
    }

    const polygonPointsString1 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays1, widthPx);

    //mirror image of 2nd chain link by flipping image on x and y axis
    for(let xyCoords0to100Array of xyCoords0to100ArrayOfArrays1) {
      xyCoords0to100Array[0] = (100 - xyCoords0to100Array[0]);
      xyCoords0to100Array[1] = (100 - xyCoords0to100Array[1]);
    }
    const polygonPointsString2 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays1, widthPx);

    return(
      <svg height={heightPx + "px"} width={widthPx + "px"}>
        <polyline points={polygonPointsString1} style={{fill:"none", stroke:"#" + p_lineColor, strokeWidth:p_lineWidth}} />
        <polyline points={polygonPointsString2} style={{fill:"none", stroke:"#" + p_lineColor, strokeWidth:p_lineWidth}} />

        {(p_plusTF) && 
          <>
            <LibraryReact.SvgHLine p_x1p={70} p_x2p={100} p_ycp={80} p_widthP={7} p_color="#77c" />
            <LibraryReact.SvgVLine p_y1p={65} p_y2p={95} p_xcp={85} p_widthP={7} p_color="#77c" />
          </>
        }
      </svg>
    );
  }
}));


export const SvgPresentation = inject("UserMobx")(observer(
class SvgPresentation extends Component { //p_sizeEm
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;

    const widthPx = Math.round(sizePx);
    const heightPx = Math.round(0.70 * widthPx);

    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";

    const xStart = Math.round(0.1 * widthPx) + "px";
    const xTriangle = Math.round(0.75 * widthPx) + "px";
    const xFinish = Math.round(0.9 * widthPx) + "px";

    const yStart = Math.round(0.1 * heightPx) + "px";
    const yTriangle = Math.round(0.31 * heightPx) + "px";
    const yFinish = Math.round(0.9 * heightPx) + "px";

    const lineWidth = "1px";
    const strokeLinecap = "round"

    const color = "#888a99";

    const contentColor = "#f9f9f9";
    const contentWidth = "4%";

    return(
      <svg height={svgHeight} width={svgWidth}>
        <line x1={xStart} y1={yStart} x2={xTriangle} y2={yStart} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xTriangle} y1={yStart} x2={xFinish} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xFinish} y1={yTriangle} x2={xFinish} y2={yFinish} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xFinish} y1={yFinish} x2={xStart} y2={yFinish} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xStart} y1={yFinish} x2={xStart} y2={yStart} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xTriangle} y1={yStart} x2={xTriangle} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />
        <line x1={xTriangle} y1={yTriangle} x2={xFinish} y2={yTriangle} style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:strokeLinecap}} />

        <circle cx="35%" cy="48%" r="22%" fill="none" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="60%" y1="40%" x2="85%" y2="40%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="60%" y1="55%" x2="85%" y2="55%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
        <line x1="60%" y1="70%" x2="85%" y2="70%" style={{stroke:contentColor, strokeWidth:contentWidth}} />
      </svg>
    );
  }
}));

export function SvgPerson(props) { //p_sizePx, p_color, p_plusTF
  const sizePx = Math.round(JSFUNC.prop_value(props.p_sizePx, 24));
  var color = JSFUNC.prop_value(props.p_color, "999999");
  color = "#" + color;

  const heightPx = Math.round(sizePx);
  const widthPx = Math.round(sizePx);

  const svgHeight = heightPx + "px";
  const svgWidth = widthPx + "px";

  //shoulders
  const e1cx = Math.round(0.5 * widthPx) + "px";
  const e1cy = Math.round(1.1 * widthPx) + "px";
  const e1rx = Math.round(0.6 * widthPx) + "px";
  const e1ry = Math.round(0.5 * widthPx) + "px";

  //head
  const e2cx = Math.round(0.5 * widthPx) + "px";
  const e2cy = Math.round(0.35 * widthPx) + "px";
  const e2rx = Math.round(0.25 * widthPx) + "px";
  const e2ry = Math.round(0.3 * widthPx) + "px";

  return(
    <svg height={svgHeight} width={svgWidth}>
      <ellipse cx={e1cx} cy={e1cy} rx={e1rx} ry={e1ry} style={{fill:color}} />
      <ellipse cx={e2cx} cy={e2cy} rx={e2rx} ry={e2ry} style={{fill:color}} />

      {(props.p_plusTF) && 
        <>
          <LibraryReact.SvgHLine key="h" p_x1p={75} p_x2p={100} p_ycp={87.5} p_widthP={7} p_color="#77c" />
          <LibraryReact.SvgVLine key="v" p_y1p={75} p_y2p={100} p_xcp={87.5} p_widthP={7} p_color="#77c" />
        </>
      }
    </svg>
  );
}

export function SvgCompany(props) { //p_sizePx, p_color, p_plusTF
  const sizePx = Math.round(JSFUNC.prop_value(props.p_sizePx, 24));
  var color = JSFUNC.prop_value(props.p_color, "999999");
  color = "#" + color;

  const heightPx = Math.round(sizePx);
  const widthPx = Math.round(sizePx);

  const svgHeight = heightPx + "px";
  const svgWidth = widthPx + "px";

  return(
    <svg height={svgHeight} width={svgWidth}>
      <rect x="5%" y="10%" width="5%" height="90%" style={{fill:color}} />
      <rect x="25%" y="10%" width="5%" height="90%" style={{fill:color}} />
      <rect x="45%" y="10%" width="5%" height="90%" style={{fill:color}} />
      <rect x="5%" y="80%" width="45%" height="20%" style={{fill:color}} />
      <rect x="5%" y="56.6%" width="45%" height="5%" style={{fill:color}} />
      <rect x="5%" y="33.3%" width="45%" height="5%" style={{fill:color}} />
      <rect x="5%" y="7%" width="45%" height="8%" style={{fill:color}} />

      <rect x="50%" y="50%" width="5%" height="50%" style={{fill:color}} />
      <rect x="70%" y="50%" width="5%" height="50%" style={{fill:color}} />
      <rect x="90%" y="50%" width="5%" height="50%" style={{fill:color}} />
      <rect x="50%" y="85%" width="45%" height="15%" style={{fill:color}} />
      <rect x="50%" y="67.5%" width="45%" height="5%" style={{fill:color}} />
      <rect x="50%" y="47%" width="45%" height="8%" style={{fill:color}} />

      {(props.p_plusTF) && [
        <LibraryReact.SvgHLine key="h" p_x1p={75} p_x2p={100} p_ycp={87.5} p_widthP={7} p_color={"#77c"} />,
        <LibraryReact.SvgVLine key="v" p_y1p={75} p_y2p={100} p_xcp={87.5} p_widthP={7} p_color={"#77c"} />
      ]}
    </svg>
  );
}

export const SvgSmartFunnel = inject("UserMobx")(observer(
class SvgSmartFunnel extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const color = JSFUNC.prop_value(this.props.p_color, "999999");

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;

    const sizePx = sizeEm * fontSizePx;
    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);
    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";
    const hashColor = "#" + color;

    const circle1X = 18;
    const circle1Y = 14;
    const circle2X = 43;
    const circle2Y = 35;
    const circle3X = 64;
    const circle3Y = 10;
    const circle4X = 84;
    const circle4Y = 19;
    const circleRadius = 7;
    const circleEdgeWidth = 5;

    const line1X1 = 23;
    const line1Y1 = 18;
    const line1X2 = 38;
    const line1Y2 = 31;
    const line2X1 = 46;
    const line2Y1 = 31;
    const line2X2 = 60;
    const line2Y2 = 15;
    const line3X1 = 70;
    const line3Y1 = 12;
    const line3X2 = 79;
    const line3Y2 = 17;
    const lineWidth = 8;

    const xyCoords0to100ArrayOfArrays1 = [
      [60,24],[71,25],[77,27],[84,30],[86,32],[86,44],[56,70],[56,93],[53,95],[47,96],
      [41,95],[38,93],[38,70],[7,44],[7,31],[11,28],[18,26],[24,25],[27,29],[19,31],
      [18,33],[26,36],[35,38],[47,39],[60,38],[70,36],[76,33],[75,31],[67,29],[56,28]
    ];
    const polygonPointsString1 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays1, sizePx);

    return(
      <svg height={svgHeight} width={svgWidth}>
        <polygon points={polygonPointsString1} style={{fill:hashColor}} />

        <circle cx={circle1X + "%"} cy={circle1Y + "%"} r={circleRadius + "%"} stroke={hashColor} strokeWidth={circleEdgeWidth + "%"} fill="transparent" />
        <circle cx={circle2X + "%"} cy={circle2Y + "%"} r={circleRadius + "%"} stroke={hashColor} strokeWidth={circleEdgeWidth + "%"} fill="transparent" />
        <circle cx={circle3X + "%"} cy={circle3Y + "%"} r={circleRadius + "%"} stroke={hashColor} strokeWidth={circleEdgeWidth + "%"} fill="transparent" />
        <circle cx={circle4X + "%"} cy={circle4Y + "%"} r={circleRadius + "%"} stroke={hashColor} strokeWidth={circleEdgeWidth + "%"} fill="transparent" />

        <line x1={line1X1 + "%"} x2={line1X2 + "%"} y1={line1Y1 + "%"} y2={line1Y2 + "%"} style={{stroke:hashColor, strokeWidth:lineWidth + "%"}} />
        <line x1={line2X1 + "%"} x2={line2X2 + "%"} y1={line2Y1 + "%"} y2={line2Y2 + "%"} style={{stroke:hashColor, strokeWidth:lineWidth + "%"}} />
        <line x1={line3X1 + "%"} x2={line3X2 + "%"} y1={line3Y1 + "%"} y2={line3Y2 + "%"} style={{stroke:hashColor, strokeWidth:lineWidth + "%"}} />
      </svg>
    );
  }
}));

export const SvgSpiralNotebookWithCheck = inject("UserMobx")(observer(
class SvgSpiralNotebookWithCheck extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const color = JSFUNC.prop_value(this.props.p_color, "999999");

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;

    const sizePx = sizeEm * fontSizePx;
    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);
    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";
    const hashColor = "#" + color;

    const x1 = 20;
    const x2 = 85;
    const yB = 95;
    const yT = 5;
    const edgeWidth = "4%";

    const spiralX1 = 13;
    const sprialX2 = 27;
    const spiralYArray = [14, 32, 50, 68, 86];
    const sprialWidth = "8%";

    const textX1 = 35;
    const textX2 = 65;
    const textYArray = [20, 30, 40];
    const textWidth = "3%";

    const checkX1 = 60;
    const checkX2 = 70;
    const checkX3 = 98;
    const checkYB = 80;
    const checkYM = 70;
    const checkYT = 40;
    const checkWidth = "6%";

    return(
      <svg height={svgHeight} width={svgWidth}>
        <line x1={x1 + "%"} x2={x1 + "%"} y1={yB + "%"} y2={yT + "%"} style={{stroke:hashColor, strokeWidth:edgeWidth}} />
        <line x1={x1 + "%"} x2={x2 + "%"} y1={yT + "%"} y2={yT + "%"} style={{stroke:hashColor, strokeWidth:edgeWidth}} />
        <line x1={x2 + "%"} x2={x2 + "%"} y1={yT + "%"} y2={yB + "%"} style={{stroke:hashColor, strokeWidth:edgeWidth}} />
        <line x1={x2 + "%"} x2={x1 + "%"} y1={yB + "%"} y2={yB + "%"} style={{stroke:hashColor, strokeWidth:edgeWidth}} />

        {spiralYArray.map((m_spiralY) =>
          <line key={"spiral" + m_spiralY} x1={spiralX1 + "%"} x2={sprialX2 + "%"} y1={m_spiralY + "%"} y2={m_spiralY + "%"} style={{stroke:hashColor, strokeWidth:sprialWidth}} />
        )}

        {textYArray.map((m_textY) =>
          <line key={"text" + m_textY} x1={textX1 + "%"} x2={textX2 + "%"} y1={m_textY + "%"} y2={m_textY + "%"} style={{stroke:hashColor, strokeWidth:textWidth}} />
        )}

        <line x1={checkX1 + "%"} x2={checkX2 + "%"} y1={checkYM + "%"} y2={checkYB + "%"} style={{stroke:hashColor, strokeWidth:checkWidth}} />
        <line x1={checkX2 + "%"} x2={checkX3 + "%"} y1={checkYB + "%"} y2={checkYT + "%"} style={{stroke:hashColor, strokeWidth:checkWidth}} />
      </svg>
    );
  }
}));

export const SvgCompanySlashPerson = inject("UserMobx")(observer(
class SvgCompanySlashPerson extends Component { //p_sizeEm, p_color, p_plusTF
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);

    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";

    const x2 = 25;
    const x1 = x2 - 20;
    const x3 = x2 + 20;
    const yTop = 5;
    const h = 50;

    const xCenter = 0.75;

    //shoulders
    const e1cx = Math.round(xCenter * widthPx) + "px";
    const e1cy = Math.round(1.05 * widthPx) + "px";
    const e1rx = Math.round(0.3 * widthPx) + "px";
    const e1ry = Math.round(0.25 * widthPx) + "px";

    //head
    const e2cx = Math.round(xCenter * widthPx) + "px";
    const e2cy = Math.round(0.7 * widthPx) + "px";
    const e2rx = Math.round(0.125 * widthPx) + "px";
    const e2ry = Math.round(0.15 * widthPx) + "px";

    return(
      <svg height={svgHeight} width={svgWidth}>
        <rect x={x1 + "%"} y={yTop + "%"} width="5%" height={h + "%"} style={{fill:color}} />
        <rect x={x2 + "%"} y={yTop + "%"} width="5%" height={h + "%"} style={{fill:color}} />
        <rect x={x3 + "%"} y={yTop + "%"} width="5%" height={h + "%"} style={{fill:color}} />
        <rect x={x1 + "%"} y={(yTop + h - 15) + "%"} width="45%" height="20%" style={{fill:color}} />
        <rect x={x1 + "%"} y={((yTop + yTop + h - 15) / 2) + "%"} width="45%" height="5%" style={{fill:color}} />
        <rect x={x1 + "%"} y={(yTop - 3) + "%"} width="45%" height="8%" style={{fill:color}} />

        <ellipse cx={e1cx} cy={e1cy} rx={e1rx} ry={e1ry} style={{fill:color}} />
        <ellipse cx={e2cx} cy={e2cy} rx={e2rx} ry={e2ry} style={{fill:color}} />

        <line x1="20%" x2="95%" y1="95%" y2="5%" style={{stroke:color, strokeWidth:"2%"}} />

        {(this.props.p_plusTF) && [
          <LibraryReact.SvgHLine key="h" p_x1p={75} p_x2p={95} p_ycp={90} p_widthP={7} p_color={"#77c"} />,
          <LibraryReact.SvgVLine key="v" p_y1p={80} p_y2p={100} p_xcp={85} p_widthP={7} p_color={"#77c"} />
        ]}
      </svg>
    );
  }
}));

export const SvgCapture = inject("UserMobx")(observer(
class SvgCapture extends Component { //p_sizeEm, p_color, p_plusTF
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    var color = JSFUNC.prop_value(this.props.p_color, "ffffff");
    color = "#" + color;

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);

    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";

    const xS = 3; //x start of whole drawer
    const xE = 97; //x end of whole drawer
    const wH = 26; //handle width inside of drawer face
    const wHT = 3; //handle thickness for inside edge

    const yS = 31; //y start at top of drawer
    const yE = 98; //y end at bottom of drawer
    const hT = 11; //height from drawer top until top of first handle is reached
    const hH = 22; //height of the top drawer handle
    const hB = 10; //height from bottom of top drawer handle to top of 2nd handle

    const wD = (xE - xS); //width of the drawer face
    const wS = ((wD - wH) / 2); //x side width before reaching handle
    const xH1 = (xS + wS); //x start of handle
    const xH2 = (xH1 + wHT); //x start of inside handle
    const xH4 = (xE - wS); //x end of handle
    const xH3 = (xH4 - wHT); //x end of inside handle

    const hY = (yE - yS); //y height of drawer
    const yH1 = (yS + hT); //y start of handle 1 top
    const yH2 = (yH1 + wHT);
    const yH4 = (yH1 + hH);
    const yH3 = (yH4 - wHT);
    const yH5 = (yH4 + hB);
    const yH6 = (yH5 + wHT);

    const fLW = "3%"; //folder line widths
    const fLC = "round"; //folder line caps

    const f1W = 81;
    const f1H = 20;
    const f1wt = 20; //width of folder 1 tab
    const f1ht = 7; //height of folder 1 tab
    const f1xS = ((100 - f1W) / 2); //left edge of folder 1
    const f1xT = (f1xS + f1wt); //x of tab transition
    const f1xE = (100 - f1xS); //right edge of folder 1
    const f1yE = yS; //bottom of folder 1 is at top of drawer
    const f1yS = f1yE - f1H; //top of folder 1

    const f2W = 73;
    const f2H = 10;
    const f2wt = 18; //width of folder 2 tab
    const f2ht = 5; //height of folder 2 tab
    const f2xS = ((100 - f2W) / 2); //left edge of folder 2
    const f2xT = (f2xS + f2wt); //x of tab transition
    const f2xE = (100 - f2xS); //right edge of folder 2
    const f2yE = f1yS; //bottom of folder 2 is at top of folder 1
    const f2yS = f2yE - f2H; //top of folder 2

    return(
      <svg height={svgHeight} width={svgWidth}>
        <rect x={xS + "%"} y={yS + "%"} width={wS + "%"} height={hY + "%"} style={{fill:color}} />
        <rect x={xH4 + "%"} y={yS + "%"} width={wS + "%"} height={hY + "%"} style={{fill:color}} />
        <rect x={(xH1 - 1) + "%"} y={yS + "%"} width={(wH + 2) + "%"} height={hT + "%"} style={{fill:color}} />
        <rect x={xH2 + "%"} y={yH2 + "%"} width={(xH3 - xH2) + "%"} height={(yH3 - yH2) + "%"} style={{fill:color}} />
        <rect x={(xH1 - 1) + "%"} y={yH4 + "%"} width={(wH + 2) + "%"} height={hB + "%"} style={{fill:color}} />
        <rect x={(xH1 - 1) + "%"} y={yH6 + "%"} width={(wH + 2) + "%"} height={(yE - yH6) + "%"} style={{fill:color}} />

        <line x1={f1xS + "%"} x2={f1xS + "%"} y1={f1yE + "%"} y2={f1yS + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f1xS + "%"} x2={f1xT + "%"} y1={f1yS + "%"} y2={f1yS + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f1xT + "%"} x2={(f1xT + f1ht) + "%"} y1={f1yS + "%"} y2={(f1yS + f1ht) + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f1xT + f1ht + "%"} x2={(f1xE) + "%"} y1={f1yS + f1ht + "%"} y2={(f1yS + f1ht) + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f1xE + "%"} x2={(f1xE) + "%"} y1={f1yS + f1ht + "%"} y2={(f1yE) + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />

        <line x1={f2xS + "%"} x2={f2xS + "%"} y1={f2yE + "%"} y2={f2yS + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f2xS + "%"} x2={f2xT + "%"} y1={f2yS + "%"} y2={f2yS + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f2xT + "%"} x2={(f2xT + f2ht) + "%"} y1={f2yS + "%"} y2={(f2yS + f2ht) + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f2xT + f2ht + "%"} x2={(f2xE) + "%"} y1={f2yS + f2ht + "%"} y2={(f2yS + f2ht) + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />
        <line x1={f2xE + "%"} x2={(f2xE) + "%"} y1={f2yS + f2ht + "%"} y2={(f1yS + f1ht) + "%"} style={{stroke:color, strokeWidth:fLW, strokeLinecap:fLC}} />

        {(this.props.p_plusTF) && [
          <LibraryReact.SvgHLine key="h" p_x1p={70} p_x2p={90} p_ycp={80} p_widthP={6} p_color={"#77c"} />,
          <LibraryReact.SvgVLine key="v" p_y1p={70} p_y2p={90} p_xcp={80} p_widthP={6} p_color={"#77c"} />
        ]}
      </svg>
    );
  }
}));


export const SvgUserActivity = inject("UserMobx")(observer(
class SvgUserActivity extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const sizePx = Math.round(sizeEm * this.props.UserMobx.c_userFontSizePx);
    const svgHeightWidth = sizePx + "px";

    const xyCoords0to100ArrayOfArrays1 = [
      [8,49],[10,39],[18,29],[25,25],[33,30],[27,39],[24,49],[8,49]
    ];
    const polygonPointsString1 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays1, sizePx);

    const xyCoords0to100ArrayOfArrays2 = [
      [32,49],[35,38],[40,29],[50,24],[60,29],[65,38],[68,49],[32,49]
    ];
    const polygonPointsString2 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays2, sizePx);

    const xyCoords0to100ArrayOfArrays3 = [
      [92,49],[90,39],[82,29],[75,25],[67,30],[73,39],[76,49],[92,49]
    ];
    const polygonPointsString3 = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays3, sizePx);

    const ellipseLineWidth = "3%";
    const ellipseLineCap = "round";

    return(
      <svg height={svgHeightWidth} width={svgHeightWidth}>
        <circle cx="25%" cy="14%" r="8%" fill={color} />
        <circle cx="50%" cy="10%" r="10%" fill={color} />
        <circle cx="75%" cy="14%" r="8%" fill={color} />

        <polygon points={polygonPointsString1} style={{fill:color}} />
        <polygon points={polygonPointsString2} style={{fill:color}} />
        <polygon points={polygonPointsString3} style={{fill:color}} />

        <line x1="8%" x2="18%" y1="55%" y2="65%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="18%" x2="40%" y1="65%" y2="68%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="40%" x2="50%" y1="68%" y2="69%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="50%" x2="60%" y1="69%" y2="68%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="60%" x2="82%" y1="68%" y2="65%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="82%" x2="92%" y1="65%" y2="55%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />

        <line x1="34%" x2="36%" y1="55%" y2="76%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="36%" x2="43%" y1="76%" y2="91%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="43%" x2="50%" y1="91%" y2="95%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="50%" x2="57%" y1="95%" y2="91%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="57%" x2="64%" y1="91%" y2="76%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="64%" x2="66%" y1="76%" y2="55%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />

        <line x1="8%" x2="12%" y1="55%" y2="73%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="12%" x2="23%" y1="73%" y2="87%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="23%" x2="50%" y1="87%" y2="95%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="50%" x2="77%" y1="95%" y2="87%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="77%" x2="88%" y1="87%" y2="73%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
        <line x1="88%" x2="92%" y1="73%" y2="55%" style={{stroke:color, strokeWidth:ellipseLineWidth, strokeLinecap:ellipseLineCap}} />
      </svg>
    );
  }
}));


export const SvgCaptureImport = inject("UserMobx")(observer(
class SvgCaptureImport extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const sizePx = Math.round(sizeEm * this.props.UserMobx.c_userFontSizePx);
    const svgHeightWidth = sizePx + "px";

    const binLineWidth = "5%";
    const arrowLineWidth = "13%";
    const binLineCap = "round";
    const arrowLineCap = "round";

    const frontToBackX = 15;
    const frontToDipX = 30;
    const frontToHoleX = 32;

    const x1 = 3;
    const x2 = x1 + frontToBackX;
    const x3 = x1 + frontToDipX;
    const x4 = x1 + frontToHoleX;
    const x8 = 97;
    const x7 = x8 - frontToBackX;
    const x6 = x8 - frontToDipX;
    const x5 = x8 - frontToHoleX;

    const y1 = 97; //bin bottom
    const y2 = 77; //front dip bottom
    const y3 = 67; //front top
    const y4 = 35; //bin back top

    const arrowCenterToEdgeX = 17;

    const ax1 = 50 - arrowCenterToEdgeX;
    const ax2 = 50;
    const ax3 = 50 + arrowCenterToEdgeX;

    const ay1 = 60;
    const ay2 = 45;
    const ay3 = 3;

    return(
      <svg height={svgHeightWidth} width={svgHeightWidth}>
        <line x1={x1 + "%"} x2={x1 + "%"} y1={y1 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x1 + "%"} x2={x3 + "%"} y1={y3 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x3 + "%"} x2={x3 + "%"} y1={y3 + "%"} y2={y2 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x3 + "%"} x2={x6 + "%"} y1={y2 + "%"} y2={y2 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x6 + "%"} x2={x6 + "%"} y1={y2 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x6 + "%"} x2={x8 + "%"} y1={y3 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x8 + "%"} x2={x8 + "%"} y1={y3 + "%"} y2={y1 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x8 + "%"} x2={x1 + "%"} y1={y1 + "%"} y2={y1 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />

        <line x1={x1 + "%"} x2={x2 + "%"} y1={y3 + "%"} y2={y4 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x2 + "%"} x2={x4 + "%"} y1={y4 + "%"} y2={y4 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />

        <line x1={x5 + "%"} x2={x7 + "%"} y1={y4 + "%"} y2={y4 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />
        <line x1={x7 + "%"} x2={x8 + "%"} y1={y4 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:binLineWidth, strokeLinecap:binLineCap}} />

        <line x1={ax2 + "%"} x2={ax2 + "%"} y1={ay3 + "%"} y2={ay1 + "%"} style={{stroke:color, strokeWidth:arrowLineWidth, strokeLinecap:arrowLineCap}} />
        <line x1={ax2 + "%"} x2={ax1 + "%"} y1={ay1 + "%"} y2={ay2 + "%"} style={{stroke:color, strokeWidth:arrowLineWidth, strokeLinecap:arrowLineCap}} />
        <line x1={ax2 + "%"} x2={ax3 + "%"} y1={ay1 + "%"} y2={ay2 + "%"} style={{stroke:color, strokeWidth:arrowLineWidth, strokeLinecap:arrowLineCap}} />
      </svg>
    );
  }
}));


export const SvgWrench = inject("UserMobx")(observer(
class SvgWrench extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const sizePx = Math.round(sizeEm * this.props.UserMobx.c_userFontSizePx);
    const svgHeightWidth = sizePx + "px";

    const xyCoords0to100ArrayOfArrays = [
      [12,3],[24,11],[25,16],[23,22],[18,27],
      [12,26],[2,21],[2,27],[6,35],[15,42],
      [19,43],[28,42],[32,45],[78,96],[84,98],
      [89,97],[94,94],[97,89],[98,85],[97,80],
      [94,76],[43,31],[41,28],[41,15],[40,11],
      [37,7],[33,4],[28,2],[21,1]
    ];
    const polygonPointsString = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(xyCoords0to100ArrayOfArrays, sizePx);

    return(
      <svg height={svgHeightWidth} width={svgHeightWidth}>
        <polygon points={polygonPointsString} style={{fill:color}} />
      </svg>
    );
  }
}));

export const SvgExecutiveManagement = inject("UserMobx")(observer(
class SvgExecutiveManagement extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    const color = JSFUNC.prop_value(this.props.p_color, "999999");

    const sizePx = (sizeEm * this.props.UserMobx.c_userFontSizePx);
    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);
    const colorWithHash = "#" + color;

    const pieRadius = 25;

    const barHeightsArray = [7, 10, 18, 25, 45, 40, 70];
    const numBars = barHeightsArray.length;
    const barLeftToBarLeftWidth0to100 = (96 / numBars);
    const barWidth0to100 = (barLeftToBarLeftWidth0to100 - 1);
    const halfBarWidth0to100 = (barWidth0to100 / 2);
    var barLeftsArray = [];
    var barCentersArray = [];
    for(let b = 0; b < numBars; b++) {
      var barLeft = (2 + (b * barLeftToBarLeftWidth0to100));
      barLeftsArray.push(barLeft);
      barCentersArray.push(barLeft + halfBarWidth0to100);
    }

    return(
      <svg height={heightPx + "px"} width={widthPx + "px"} viewBox="0 0 100 100">
        <LibraryReact.CircleGraphSegment0to180deg p_radius0to100={pieRadius} p_angleDeg={180} p_rotationDeg={0} p_color={color} />
        <LibraryReact.CircleGraphSegment0to180deg p_radius0to100={pieRadius} p_angleDeg={135} p_rotationDeg={90} p_color={color} />
        <LibraryReact.CircleGraphSegment0to180deg p_radius0to100={pieRadius - 2} p_angleDeg={90} p_rotationDeg={225} p_color={color} />
        <LibraryReact.CircleGraphSegment0to180deg p_radius0to100={pieRadius - 2} p_angleDeg={42} p_rotationDeg={320} p_color={color} />
        {barHeightsArray.map((m_barHeight, m_index) =>
          <React.Fragment key={m_barHeight}>
            <rect x={barLeftsArray[m_index]} y={(100 - m_barHeight)} width={barWidth0to100} height={m_barHeight} style={{fill:colorWithHash}} />
            {(m_index > 0) &&
              <line x1={barCentersArray[m_index]} x2={barCentersArray[m_index - 1]} y1={(85 - barHeightsArray[m_index])} y2={(85 - barHeightsArray[m_index - 1])} style={{stroke:colorWithHash, strokeWidth:"0.5em"}} />
            }
          </React.Fragment>
        )}
      </svg>
    );
  }
}));


export const SvgTodo = inject("UserMobx")(observer(
class SvgTodo extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);

    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";

    const boardWidth = 70;
    const clipboardHeight = 95;
    const clipWidth = 30;
    const clipHeight = 15;
    const cbLW = 2.5;
    const cbLC = "round";

    const x1 = 50 - (boardWidth / 2);
    const x4 = 50 + (boardWidth / 2);
    const x2 = 50 - (clipWidth / 2);
    const x3 = 50 + (clipWidth / 2);

    const y1 = 50 - (clipboardHeight / 2);
    const y4 = 50 + (clipboardHeight / 2);
    const y2 = y1 + (clipHeight / 2);
    const y3 = y1 + clipHeight;

    const lx1 = 35;
    const lx2 = 75;
    const ly1 = 35;
    const lySpace = 12;
    const lLW = 1.2;

    return(
      <svg height={svgHeight} width={svgWidth}>
        <line x1={x1 + "%"} x2={x1 + "%"} y1={y4 + "%"} y2={y2 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x1 + "%"} x2={x2 + "%"} y1={y2 + "%"} y2={y2 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x2 + "%"} x2={x2 + "%"} y1={y1 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x2 + "%"} x2={x3 + "%"} y1={y1 + "%"} y2={y1 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x2 + "%"} x2={x3 + "%"} y1={y3 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x3 + "%"} x2={x3 + "%"} y1={y1 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x3 + "%"} x2={x4 + "%"} y1={y2 + "%"} y2={y2 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x4 + "%"} x2={x4 + "%"} y1={y2 + "%"} y2={y4 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />
        <line x1={x1 + "%"} x2={x4 + "%"} y1={y4 + "%"} y2={y4 + "%"} style={{stroke:color, strokeWidth:cbLW, strokeLinecap:cbLC}} />

        <line x1={lx1 + "%"} x2={lx2 + "%"} y1={ly1 + "%"} y2={ly1 + "%"} style={{stroke:color, strokeWidth:lLW, strokeLinecap:cbLC}} />
        <line x1={lx1 + "%"} x2={lx2 + "%"} y1={(ly1 + (1 * lySpace)) + "%"} y2={(ly1 + (1 * lySpace)) + "%"} style={{stroke:color, strokeWidth:lLW, strokeLinecap:cbLC}} />
        <line x1={lx1 + "%"} x2={lx2 + "%"} y1={(ly1 + (2 * lySpace)) + "%"} y2={(ly1 + (2 * lySpace)) + "%"} style={{stroke:color, strokeWidth:lLW, strokeLinecap:cbLC}} />
      </svg>
    );
  }
}));

export const SvgWorkload = inject("UserMobx")(observer(
class SvgWorkload extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);

    const svgHeight = heightPx + "px";
    const svgWidth = widthPx + "px";


    const x1 = 10;
    const x2 = 60;
    const x3 = 95;
    const y1 = 27;
    const y2 = 60;
    const y3 = 95;
    const calendarLW = 2;
    const calendarLC = "round";

    const clockX = 27;
    const clockY = 32;
    const clockR = 25;
    const clockLW = 3;

    const minX = clockX;
    const minY = clockY - (clockR * 0.9);
    const hourX = clockX + (clockR * 0.25);
    const hourY = clockY + (clockR * 0.3);

    return(
      <svg height={svgHeight} width={svgWidth}>
        <line x1={x1 + "%"} x2={x1 + "%"} y1={y2 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:calendarLW, strokeLinecap:calendarLC}} />
        <line x1={x1 + "%"} x2={x3 + "%"} y1={y3 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:calendarLW, strokeLinecap:calendarLC}} />
        <line x1={x3 + "%"} x2={x3 + "%"} y1={y1 + "%"} y2={y3 + "%"} style={{stroke:color, strokeWidth:calendarLW, strokeLinecap:calendarLC}} />
        <line x1={x2 + "%"} x2={x3 + "%"} y1={y1 + "%"} y2={y1 + "%"} style={{stroke:color, strokeWidth:(calendarLW), strokeLinecap:calendarLC}} />

        <circle cx={clockX + "%"} cy={clockY + "%"} r={clockR + "%"} fill="none" style={{stroke:color, strokeWidth:clockLW}} />
        <line x1={clockX + "%"} x2={minX + "%"} y1={minY + "%"} y2={clockY + "%"} style={{stroke:color, strokeWidth:clockLW, strokeLinecap:calendarLC}} />
        <line x1={clockX + "%"} x2={hourX + "%"} y1={clockY + "%"} y2={hourY + "%"} style={{stroke:color, strokeWidth:clockLW, strokeLinecap:calendarLC}} />

        {[0,1,2].map((m_col) =>
          [0,1].map((m_row) =>
            ((m_col > 0) || (m_row > 0)) &&
            <rect key={"c" + m_col + "r" + m_row} x={(29 + (m_col * 20)) + "%"} y={(47 + (m_row * 20)) + "%"} width="12%" height="12%" style={{fill:color}} />
          )
        )}
      </svg>
    );
  }
}));


export const SvgOpenCapture = inject("UserMobx")(observer(
class SvgOpenCapture extends Component { //p_sizeEm, p_color
  render() {
    const p_sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const p_color = JSFUNC.prop_value(this.props.p_color, "999999");

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const sizePx = Math.round(p_sizeEm * c_userFontSizePx);
    const svgHeightWidth = sizePx + "px";
    const hashColor = "#" + p_color;

    const outer4CurlsLC = "round";
    const rectHeight0to100 = 55;
    const rectLW = "5%";
    const arrowLW = "12%";

    const rectCenterX = 36;
    const rectCenterY = (100 - rectCenterX);
    const rectX = (rectCenterX - (rectHeight0to100 / 2));
    const rectY = (rectCenterY - (rectHeight0to100 / 2));
    const rectRadius = 4;

    const arrowBaseX = rectCenterX;
    const arrowBaseY = rectCenterY;
    const arrowTipX = 90;
    const arrowTipY = 10;
    const arrowLeftEndX = 65;
    const arrowLeftEndY = 15;
    const arrowRightEndX = (arrowTipX - (arrowLeftEndY - arrowTipY));
    const arrowRightEndY = (arrowTipY + (arrowTipX - arrowLeftEndX));

    return(
      <svg height={svgHeightWidth} width={svgHeightWidth}>
        <rect x={rectX + "%"} y={rectY + "%"} rx={rectRadius + "%"} ry={rectRadius + "%"} width={rectHeight0to100 + "%"} height={rectHeight0to100 + "%"} style={{stroke:hashColor, strokeWidth:rectLW, strokeLinecap:outer4CurlsLC, fill:"transparent"}} />
        <line x1={arrowBaseX + "%"} x2={arrowTipX + "%"} y1={arrowBaseY + "%"} y2={arrowTipY + "%"} style={{stroke:hashColor, strokeWidth:arrowLW, strokeLinecap:outer4CurlsLC}} />
        <line x1={arrowTipX + "%"} x2={arrowLeftEndX + "%"} y1={arrowTipY + "%"} y2={arrowLeftEndY + "%"} style={{stroke:hashColor, strokeWidth:arrowLW, strokeLinecap:outer4CurlsLC}} />
        <line x1={arrowTipX + "%"} x2={arrowRightEndX + "%"} y1={arrowTipY + "%"} y2={arrowRightEndY + "%"} style={{stroke:hashColor, strokeWidth:arrowLW, strokeLinecap:outer4CurlsLC}} />
      </svg>
    );
  }
}));


export const SvgDownloadIcon = inject("UserMobx")(observer(
class SvgDownloadIcon extends Component { //p_sizeEm, p_color
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    var color = JSFUNC.prop_value(this.props.p_color, "999999");
    color = "#" + color;

    const fontSizePx = this.props.UserMobx.c_userFontSizePx;
    const sizePx = sizeEm * fontSizePx;

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx);

    const lineWidth = "10%";
    const lineCap = "round";

    return(
      <svg height={heightPx + "px"} width={widthPx + "px"}>
        <line x1="50%" x2="50%" y1="10%" y2="30%" style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:lineCap}} />
        <line x1="20%" x2="50%" y1="30%" y2="60%" style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:lineCap}} />
        <line x1="50%" x2="80%" y1="60%" y2="30%" style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:lineCap}} />
        <line x1="5%" x2="5%" y1="70%" y2="90%" style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:lineCap}} />
        <line x1="5%" x2="95%" y1="90%" y2="90%" style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:lineCap}} />
        <line x1="95%" x2="95%" y1="70%" y2="90%" style={{stroke:color, strokeWidth:lineWidth, strokeLinecap:lineCap}} />
      </svg>
    );
  }
}));

export const SvgTriangleWithNumber = inject("UserMobx")(observer(
class SvgTriangleWithNumber extends Component { //p_sizeEm, p_color, p_number
  render() {
    const sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 2);
    const color = JSFUNC.prop_value(this.props.p_color, "999");
    const number = this.props.p_number;

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const sizePx = (sizeEm * c_userFontSizePx);
    const hashColor = "#" + color;
    const number1Decimal = JSFUNC.number_to_fixed_decimals(number, 1);

    const heightPx = Math.round(sizePx);
    const widthPx = Math.round(sizePx * 1.5);

    var bgColor = hashColor;
    var fontColor = "#333";
    var numberDisplay = number1Decimal;
    var textX = (0.3 * widthPx);
    if(number === -1) {
      bgColor = "#ddd";
      fontColor = "#777";
      numberDisplay = "N/A";
      textX = textX = (0.27 * widthPx);
    }

    const xl = (0.05 * widthPx);
    const xc = (0.5 * widthPx);
    const xr = (0.95 * widthPx);
    const yb = (0.95 * heightPx);
    const yt = (0.05 * heightPx);
    const bgPolyPoints = xl + "," + yb + " " + xc + "," + yt + " " + xr + "," + yb + " " + xl + "," + yb;
    const edgeColor = "#aaa";

    const textY = (0.83 * heightPx);
    const textFontSize = (sizeEm * 0.4) + "em";

    return(
      <svg width={widthPx + "px"} height={heightPx + "px"}>
        <polygon points={bgPolyPoints} style={{fill:bgColor, stroke:edgeColor, strokeWidth:"1"}} />
        <text x={textX + "px"} y={textY + "px"} fill={fontColor} style={{fontSize:textFontSize, fontWeight:"bold"}}>
          {numberDisplay}
        </text>
      </svg>
    );
  }
}));


export const SvgFavoriteIcon = inject("UserMobx")(observer(
class SvgFavoriteIcon extends Component { //p_sizeEm, p_color
  render() {
    const p_sizeEm = JSFUNC.prop_value(this.props.p_sizeEm, 1);
    const p_color = JSFUNC.prop_value(this.props.p_color, "999999");

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const betweenScale0to1 = 0.5;
    const rgbDecimalAdd = -30;
    
    const sizePx = Math.round(p_sizeEm * c_userFontSizePx);

    //XY of 5 points on a circle starting at the top and going clockwise
    const starTipsCircleXY0to1ArraysObj = JSFUNC.calculate_circle_points_xy_0to1_arrays_obj(90, 72, false, 6, 4);
    const starTipsX0to1Array = starTipsCircleXY0to1ArraysObj.x0to1Array; //[0, 0.9511, 0.5878, -0.5878, -0.9511, 0]
    const starTipsY0to1Array = starTipsCircleXY0to1ArraysObj.y0to1Array; //[1, 0.309, -0.809, -0.809, 0.309, 1]

    //XY of 5 points between the main 5 points (rotated 36 degrees)
    const betweenCircleXY0to1ArraysObj = JSFUNC.calculate_circle_points_xy_0to1_arrays_obj((90-36), 72, false, 5, 4);
    const betweenX0to1Array = betweenCircleXY0to1ArraysObj.x0to1Array; //[0.5878, 0.9511, 0, -0.9511, -0.5878]
    const betweenY0to1Array = betweenCircleXY0to1ArraysObj.y0to1Array; //[0.809, -0.309, -1, -0.309, 0.809]

    var starXYCoords0to100ArrayOfArrays = [];
    for(let p = 0; p <= 5; p++) {
      var starTipX0to100 = ((starTipsX0to1Array[p] * 50) + 50);
      var starTipY0to100 = (50 - (starTipsY0to1Array[p] * 50));
      starXYCoords0to100ArrayOfArrays.push([starTipX0to100, starTipY0to100]);
      if(p <= 4) {
        var betweenX0to100 = (((betweenX0to1Array[p] * betweenScale0to1) * 50) + 50);
        var betweenY0to100 = (50 - ((betweenY0to1Array[p] * betweenScale0to1) * 50));
        starXYCoords0to100ArrayOfArrays.push([betweenX0to100, betweenY0to100]);
      }
    }
    const starPolygonPointsString = JSFUNC.svg_polygon_points_string_from_xy_coords_0to100_array_of_arrays(starXYCoords0to100ArrayOfArrays, sizePx);

    const darkColor = JSFUNC.color_add_decimal_rgb_values(p_color, rgbDecimalAdd, rgbDecimalAdd, rgbDecimalAdd, false);

    const svgRadialGradientDefPolygonUniqueFillUrlID = "starRadialGradientFillUrlID" + p_color;

    return(
      <svg height={sizePx + "px"} width={sizePx + "px"}>
        <defs>
          <radialGradient id={svgRadialGradientDefPolygonUniqueFillUrlID} cx="0.8" cy="0.8" r="0.6">
            <stop offset="10%" stopColor={"#" + darkColor} />
            <stop offset="90%" stopColor={"#" + p_color} />
          </radialGradient>
        </defs>
        <polygon points={starPolygonPointsString} style={{stroke:"#bbbbbb", strokeWidth:"0.1em", fill:"url(#" + svgRadialGradientDefPolygonUniqueFillUrlID + ")"}} />
      </svg>
    );
  }
}));
